var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'upcomingEventComponent',
    'my-' + _vm.data.techSettings.rootMarginY,
    'mx-' + _vm.data.techSettings.rootMarginX,
    'text-left'
  ]},[_c('b-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('b-container',{class:[
        'backgroundContainer',
        'py-' + _vm.data.techSettings.rootPaddingY,
        'px-' + _vm.data.techSettings.rootPaddingX,
        _vm.data.techSettings.backgroundStyle,
        _vm.data.techSettings.backgroundColor
      ],attrs:{"fluid":_vm.data.techSettings.fullWidth}},[(_vm.data.aoi || _vm.data.headline || _vm.data.subHeadline)?_c('b-row',{staticClass:"headerContainer"},[_c('b-col',{staticClass:"headerContent"},[(_vm.data.aoi)?_c('p',{staticClass:"aoiContent",domProps:{"innerHTML":_vm._s(_vm.data.aoi)}}):_vm._e(),(_vm.data.headline)?_c('p',{staticClass:"headlineContent h2",domProps:{"innerHTML":_vm._s(_vm.data.headline)}}):_vm._e(),(_vm.data.subHeadline)?_c('p',{staticClass:"subHeadlineContent",domProps:{"innerHTML":_vm._s(_vm.data.subHeadline)}}):_vm._e()])],1):_vm._e(),_c('b-row',{staticClass:"eventContent"},[_c('b-col',{staticClass:"eventsContainer"},[_c('ul',{staticClass:"eventList"},_vm._l((_vm.data.events),function(event,eIndex){return _c('li',{key:eIndex,staticClass:"event"},[(event.date)?_c('b-col',{staticClass:"col-12 dateContainer"},[_c('BIconCalendar2WeekFill',{staticClass:"icon"}),_c('span',{staticClass:"dateContent pl-3",domProps:{"innerHTML":_vm._s(event.date)}})],1):_vm._e(),(event.location)?_c('b-col',{staticClass:"col-12 locationContainer"},[_c('BIconGeoAltFill',{staticClass:"icon"}),_c('span',{staticClass:"locationContent pl-3",domProps:{"innerHTML":_vm._s(event.location)}})],1):_vm._e()],1)}),0)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
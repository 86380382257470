<template>
  <div :class="['googleMapiFrameComponent', data.techSettings.rootClasses]">
    <b-container :fluid="data.techSettings.fullWidth" :class="['px-md-0', 'py-3', data.techSettings.rootContainerClasses]" :style="data.techSettings.frameComponent" >
      <b-container 
        :fluid="data.techSettings.fullWidth"
        class="detailsContainer px-0 mb-3"
      >
        <b-row class="mx-0">
          <b-col class="locationDetails px-0">
            <h3 class="uppercase mb-3 pt-2 vhtml"><span v-if="data.techSettings.campusName" :class="[data.techSettings.campusNameClasses]" v-html="data.techSettings.campusName + '<br/>Campus Location'"></span></h3>
            <b-col class="addressContainer">
              <ul class="pl-0">
                <li>
                  <b-row align-v="center">
                    <b-col class="col-12 px-0 align-items-center flex">
                      <div class="iconContainer mr-2">
                      <BIconGeoAltFill class="icon"></BIconGeoAltFill>
                      </div>
                      <div class="addresses">
                        <div>
                          <span>{{this.campusInfo.address.address1}}</span><span v-if="this.campusInfo.address.address2.length > 1">, {{this.campusInfo.address.address2}}</span>
                          <br>
                          <span>{{this.campusInfo.address.city}}, {{this.campusInfo.address.state}} {{this.campusInfo.address.zip}}</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="mt-2 col-12 px-0 align-items-center flex" style="">
                      <div class="iconContainer mr-2">
                        <BIconCalendar3 class="icon"></BIconCalendar3>
                      </div>
                      <div class="hours">
                        <div><span>{{this.campusInfo.hours.operation}} | Hours</span></div>
                      </div>
                    </b-col>
                  </b-row>
                </li>
              </ul>
            </b-col>
            <div
            v-for="(nestedComponent, index) in data.nestedComponents"
            v-bind:key="index"
          >
            <component
              v-if="nestedComponent.data !== null"
              v-bind:is="nestedComponent.type"
              :data="nestedComponent.data"
              :class="['googleMapiFrameNestedComponent', nestedComponent.classes]"
            ></component>
          </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        :fluid="data.techSettings.fullWidth"
        class="googleMapContainer px-0"
      >
        <b-row class="mx-0">
          <iframe width="1200" height="400" :style="data.techSettings.iframe" style="border:0" loading="lazy" allowfullscreen :src="getEmbedUrl()"></iframe>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { BIconGeoAltFill, BIconCalendar3 } from "bootstrap-vue"
import Grid from "@/components/Layout/Grid";
import Modal from "@/components/Content/Modal";
import Button from "@/components/Content/Button";
import TwoStepForm from "@/components/Form/TwoStepForm";



export default {
  name: "GoogleMapiFrame",
  props: ["data"],
  components: {
    ...mapGetters(["PLACE_IDS", "CAMPUSES"]),
    BIconGeoAltFill,BIconCalendar3,
    Grid,
    Modal,
    Button,
    TwoStepForm
  },
  data() {
    return {
      "embedUrl": "https://www.google.com/maps/embed/v1/place",
      "campusInfo": {},
    }
  },
  created() {
    this.getCampusData(this.data.techSettings.school);
  },
  methods: {
    getGoogleAPIKey() {
      let key = process.env.VUE_APP_GOOGLE_MAP_IFRAME_API_KEY;
      return key;
    },
    getEmbedUrl() {
      let src = `${this.embedUrl}?key=${this.getGoogleAPIKey()}&q=place_id:${this.campusInfo.google.placesId}&zoom=${this.data.zoom}`
      return src;
    },
    getPlaceId(name) {
      const campusData = this.getCampusData(name)
      return campusData.google.placesId
    },
    getCampusData(arg) {
      const campuses = this.$store.getters.CAMPUSES;
      const campus = campuses.filter( c => c.name == arg);
      this.campusInfo = campus[0];
      return campus[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.googleMapComponent {
  .googleMapContainer {
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-bottom: 70%;
    }
  }

  h1, h2, h3 {
    span.highlight-gold,&.highlight-gold {
      padding-left: 10px;
      padding-right: 10px;
    }
    
  }
  span.highlight-gold {

    padding: 3px;
    
  }
}

#map {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
}

.addressContainer {
  ul {
    list-style-type: none;
  }

  .icon-list {
    li,
    .icon,
    .item {
      display: flex;
    }
  }

  .addresses {
    width: 90%;
  }
}

.detailsContainer {
  color: black;
}

p.firstHeading {
  margin: 0 5px;
}

.flex {
  display: flex;
}
</style>
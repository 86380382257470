<template>
  <div>
    <label> Are you current or former U.S. Military, Guard, or Reserve?</label>
    <b-form-checkbox
      name="military-status"
      checked=""
      value="true"
      unchecked-value=""
      @input="check($event)"
      @click="optEvent('engagedIsMilitary')"
    >
      Yes
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: false
    }
  },
  methods: {
    check(event) {
      this.value = event
      this.$emit("send-military-value", event)
      console.log("send=military-value was emitted")
      console.log(this.value)
      console.log(event)
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>

  <template>
    <div class="selectionForm-component">
      <div v-show="firstStepComplete == 1">
        <b-container>
          <b-row>
            <b-col class="pt-4 pb-2">
              <span class="">Choose the <strong>day</strong> and <strong>time</strong> that's best for you:</span>
            </b-col>
          </b-row>
        </b-container>
      <b-container
        :class="[container.classes]"
        v-for="(container, conIndex) in data.containers"
        :key="conIndex"
        :fluid="container.techSettings.fluid"
      >
        <b-row
          v-for="(row, rIndex) in container.rows"
          :key="rIndex"
          :no-gutters="!row.techSettings.noGutters"
          :align-h="row.techSettings.horizontalAlignment"
          :align-v="row.techSettings.verticalAlignment"
          :class="row.classes"
        >
          <b-col
            v-for="(column, cIndex) in mktoObjects"
            :key="cIndex"
            :class="[column.classes, isVisible(column.data.eventDate)]" 
          >
              <!-- <component
                v-if="nestedComponent.data !== null"
                v-bind:is="nestedComponent.type"
                :data="nestedComponent.data"
                :class="['gridColNested', nestedComponent.classes]"
              ></component> -->
              <b-form-group :key="index" v-slot="{ ariaDescribedby }" :id="'card_' + column.data.mktoExe">
                <b-card :class="activeCard !== cIndex ? 'card-border' : 'card-border-active' " @click="firsStepSubmit(cIndex)">
                    <b-card-text>
                      <b-row class="d-flex align-items-center">
                        <b-col cols="2" sm="1">
                          <b-form-radio class="radioArea" v-model="selected" :aria-describedby="ariaDescribedby" :checked="true" name="some-radios" :value="cIndex">
                          </b-form-radio>
                        </b-col>
                        <b-col cols="10" sm="11">
                          <h2 class="day-head mb-3">{{ getEventDay(column.data.eventDate) }} <!--{{ getEventTime(column.data.eventDate) }}--></h2>
                          <span class="day-time">
                            <table>
                              <tr>
                                <th>PDT</th>
                                <th>MDT</th>
                                <th>CDT</th>
                                <th>EDT</th> 
                              </tr>
                              <tr>
                                <th>{{ convertTimes(column.data.eventDate, "pdt") }}</th>
                                <th>{{ convertTimes(column.data.eventDate, "mdt") }}</th>
                                <th>{{ convertTimes(column.data.eventDate, "cdt") }}</th>
                                <th>{{ convertTimes(column.data.eventDate, "edt") }}</th>
                              </tr>
                            </table>
                          </span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-form-group>
          </b-col>
          <p class="mt-3 px-4 disclaimer-text">{{container.disclaimerText}}</p>
        </b-row>
        <component
                v-if="container.reasons.data !== null"
                v-bind:is="container.reasons.type"
                :data="container.reasons.data"
                :class="['gridColNested', container.reasons.classes]"
          ></component>
      </b-container>
      </div>
      <div v-show="firstStepComplete > 1">
        <b-row class="mx-0">
          <b-col cols="12" md="6"
              v-for="(object, index) in this.mktoObjects"
              :key="index"
              :class="`py-3 container content-block gray-theme formOverlap gridColNested`"
              style="max-width:576px;"
              v-show="mktoVisible(index) || false"
            >
              <button @click="previousStep()">&lt; Back</button>
              <form :id="'mktoForm_' + object.data.mktoExe" class="mktoForm mx-auto py-3"></form>
          </b-col>
          <b-col cols="12" md="6">
            <div data-v-4be60cb6="" data-v-41158416="" class="container-fluid white-theme basic-list pt-3 pb-3 gridColNested px-4">
              <div data-v-4be60cb6="" class="row mx-auto" style="max-width: 1140px;">
                <div data-v-4be60cb6="" class="col-12 pl-0 order-lg-1 col order-2">
                  <div data-v-4be60cb6="" class="col order-1 header-container pl-0 col-xs-12">
                    <h2 data-v-4be60cb6="" class="pt-2 font-weight-bold text-left">You'll Get To:</h2>
                  </div>
                  <div data-v-4be60cb6="" class="col order-2 content-container col-12">
                    <ol data-v-4be60cb6="" class="list mt-3 mb-4 pl-3 text-left col-xs-12 col-lg-12">
                      <div data-v-4be60cb6="" class="row row-cols-1 row-cols-md-1">
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">Hear from UTI graduates about their journey and where they work now</p>
                          </li>
                        </div>
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">See how the skilled trades can offer you an exciting in-demand career</p>
                          </li>
                        </div>
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">Learn how our programs can prepare you for the industry in less than a year<sup>1</sup></p>
                          </li>
                        </div>
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">See how UTI can assist with housing, financial aid<sup>2</sup> and more.</p>
                          </li>
                        </div>
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">Listen to expert advice from employers who have hired our graduates</p>
                          </li>
                        </div>
                        <div data-v-4be60cb6="" class="col">
                          <li data-v-4be60cb6="" class="accessory">
                            <p data-v-4be60cb6="">Ask questions on specific opportunities based on your interests</p>
                          </li>
                        </div>
                      </div>
                    </ol>
                  </div>
                  <div data-v-4be60cb6="" class="col paragraph-container">
                    <h3 style="font-size: 18px; line-height: 22px; font-family: Montserrat; font-weight: bold">Attend at no cost!</h3>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <b-container>
          <SingleStepForm :data="data.data" :step="previousStep" :increaseStep="increaseFormStep"/>
          <b-row class="paragraphs-container">
            
            <b-col
              v-for="(form, index) in this.mktoForms"
              :key="index"
              v-html=""
              :class="`paragraph text-${item.textAlign} col-${data.techSettings.xsColumns} col-md-${data.techSettings.mdColumns}`"
            ></form>
            </b-col>
          </b-row> 
        </b-container>-->
      </div>
      <!-- <div v-show="firstStepComplete == 3">
        <b-container style="min-height: 35vh;">
          <h1 class="thankyou-text ">Thanks for Registering!</h1>
          <p class="thankyou-para">Look out for a confirmation email with your event details.</p>
        </b-container>
      </div> -->
    </div>
</template>
<script>
  import { BFormGroup, BFormRadio , BCard } from "bootstrap-vue"
  import SingleStepForm from "./SingleStepForm.vue"
  import BasicList from "@/components/Content/BasicList"
  //import { none } from "html-webpack-plugin/lib/chunksorter"

  export default {
    name: 'SelectionForm',
    props: ["data"],
    data() {
      return {
        firstStepComplete: 1,
        activeCard: '',
        selected: null,
        mktoObjects: [],
        now: new Date()      
      }
    },
    beforeCreate() {
      this.firstStepComplete = 1;
      this.activeCard = '';
    },
    beforeMount() {
      this.getMkto();
    },
    mounted: function() {
      this.loadMkto();
      this.initMkto();
    },
    components: {
      'b-card':BCard,
      'b-form-group':BFormGroup,
      'b-form-radio':BFormRadio,
      SingleStepForm,
      BasicList,
    },
    methods: {
      isVisible(arg) {
        const timeOffset = 24*60*60*1000,
        eventDay = new Date(arg),
        eventExpiration = new Date(new Date().setTime(eventDay.getTime())),
        deltaDays = (eventExpiration - this.now) / timeOffset,
        daysVisible = 11;
        console.log(`eventDay`, eventDay)
        console.log(`eventExpiration`, eventExpiration)
        console.log(`deltaDays`, deltaDays)

        //if event Day is > 10 days in the future - Hide
        if ( deltaDays > daysVisible) {
          console.log("event is more than 10 days in the future");
          return 'futureEvent'
        } 
        //if event day is >= 0 days in the future  - visible
        else if ( deltaDays >= 0){
          console.log("event is less than 10 days but greater than 0 days in the future");
          return 'activeEvent'
        }
        //if event day is > 0 days in the past - expired
        else {
          console.log("event is in the past");
          return 'expiredEvent'
        }

      },
      convertTimes(dateTime,returnTimeZone) {
        const date = new Date(dateTime)
        switch(returnTimeZone.toUpperCase()) {
          case 'PST':
          case 'PDT':
            return date.toLocaleTimeString('en-us', {timeZone: 'America/Los_Angeles', hour:'2-digit', minute: '2-digit'});
          case 'MST': 
          case 'MDT':
            return date.toLocaleTimeString('en-us', {timeZone: 'America/Denver', hour:'2-digit', minute: '2-digit'});
          case 'CST': 
          case 'CDT':
            return date.toLocaleTimeString('en-us', {timeZone: 'America/Chicago', hour:'2-digit', minute: '2-digit'});
          case 'EDT':
          case 'EST': 
            return date.toLocaleTimeString('en-us', {timeZone: 'America/New_York', hour:'2-digit', minute: '2-digit'});
          default:
            return 'error';
        }         
      },
      getEventDay(arg) {
        let day = new Date(arg);
        let options = {
            timeZone: 'America/Phoenix',
            weekday: "long",
            month: "short",  
            day: "numeric"
        };
        return day.toLocaleString("en-us", options)
      },
      getEventTime(arg) {
        let day = new Date(arg);
        let options = {
          hour: "2-digit",
          minute: "2-digit",
        };
        return `@ ${day.toLocaleString("en-us", options)}`
      },
      getMkto() {
          this.data.containers[0].rows[0].columns.forEach(column => {
            let date = column.data.eventDate;
            let result = this.isVisible(date);

            if (result == "activeEvent") {
              this.mktoObjects.push(column)
            } 
        });
      },
      initMkto() {
        this.injectJS('SRC','https://info.uti.edu/js/forms2/js/forms2.min.js');
      },
      loadMkto() {
        let mktoEmbed = '';
        this.mktoObjects.forEach(object => {
          mktoEmbed = mktoEmbed + 'MktoForms2.loadForm("https://info.uti.edu", "198-VVS-594",'+ object.data.mktoExe + ');\n'
        });
        this.injectJS('EXE', mktoEmbed);
      },
      firsStepSubmit (i) { 
        this.activeCard = i
        this.selected = i
        this.increaseFormStep()
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      increaseFormStep () {
        this.firstStepComplete = this.firstStepComplete + 1
      },
      previousStep() {
        this.firstStepComplete = this.firstStepComplete - 1
      },
      isMobile() {
        let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
        // console.log(`current breakpoint: `, currentBreakpoint)
        if (
          currentBreakpoint == "xs" ||
          currentBreakpoint == "sm" ||
          currentBreakpoint == "md"
        ) {
          return true
        } else {
          return false
        }
      },
      injectJS(type, arg) {
      let newScript = document.createElement('script');

      if (type == 'SRC') {
        newScript.src = arg;
        document.head.prepend(newScript);
      } else if ( type == "EXE") {
        window.addEventListener('load', () => {
          newScript.text = arg;
          document.head.prepend(newScript);
        })
      } else return;
      },
      mktoVisible(arg) {
        if (arg == this.selected) {
          return true
        } else {
          return false;
        }
      }
    }
    
  }
</script>

<style lang="scss" scoped>
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
    top: 2.5rem !important;
  }
  .card-border{
    border: 2px solid #969A9C!important;
    cursor: pointer;
  }
  .card-border-active{
    border: 2px solid #0086D2!important;
    background: #F6F6FB;
  }
  .card-border:hover{
    border: 2px solid #0086D2;
    background: #F6F6FB;
  }
  .day-head {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-bottom: 0px;
  }

  .day-timezone {
    font-weight: bold;
  }

  .thankyou-text{
    font-size: 2.875rem;
    color: #25292B;
    margin-bottom: 0%;
    text-align: center;
  }
  .thankyou-para{
    font-size: 1.125rem;
    color:#25292B;
    text-align: center;
  }
  .disclaimer-text{
    font-size: 1.125rem;
    color:#25292B;
  }

  .mktoForm {
    /* */
    &.blue-theme {
      background-color: #003653;
      color: #fff;

      .accessory {
        color: #b2b2b2;
      }
    }

    &.white-theme,
    &.-theme {
      background-color: #fff;
      color: #000;

      .accessory {
        color: #737373;
      }
    }

    &.gray-theme,
    &.grey-theme {
      background-color: #f2f5f7;
      color: #003653;

      .accessory {
        color: #0086d2;
      }
    }


    @media screen and (min-width: 768px) {
      &.formOverlap {
        position: absolute;
        z-index: 99;
      }
    }
  }
  .expiredEvent, .futureEvent {
    display: none!important;
  }

  th {
    padding: 2px 10px;
  }
</style>
<style lang="scss">
  .selectionForm-component {
    .form-group {
      tr:first-child {
        background-color: lightgray!important;
      }

      th {
        border: 1px solid!important;;
        font-weight: normal!important;
        padding: 5px;
      }
    }
  }
</style>
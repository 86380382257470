<template>
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" class="wrench-icon" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_111_803)">
<path d="M53.5167 12.0751C53.2699 11.0763 52.4979 10.2842 51.5065 10.0068C50.5077 9.73156 49.434 10.0068 48.6957 10.743L41.9595 17.4793L37.2925 16.703L36.5141 12.0371L43.2504 5.30082C43.9823 4.57098 44.265 3.49836 43.9887 2.50168C43.7123 1.50289 42.914 0.726641 41.9057 0.47668C36.4498 -0.876484 30.7914 0.689727 26.8036 4.67223C22.8127 8.66633 21.2971 14.4302 22.7304 19.9336L2.34013 40.3196C-0.780693 43.4446 -0.780693 48.5282 2.34013 51.6522C3.8536 53.1657 5.867 54 8.00907 54C10.1511 54 12.1614 53.1657 13.6738 51.6522L34.044 31.2852C39.5316 32.7332 45.3081 31.2092 49.3254 27.1909C53.3132 23.2031 54.8815 17.5521 53.5167 12.0751V12.0751ZM46.9397 24.8052C43.5784 28.1601 38.6678 29.3013 34.0968 27.7825L33.1075 27.4514L11.2881 49.2665C9.5352 51.0194 6.47977 51.0194 4.72688 49.2665C2.92126 47.4588 2.92126 44.5141 4.72688 42.7053L26.56 20.8754L26.2341 19.8903C24.7248 15.3077 25.8586 10.3918 29.1904 7.05793C31.5856 4.66168 34.7454 3.37496 38.0276 3.37496C38.7522 3.37496 39.4841 3.43719 40.2129 3.56586L32.9029 10.8801L34.3594 19.6361L43.1196 21.0937L50.4286 13.7795C51.1374 17.8136 49.8654 21.8784 46.9397 24.8052ZM8.43728 43.875C7.50493 43.875 6.74978 44.6301 6.74978 45.5625C6.74978 46.4948 7.50493 47.25 8.43728 47.25C9.36962 47.25 10.1248 46.4948 10.1248 45.5625C10.1248 44.6301 9.36962 43.875 8.43728 43.875Z" fill="#25292B"/>
</g>
<defs>
<clipPath id="clip0_111_803">
<rect width="54" height="54" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: "wrenchIcon"
}
</script>

<style scoped>
.wrench-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>

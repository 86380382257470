<template>
  <div class="FormAoiSelectForm1" @click="engaged()">
    <b-container fluid>
      <b-container :fluid="data.techSettings.fullWidth" class="px-0">
        <b-row>
          <b-col sm>
            <AoISection :v="$v.form.AOI" v-on:send-updated-aoi="updateAoi($event)" />
          </b-col>
          <b-col md>
            <PersonalInfoFormHeader />
            <FirstNameInput ref="FirstName" v-model="form.FirstName" :v="$v.form.FirstName" />
            <LastNameInput ref="LastName" v-model="form.LastName" :v="$v.form.LastName" />
            <AgeSelect v-on:send-updated-age="updateAge($event)" :v="$v.form.Age" />

            <EducationTypeSelect
              v-if="this.$store.state.formData.Age >= 14 && this.$store.state.formData.Age < 20"
              v-on:send-updated-education="updateEducation($event)"
              :v="$v.form.Education"
            />
            <GradYearInput
              v-if="this.$store.state.formData.Education != null && this.$store.state.formData.Age < 20"
              :v="$v.form.GradYear"
              v-model="form.GradYear"
            />
          </b-col>
          <b-col md>
            <SectionHeader />
            <CountrySelect v-on:send-updated-country="updateCountry($event)" :v="$v.form.Country" />
            <ZipcodeInput v-model="form.ZipCode" :v="$v.form.ZipCode" />
            <PhoneNumberInput v-model="form.PhoneNumber" :Country="this.form.Country" :v="$v.form.PhoneNumber" />
            <EmailAddressInput v-model="form.email" :v="$v.form.email" />
            <MilitaryCheckbox
              v-if="this.$store.state.formData.Age >= 18"
              v-on:send-military-value="updateIsMilitary($event)"
              :v="$v.form.isMilitary"
            />
            <MilitaryReleaseDateInput v-if="this.form.isMilitary == 'true'" v-model="form.MilitarySeparation" :v="$v.form.MilitarySeparation" />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md>
            <Ewc />
          </b-col>
          <b-col md>
            <SubmitButton v-on:submit="submit" :v="$v" />
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <!-- <p>Are there any validation errors for this form?</p>-->

    <!-- <div>{{ this.$v.$invalid }}</div>
    <div>{{this.form.isMilitary}}</div>
    <div>{{ $v.form }}</div> -->
    <!-- <div>{{ Object.keys(this.$v.form) }}</div> -->
  </div>
</template>

<script>
// Info on the template above. :v and $v are used to bind the components value for validation. $v is a global object that can be console logged or displayed on the DOM
//$v contains all the validation that is used for the forms such as required:true/false if it is dirty or not.....etc
//For more info on the $v object a link for vuelidate is here. https://vuelidate.js.org/

///Validation Imports
import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";

//Component Imports
import AoISection from "@/components/Form/aoiSection";
import PersonalInfoFormHeader from "@/components/Header/PersonalInfoFormHeader";
import FirstNameInput from "@/components/Form/FirstNameInput";
import LastNameInput from "@/components/Form/LastNameInput";
import AgeSelect from "@/components/Form/AgeSelect";
import EducationTypeSelect from "@/components/Form/EducationTypeSelect";
import GradYearInput from "@/components/Form/GradYearInput";
import SectionHeader from "@/components/Header/SectionHeader";
import CountrySelect from "@/components/Form/CountrySelect";
import ZipcodeInput from "@/components/Form/ZipcodeInput";
import PhoneNumberInput from "@/components/Form/PhoneNumberInput";
import EmailAddressInput from "@/components/Form/EmailAddressInput";
import MilitaryCheckbox from "@/components/Form/MilitaryCheckbox";
import MilitaryReleaseDateInput from "@/components/Form/MilitaryReleaseDateInput";
import Ewc from "@/components/Form/Ewc";
import SubmitButton from "@/components/Form/SubmitButton";
import { helpers } from "vuelidate/lib/validators";

export default {
  name: "AOISelectForm",
  //mixins: [validationMixin],
  components: {
    AoISection,
    PersonalInfoFormHeader,
    FirstNameInput,
    LastNameInput,
    AgeSelect,
    EducationTypeSelect,
    GradYearInput,
    SectionHeader,
    CountrySelect,
    ZipcodeInput,
    PhoneNumberInput,
    EmailAddressInput,
    MilitaryCheckbox,
    MilitaryReleaseDateInput,
    Ewc,
    SubmitButton
  },
  props: ["data"],
  mounted() {
    this.initBaseFormData();
    this.initializeInquiryPayload();
    this.getEwc();
  },
  data() {
    return {
      // heroData: {},
      // heroImg: null,
      form: {
        AOI: "",
        FirstName: "",
        LastName: "",
        email: "",
        Education: "",
        GradYear: "",
        ZipCode: "",
        Age: null,
        Country: "US",
        PhoneNumber: "",
        isMilitary: false,
        MilitarySeparation: "",
        EWC: ""
      }
    };
  },
  validations() {
    const isMilitaryReleaseDate = helpers.regex("isMilitaryReleaseDate", /^((0[1-9])|(1[0-2]))\/(\d{4})/);

    if (this.form.Age < 18 && this.form.Age != null) {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          Education: { required },
          ZipCode: { required, numeric, maxLength: maxLength(5) },
          Age: { required },
          Country: { required },
          PhoneNumber: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14)
          },
          GradYear: {
            required,
            numeric,
            minLength: minLength(4),
            maxLength: maxLength(4)
          }
        }
      };
    } else if (this.form.Age >= 18 && this.form.Age < 20 && this.form.isMilitary == "true") {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          Education: { required },
          ZipCode: { required },
          Age: { required },
          Country: { required },
          PhoneNumber: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14)
          },
          MilitarySeparation: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(7),
            isMilitaryReleaseDate
          },
          GradYear: { required }
        }
      };
    } else if (this.form.Age >= 18 && this.form.Age < 20 && this.form.isMilitary == false) {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          Education: { required },
          ZipCode: { required },
          Age: { required },
          Country: { required },
          PhoneNumber: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14)
          },
          GradYear: { required }
        }
      };
    } else if (this.form.Age >= 20 && this.form.isMilitary == false) {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          ZipCode: { required },
          Age: { required },
          Country: { required },
          PhoneNumber: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14)
          }
        }
      };
    } else if (this.form.Age >= 20 && this.form.isMilitary == "true") {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          ZipCode: { required },
          Age: { required },
          Country: { required },
          PhoneNumber: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14)
          },
          MilitarySeparation: { required }
        }
      };
    } else {
      return {
        form: {
          AOI: { required },
          FirstName: { required },
          LastName: { required },
          email: { required, email },
          ZipCode: { required, numeric, maxLength: maxLength(5) },
          Age: { required },
          Country: { required },
          PhoneNumber: { required, minLength: minLength(14) }
        }
      };
    }
  },
  methods: {
    initializeInquiryPayload: function() {
      /// lead guid, GA Client Id, UTMs, conent page for conversion, previous page, incoming query string, IP, etc
      this.$store.dispatch("INITIALIZE_INQUIRY_PAYLOAD_ACTION");
    },
    getAge: function () {
      if (this.form.Age < 20) {
        return true;
      } else {
        return false;
      }
    },
    // createLeadGUID: function () {
    //   this.$store.dispatch("CREATE_LEADGUID_ACTION");
    // },
    // getGAClient: function () {
    //   this.$store.dispatch("SET_GACLIENTID_ACTION");
    // },
    // createQueryParams: function (route) {
    //   this.$store.dispatch("SET_QUERY_PARAMS_ACTION", route);
    // },
    updateAoi: function(updatedAoiValue) {
      this.form.AOI = updatedAoiValue;
      this.$store.dispatch("SUBMIT_DATA_LAYER_FORM_START_ACTION");
    },
    updateCountry: function (updatedCountryValue) {
      this.form.Country = updatedCountryValue;
    },
    updateAge: function (updatedAgeValue) {
      this.form.Age = updatedAgeValue;
    },
    updateEducation: function (updatedEducationValue) {
      this.form.Education = updatedEducationValue;
    },
    updateIsMilitary: function (MilitaryValue) {
      if (MilitaryValue == "false") {
        this.form.isMilitary = MilitaryValue;
        this.form.MilitarySeparation = "";
        console.log(this.form.MilitarySeparation);
      } else {
        this.form.isMilitary = MilitaryValue;
        console.log(this.form.isMilitary);
      }
    },
    getEwc() {
      let ewc = document.getElementsByClassName("ewc");
      let concantEwc = ewc[0].innerHTML;
      let finalEwc = encodeURIComponent(concantEwc);
      //this.$store.dispatch("SET_EWC_ACTION", finalEwc);
      this.form.EWC = finalEwc;
    },
    submit: function() {
      /// Form-Submit DL Event
      window.dataLayer.push({
          event: "form-submit",
          formId: this.$store.state.formData.AcquisitionPoint,
          'age': this.form.Age,
          'areaOfInterest': this.form.AOI,
          //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
          //'email': dataPayload.EmailAddresses[0].Addresses,
          //'errorMessage': errMsg,
          //'formName': 'Paid Media Landing Page' + formName,
          'formName': "PMLPv2 AOI Select Form",
          'formType': 'inquiry',
          //'formValidationStatus': "",
          'inquiryDate': new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],

          'isMilitary': this.form.isMilitary,
          'leadId': this.$store.formData.leadId,
          //'numberOfInquiries': inqcount != undefined ? inqcount : "",
          'referralPage': document.referrer,
          //'stepNumber': this.form.currentStep,
          //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
        });

      if (this.$v.$invalid) {
        //  for (let key in Object.keys(this.$v.form)) {
        //  const input = Object.keys(this.$v.form)[key];
        // if (!this.$v.form[input].$error) {
        // console.log("inside focus", this.$refs[input]);
        // console.log(this.$refs[input]);
        // this.$refs[input].focus();
        console.log(`error submitting form`);
        this.$router.push({ path: "/thankyou" });
        // break;
        // }
        //}
      } else {
        console.log(this.form);
        console.log(this.$store.state.formData);
        this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
        this.$router.push({ path: "/thankyou" });
      }
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event);
    }
  }
};
</script>

<template>
<div class="call-to-action container-fluid pr-4 pb-1">
    <h2 class="text-left pt-2 font-weight-bold">{{data.copy}}</h2>
    <ul class="text-left">
        <li v-for="item in data.list" :key="item">
            {{item}}
        </li>
    </ul>   
</div>
</template>

<script>
export default {
    name: "HeroContentSection",
    props: ['data'],
};
</script>

<style lang="scss" scoped>
.call-to-action {
    background-color: #003852;
    color: #fff;
}

::marker {
    color: #fff;
    font-size: 1.2em;
}
</style>

export default {
  actions: {
    SET_OPTIMIZELY_ACTION: (context, payload) => {
      //console.log("working", payload)
      window["optimizelyEdge"] = window["optimizelyEdge"] || []
      window["optimizelyEdge"].push({
        type: "event",
        eventName: `${payload}`
      })
    },
  }
}

<template>
  <div :class="['googleMapComponent', data.techSettings.rootClasses]">
    <b-container fluid class="px-md-0 py-3">
      <b-container
        :fluid="data.techSettings.fullWidth"
        class="detailsContainer px-0 mb-3"
      >
        <b-row class="mx-0">
          <b-col class="locationDetails px-0">
            <h3 class="uppercase mb-3 pt-2">Campus Location</h3>
            <b-col class="addressContainer">
              <ul class="pl-0">
                <li>
                  <b-row align-v="center">
                    <div v-for="(geoCode, index) in geoCodes" :key="index">
                      <div class="iconContainer mr-2">
                        <BIconGeoAltFill class="icon"></BIconGeoAltFill>
                      </div>
                      <div
                        class="addresses"
                        v-html="geoCode.data.results[0].formatted_address"
                      ></div>
                    </div>
                  </b-row>
                </li>
              </ul>
            </b-col>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        :fluid="data.techSettings.fullWidth"
        class="googleMapContainer px-0"
      >
        <b-row class="mx-0">
          <b-col id="map"> </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
//import { mapGetters } from "vuex"
import axios from "axios"
import { BIconGeoAltFill } from "bootstrap-vue"

export default {
  name: "GoogleMap",
  props: ["data"],
  components: {
   // ...mapGetters(["GOOGLE_API_KEY"]),
    BIconGeoAltFill,
  },
  data() {
    return {
      map: null,
      mapCenter: { lat: 0, long: 0 },
      initialized: !!window.google,
      resolveInitPromise: null,
      rejectInitPromise: null,
      CALLBACK_NAME: "gmapsCallback",
      geoCodes: {},
      placeData: {},
      errors: []
    }
  },
  methods: {
    getGoogleAPIKey() {
      let key = process.env.VUE_APP_GOOGLE_API_KEY
      return key
    },
    initPromise() {
      //using gmaps with vue: https://markus.oberlehner.net/blog/using-the-google-maps-api-with-vue/
      return new Promise((resolve, reject) => {
        this.resolveInitPromise = resolve
        this.rejectInitPromise = reject
      })
    },
    googleMapsInit() {
      // If Google Maps already is initialized
      // the `initPromise` should get resolved
      // eventually.
      if (this.initialized) return this.initPromise

      this.initialized = true
      // The callback function is called by
      // the Google Maps script if it is
      // successfully loaded.
      window[this.CALLBACK_NAME] = () => this.resolveInitPromise(window.google)

      // We inject a new script tag into
      // the `<head>` of our HTML to load
      // the Google Maps script.
      const script = document.createElement("script")
      script.async = true
      script.defer = true
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.getGoogleAPIKey()}&libraries=places&callback=${
        this.CALLBACK_NAME
      }`
      script.onerror = this.rejectInitPromise
      document.querySelector("head").appendChild(script)
      //console.log(`store api key`, this.getGoogleAPIKey())
      return this.initPromise()
    },
    formatAddress(arg) {
      let splitComma = arg.split(",")
      const address = splitComma[0]
      const city = splitComma[1]
      const stateZip = splitComma[2]
      return `<p class="mb-0">${address}</p><p class="mb-0">${city}, ${stateZip}</p>`
    }
  },
  created() {
    // get place ids: https://developers.google.com/maps/documentation/places/web-service/place-id
    //get location data
    //console.log(`Calling Axios.get`)
    axios
      .post(this.data.placeIDs.map((id) => {return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${id}&key=${this.getGoogleAPIKey()}`)}))
      .then((response) => {
        //console.log(`Received Axios... First Then`)
        //console.log(response)

        this.geoCodes = response;
        // JSON responses are automatically parsed.
        this.geoCodes.forEach(element => {
          element.data.results[0].formattedAddress = this.formatAddress(element.data.results[0].formatted_address)
        });

        //console.log(`geoCodes: `, this.geoCodes)
        return this.googleMapsInit()
      })
      .then((google) => {
        //console.log(`Returned googleMapsInit... Second Then`)
        //console.log(google)

        const infowindow = new google.maps.InfoWindow(); // Only one InfoWindow
        const bounds = new google.maps.LatLngBounds();
        //create map
        
        const map = new google.maps.Map(document.getElementById("map"), {
          disableDefaultUI: true
        })

        const service = new google.maps.places.PlacesService(map)

        //create a map markers / pins
        const markers = this.geoCodes.map((x) => {
          let results = x.data.results[0];
          let location = results.geometry.location;
          let request = { placeId: results.place_id };
          
          //get places name and store it, set click events
          service.getDetails(request, ((response, status) => {
            //console.log(response, status);
            results.additionalData = response;
            results.additionalData.status = status;
            const contentString =
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<strong><p id="firstHeading" class="firstHeading uppercase my-0 text-center" style="margin: 0 5px;"><strong>${results.additionalData.name}</strong></p></strong>` +
              "</div>" +
              "</div>";

            google.maps.event.addListener(marker, 'click', () => {
              infowindow.close(); // Close previously opened infowindow
              infowindow.setContent(contentString);
              infowindow.setOptions({disableAutoPan: false});
              infowindow.open(map, marker);
            })
          }));
          
          //initialize markers
          const marker = new google.maps.Marker({
              position: location,
              map,
              animation: google.maps.Animation.DROP
          })

          //set boundaries of map via markers
          bounds.extend(marker.position);
          return marker;
        });

        //load initial window
        service.getDetails({ placeId: this.geoCodes[0].data.results[0].place_id }, ((response) => {
         // console.log(response)
          const contentString =
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<strong><p id="firstHeading" class="firstHeading uppercase my-0 text-center" style="margin: 0 5px;"><strong>${response.name}</strong></p></strong>` +
              "</div>" +
              "</div>";

          infowindow.setContent(contentString);
          infowindow.setOptions({disableAutoPan: true})
          infowindow.open({
              anchor: markers[0],
              map
            })
        }))

        if (this.data.placeIDs.length == 1) {
          map.setCenter(this.geoCodes[0].data.results[0].geometry.location)
          map.setZoom(this.data.zoom)
        } else {
          map.fitBounds(bounds);
        }
          
        
      //  console.log(`geoCodes: `, this.geoCodes)
      })
      .catch((e) => {
        console.log(`axios error`, e)
        this.errors.push(e)
        
      })
  }
}
</script>

<style lang="scss" scoped>
.googleMapComponent {
  .googleMapContainer {
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-bottom: 70%;
    }
  }
}

#map {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
}

.addressContainer {
  ul {
    list-style-type: none;
  }

  .icon-list {
    li,
    .icon,
    .item {
      display: flex;
    }
  }

  .addresses {
    width: 90%;
  }
}

.detailsContainer {
  color: black;
}

p.firstHeading {
  margin: 0 5px;
}
</style>
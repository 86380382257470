<template>
  <b-container fluid>
    <h1>404 Not Found</h1>
  </b-container>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-row class="col-12 py-2 pt-4">
      <h5 class="text-muted mb-0 pb-0 font-weight-light">
        {{ sectionHeader }}
      </h5>
    </b-row>
    <hr color="grey" class="mt-0" />
    <p class="text-left">
      All your information is kept confidential and not shared with others
    </p>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  data() {
    return {
      sectionHeader: "Contact Information",
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.85rem;
}
</style>

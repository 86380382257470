<template>
  <div>
    <b-form-group
      label="Email Address"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-input
        type="text"
        name="email"
        placeholder="Email Address"
        @input="v.$touch()"
        @focus="optEvent('engagedEmail')"
        v-model.lazy="email"
        :class="{ isInvalidInput: v.$error }"
        ref="email"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "EmailAddressInput",
  props: {
    value: {
      type: String,
      default: undefined
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    email: {
      get() {
        return this.value
      },
      set(value) {
        this.v.$touch()
        this.$emit("input", value)
      }
    }
  },
  methods: {
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'imageBlockContainer',
    'my-' + _vm.data.techSettings.rootMarginY,
    'mx-' + _vm.data.techSettings.rootMarginX,
    _vm.data.techSettings.rootClasses
  ],attrs:{"id":_vm.data.techSettings.rootID}},[_c('b-container',{class:['px-0', ((_vm.data.techSettings.backgroundColor) + "-theme")],style:('background-color:'+_vm.data.techSettings.backgroundColorHex),attrs:{"fluid":""}},[_c('b-container',{attrs:{"fluid":_vm.data.techSettings.fullWidthContainer}},[(_vm.data.headline || _vm.data.subHeadline || _vm.data.body)?_c('b-row',{staticClass:"headerContent"},[_c('b-col',{staticClass:"text-center",staticStyle:{"padding-bottom":"40px"}},[(_vm.data.headline)?_c('span',{staticClass:"vhtml headline",domProps:{"innerHTML":_vm._s(_vm.data.headline)}}):_vm._e(),(_vm.data.subHeadline)?_c('span',{staticClass:"vhtml subHeadline h3",domProps:{"innerHTML":_vm._s(_vm.data.subHeadline)}}):_vm._e(),(_vm.data.body)?_c('span',{staticClass:"vhtml body",domProps:{"innerHTML":_vm._s(_vm.data.body)}}):_vm._e()])],1):_vm._e(),_c('b-row',{class:['imageBlockRow', _vm.data.techSettings.type],attrs:{"cols":_vm.data.techSettings.type == 'square'
            ? _vm.data.techSettings.blockSettings.mobileCols
            : '',"cols-md":_vm.data.techSettings.type == 'square'
            ? _vm.data.techSettings.blockSettings.desktopCols
            : '',"align-h":_vm.data.techSettings.type == 'square'
            ? _vm.data.techSettings.blockSettings.horizontalAlignment
            : ''}},_vm._l((_vm.data.blocks),function(block,index){return _c('b-col',{key:index,class:[
            'imageBlockWrapper',
            block.blockContent ? 'hasBlockContent' : ''
          ]},[_c('div',{staticClass:"imageBlock backgroundImage",on:{"click":function($event){(block.data && block.data.modalData) ? _vm.boxClick(block.data.modalData.id, index) : function () {}}}},[_c('b-row',{class:['imageBlockSquareSpacer',("" + (_vm.data.techSettings.squareSpacerClasses))],style:(_vm.getResponsibleImageAnchorStyles())},[(block.backgroundType == 'video')?_c('video',{class:['videoembed', block.backgroundImageClass, 'video-'+block.backgroundVideoPosition],attrs:{"poster":block.posterUrl ? block.posterUrl : null,"autoplay":"","muted":"","loop":"","playsinline":"","preload":"auto"},domProps:{"muted":true}},[_c('source',{attrs:{"src":block.backgroundVideo,"type":"video/mp4"}})]):_vm._e(),(block.backgroundType == 'gif')?_c('img',{class:['imageBlockImage', block.backgroundImageClass],attrs:{"src":block.backgroundGif}}):_vm._e(),(block.backgroundType == 'image')?_c('img',{class:['imageBlockImage', block.backgroundImageClass],attrs:{"src":block.backgroundImage,"alt":block.backgroundImageAlt}}):_vm._e(),(_vm.data.techSettings.overlay.enabled)?_c('div',{class:[
                  'imageBlockOverlay',
                  block.backgroundType,
                  block.backgroundOverlayClass,
                  _vm.data.techSettings.overlay.formExpands ? 'formExpands': null,
                  _vm.getOverlayClass(),
                  { hoverChange: _vm.data.techSettings.overlay.hoverChange }
                ]}):_vm._e(),(block.blockContent.nestedComponents)?_c('div',{class:[
                  'imageBlockNestedContent',
                  'nested',
                  'align-self-' + block.blockContent.alignment || 'top',
                  'nested',
                  block.blockContent.nestedComponentClasses
                ],style:(block.blockContent.style)},_vm._l((block.blockContent.nestedComponents),function(item,index){return _c('div',{key:index},[_c(item.type,{tag:"component",staticClass:"blockNested",attrs:{"data":item.data}})],1)}),0):_vm._e(),(
                  block.blockContent.headline ||
                  block.blockContent.subHeadline ||
                  block.blockContent.body ||
                  block.blockContent.button
                )?_c('div',{staticClass:"imageBlockContent align-self-middle text-center"},[(block.blockContent.headline)?_c('p',{staticClass:"vhtml h2",domProps:{"innerHTML":_vm._s(block.blockContent.headline)}}):_vm._e(),(block.blockContent.subHeadline)?_c('p',{staticClass:"vhtml h3",domProps:{"innerHTML":_vm._s(block.blockContent.subHeadline)}}):_vm._e(),(block.blockContent.body)?_c('p',{staticClass:"vhtml body",domProps:{"innerHTML":_vm._s(block.blockContent.body)}}):_vm._e(),(
                    block.blockContent.button &&
                    block.blockContent.button.cta &&
                    block.blockContent.button.url
                  )?_c('a',{staticClass:"btn ltr",attrs:{"href":block.blockContent.button.url}},[_vm._v(_vm._s(block.blockContent.button.cta))]):_vm._e(),(block.blockUrl)?_c('a',{staticClass:"imageBlockLink",attrs:{"href":block.blockUrl,"target":"_blank"}},[_vm._v(_vm._s(block.blockUrl)+" s")]):_vm._e()]):_vm._e()])],1),(block.data && block.data.modalData)?_c('Modal',{attrs:{"data":block.data,"selectedAOI":'AU',"carousel":_vm.data.techSettings.carousel ? true : false,"carouselData":_vm.carouselObject,"carouselIndex":_vm.carouselIndex}}):_vm._e(),(block.bottomContent)?_c('b-row',{staticClass:"imageBlockBottomContent text-center"},[(block.bottomContent.headline)?_c('p',{staticClass:"vhtml h2",domProps:{"innerHTML":_vm._s(block.bottomContent.headline)}}):_vm._e(),(block.bottomContent.subHeadline)?_c('p',{staticClass:"vhtml h3",domProps:{"innerHTML":_vm._s(block.bottomContent.subHeadline)}}):_vm._e(),(block.bottomContent.body)?_c('p',{staticClass:"vhtml body",domProps:{"innerHTML":_vm._s(block.bottomContent.body)}}):_vm._e()]):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
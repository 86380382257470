<template>
  <div
    :class="[
      'upcomingEventComponent',
      'my-' + data.techSettings.rootMarginY,
      'mx-' + data.techSettings.rootMarginX,
      'text-left'
    ]"
  >
    <b-container class="px-0" fluid>
      <b-container
        :class="[
          'backgroundContainer',
          'py-' + data.techSettings.rootPaddingY,
          'px-' + data.techSettings.rootPaddingX,
          data.techSettings.backgroundStyle,
          data.techSettings.backgroundColor
        ]"
        :fluid="data.techSettings.fullWidth"
      >
        <b-row
          class="headerContainer"
          v-if="data.aoi || data.headline || data.subHeadline"
        >
          <b-col class="headerContent">
            <p class="aoiContent" v-if="data.aoi" v-html="data.aoi"></p>
            <p
              class="headlineContent h2"
              v-if="data.headline"
              v-html="data.headline"
            ></p>
            <p
              class="subHeadlineContent"
              v-if="data.subHeadline"
              v-html="data.subHeadline"
            ></p>
          </b-col>
        </b-row>
        <b-row class="eventContent">
          <b-col class="eventsContainer">
            <ul class="eventList">
              <li
                class="event"
                v-for="(event, eIndex) in data.events"
                :key="eIndex"
              >
                <b-col class="col-12 dateContainer" v-if="event.date">
                  <BIconCalendar2WeekFill class="icon"></BIconCalendar2WeekFill>
                  <span class="dateContent pl-3" v-html="event.date"></span>
                </b-col>
                <b-col class="col-12 locationContainer" v-if="event.location">
                  <BIconGeoAltFill class="icon"></BIconGeoAltFill>
                  <span
                    class="locationContent pl-3"
                    v-html="event.location"
                  ></span>
                </b-col>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { BIconCalendar2WeekFill, BIconGeoAltFill } from "bootstrap-vue"
export default {
  name: "UpcomingEvents",
  props: ["data"],
  components: {
    BIconCalendar2WeekFill,
    BIconGeoAltFill
  }
}
</script>

<style lang="scss" scoped>
.upcomingEventComponent {
  .headerContent {
    & span {
      display: block;
    }
  }
  .backgroundContainer {
    &.grid {
      background-image: url("https://cdn.uti.edu/PMLP-rev.uti.edu/b-pattern-blue-diagonal.png");
    }
    &.blue {
      background-color: #0f83d1;
      color: white;
    }
    background-size: 2%;
  }
  .headerContainer {
    .aoiContent {
      text-transform: uppercase;
    }
  }
  .eventsContainer {
    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li.event {
      border-bottom: white 1px solid;
      padding: 1.25rem 0;
      .col:not(:last-child) {
        margin-bottom: 1rem;
      }

      .dateContainer,
      .locationContainer {
        padding-left: 0;
      }
    }
    li.event:last-child {
      border-bottom: none;
    }
  }
}
</style>
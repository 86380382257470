<template>
  <div :class="'navbar-container '">
    <b-navbar
      :style="{ backgroundColor: data.color.background }"
      :fixed="isSticky"
    >
      <b-container :fluid="data.techSettings.fullWidth" class="">
        <b-row class="mx-0" align-h="between">
          <b-col class="logo-container px-0" cols="3">
            <img class="ml-0 mr-0 pl-0 pr-0" :src="data.logo" :alt="data.alt || 'This is a logo'"/>
          </b-col>
          <b-col class="cto-container px-0 my-auto" cols="8">
            <div
              class="cto-headline d-none d-md-block"
              :style="{ color: data.color.headline }"
            >
              {{ data.headline }}
            </div>
            <div class="cto-number">
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderMobile }"
                v-if="
                  data.cto.mobile &&
                  (activeBreakpoint('xs') || activeBreakpoint('sm'))
                "
                ><b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.mobile }}</a
              >
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderDesktop }"
                v-else
                ><b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.desktop }}</a
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { BIconTelephoneFill, BNavbar } from "bootstrap-vue"
import { mapGetters } from "vuex"
export default {
  name: "TopNavDynamic",
  props: ["data"],
  components: {
    BIconTelephoneFill,
    'b-navbar': BNavbar,
    ...mapGetters(["CURRENT_BREAKPOINT"])
  },
  computed: {
    isSticky() {
      if (this.data.techSettings) {
        let stickyMobile = this.data.techSettings.stickyMobile ? "top" : ""
        let stickyDesktop = this.data.techSettings.stickyDesktop ? "top" : ""

        if (window.innerWidth >= 576) {
          return stickyDesktop
        } else {
          return stickyMobile
        }
      } else {
        return ""
      }
    },
    isTransparent() {
      if (this.topNav.techSettings.transparent) {
        return true
      } else {
        return ""
      }
    }
  },
  methods: {
    activeBreakpoint(arg) {
      if (this.$store.getters.CURRENT_BREAKPOINT == arg) {
        return true
      } else {
        return false
      }
    },
    engaged() {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedCallButton")
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 50px;
  width: 109.89px;
}

nav .row {
  width: 100%;
}

.navbar-expand {
  width: 100%;
}

.navbar-container {
  width: 100%;
  height: 66px;
  &.transparentDrop {
    position: absolute;
    .cto-number a {
      color: white;
      border: 1px solid white;
    }
    .cto-headline {
      color: black;
    }
  }
}

.cto-container {
  text-align: right;

  .cto-number a {
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    font-family: Montserrat;
    line-height: 25px;
    text-decoration: none;
  }

  .ctoBorder {
    border: 1px solid;
    padding: 8px;
  }

  @media (min-width: 768px) {
    .cto-number a {
      font-size: 20px;
    }
  }
}
.cto-container.div {
  display: block;
}
</style>
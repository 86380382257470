<template>
  <div :class="'navbar-container '">
    <header>
      <component
      v-if="data.nestedComponents !== undefined"
      v-bind:is="data.nestedComponents.type"
      :data="data.nestedComponents.data"
      :class="['alertNested', data.nestedComponents.rootClasses]"
      ></component>
    </header>
    
    <!-- <b-navbar
      :style="{ backgroundColor: data.color.background }"
      :fixed="isSticky"
    >
      <b-container :fluid="data.techSettings.fullWidth" class="">     
        <b-row class="mx-0" align-h="between">
          <b-col class="cto-container px-0 my-auto" :class="data.cto.classes" cols="12">
            <a v-for="(data,index) in data.tabsContents" :href="data.link" :key="index">{{ data.title }}</a>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar> -->
    <div>
      <b-navbar toggleable="lg" type="dark" class="py-3" :style="{ backgroundColor: data.color.background }"
      :fixed="isSticky">
      <p class="text-white d-md-none d-block m-0" style="font-size: 14px; line-height: 14px;">Overview</p>
        <b-navbar-toggle class="nav-toggle" target="nav-collapse">
          <template #default="{ expanded }">
            <div v-if="expanded">
              <img src="https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/caret-down-icon.svg" class="icon"  />
            </div>
            <div v-else>
              <img src="https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/caret-down-icon.svg"  />
            </div>
            <!-- <b-icon-chevron-up v-if="expanded"></b-icon-chevron-up>
            <b-icon-chevron-down v-else></b-icon-chevron-down> -->
          </template>
        </b-navbar-toggle>
        <b-container>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="w-100 d-flex justify-content-between flex-md-row flex-column">
            <a class="nav-links" v-for="(item,index) in data.tabsContents" :href="item.link" :class="data.techSettings.linkClass" :key="index">{{item.title}}</a>
          </b-navbar-nav>

        </b-collapse>
      </b-container>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { BIconTelephoneFill, BNavbar, BNavbarToggle } from "bootstrap-vue"
import GlobalAlert from "@/components/Header/GlobalAlert"

export default {
  name: "TabComponent",
  props: ["data"],
  components: {
    // BIconChevronDown,
    // BIconChevronUp,
    BIconTelephoneFill,
    'b-navbar': BNavbar,
    'b-navbar-toggle': BNavbarToggle,
    GlobalAlert,
    ...mapGetters(["CURRENT_BREAKPOINT"])
  },
  computed: {
    isSticky() {
      if (this.data.techSettings) {
        let stickyMobile = this.data.techSettings.stickyMobile ? "top" : ""
        let stickyDesktop = this.data.techSettings.stickyDesktop ? "top" : ""

        if (window.innerWidth >= 576) {
          return stickyDesktop
        } else {
          return stickyMobile
        }
      } else {
        return ""
      }
    },
    isTransparent() {
      if (this.topNav.techSettings.transparent) {
        return true
      } else {
        return ""
      }
    }
  },
  methods: {
    activeBreakpoint(arg) {
      if (this.$store.getters.CURRENT_BREAKPOINT == arg) {
        return true
      } else {
        return false
      }
    },
    engaged() {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedCallButton")
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-dark button{
  border: none;
  outline: none;
}
.nav-toggle > span {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z' fill='rgba(255,235,235,1)'/%3E%3C/svg%3E") !important;
   background-size: 13rem;
}
.nav-toggle{
  span{
    // background-image: url('https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/caret-down-icon.svg') !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E") !important;
  }
  // background-color: red;
}
img {
  // height: 50px;
  // width: 109.89px;
  width: 12px !important;
    height: 8px !important;
  
}
.icon{
  transform: rotate(180deg);
  }

img.fullWidthLogo {
  width: 100%;
  object-fit: contain;
}

.nav-links{
  font-size: 14px;
}

nav .row {
  width: 100%;
}

.navbar-expand {
  width: 100%;
}

.navbar-container {
  width: 100%;
  //height: 66px;
  &.transparentDrop {
    position: absolute;
    .cto-number a {
      color: white;
      border: 1px solid white;
    }
    .cto-headline {
      color: black;
    }
  }
}

.cto-container {
  text-align: right;

  .cto-number a {
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    font-family: Montserrat;
    line-height: 25px;
    text-decoration: none;
  }

  .ctoBorder {
    border: 1px solid;
    padding: 8px;
  }
 

  @media (min-width: 768px) {
    .cto-number a {
      font-size: 20px;
    }
  }
}

@media (min-width: 992px) {
  img.fullWidthLogo {
    width: auto;
  }
}
.cto-container.div {
  display: block;
}
</style>
import { render, staticRenderFns } from "./calendarIcon.vue?vue&type=template&id=7857374b&scoped=true&"
import script from "./calendarIcon.vue?vue&type=script&lang=js&"
export * from "./calendarIcon.vue?vue&type=script&lang=js&"
import style0 from "./calendarIcon.vue?vue&type=style&index=0&id=7857374b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7857374b",
  null
  
)

export default component.exports
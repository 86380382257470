<template>
    <div class="g-recaptcha" id="g-recaptcha" :data-sitekey="getGoogleSiteKey()" data-callback="recaptchaCallback" data-expired-callback="expiredCallback"></div>
</template>

<script>
    import axios from "axios"
    import config from "@/config/index"

    export default {
        name: "GoogleRecaptchaV2",
        props: ["data"],
        data() {
            return {
                valid: false
            }
        },
        beforeCreate: function() {
            const script = document.createElement('script');
            script.setAttribute('src','https://www.google.com/recaptcha/api.js');
            document.head.append(script);
        },
        methods: {
            getGoogleSiteKey() {
                let key = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY
                return key
            },
            validate(bool) {
                this.valid = bool;
                this.$emit('success', this.valid);
            },
            recaptchaCallback: function(res) {
                axios.post(`${config.api}/recaptcha/validate`,{response: res})
                .then((response) => {
                    //console.log(response)
                    const success = response.data.success;                  
                    this.validate(success);
                })
                .catch(error => {
                    console.log(error);
                })
           },
           expiredCallback: function() {
               this.validate(false);
           }
        },
        mounted: function() {
            window.recaptchaCallback = this.recaptchaCallback;
            window.expiredCallback = this.expiredCallback;
        }
    }
</script>

<style lang="scss" scoped>

</style>


<template>
<div>
    <img :src="`${data.img}`" />
</div>
</template>

<script>
export default {
    name: "HeroImage",
    props: ["data"]
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>

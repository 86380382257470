var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:[
    "video-component",
    ((_vm.data.techSettings.backgroundColor) + "-theme"), 
    _vm.data.techSettings.rootClasses
  ],attrs:{"fluid":""}},[_c('b-container',{attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('b-row',{staticClass:"content-container",attrs:{"cols":_vm.data.techSettings.xsColumns,"cols-md":_vm.data.techSettings.mdColumns}},[(_vm.data.videoSettings.type == 'wistia')?_c('b-col',{class:[
          "video-container wistia-video",
          _vm.data.techSettings.videoContainerClasses
          ]},[_c('div',{staticClass:"video-responsive-padding"},[_c('div',{staticClass:"video-responsive-wrapper"},[_c('iframe',{staticClass:"wistia_embed responsive-video",attrs:{"src":("//fast.wistia.net/embed/iframe/" + (_vm.data.videoSettings.id) + "?playButton=false"),"allowtransparency":"true","frameborder":"0","scrolling":"no","name":"wistia_embed","allowfullscreen":"","mozallowfullscreen":"","webkitallowfullscreen":"","oallowfullscreen":"","msallowfullscreen":"","autoPlay":_vm.data.techSettings.autoPlay,"fitStrategy":"cover"},on:{"click":function($event){return _vm.optEvent('engagedVideo')}}})])])]):(_vm.data.videoSettings.type == 'youtube')?_c('b-col',{staticClass:"text-center video-container youtube-video py-3 pt-md-0 mx-auto"},[_c('div',{staticClass:"video-responsive-padding"},[_c('div',{staticClass:"video-responsive-wrapper"},[_c('iframe',{staticClass:"responsive-video",attrs:{"id":"ytplayer","type":"text/html","src":("https://www.youtube.com/embed/" + (_vm.data.videoSettings.id)),"frameborder":"0"},on:{"click":function($event){return _vm.optEvent('engagedVideo')}}})])])]):_c('b-col',{staticClass:"text-center video-container html-video py-3 pt-md-0 mx-auto"},[_c('div',{staticClass:"video-responsive-padding"},[_c('div',{staticClass:"video-responsive-wrapper"},[_c('video',{ref:"videoPlayer",staticClass:"responsive-video",attrs:{"controls":"","preload":"none","poster":_vm.data.videoSettings.posterUrl},on:{"click":function($event){return _vm.videoClick($event)},"touchstart":function($event){return _vm.videoClick($event)}}},[_c('source',{attrs:{"src":("" + (_vm.data.videoSettings.html5Url)),"type":"video/mp4"}})])])])])],1),(_vm.data.vid)?_c('b-row',{staticClass:"p-0 mt-3"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container
    fluid
    :class="[
      `video-component`,
      `${data.techSettings.backgroundColor}-theme`, 
      data.techSettings.rootClasses
    ]"
  >
    <b-container :fluid="data.techSettings.fullWidth">
      <b-row
        class="content-container"
        :cols="data.techSettings.xsColumns"
        :cols-md="data.techSettings.mdColumns"
      >
        <!--Wistia-->
        <b-col
          v-if="data.videoSettings.type == 'wistia'"
          :class="[
            `video-container wistia-video`,
            data.techSettings.videoContainerClasses
            ]"
        >
          <div class="video-responsive-padding">
            <div class="video-responsive-wrapper">
              <iframe
                :src="`//fast.wistia.net/embed/iframe/${data.videoSettings.id}?playButton=false`"
                allowtransparency="true"
                frameborder="0"
                scrolling="no"
                class="wistia_embed responsive-video"
                name="wistia_embed"
                allowfullscreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen
                :autoPlay='data.techSettings.autoPlay'
                fitStrategy="cover"
                @click="optEvent('engagedVideo')"
              ></iframe>
            </div>
          </div>
        </b-col>
        <!--Youtube-->
        <b-col
          v-else-if="data.videoSettings.type == 'youtube'"
          class="text-center video-container youtube-video py-3 pt-md-0 mx-auto"
        >
          <div class="video-responsive-padding">
            <div class="video-responsive-wrapper">
              <iframe
                class="responsive-video"
                id="ytplayer"
                type="text/html"
                :src="`https://www.youtube.com/embed/${data.videoSettings.id}`"
                frameborder="0"
                @click="optEvent('engagedVideo')"
              ></iframe>
            </div>
          </div>
        </b-col>
        <!--HTML5-->
        <b-col
          v-else
          class="text-center video-container html-video py-3 pt-md-0 mx-auto"
        >
          <div class="video-responsive-padding">
            <div class="video-responsive-wrapper">
              <video
                class="responsive-video"
                controls
                @click="videoClick($event)"
                @touchstart="videoClick($event)"
                preload="none"
                :poster="data.videoSettings.posterUrl"
                ref="videoPlayer"
              >
                <source
                  :src="`${data.videoSettings.html5Url}`"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.vid" class="p-0 mt-3"> </b-row>
    </b-container>
  </b-container>
</template>
<script v-if="data.videoSettings.type='wistia'" :src="`//fast.wistia.com/embed/medias/${data.videoSettings.id}.jsonp`" async></script>
<script v-if="data.videoSettings.type='wistia'" src="//fast.wistia.com/assets/external/E-v1.js" async></script> 
<script>
export default {
  name: "Video",
  props: ["data"],
  mounted() {
    //intersection observer -- video lazy loading
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    let observer = new IntersectionObserver(this.handleIntersect, options);
    observer.observe(this.$refs.videoPlayer);
  },
  methods: {
    handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.readyState !== 4) {
          entry.target.load();
          observer.unobserve(el);
        }
      });
    },
    videoPlacement() {
      let settings = this.data.techSettings
      if (settings.mdColumns !== "1" && settings.videoPlacement == "left") {
        return 1
      }
    },
    videoClick(e) {
      e.preventDefault();
      this.$refs.videoPlayer.paused ? this.$refs.videoPlayer.play() : this.$refs.videoPlayer.pause()
      this.optEvent('clickVideo')
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>
<style lang="scss" scoped>
.video-component {
  &.blue-theme {
    background-color: #003653;
    color: #fff;
    .accessory {
      &::marker {
        color: #b2b2b2;
      }
    }
  }
  &.white-theme,
  &.-theme {
    background-color: #fff;
    color: #000;
    .accessory {
      &::marker {
        color: #737373;
      }
    }
  }
  &.gray-theme,
  &.grey-theme {
    background-color: #f2f5f7;
    color: #003653;
    .accessory {
      &::marker {
        color: #0086d2;
      }
    }
  }
}
strong {
  font-weight: 700;
  font-size: 18px;
}
video {
  width: 100%;
}
// p {
//   margin-bottom: 0;
//   line-height: 24px;
//   font-size: 20px;
//   font-weight: 400;
// }
// h2 {
//   font-family: Tungsten-Semibold;
//   font-size: 36px;
//   margin-bottom: 15px;
//   font-weight: 400;
// }
.video-responsive-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.video-responsive-padding {
  padding: 56.25% 0 0 0;
  position: relative;
}
.responsive-video {
  width: 100%;
  height: 100%;
}
.video-container {
  max-width: 500px;
}
</style>
<template>
<b-container fluid>
<div class='row d-flex justify-content-center pb-5'>
<div class='col-lg-6 px-0 '  v-for="(item,index) in data.content" :key="index">
<b-card
    overlay
    :img-src="item.image"
    img-alt="Card Image"
    
  >
    <b-card-text>
    <div class='d-flex justify-content-center '>
    <button type="button" class="btn career-btn btn-outline-light py-2 px-5">{{item.button_text}}</button>
    </div>
    </b-card-text>
  </b-card>
</div>
</div>
</b-container>
</template>

<script>
import {  BCard, BCardText } from "bootstrap-vue"
export default {
    name:"ImageGrid",
    props: ["data"],
    components: {
      BCard,
      BCardText
    }
}
</script>
<style>
.card-img{
    height:35vh;
}
.card-body{
    display:flex;
    align-items: end;
    justify-content: center;
}

</style>
<template>
  <b-container
    fluid
    :class="'copyright ' + copyright.backgroundColor + '-theme'"
  >
    <b-row class="copyright-spacer m-0">
      <b-col class="col-4">
        <p :class="getAlignment + ' copyright-content mb-0'">
          © {{ new Date().getFullYear() }} {{ copyright.text }}
        </p>
      </b-col>
      <b-col class ="col-4">
        <div>
           <p class="text-center" @click="showModal">Do Not Sell My Personal Information</p>
                <b-modal id="modal-scoped">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>Do Not Sell My Personal Information</h5>
    </template>

    <template #default>
      <p>If you wish to opt-out of the sharing of your personal information with third parties, we will promptly honor your request when you click “Opt-Out.”  If you do not opt-out of sharing, this information may be used for analytics and to personalize your experience with tailored UTI ads on our partners’ platforms.  
                   For more information, please see our <a :class="'privacy-content mb-0'" href="https://www.uti.edu/privacy-policy" target="_blank">
          Privacy Policy
        </a></p>
    </template>

    <template #modal-footer >
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="primary" @click="optOutOk">
        OPT-OUT
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel">
        Cancel
      </b-button>
      <!-- Button with custom close trigger value -->
    </template>
  </b-modal>
      </div>
      </b-col>
      <b-col class="col-4 text-center">
        <a :class="'privacy-content mb-0'" href="https://www.uti.edu/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue"
export default {
  name: "Copyright",
  props: ["copyright"],
  components:[BModal],
  data() {
      return {
        show:false,
        optOut:false
      }
  },
  directives: { 
            'b-modal': VBModal 
        },
  computed: {
    getAlignment() {
      if (this.copyright.align) {
        let alignment = this.copyright.align;

        if (alignment == "left") {
          return "left-align";
        } else if (alignment == "center") {
          return "center-align";
        } else if (alignment == "right") {
          return "right-align";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show("modal-scoped")

    },
    optOutOk ()  {
      this.optOut=true
      this.$bvModal.hide("modal-scoped")
      //console.log(this.optOut)
      this.$store.dispatch("SET_CAPI_OPTOUT_ACTION", this.optOut)
    },
    cancel(){
      this.$bvModal.hide("modal-scoped")
    }
  }
};
</script>

<style lang="scss" scoped>
.copyright {
  background-color: #003653;
  color: #fff;
  padding: 15px;
  font-size: 12px;

  &.white-theme {
    background: white;
    color: black;
    & a{
      color: black!important;
    }
  }

  &.blue-theme,
  &.-theme {
    background: #003653;
    color: white;
    & a{
      color: white!important;
    }
  }

  &.grey-theme,
  &.gray-theme {
    background: #f2f5f7;
    color: #003653;
    & a{
      color: #003653!important;
    }
  }

  & .left-align {
    margin-right: auto;
  }

  & .center-align {
    margin-left: auto;
    margin-right: auto;
  }

  & .right-align {
    margin-left: auto;
  }
}
</style>
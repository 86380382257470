<template>
    <div :class="`${rootFluidClass}  container-fluid`">
        <b-button :class="data.classes" :variant="data.variant" @click="btnClick('CTAModal')">{{data.CTA}}<img
                v-if="data.chevron"
                src='https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Solid-Chevron.svg' /></b-button>
    </div>
</template>

<script>
import { BButton } from "bootstrap-vue"
export default {
    name: "Button",
    props: ["data", "chevron"],
    components: {
        BButton
    },
    methods: {
        btnClick: function () {
            const type = this.data.type;

            if (type === "scroll") {
                console.log("scroll");
                const selector = this.data.selector;
                let element = document.querySelector(selector)
                element.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
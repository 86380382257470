<template>
  <b-container fluid :class="`disclaimer ${data.techSettings.backgroundColor}-theme text-justify p-3 ${data.techSettings.rootClasses} footer-disclaimer`">
    <b-container :fluid="data.techSettings.fullWidth" class="px-0" v-if="data.disclaimers[0]">
      <span v-if="data.prepend" v-html="data.prepend"></span>
      <ul v-if="data.techSettings.listType == 'ul'" :class="['my-0', { hideListStyle: data.techSettings.hideListStyle }]">
        <li v-for="(item, index) in data.disclaimers" :key="index">
          <sup>{{index + 1}}</sup> <span v-html="item"></span>
        </li>
      </ul>
      <ol v-else-if="data.techSettings.listType == 'ol'" :class="['my-0', { hideListStyle: data.techSettings.hideListStyle }]">
        <li v-for="(item, index) in data.disclaimers" :key="index">
          <sup>{{index + 1}}</sup> <span v-html="item"></span>
        </li>
      </ol>
      <span v-else v-for="(item, index) in data.disclaimers" :key="index" class="inline-disclaimer">
        <sup>{{index + 1}}</sup> <span v-html="item"></span>
      </span>
      <span v-if="data.append" class="ml-2" v-html="data.append"></span>
    </b-container>
    <b-container :fluid="data.techSettings.fullWidth" v-else-if="data.techSettings.listType == 'ul' && data.disclaimers[0] !== ``">
      <span v-if="data.prepend" v-html="data.prepend"></span>
      <span v-for='(item, index) in data.disclaimers' :key='index'>
        <sup>{{index + 1}}</sup> <span v-html="item"></span>
      </span>
      <span v-if="data.append" class="ml-3" v-html="data.append"></span>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Disclaimer",
  props: ["data"]
};
</script>

<style lang="scss" scoped>
span{
  word-wrap: break-word;
    white-space: normal;
}
.footer-disclaimer{
      max-width: 100%;
    overflow: hidden;
}
.disclaimer {
  font-size: 0.6rem;
  line-height: 0.85rem;

  .inline-disclaimer {
    sup {
      margin-left: .5rem;
    }
  }

  ol, ul{
    padding-left: 8px;
  }

  &.white-theme,
  &.-theme {
    background: white;
    color: black;

    span >>> a,
    li >>> a,
    a {
      color: #007bff;
    }
  }

  &.blue-theme {
    background: #003653;
    color: white;
  }

  &.grey-theme,
  &.gray-theme {
    background: #f2f5f7;
    color: #003653;

    span >>> a,
    li >>> a,
    a {
      color: #007bff;
    }
  }

  &.blue-gradient-theme,
  &.gradient-blue-theme {
    background: linear-gradient(180deg, #003852 0, #0086d2 100%);
    color: #fff;
  }
  span >>> a,
  li >>> a,
  a {
    color: white;
    text-decoration: underline;
  }

  .hideListStyle {
    list-style: none;
  }
}
</style>

<template>
<svg width="57" height="46" viewBox="0 0 57 46" fill="none" class="money-icon" xmlns="http://www.w3.org/2000/svg">
<path d="M28.5 12.825C23.7779 12.825 19.95 17.2906 19.95 22.8C19.95 28.3085 23.7779 32.775 28.5 32.775C33.2203 32.775 37.05 28.3103 37.05 22.8C37.05 17.2906 33.2221 12.825 28.5 12.825ZM28.5 29.925C25.357 29.925 22.8 26.7286 22.8 22.8C22.8 18.8715 25.357 15.675 28.5 15.675C31.643 15.675 34.2 18.8715 34.2 22.8C34.2 26.7286 31.643 29.925 28.5 29.925ZM55.3221 4.85035C51.8673 3.4013 48.4099 2.85001 44.9543 2.85001C33.9845 2.84912 23.0155 8.40216 12.0457 8.40216C6.26466 8.40216 4.17703 7.03594 2.87227 7.03594C1.3395 7.03594 0 8.22226 0 9.86902V38.125C0 39.2498 0.643922 40.3159 1.67794 40.7488C5.13267 42.1987 8.59008 42.75 12.0457 42.75C23.0155 42.75 33.9845 37.197 44.9543 37.197C50.7353 37.197 52.8221 38.5641 54.1277 38.5641C55.6605 38.5641 57 37.3778 57 35.731V7.47502C57 6.34927 56.3561 5.28408 55.3221 4.85035ZM2.93461 9.9091C4.73545 10.4809 6.58884 10.8469 8.51616 11.0571C8.3407 14.0327 5.91286 16.4044 2.91502 16.4392L2.93461 9.9091ZM2.85 38.125L2.86158 34.3363C5.8523 34.3425 8.2837 36.6795 8.50636 39.6293C6.50691 39.3585 4.62056 38.8767 2.85 38.125ZM54.0654 35.6909C52.2699 35.1209 50.4227 34.7549 48.5017 34.5447C48.759 31.6501 51.1459 29.3666 54.085 29.3327L54.0654 35.6909ZM54.093 26.4694C49.6399 26.4997 46.0248 29.9713 45.6695 34.3603C39.3701 34.233 33.4519 35.7693 27.8035 37.1987C21.1176 38.8909 16.5336 39.9784 11.3795 39.859C11.27 35.226 7.50708 31.4863 2.86959 31.4756L2.90611 19.3034C7.41712 19.2731 11.0749 15.7124 11.3475 11.2415C17.629 11.3671 23.5508 9.83161 29.1956 8.40305C35.8566 6.71621 40.4611 5.62252 45.6027 5.74097C45.6187 10.4551 49.4333 14.2856 54.1295 14.2972L54.093 26.4694ZM54.1384 11.4356C51.0889 11.4294 48.613 9.00066 48.4758 5.96897C50.4824 6.23883 52.375 6.72155 54.15 7.47502L54.1384 11.4356Z" fill="#25292B"/>
</svg>
</template>

<script>
export default {
  name: "moneyIcon"
}
</script>

<style scoped>
.money-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
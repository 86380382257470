<template>
  <div>
    <b-row class="text-left pl-3 pr-2 ewc">
      <p>
        By submitting this form, I agree that Universal Technical Institute, Inc., Custom Training Group, Inc., and their representatives may email,
        call, and / or text me with marketing messages about educational programs and services, as well as for school - related communications, at any
        phone number I provide, including a wireless number, using prerecorded calls or automated technology. I understand that my consent is not
        required to apply, enroll or make any purchase.
      </p>
    </b-row>
    <b-row class="text-left pl-3 pr-2 ewc">
      <p>
        By submitting this form, I further understand and agree that all information provided is subject to UTI’s Privacy Policy available at
        <a href="https://www.uti.edu/privacy-policy" @click="optEvent()" target="_blank">www.uti.edu/privacy-policy</a>
        and UTI's SMS Terms and Conditions available at 
        <a href='https://www.uti.edu/sms-terms-conditions' target="_blank" rel="noopener">www.uti.edu/sms-terms-conditions</a>.
      </p>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Ewc",
  mounted() {
    this.getEwc();
  },
  methods: {
    getEwc() {
      let ewc = document.getElementsByClassName("ewc");
      let concantEwc = ewc[0].firstChild.innerHTML + ewc[1].firstChild.innerHTML;
      let finalEwc = encodeURIComponent(concantEwc);
      this.$store.dispatch("SET_EWC_ACTION", finalEwc);
    },
    optEvent() {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedLink");
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedPrivacyPolicy");
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.75rem;
}
</style>

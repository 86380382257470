<template>
  <b-container
    fluid
    :class="
      data.techSettings.backgroundColor +
      '-theme ' +
      'basic-list pt-3 pb-3 uti-component'
    "
  >
    <b-row
      :fluid="data.techSettings.fullWidth"
      align-v="center"
      :style="`${data.techSettings.fullWidth ? '' : 'max-width: 1140px'}`"
      :class="`${data.techSettings.fullWidth ? '' : 'mx-auto'}`"
    >
      <b-col
        :class="`header-container ${
          data.techSettings.contentOrientation == 'left' ||
          data.techSettings.contentOrientation == 'right'
            ? 'col-12 col-lg-5'
            : `col-xs-${data.techSettings.xsColWidth} col-lg-${data.techSettings.mdColWidth}`
        }`"
        :order="`${
          data.techSettings.contentOrientation == 'left' ||
          data.techSettings.contentOrientation == 'center'
            ? 1
            : 2
        }`"
      >
         <h2
         
          :class="`pt-2 program-name font-weight-bold text-${data.techSettings.headerAlignment}`"
        > 
          {{getProgram()}}
        </h2>
        <h3
          v-if="data.proTitle"
          :class="`pt-2 font-weight-bold text-${data.techSettings.headerAlignment}`"
        >
          {{ data.proTitle }}
        </h3>
     
        <p
         
          :class="`text-${data.techSettings.headerAlignment}`"
        >
          {{ getContent() }}
        </p>
      </b-col>
      <b-col
        :class="`content-container ${
          data.techSettings.contentOrientation == 'left' ||
          data.techSettings.contentOrientation == 'right'
            ? 'col-12 col-lg-7'
            : 'col-12'
        }`"
        :order="`${
          data.techSettings.contentOrientation == 'left' ||
          data.techSettings.contentOrientation == 'center'
            ? 2
            : 1
        }`"
      >
        <ul
          v-if="data.techSettings.listType === 'ul'"
          :class="`list mt-3 mb-4 pl-3 text-${data.techSettings.listAlignment} col-xs-${data.techSettings.xsColWidth} col-lg-${data.techSettings.mdColWidth}`"
        >
          <b-row
            :cols="data.techSettings.xsColumns"
            :cols-md="data.techSettings.mdColumns"
          >
            <b-col v-for="(item, index) in getList()" :key="index">
              <li class="accessory">
                <p v-html="item"></p>
              </li>
            </b-col>
          </b-row>
       </ul>
        <p
          v-if="data.paragraph"
          v-html="data.paragraph"
          :class="`bodyBold text-${data.techSettings.paragraphAlignment}`"
        ></p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "ProgramInfo",
  props: ["data"],

  data(){
      return {
          // program: '',
          // programContent: '',
           //defaultAOI: 'AU'
      }
  },
  
  methods: {
    
      getProgram() {
          let program = this.$store.getters.PROGRAM;
          let content = this.$store.getters.AOIS;
    
          let programSelected = content.filter(function(content){
            return content.shortCode === program
          })
        // if(programSelected == "" && defaultAOI !== ""){
        //   return defaultAOI
        // }
        // else{
        //   return programSelected[0].name
        // }
         return programSelected[0].name
      },
      getContent() {
          let program = this.$store.getters.PROGRAM;
          let content = this.$store.getters.AOIS;
    
          let programSelected = content.filter(function(content){
            return content.shortCode === program
          })
        //  if(programSelected == "" && defaultAOI !== ""){
        //   return defaultAOI
        // }
        // else{
        //   return programSelected[0].description
        // }
         return programSelected[0].description
        
  
      },
      getList() {
          let program = this.$store.getters.PROGRAM;
          let content = this.$store.getters.AOIS;
    
          let programSelected = content.filter(function(content){
            return content.shortCode === program
          })

    //  if(programSelected == "" && defaultAOI !== ""){
    //       return defaultAOI
    //     }
    //     else{
    //         return programSelected[0].list
    //     }
          return programSelected[0].list
          
      },
      //    getContent(){
      //     let program = this.$store.getters.PROGRAM;
      //     let content = this.$store.getters.AOIS;
    
      //     let programSelected = content.filter(function(content){
      //       return content.shortCode === program
      //     })
      //     let programStuff = programSelected[0]
      //     return programStuff
      //     // programName = programSelected[0].name
      //     // programDesc = programSelected[0].description
      //     // programList = programSelected[0].list


      //     // if(programName){
      //     //   return programName
      //     // }
      //     // else if(programDesc){
      //     //   return programDesc
      //     // }
      //     // else {
      //     //   return programList
      //     // }  
         
      // }
  }

}

</script>

<style lang="scss" scoped>
.component-name {
  font-weight: bold;
  text-align: center;
}

.blue-theme {
  background-color: #003653;

  h2,
  h4,
  p {
    color: #fff;
  }

  .accessory {
    &::marker {
      color: #b2b2b2;
    }
  }
}

.white-theme {
  background-color: #fff;

  h2,
  h4,
  p {
    color: #000;
  }

  .accessory {
    &::marker {
      color: #737373;
    }
  }
}

.gray-theme,
.grey-theme {
  background-color: #f2f5f7;
  color: #003653;

  h2,
  h4,
  p {
    color: #003653;
  }

  .accessory {
    &::marker {
      color: #0086d2;
    }
  }
}

.program-name{
  color: #003553 !important;
}

li p {
  margin-bottom: 0;
  // line-height: 24px;
  // font-size: 20px;
  // font-weight: 400;
}

// h2 {
//   font-family: Tungsten-Semibold;
//   font-size: 36px;
//   margin-bottom: 15px;
//   font-weight: 400;
// }

// h4 {
//   font-family: Montserrat, sans-serif;
//   font-size: 1.2rem;
//   margin-bottom: 15px;
//   font-weight: 700;
//   letter-spacing: 0.2px;
// }

li {
  font-size: 18px;
  line-height: 22px;
  padding: 7px;
}
</style>

<template>
  <b-container
    :fluid="globalalert.techSettings.fullWidth"
    :class="[
      'globalAlertComponent container-fluid px-0 mx-0',
      { 'uti-component': globalalert.techSettings.padding }, globalalert.techSettings.rootClasses
    ]"
    v-if="isIncluded() && (globalalert.headline || globalalert.subHeadline || globalalert.body)"
  >
    <b-alert
      :class="['alertContent mb-0', globalalert.techSettings.textAlignment]"
      show
      :variant="globalalert.techSettings.variant"
      :dismissible="globalalert.techSettings.dismissible"
      :fade="globalalert.techSettings.dismissible"
    >
      <strong v-if="globalalert.headline"
        ><p v-html="globalalert.headline" class="alertHeadline my-0"></p
      ></strong>
      <p
        v-if="globalalert.subHeadline"
        v-html="globalalert.subHeadline"
        class="alertSubHeadline"
      ></p>
      <p class="alertBody mb-0" v-if="globalalert.body" v-html="globalalert.body"></p>
    </b-alert>
  </b-container>
</template>

<script>

export default {
  name: "Globalalert",
  props: ["globalalert"],
  methods: {
    isIncluded() {
      const query = this.$route.query.expvar;
      
      return !this.globalalert.techSettings.excludedPages.includes(query);
    },
  }
}
</script>

<style lang="scss" scoped>
  .globalAlertComponent {
    &.container-fluid {
      max-width: 100%;
    }

    .alert {
      padding: .75rem;
    }
    .alert-warning {
      color: black;
      background-color: #ffd400;
      border-color: #ffd400;
    }

    .alertHeadline {
      font-size: 14px;
      line-height: 21px;
    }

    &.cro-hide {
      display: none;
    }
  }
</style>
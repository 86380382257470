<template>
<svg width="56" height="45" viewBox="0 0 56 45" fill="none" class="checkmark-icon" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_111_698)">
<path d="M55.7988 12.6525L54.565 11.41C54.2937 11.1388 53.8475 11.13 53.5762 11.41L43.4962 21.1838L38.8325 16.5113C38.5612 16.24 38.115 16.24 37.8437 16.5113L36.6012 17.745C36.33 18.0163 36.33 18.4625 36.6012 18.7338L42.7437 24.8938C43.155 25.305 43.82 25.305 44.2312 24.8938L55.79 13.6413C56.07 13.37 56.07 12.9238 55.7988 12.6525ZM27.44 25.2C24.9288 25.2 23.7212 26.6 19.6 26.6C15.4787 26.6 14.28 25.2 11.76 25.2C5.2675 25.2 0 30.4675 0 36.96V40.6C0 42.9188 1.88125 44.8 4.2 44.8H35C37.3187 44.8 39.2 42.9188 39.2 40.6V36.96C39.2 30.4675 33.9325 25.2 27.44 25.2ZM36.4 40.6C36.4 41.37 35.77 42 35 42H4.2C3.43 42 2.8 41.37 2.8 40.6V36.96C2.8 32.0163 6.81625 28 11.76 28C13.475 28 15.1812 29.4 19.6 29.4C24.01 29.4 25.725 28 27.44 28C32.3837 28 36.4 32.0163 36.4 36.96V40.6ZM19.6 22.4C25.7862 22.4 30.8 17.3863 30.8 11.2C30.8 5.01375 25.7862 0 19.6 0C13.4138 0 8.4 5.01375 8.4 11.2C8.4 17.3863 13.4138 22.4 19.6 22.4ZM19.6 2.8C24.2287 2.8 28 6.57125 28 11.2C28 15.8288 24.2287 19.6 19.6 19.6C14.9712 19.6 11.2 15.8288 11.2 11.2C11.2 6.57125 14.9712 2.8 19.6 2.8Z" fill="#25292B"/>
</g>
<defs>
<clipPath id="clip0_111_698">
<rect width="56" height="44.8" fill="white"/>
</clipPath>
</defs>
</svg>
</template>


<script>
export default {
  name: "checkmarkIcon"
}
</script>

<style scoped>
.checkmark-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
<template>
 <div class='container py-4'>
  <div class='row d-flex justify-content-center' >
    <div class='col-lg-8 d-flex justify-content-center'>
    <div class='row d-flex justify-content-center align-items-center'>
    <div class='col-lg-3'>
    <h6 class='text-dark '>I want to see</h6>
    </div>
    <div class='col-lg-4'>
    <b-form-select v-model="selected">
    <b-form-select-option :value="null"  v-for="(item,index) in data.option" :key="index" >{{item.title}}</b-form-select-option>
    </b-form-select>
    </div>
    <div class='col-lg-4'>
    <h6 class='text-dark '>programs locations</h6>
    </div>
    </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
    name:"SelectOption",
    props: ["data"],
    components: {
     
    },
    data() {
      return {
        selected: null,
        options: [
          { value: null},
        ]
      }
    }
}

</script>
<style lang="scss" scoped>
h6{
  font-size: 18px !important;
    line-height: 22px !important;
     font-family: Montserrat !important;
}

</style>
<template>
    <div :class="['WYSIWYGComponent']">
      <b-container class="px-0 mt-5">
        <div class="row">
            <div class="col-12">
            <div class="">
                <div v-if="form.currentStep == 1">
                    <form
                    action=""
                    :class="[
                        this.$store.getters.CURRENT_BREAKPOINT == 'xs' ||
                        this.$store.getters.CURRENT_BREAKPOINT == 'sm' ||
                        this.$store.getters.CURRENT_BREAKPOINT == 'md'
                        ? 'col-md-12'
                        : 'col-12',
                        '',
                        'py-3',
                        data.techSettings.classes
                    ]"
                        @click="engaged('Form')"
                        @submit.prevent="submit"
                        >
                        <div class="col-md-6 pl-0">
                            <label for="campusSelection"
                            >Campus
                            </label>````````
                            <b-form-select
                                    name="campusSelection"
                                    id="campusSelection"
                                    v-model="dropdownCampusValue"
                                    @blur="this.$v.dropdownCampusValue.$touch()"
                                    @focus="engaged('Campus')"
                                    @change="campusSelected($event, 'Campus')"
                                >
                                    <option disabled value="">Select a Campus</option>

                                    <b-form-select-option-group
                                    v-for="(state, index) in Object.keys(campusData)"
                                    :key="`${index} top`"
                                    :label="`${state}`"
                                    >
                                    <b-form-select-option
                                        v-for="(city, index) in campusData[state]"
                                        :key="`${index} bottom`"
                                        :value="`${state}:${index}`"
                                    >
                                        {{ index }}
                                    </b-form-select-option>
                                    </b-form-select-option-group>
                            </b-form-select>
                            <p v-if="error" class="error">{{ error }}</p>
                        </div>
                        <div>
                          {{ campus }}
                            <p class="mt-4" v-if="dropdownCampusValue == ''">Training at <strong>our campuses</strong></p>
                            <p v-else class="mt-4">Training at <strong>{{dropdownCampusValue}}</strong></p>
                            <p class="mt-2">Change campus to see programs available in other locations.</p>

                            <b-row class="col-12">
                                </b-row>
                                <div class="aoiContainer">
                                <label v-for="item in form.options" v-bind:key="item.value">
                                    <span class="outline-primary">{{ item.text }}</span>
                                    <input
                                    v-on:click="AoiSelected($event, item)"
                                    type="checkbox"
                                    :id="item.value"
                                    :value="item.value"
                                    @change="optEvent('selectedAOI')"
                                    @click="optEvent('engagedAOISelection')"
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="fields-container">
                        <div class="row">
                        <div class="col-6">
                            <label
                            for="firstNameInput"
                            :class="['mr-2', { hasError: $v.form.FirstName.$error }]"
                            >First Name</label
                            >
                            <div>
                            <b-form-input
                                type="text"
                                id="firstNameInput"
                                name="firstName"
                                placeholder="First Name"
                                maxlength="30"
                                v-model.trim="form.FirstName"
                                :class="{ isInvalidInput: $v.form.FirstName.$error }"
                                :state="getContextualState('FirstName', $v)"
                                @keydown="validateCharacter($event)"
                                @blur="$v.form.FirstName.$touch(), formStartEvent()"
                                @focus="engaged('FirstName')"
                            ></b-form-input>
                            </div>
                        </div>
                        <div class="col-6">
                            <label
                            for="lastNameInput"
                            :class="{ hasError: $v.form.LastName.$error }"
                            >Last Name</label
                            >
                            <div>
                            <b-form-input
                                type="text"
                                id="lastNameInput"
                                name="lastName"
                                placeholder="Last Name"
                                maxlength="30"
                                v-model.trim="form.LastName"
                                :class="{ isInvalidInput: $v.form.LastName.$error }"
                                :state="getContextualState('LastName', $v)"
                                @keydown="validateCharacter($event)"
                                @blur="$v.form.LastName.$touch()"
                                @focus="engaged('LastName')"
                            ></b-form-input>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-6">
                            <label
                            for="ageSelection"
                            :class="{ hasError: $v.form.Age.$error }"
                            >Age</label
                            >
                            <div>
                            <select
                                name="age"
                                id="ageSelection"
                                v-model.number="form.Age"
                                :class="{ isInvalidInput: $v.form.Age.$error }"
                                @blur="$v.form.Age.$touch()"
                                @focus="engaged('Age')"
                                @change="selected('Age')"
                            >
                                <option disabled value="">Age</option>
                                <option
                                v-for="(age, index) in 67"
                                :key="index"
                                :value="`${age + 13}`"
                                >
                                {{ age + 13 }}
                                </option>
                                >
                            </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <label
                            for="zipCodeInput"
                            :class="{ hasError: $v.form.ZipCode.$error }"
                            >Zip</label
                            >
                            <div>
                            <b-form-input
                                type="tel"
                                id="zipCodeInput"
                                name="zipCode"
                                placeholder="90210"
                                :minlength="getInputLength('zip', 'min')"
                                :maxlength="getInputLength('zip', 'max')"
                                v-model="form.ZipCode"
                                @keydown="validateNumber($event)"
                                @input="formatZip()"
                                :class="{ isInvalidInput: $v.form.ZipCode.$error }"
                                :state="getContextualState('ZipCode', $v)"
                                @blur="$v.form.ZipCode.$touch()"
                                @focus="engaged('Zip')"
                            ></b-form-input>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div v-if="form.Age !== '' && form.Age < 20" class="col-6">
                            <label
                            for="graduationYearInput"
                            :class="{ hasError: $v.form.GradYear.$error }"
                            >High School <br />Graduation Year</label
                            >
                            <div>
                            <b-form-input
                                type="tel"
                                id="graduationYearInput"
                                name="graduationYearInput"
                                placeholder="YYYY"
                                maxlength="4"
                                v-model.number="form.GradYear"
                                @keydown="validateNumber($event)"
                                :class="{ isInvalidInput: $v.form.GradYear.$error }"
                                :state="getContextualState('GradYear', $v)"
                                @blur="$v.form.GradYear.$touch()"
                                @focus="engaged('HighSchoolGradYear')"
                            ></b-form-input>
                            </div>
                        </div>
                        <div
                            :class="
                            form.Age !== '' && form.Age < 20 ? 'col-6' : 'col-12'
                            "
                        >
                            <label
                            for="countryInput"
                            :class="{ hasError: $v.form.Country.$error }"
                            ><br
                                v-if="form.Age !== '' && form.Age < 20"
                                class="col-6"
                            />Country</label
                            >

                            <div>
                            <select
                                name="countryInput"
                                id="countryInput"
                                v-model="form.Country"
                                :class="{ isInvalidInput: $v.form.Country.$error }"
                                @blur="$v.form.Country.$touch()"
                                @focus="engaged('Country')"
                                @change="selected('Country')"
                            >
                                <option
                                v-for="(country, index) in getCOUNTRIES()"
                                :key="index"
                                :value="`${country.code}`"
                                >
                                {{ country.country }}
                                </option>
                                >
                            </select>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-6">
                            <label
                            for="phoneInput"
                            :class="{ hasError: $v.form.PhoneNumber.$error }"
                            >Phone</label
                            >
                            <div>
                            <b-form-input
                                type="tel"
                                id="phoneInput"
                                name="phone_number"
                                placeholder="( ___ ) ___-____"
                                :class="{ isInvalidInput: $v.form.PhoneNumber.$error }"
                                @blur="$v.form.PhoneNumber.$touch()"
                                v-model="form.PhoneNumber"
                                :minlength="getInputLength('phone', 'min')"
                                :maxlength="getInputLength('phone', 'max')"
                                :state="getContextualState('PhoneNumber', $v)"
                                @focus="engaged('Phone')"
                                @input="formatPhoneNumber()"
                                @keydown="validateNumber($event)"
                            ></b-form-input>
                            </div>
                        </div>
                        <div class="col-6">
                            <label
                            for="emailInput"
                            :class="{ hasError: $v.form.email.$error }"
                            >Email</label
                            >
                            <div>
                            <b-form-input
                                type="email"
                                id="emailInput"
                                name="email"
                                placeholder="____@____.___"
                                maxlength="100"
                                v-model.trim="form.email"
                                :class="{ isInvalidInput: $v.form.email.$error }"
                                :state="getContextualState('email', $v)"
                                @blur="$v.form.email.$touch()"
                                @focus="engaged('Email')"
                            ></b-form-input>
                            </div>
                        </div>
                        </div>
                        <!-- Personal Info -->
                        <div class="row">
                        <div class="col-12" v-if="form.Age >= 18">
                            <input
                            type="checkbox"
                            id="isMilitaryCheckBox"
                            name="isMilitaryCheckBox"
                            class="mr-2"
                            v-model="form.isMilitary"
                            @click="engaged('IsMilitary')"
                            /><label for="isMilitaryCheckBox" style="display: inline"
                            >Are you current or former U.S. Military, Guard, or
                            Reserve?</label
                            >
                        </div>
                        <div class="col-6 mt-2" v-if="form.isMilitary">
                            <label
                            for="militaryReleaseYearInput"
                            :class="{ hasError: $v.form.MilitarySeparation.$error }"
                            >Military Release Date</label
                            >
                            <div>
                            <b-form-input
                                type="tel"
                                id="militaryReleaseYearInput"
                                name="militaryReleaseYearInput"
                                v-model="form.MilitarySeparation"
                                @keydown="validateNumber($event)"
                                @input="formatMilitaryRelease()"
                                placeholder="MM/YYYY"
                                maxlength="7"
                                :class="{
                                isInvalidInput: $v.form.MilitarySeparation.$error
                                }"
                                :state="getContextualState('MilitarySeparation', $v)"
                                @blur="$v.form.MilitarySeparation.$touch()"
                                @focus="engaged('MilitaryReleaseDate')"
                            >
                            </b-form-input>
                            </div>
                        </div>
                        </div>
                        <!-- Google Recaptcha -->
                            
                        <div class="row">
                            <!-- <span :class="{
                                isInvalidInput: $v.form.Recaptcha.$error
                                }">{{$v.form.Recaptcha}}</span>
                            <br/>
                            <span>{{$v.form.PhoneNumber}}</span> -->
                        <div class="pt-3 px-3 pb-0 mx-auto" v-if="data.techSettings.activateRecaptcha !== undefined && data.techSettings.activateRecaptcha == true" :class="{
                                isInvalidInput: $v.form.Recaptcha.$error
                                }">
                            <GoogleRecaptchaV2 @success="setRecaptchaValue($event)"></GoogleRecaptchaV2>
                        </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                    <button
                        :class="`submit-button green-theme my-3 py-2 col-lg-4 ${data.techSettings.submitButtonColor}-theme`"
                        @click="engaged('StepTwoSubmit')"
                        >
                        <span
                            v-if="isMobile()"
                            v-html="data.content.CTA.mobile"
                            ></span>
                            <span v-else v-html="data.content.CTA.desktop"></span>
                        </button>
                    </div>
                    </form>
                </div>

        </div>
    </div>
</div>
</b-container>
<div v-if="form.currentStep == 2">
  <WYSIWYG :data="data.secondStep.data"/>
  <div class="container">
    <div class="row mt-5 justify-content-center">
    <div class="col-lg-3 pl-0 col-12 pr-0 px-1">
      <label for="campusSelection"
      >Campus
      </label>````````
      <b-form-select
              name="campusSelection"
              id="campusSelection"
              v-model="dropdownCampusValue"
              @blur="this.$v.dropdownCampusValue.$touch()"
              @focus="engaged('Campus')"
              @change="campusSelected($event, 'Campus')"
          >
              <option disabled value="">Select a Campus</option>

              <b-form-select-option-group
              v-for="(state, index) in Object.keys(campusData)"
              :key="`${index} top`"
              :label="`${state}`"
              >
              <b-form-select-option
                  v-for="(city, index) in campusData[state]"
                  :key="`${index} bottom`"
                  :value="`${state}:${index}`"
              >
                  {{ index }}
              </b-form-select-option>
              </b-form-select-option-group>
      </b-form-select>
  </div>
  </div>
  </div>
  <div class="booking-section">
    <iframe src="https://outlook.office365.com/owa/calendar/UTILongBeachTourRequest@uticorp.onmicrosoft.com/bookings/" frameborder="0" height="100%" width="100%"></iframe>
  </div>
</div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  import {
    helpers,
    required,
    requiredIf,
    email,
    between,
    maxLength,
    minLength,
    maxValue,
    numeric
} from "vuelidate/lib/validators"
import WYSIWYG from '../Content/WYSIWYG.vue'

let militaryDate = helpers.regex('test',/^(0?[1-9]|1[0-2])\/(19|2[0-1])?\d{2}$/)
const getRecaptchaValue = ((value, data) => {
  // console.log(`getRecaptchaValue data: `, data)
  if (!data.hasRecaptcha) {
    return true;
  }
  else {
    return data.recaptchaValue
  }
})

const usPhoneLength = (value, data) => {
  if (data.Country == "US") {
    return minLength(16);
  } else return minLength(0);
}
  export default {
    name: "CampusTourForm",
    props: ["data"],
    components:{
      WYSIWYG
    },
    data() {
        return {
            campusData: "",
            current: "",
            error:"",
            form: {
                AOI: "",
                FirstName: "",
                LastName: "",
                email: "",
                Education: "",
                GradYear: "",
                ZipCode: "",
                Age: "",
                Country: "",
                PhoneNumber: "",
                isMilitary: false,
                MilitarySeparation: "",
                currentStep: 2,
                EWC: "",
                campusOfInterestNameId: "",
                campusOfInterestName: "",
                options: [
                    { text: "Automotive", value: "AU" },
                    { text: "Diesel", value: "DI" },
                    { text: "Welding", value: "WELD" },
                    { text: "Aviation", value: "AV" },
                    { text: "Airframe and Powerplant", value: "AP" },
                    { text: "Wind Power", value: "WP" },
                    { text: "Energy", value: "ET" },
                    { text: "Industrial Maintenance", value: "IM" },
                    { text: "Robotics and Automation", value: "RA" },
                    { text: "HVACR", value: "HVAC" },
                    { text: "Collision", value: "CR" },
                    { text: "CNC Machining", value: "CNC" },
                    { text: "NASCAR", value: "NASCAR" },
                    { text: "Marine", value: "MA" },
                    { text: "Motorcycle", value: "MO" },
                    { text: "Undecided", value: "U" },
                ]
            },
            dropdownCampusValue: "",
        }
    },
    mounted() {
        axios
        .get("https://utiedu-apihub-dev.azurewebsites.net/info")
        .then((response) => {
            let campuses = response.data.campus                                                                               
            let dropdownObj = {}

            //filter excluded campuses
            for (let excludedCampus of this.data.techSettings.hiddenCampuses) {
            for (let campus in campuses) {
                let currCampus = campuses[campus];
                if (currCampus.campus_id[excludedCampus]) {
                delete currCampus.campus_id[excludedCampus]
                currCampus.aoi = currCampus.aoi.filter((item) => {
                    return item.school != excludedCampus
                })

                }
                if (currCampus.aoi.length < 1) {
                delete campuses[campus]
                }
            }
            }
            //filter excluded states
            for (let state of this.data.techSettings.hiddenStates) {
            for (let campus in campuses) {
                let currCampus = campuses[campus]
                if (currCampus.state === state) {
                delete campuses[campus]
                }
            }
            }

            //if undecided, filter hidden campuses

            //put state in object
            for (let i in campuses) {
            if (dropdownObj[campuses[i].state]) {
                dropdownObj[campuses[i].state][i] = campuses[i]
            } else {
                dropdownObj[campuses[i].state] = { [i]: campuses[i] }
            }
            }
            //this.campusData = response.data.campus
            this.campusData = dropdownObj

        
        })
    },
    methods: {
      getContextualState: function (x, v) {
      let key = x

      if (v.form[key].$dirty == false) {
        //console.log("not dirty");
        return null
      } else {
        //console.log("dirty");
        //console.log("valid or invalid: ", !v.form[key].$error);
        return !v.form[key].$error
      }
    },
    validateNumber(e) {
      let key = e.key
      let goodKeys = [8, 9, 37, 38, 39, 40, 46]

      if (this.form.Country == "US") {
        //prevents non numbers
        if (!/^\d+/g.test(key)) {
          if (!goodKeys.includes(e.keyCode)) {
            // lets you backspace and use keyboard arrows
            e.preventDefault()
          }
        }
      }
    },
    formatPhoneNumber() {
      let length = null
      let phoneNumber = this.form.PhoneNumber

      if (this.form.Country == "US") {
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        // 2. Clear out a leading 0 or 1
        let formattedNumber = phoneNumber
          .replace(/[\D._\-()]+/g, "")
          .replace(/^[0|1]/, "")
        //console.log(`formattedNumber:`, formattedNumber)

        // 3. Get length of all digits without numeric characters
        length = formattedNumber.replace(/\D/g, "").toString().length

        // 4. Format
        if (length > 0 && length < 4) {
          formattedNumber = "(" + formattedNumber
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 4 && length <= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6)
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6) +
            " - " +
            formattedNumber.substring(6, 10)
          this.form.PhoneNumber = formattedNumber
        }

        // 5. return the phone number
        this.form.PhoneNumber = formattedNumber
      }
    },
    isMobile() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      // console.log(`current breakpoint: `, currentBreakpoint)
      if (
        currentBreakpoint == "xs" ||
        currentBreakpoint == "sm" ||
        currentBreakpoint == "md"
      ) {
        return true
      } else {
        return false
      }
    },
    optimizely(apiName) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
    },
    validateCharacter(e) {
      let key = e.key

      if (/^\d+/g.test(key)) {
        e.preventDefault()
      }
    },
    formatZip() {
      let mask = this.form.ZipCode

      if (this.form.Country == "US") {
        //("country is us");
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        let formattedNumber = mask.replace(/[\D._\-()]+/g, "")

        // 2. Format
        mask = formattedNumber.substring(0, 5)
        //console.log("mask", mask);

        // 3. return the phone number
        this.form.ZipCode = mask
      }
    },
    formStartEvent() {
      this.$store.dispatch("DATA_LAYER_FORM_START_ACTION")
    },
    getInputLength: function (field, type) {
      if (this.form.Country == "US") {
        if (field == "zip") {
          if (type == "min") {
            return 5
          } else if (type == "max") {
            return 5
          }
        } else if (field == "phone") {
          if (type == "min") {
            return 16
          } else if (type == "max") {
            return 16
          }
        }
      }
    },
    getCOUNTRIES() {
      return this.$store.getters.COUNTRIES
    },
    engaged(type) {
      this.optimizely(`engaged${type}`)
    },
    submit() {
      // if (this.$v.$invalid) {
      // 	//  for (let key in Object.keys(this.$v.form)) {
      // 	//  const input = Object.keys(this.$v.form)[key];
      // 	// if (!this.$v.form[input].$error) {
      // 	// console.log("inside focus", this.$refs[input]);
      // 	// console.log(this.$refs[input]);
      // 	// this.$refs[input].focus();
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	console.log(`error submitting form`);
      // 	this.$router.push({ path: "/thankyou" });
      // 	// break;
      // 	// }
      // 	//}
      // } else {
      // 	console.log(this.form);
      // 	console.log(this.$store.state.formData);
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	this.$router.push({ path: "/thankyou" });
      // }
      let currentStep = this.form.currentStep
      event.preventDefault()
      //console.log(`submitting form step ${currentStep}`);
      console.log("this.currentStep",this.currentStep)
      if (currentStep == 1) {
        if (!this.$v.form.AOI.$invalid) {
          currentStep = currentStep + 1
          //console.log("form step 1 submission is valid");
        } else {
          //console.log("form step 1 submission is invalid");
          this.$v.form.AOI.$touch()
        }
      } else if (currentStep == 2) {
        this.$v.$touch()

        /// Form-Submit DL Event
        window.dataLayer.push({
          event: "form-submit",
          formId: this.$store.state.formData.AcquisitionPoint,
          age: this.form.Age,
          areaOfInterest: this.form.AOI,
          campus: this.form.Campus,
          //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
          //'email': dataPayload.EmailAddresses[0].Addresses,
          //'errorMessage': errMsg,
          //'formName': 'Paid Media Landing Page' + formName, 
          formName: "PMLPv2 Two Step Form",
          formType: "inquiry",
          //'formValidationStatus': "",
          inquiryDate: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
          isMilitary: this.form.isMilitary,
          leadId: this.$store.state.formData.inqId,
          //'numberOfInquiries': inqcount != undefined ? inqcount : "",
          referralPage: document.referrer,
          stepNumber: this.form.currentStep
          //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
        })

        if (!this.$v.$invalid) {
          //console.log("form step 2 submission is valid");
          //console.log(`form`, this.form);
          let domain = this.form.email.split('@').pop().toLowerCase();

          if(this.form.email && this.isRestrictedDomain(domain)) { 
           this.$store.dispatch("SET_FORM_COMPLETE", {...this.form,markComplete:true});
           this.$router.push({ path: "/thankyou" });
           return;
          }

          this.$store.dispatch("SET_FORM_DATA_ACTION", this.form)
          this.$router.push({ path: "/thankyou" })
        } else {
          // console.log("form step 2 submission is invalid");
          //console.log(`form invalid`, this.form);
        }
      }
    },
    selected(type) {
      this.optimizely(`selected${type}`)
    },
    campusSelected(e) {
      this.dropdownCampusValue = e
      this.error = ""
    }, 
    AoiSelected(e) {
      if(this.dropdownCampusValue != ''){
        if (this.current) {
        let old = document.getElementById(this.current).parentNode
        old.className = ""
        this.current = e.target.value
        e.target.parentNode.className = "selected"
        } else {
          this.current = e.target.value
          e.target.parentNode.className = "selected"
        }
        this.$emit("send-updated-aoi", e.target.value)
      }else{
        this.error = "Please Select a Campus First"
      }
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  },
  validations: {
    form: {
      AOI: { required },
      FirstName: { required, maxLength: maxLength(30) },
      LastName: { required, maxLength: maxLength(30) },
      Age: { required, between: between(14, 80) },
      ZipCode: { required, minLength: minLength(5), maxLength: maxLength(15) },
      Country: { required, maxLength: maxLength(100) },
      GradYear: {
        required: requiredIf(function () {
          let condition = this.form.Age >= 14 && this.form.Age < 20
          return condition
        }),
        maxValue: maxValue(new Date().getFullYear() + 10),
        minLength: minLength(4),
        maxLength: maxLength(4),
        numeric
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      PhoneNumber: {
        required,
        minLength: usPhoneLength,
        maxLength: maxLength(20)
      },
      MilitarySeparation: {
        required: requiredIf(function () {
          return this.form.isMilitary
        }),
        militaryDate,
        minLength: minLength(7),
        maxLength: maxLength(7)
      },
      
      Recaptcha: {
        // required: requiredIf(function() {
        //   return this.form.hasRecaptcha;
        // }),
        getRecaptchaValue
      }
    }
  }
  }
  </script>
  
  <style lang="scss" >
    
.aoiContainer {
  display: flex;
  text-align: center;
  margin: 0 0 1em 0 ;
  flex-wrap: wrap;

  label {
    margin-right: 1rem;
    width: fit-content;
    color: black;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    border: 1px solid #007bff;
    transition: background-color 0.2s linear;
    border-radius: 50rem;
    padding: 10px;
    &.selected {
      color: white;
      background-color: #007bff;
    }
  }

  input {
    display: none;
  }
}
.submit-button {
    //border: 1px solid #0c3;
    text-align: center;
    position: relative;
    color: #fff;
    overflow: hidden;
    //background-color: #0c3 !important;
    padding: 0 28px;
    font-size: 22px;
    width: 100%;
    font-family: Oswald, sans-serif;
    //text-transform: uppercase;
    line-height: 31px;

    &:before {
      //background-color: #f6001d 1%;
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(#f6001d 1%),
      //   to(#b60002 100%)
      // );
      // background-image: -webkit-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -moz-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -ms-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -o-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: linear-gradient(top, #f6001d 1%, #b60002 100%);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all linear 0.15s;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      transition-delay: 0s;
    }

    &:after {
      content: "";
      font-family:'';
      padding-left: 5px;
      font-size: 16px;
      vertical-align: middle;
      position: absolute;
      top: 0;
    }



    &.red-theme,
    &.-theme,
    &.undefined-theme {
      border: 1px solid #d50019;
      background-color: #d50019;

      &:before {
        background-color: #f6001d 1%;
      }

      &:hover {
        //color: #d50019 !important;
        box-shadow: 0 0 5px #d50019;
      }

      &:focus {
        outline: 1px auto #d50019;
      }
    }

    &.green-theme {
      border: 1px solid #0c3;
      background-color: #0c3;

      &:before {
        background-color: #0c3 1%;
      }

      &:hover {
        //color: #0c3 !important;
        box-shadow: 0 0 5px #0c3;
      }

      &:focus {
        outline: 1px auto #0c3;
      }
    }

    &.blue-theme {
      border: 1px solid #008ed4;
      background-color: #008ed4;

      &:before {
        background-color: #008ed4 1%;
      }

      &:hover {
        //color: #008ed4 !important;
        box-shadow: 0 0 5px #008ed4;
      }

      &:focus {
        outline: 1px auto #008ed4;
      }
    }
  }
  .error{
    color: red;
  }
  .booking-section{
    min-height: 185vh;
    height: 100%;
    iframe{
      min-height: 185vh;
      height: 100%;
    }
  }
  </style>
<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
      class="Briefcase-icon"
      fill="currentColor"
    >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 34.5C0.5 35.6935 1.03337 36.8381 1.98277 37.682C2.93217 38.5259 4.21984 39 5.5625 39H49.4375C50.7802 39 52.0678 38.5259 53.0172 37.682C53.9666 36.8381 54.5 35.6935 54.5 34.5V16.5H51.125V34.5C51.125 34.8978 50.9472 35.2794 50.6307 35.5607C50.3143 35.842 49.8851 36 49.4375 36H5.5625C5.11495 36 4.68572 35.842 4.36926 35.5607C4.05279 35.2794 3.875 34.8978 3.875 34.5V16.5H0.5V34.5Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 10.5C0.5 9.30653 1.03337 8.16193 1.98277 7.31802C2.93217 6.47411 4.21984 6 5.5625 6H49.4375C50.7802 6 52.0678 6.47411 53.0172 7.31802C53.9666 8.16193 54.5 9.30653 54.5 10.5V17.652L28.8027 23.742C27.9491 23.9441 27.0509 23.9441 26.1973 23.742L0.5 17.652V10.5ZM5.5625 9C5.11495 9 4.68572 9.15804 4.36926 9.43934C4.05279 9.72064 3.875 10.1022 3.875 10.5V15.348L27.0646 20.844C27.3499 20.9117 27.6501 20.9117 27.9354 20.844L51.125 15.348V10.5C51.125 10.1022 50.9472 9.72064 50.6307 9.43934C50.3143 9.15804 49.8851 9 49.4375 9H5.5625ZM17.375 4.5C17.375 3.30653 17.9084 2.16193 18.8578 1.31802C19.8072 0.474106 21.0948 0 22.4375 0H32.5625C33.9052 0 35.1928 0.474106 36.1422 1.31802C37.0916 2.16193 37.625 3.30653 37.625 4.5V6H34.25V4.5C34.25 4.10218 34.0722 3.72064 33.7557 3.43934C33.4393 3.15804 33.0101 3 32.5625 3H22.4375C21.9899 3 21.5607 3.15804 21.2443 3.43934C20.9278 3.72064 20.75 4.10218 20.75 4.5V6H17.375V4.5Z"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: "briefcase"
  }
  </script>
  <style scoped>
  .Briefcase-icon {
    font-size: 315%;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }
  .stroke {
    font-size: 300%;
    stroke-width: 1%;
  }
  </style>
  
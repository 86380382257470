<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
      class="graduation-icon"
    >
    <path d="M59.803 11.249L34.1307 0.538804C32.4207 -0.179601 30.5804 -0.179601 28.8703 0.538804L3.19807 11.249C1.55828 11.9321 0.5 13.7978 0.5 16.0002C0.5 18.2027 1.55934 20.067 3.197 20.7515L6.07466 21.9508C5.75397 22.7312 5.51524 23.5642 5.36486 24.4273C4.32903 25.0743 3.6 26.4155 3.6 28.0002C3.6 29.5918 4.33331 30.94 5.37555 31.5841L3.62672 45.5652C3.47066 46.8132 4.00621 47.9986 4.72562 47.9986H8.67331C9.39272 47.9986 9.92934 46.8132 9.77221 45.5638L8.02445 31.5827C9.06669 30.94 9.8 29.5918 9.8 28.0002C9.8 26.6743 9.26766 25.557 8.49907 24.828C8.64338 24.2464 8.86679 23.7205 9.1351 23.228L14.3153 25.3887L12.9 40.0001C12.9 44.4188 21.2272 48 31.5 48C41.7728 48 50.1 44.4188 50.1 40.0001L48.6847 25.3887L59.803 20.7501C61.4407 20.0684 62.5 18.2027 62.5 16.0002C62.5 13.7978 61.4407 11.9321 59.803 11.249ZM46.9498 39.7329C45.9075 41.1479 40.6108 44 31.5 44C22.3903 44 17.0925 41.1479 16.0502 39.7329L17.318 26.6409L28.8693 31.4603C29.1216 31.5646 31.361 32.6137 34.1307 31.4603L45.682 26.6409L46.9498 39.7329ZM58.8463 16.9463L33.174 27.6565C32.0857 28.1139 30.9143 28.1139 29.826 27.6565L12.8081 20.5567L31.7843 15.9641C31.9838 15.9175 32.1742 15.8201 32.3446 15.6773C32.515 15.5346 32.662 15.3494 32.7772 15.1324C32.8923 14.9154 32.9733 14.6709 33.0156 14.4129C33.0578 14.155 33.0604 13.8887 33.0233 13.6295C32.9435 13.1077 32.7092 12.6478 32.3709 12.3491C32.0326 12.0505 31.6176 11.9372 31.2157 12.0337L11.474 16.8086C10.3107 17.0982 9.21415 17.7323 8.26924 18.6618L4.15372 16.9449C3.39262 16.6249 3.43003 15.3519 4.15372 15.0514L29.8239 4.34121C30.9124 3.88585 32.0833 3.88585 33.1719 4.34121L58.8441 15.0514C59.5603 15.3505 59.6106 16.6263 58.8441 16.9463H58.8463Z" fill="#fff"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: "graduation"
  }
  </script>
  <style scoped>
  .graduation-icon {
    font-size: 315%;
    height: 1em;
    width: 1em;
    fill: #737373;
  }
  .stroke {
    font-size: 300%;
    stroke-width: 1%;
  }
  </style>
  
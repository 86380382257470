<template>
  <div :class="`accordionComponent accordion ${data.techSettings.accordionClass} ${data.techSettings.type}`" >
    <b-card no-body :class="`bg-${data.techSettings.backgroundColor} ${data.techSettings.cardClass}`">
      <b-card-header header-tag="header" :class="data.techSettings.headerClass" role="tab">
        <b-button
          block
          v-b-toggle.accordion-1
          v-html="data.accordionHeader"
          v-if="data.techSettings.type !== 'yeti'"
          :class="['accordionHeader',data.techSettings.headerButtonClass]"
        ></b-button>
        <b-button
          block
          v-b-toggle.accordion-1
          v-if="data.techSettings.type == 'yeti'"
          :class="['accordionHeader flex justify-content-between',data.techSettings.headerButtonClass]"
        >
          <div class="headerContainer mx-auto mx-md-0">{{ data.accordionHeader }} <span class="strong">{{ this.nextStartDate }}</span></div> 
          <span class="collapseIcon align-self-middle"></span>
        </b-button>
        
      </b-card-header>
      <b-collapse
        class="accordionCollapse text-left"
        :visible="data.techSettings.startExpanded"
        id="accordion-1"
        accordion="my-accordion"
        role="tabpanel"
      >
        <!-- Carousel -->
        <StartDateCarousel v-if=data.startDateCarousel :data="data.startDateCarousel.data" @sendData="receiveData" @slideChange="onSlideChange" @showPrev="showPrevSlide" @showNext="showNextSlide" ></StartDateCarousel>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCollapse, BCard,  BCardHeader} from "bootstrap-vue"
import StartDateCarousel from "@/components/Content/StartDateCarousel.vue"

export default {
  name: "AccordionButton",
  props: ["data"],
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
    StartDateCarousel
  },
  data() {
      return {
          //now: new Date(),
          cardObject: {},
          innerSlideData: {},
          currentSlide: '',
          nextStartDate: '',
      }
  },
  mounted() {
    let component = document.querySelector('.accordionComponent');
    if (component.classList.contains('stickyFooter')) {
      let stickyFooter = document.querySelector('.accordionComponent.stickyFooter');
      stickyFooter.classList.add('hide'); //initialize as hidden

      window.addEventListener("scroll", () => {
        let isVisible = this.isVisible(document.querySelector("form"))

        //if form is visible, show
        if (isVisible) {
          if (!stickyFooter.classList.contains('hide')) stickyFooter.classList.add('hide');
        } else {
          if (stickyFooter.classList.contains('hide')) stickyFooter.classList.remove('hide');
        }
      })
    }
  },
  methods: {
    scrollTo(arg) {
      try {
        if (arg.length > 0) {
          let destination = document.querySelector(arg);
          destination.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else throw new Error("ScrollToFunction argument undefined");        
      }
      catch(err) {
        if (this.data.cardButton.url.length == 0) console.log('error: ', err);
      }
      
    },
    isVisible(e) {
      let bottom = e.getBoundingClientRect().bottom > 0
      
      return bottom;
    },
    receiveData(cards, refs) {
      this.cardObject = cards;
      this.innerSlideData = refs;
      let startDate = new Date(this.cardObject[0].expiration);
      startDate = `${startDate.toLocaleString('en-US', {month: 'short'})} ${startDate.getDate()}`
      this.nextStartDate = startDate;
      this.currentSlide = `${this.innerSlideData.currentSlide + 1} / ${this.innerSlideData.slideCount}`
    },
    onSlideChange(data) {
      this.currentSlide = data;
    },
    showPrevSlide() {
      console.log('show previous slide')
      this.$emit('showPrev')
    },
    showNextSlide() {
      console.log('show next slide')
      this.$emit('showNext')
    }
  }
}
</script>

<style lang="scss" >
.accordion {
  font-size: 22px;
  &.hide, &.hidden {
    display: none;
  }
  .bg-black {
    background-color: #25292B;
    color: white;

    .accordionHeader.btn-secondary {
      background-color: #25292B;
      color: white;
    }

    .accordionCollapse {
      border-color:white;
    }
  }

  .bg-, .bg-white {
    background-color: white;
    color: black;

    .accordionHeader.btn-secondary {
      background-color: white;
      color: black;
    }

    .accordionCollapse {
      border-color:#6c757d;
    }
  }

  button {
    text-align: left;
    border-radius: 0px;
    //border: none;
    border-color: rgba(0, 0, 0, 0.125);
    border-style: solid;
    &.btn {
      font-size: 22px;
      line-height: 25px;
      font-family: Oswald, sans-serif;
    }

    // &.btn-secondary {
    //   color: #262626;
    //   background-color: #fff;

    //   &:focus {
    //     color: #262626;
    //     background-color: #fff;
    //     //border-color: none;
    //   }
    //   &:active {
    //     color: #262626;
    //     background-color: #fff;
    //     border-color: rgba(0, 0, 0, 0.125);
    //     box-shadow: none;
    //     &:focus {
    //       box-shadow: none;
    //     }
    //   }
    // }

    &::after {
      padding-left: 5px;
      vertical-align: middle;
      position: absolute;
      right: 17px;
      top: 8px;

    }
    &.collapsed {
      .collapseIcon {
        content: url("https://res.cloudinary.com/utidinary/image/upload/f_auto/q_auto/a_90/Solid-Chevron.svg");
      }
    }
    &.not-collapsed {
      .collapseIcon {
        content: url("https://res.cloudinary.com/utidinary/image/upload/f_auto/q_auto/a_270/Solid-Chevron.svg");
      }
    }
    &.btn:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .accordionCollapse {
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-top: 1px;
    //border-color: white;
    border-style: solid;
    padding: 10px;
    //background-color: white;
  }

  .card {
    border: 1px solid black;
    border-radius: 0px;
  }

  b-card-body {
    font-size: 16px;
    line-height: 18px;

    b-card-text {
      display: block;
    }
  }

  &.stickyFooter {
    margin-bottom: 0px;
    position: fixed;
    bottom: 0;
    z-index: 10;
    filter: drop-shadow(0px 1px 5px black);
    .card {
      border-radius: 8px 8px 0px 0px;
      margin-bottom: 0px!important;
    }

    .accordionHeader, .accordionCollapse b-card-text {
      font-size: 14px;
      line-height: 21px;
      font-family: "Montserrat", sans-serif;
    }

    .accordionHeader {
      font-weight: 700;
    }

    .accordionButton {
      width: 100%;
      border-radius: 8px;
      font-family: Tungsten-Semibold, sans-serif;
      font-size: 27px;
      line-height: 36px;
      font-weight: normal;

      &.bg-green {
      background-color: #05CC33;
      color: white;
    }
    }
    
  }

  &.yeti {
    //position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    //padding: 0.5rem 1rem;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1031;

    &:has(.collapse.show) {
      position:absolute;
      transition: none;
    }

    button .headerContainer, button .currentSlideContainer {
      display: inline-block;

    }
    
    .collapsing {
      position: absolute;
      transition: none;
    }
    .card {
      border: 0px!important;
    }

    .carousel-inner {
      display: flex !important;
      position: static;

    .carousel-item {
      display: block;
      float: none;
      margin-right: 0px;

      .carousel-caption {
            display: block;
            position: static;
      }

      
    }
    }

    .accordionCollapse {
      border: none;
    }


    button.accordionHeader{
      border: none;
      &:after {
        position: unset;
      }

      // &.collapsed {
      //   .currentSlideContainer {
      //     display: none;
      //   }
      // }

      // &.not-collapsed {
      //   .headerContainer {
      //     display: none;
      //   }
      // }
    }
    
  }


}

    
    
</style>
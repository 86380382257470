var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:_vm.data.techSettings.backgroundColor +
    '-theme ' +
    'facts-unordered-list ' + _vm.data.techSettings.overrideClasses,attrs:{"fluid":""}},[_c('b-container',{attrs:{"fluid":_vm.data.techSettings.fullWidth}},[(_vm.data.title)?_c('h2',{class:("text-" + (_vm.data.techSettings.titleAlignment) + " title pt-2 font-weight-bold p-2"),domProps:{"innerHTML":_vm._s(_vm.data.title)}}):_vm._e(),(_vm.data.subTitle)?_c('p',{staticClass:"sub-title",domProps:{"innerHTML":_vm._s(_vm.data.subTitle)}}):_vm._e(),(_vm.data.facts)?_c('ul',{class:'fact-list text-' + _vm.data.techSettings.itemAlignment + ' p-0 m-0'},[_c('b-row',{class:[_vm.data.techSettings.containerRowClasses,'pt-4'],attrs:{"cols":_vm.data.techSettings.xsColumns,"cols-md":_vm.data.techSettings.mdColumns}},_vm._l((_vm.data.facts),function(item,index){return _c('b-col',{key:index,class:[_vm.data.techSettings.listContainerClasses,'col-md mx-auto']},[(
              _vm.data.techSettings.itemAlignment &&
              _vm.data.techSettings.itemAlignment != 'center'
            )?_c('li',{class:'fact-group mb-4 item-' +
              _vm.data.techSettings.itemAlignment +
              '-aligned'},[_c('b-row',{class:_vm.data.techSettings.iconRowClasses},[_c('b-col',{staticClass:"col-12 text-center px-0 mx-auto",attrs:{"order":_vm.alignOrder(),"align-self":"center"}},[(_vm.data.facts[index].bootstrapIcon)?_c(_vm.data.facts[index].bootstrapIcon,{tag:"component",staticClass:"fact-icon accessory",attrs:{"font-scale":"3"}}):_vm._e()],1),_c('b-col',{staticClass:"facts-content mx-auto flex-col",attrs:{"cols":"10"}},[(_vm.data.facts[index].headline)?_c('h3',{class:['fact-headline mt-3']},[_vm._v(" "+_vm._s(_vm.data.facts[index].headline)+" ")]):_vm._e(),(_vm.data.facts[index].subHeadline)?_c('p',{staticClass:"fact-subHeadline mt-3",domProps:{"innerHTML":_vm._s(_vm.data.facts[index].subHeadline)}}):_vm._e()])],1)],1):_vm._e(),(
              _vm.data.techSettings.itemAlignment &&
              _vm.data.techSettings.itemAlignment == 'center'
            )?_c('li',{class:'fact-group mb-4 item-' +
              _vm.data.techSettings.itemAlignment +
              '-aligned'},[(_vm.data.facts[index].bootstrapIcon)?_c(_vm.data.facts[index].bootstrapIcon,{tag:"component",class:[_vm.data.icon.iconColor,'fact-icon accessory'],attrs:{"font-scale":_vm.data.icon.scale}}):_vm._e(),(_vm.data.facts[index].headline)?_c('h3',{class:[_vm.data.headlineColor,'fact-headline mt-3 mb-3']},[_vm._v(" "+_vm._s(_vm.data.facts[index].headline)+" ")]):_vm._e(),(_vm.data.facts[index].subHeadline)?_c('p',{staticClass:"fact-subHeadline",domProps:{"innerHTML":_vm._s(_vm.data.facts[index].subHeadline)}}):_vm._e()],1):_vm._e()])}),1)],1):_vm._e(),(_vm.data.copy)?_c('p',{staticClass:"copy",domProps:{"innerHTML":_vm._s(_vm.data.copy)}}):_vm._e(),_vm._l((_vm.data.nestedComponents),function(nestedComponent,index){return _c('div',{key:index},[(nestedComponent.data !== null)?_c(nestedComponent.type,{tag:"component",class:['unorderedListFactsNested', nestedComponent.classes],attrs:{"data":nestedComponent.data}}):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
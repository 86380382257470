<template>
  <div>
    <b-form-group
      label="Age"
      label-for="input-4"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-select
        id="age"
        name="age"
        class="ageselect"
        v-model="Age"
        :value="value"
        :options="options"
        :class="{ isInvalidInput: v.$error }"
        @focus="optEvent('engagedAge')"
        @change="optEvent('selectedAge')"
        ref="Age"
      >
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "AgeSelect",
  props: {
    v: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: null,
      value: null,
      options: [
        {
          value: 14,
          text: "14"
        },
        {
          value: 15,
          text: "15"
        },
        {
          value: 16,
          text: "16"
        },
        {
          value: 17,
          text: "17"
        },
        {
          value: 18,
          text: "18"
        },
        {
          value: 19,
          text: "19"
        },
        {
          value: 20,
          text: "20"
        },
        {
          value: 21,
          text: "21"
        },
        {
          value: 22,
          text: "22"
        },
        {
          value: 23,
          text: "23"
        },
        {
          value: 24,
          text: "24"
        },
        {
          value: 25,
          text: "25"
        },
        {
          value: 26,
          text: "26"
        },
        {
          value: 27,
          text: "27"
        },
        {
          value: 28,
          text: "28"
        },
        {
          value: 29,
          text: "29"
        },
        {
          value: 30,
          text: "30"
        },
        {
          value: 31,
          text: "31"
        },
        {
          value: 32,
          text: "32"
        },
        {
          value: 33,
          text: "33"
        },
        {
          value: 34,
          text: "34"
        },
        {
          value: 35,
          text: "35"
        },
        {
          value: 36,
          text: "36"
        },
        {
          value: 37,
          text: "37"
        },
        {
          value: 38,
          text: "38"
        },
        {
          value: 39,
          text: "39"
        },
        {
          value: 40,
          text: "40"
        },
        {
          value: 41,
          text: "41"
        },
        {
          value: 42,
          text: "42"
        },
        {
          value: 43,
          text: "43"
        },
        {
          value: 44,
          text: "44"
        },
        {
          value: 45,
          text: "45"
        },
        {
          value: 46,
          text: "46"
        },
        {
          value: 47,
          text: "47"
        },
        {
          value: 48,
          text: "48"
        },
        {
          value: 49,
          text: "49"
        },
        {
          value: 50,
          text: "50"
        },
        {
          value: 51,
          text: "51"
        },
        {
          value: 52,
          text: "52"
        },
        {
          value: 53,
          text: "53"
        },
        {
          value: 54,
          text: "54"
        },
        {
          value: 55,
          text: "55"
        },
        {
          value: 56,
          text: "56"
        },
        {
          value: 57,
          text: "57"
        },
        {
          value: 58,
          text: "58"
        },
        {
          value: 59,
          text: "59"
        },
        {
          value: 60,
          text: "60"
        },
        {
          value: 61,
          text: "61"
        },
        {
          value: 62,
          text: "62"
        },
        {
          value: 63,
          text: "63"
        },
        {
          value: 64,
          text: "64"
        },
        {
          value: 65,
          text: "65"
        },
        {
          value: 66,
          text: "66"
        },
        {
          value: 67,
          text: "67"
        },
        {
          value: 68,
          text: "68"
        },
        {
          value: 69,
          text: "69"
        },
        {
          value: 70,
          text: "70"
        },
        {
          value: 71,
          text: "71"
        },
        {
          value: 72,
          text: "72"
        },
        {
          value: 73,
          text: "73"
        },
        {
          value: 74,
          text: "74"
        },
        {
          value: 75,
          text: "75"
        },
        {
          value: 76,
          text: "76"
        },
        {
          value: 77,
          text: "77"
        },
        {
          value: 78,
          text: "78"
        },
        {
          value: 79,
          text: "79"
        },
        {
          value: 80,
          text: "80"
        }
      ]
    }
  },
  computed: {
    Age: {
      get() {
        return this.value
      },
      set(value) {
        this.v.$touch()
        this.value = value
        this.$emit("send-updated-age", value)

        this.$store.dispatch("SET_AGE_ACTION", value)
      }
    }
  },
  methods: {
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>
<style lang="scss" scoped>
.ageselect {
  width: 50%;
}
</style>

<template>
  <div  :class="['WYSIWYGComponent', data.techSettings.rootClasses]" :style="data.techSettings.style" :id="data.techSettings.id">
    <b-container fluid class="px-0" :style="data.techSettings.fluidStyle">
      <b-container
        :fluid="data.techSettings.fullWidth"
        :class="['htmlContainer','text-' + data.techSettings.textAlignment, data.techSettings.classes]"
        :style="data.techSettings.containerStyle"
      >
        <b-row :no-gutters="!data.techSettings.gutters" :class="data.techSettings.rowClasses" :style="data.techSettings.rowStyle">
          <b-col v-html="data.html" :class="['vhtml', data.techSettings.colClasses]" :style="data.techSettings.colStyle"> </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "WYSIWYG",
  props: ["data"],
  
}
</script>

<style lang="scss" >
.WYSIWYGComponent {
  .flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }
  .htmlContainer {

    li {
      line-height: 22px;
      padding: 7px;
    }
    
    .title{
      font-size: 2.875rem;
      color: #25292B;
      padding: 1rem 0;
    }

    .desc{
      font-size: 1.125rem;
      padding-bottom: 1rem;
    }
  }

  h1, h2, h3 {
    span.highlight-gold,&.highlight-gold {
      padding-left: 10px;
      padding-right: 10px;
    }
    
  }
  span.highlight-gold {

    padding: 3px;
    
  }
  
}


</style>
<template>
<svg xmlns="http://www.w3.org/2000/svg" width="58" height="46" viewBox="0 0 58 46" className="gradCap-icon" fill="currentColor">
<path d="M55.477 13.8349L31.4614 6.13708C29.8473 5.62048 28.1536 5.62048 26.5395 6.13708L2.52391 13.8349C0.991438 14.3263 0 15.6668 0 17.2499C0 18.8329 0.991438 20.1734 2.523 20.6648L5.21547 21.5273C4.91641 22.0826 4.69528 22.68 4.55119 23.3062C3.58241 23.7716 2.9 24.7356 2.9 25.8749C2.9 27.0186 3.58603 27.988 4.56116 28.4507L2.92537 38.5006C2.77947 39.3981 3.27972 40.2499 3.95306 40.2499H7.64603C8.31938 40.2499 8.82053 39.3981 8.67372 38.5006L7.03884 28.4507C8.01397 27.988 8.7 27.0186 8.7 25.8749C8.7 24.9225 8.20247 24.1193 7.48291 23.5955C7.61794 23.1768 7.82728 22.7995 8.07831 22.4446L12.924 23.998L11.6 34.4999C11.6 37.6758 19.3901 40.2499 29 40.2499C38.6099 40.2499 46.4 37.6758 46.4 34.4999L45.076 23.998L55.477 20.6639C57.0086 20.1734 58 18.8329 58 17.2499C58 15.6668 57.0086 14.3263 55.477 13.8349ZM43.4529 34.3076C42.4778 35.3255 37.5224 37.3749 29 37.3749C20.4776 37.3749 15.5223 35.3255 14.5471 34.3076L15.7334 24.8983L26.5395 28.3617C26.7752 28.4372 28.8704 29.191 31.4614 28.3617L42.2675 24.8983L43.4529 34.3076ZM54.5816 17.9309L30.566 25.6287C29.541 25.9575 28.459 25.9575 27.434 25.6287L11.5139 20.5256L29.2664 17.2247C30.054 17.0792 30.5723 16.3281 30.4246 15.5473C30.2778 14.7657 29.5039 14.2545 28.7336 14.4L10.266 17.832C9.14225 18.0405 8.13541 18.5193 7.26813 19.1644L3.41747 17.93C2.70606 17.7009 2.74141 16.7854 3.41747 16.5688L27.4331 8.87103C28.8015 8.43259 29.9561 8.67607 30.5651 8.87103L54.5807 16.5688C55.2504 16.7836 55.2976 17.7009 54.5816 17.9309Z"/>
</svg>
</template>

<script>
export default {
  name: "gradCapIcon"
}
</script>

<style scoped>
.gradCap-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill:currentColor;
}

</style>

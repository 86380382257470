<template>
  <div class="back-to-top-button" @click="scrollToTop">
    <b-icon-arrow-up
      class="icon accessory mx-auto my-auto"
      font-scale="1.5"
    ></b-icon-arrow-up>
  </div>
</template>

<script>
import { BIconArrowUp } from "bootstrap-vue"

export default {
  name: "BackToTopButton",
  props: ["backToTopButton"],
  components: {
    BIconArrowUp
  },
  methods: {
    scrollToTop() {
      window.scroll({ top: 0, left: 0, behavior: "smooth" })

      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedScrollToTopButton")
    }
  }
}
</script>

<style lang="scss" scoped>
.back-to-top-button {
  border-radius: 50%;
  opacity: 0.6;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #1c85c7;
  display: flex;
  z-index: 99; //

  .icon {
    color: white;
  }
}
</style>
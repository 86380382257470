<template>
  <div class="twoStepFormComponent">
    <b-container fluid :class="`px-0`">
      <b-container
        :fluid="
          this.$store.getters.CURRENT_BREAKPOINT == 'xs' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'sm' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'md'
            ? true
            : false
        "
        :class="[
          this.$store.getters.CURRENT_BREAKPOINT == 'lg' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'xl'
            ? 'desktop'
            : '',
          'px-0'
        ]"
      >
        <div :class="['two-step-form', this.formOverlap]" align-h="end">
          <form
            action=""
            :class="[
              this.$store.getters.CURRENT_BREAKPOINT == 'xs' ||
              this.$store.getters.CURRENT_BREAKPOINT == 'sm' ||
              this.$store.getters.CURRENT_BREAKPOINT == 'md'
                ? 'col-12'
                : 'col-5',
              data.techSettings.formColor+'-theme',
              'py-3',
              data.techSettings.classes
            ]"
            @click="engaged('Form')"
            @submit.prevent="submit"
          >
            <div class="header-container text-center">
              <div class="step-1-header" v-if="form.currentStep == 1">
                <div class="headline">
                  <h2
                    v-if="isMobile()"
                    v-html="data.content.stepOne.title.mobile"
                  ></h2>
                  <h2 v-else v-html="data.content.stepOne.title.desktop"></h2>
                </div>
                <div class="subHeadline">
                  <p
                    v-if="isMobile()"
                    v-html="data.content.stepOne.subTitle.mobile"
                  ></p>
                  <p v-else v-html="data.content.stepOne.subTitle.desktop"></p>
                </div>
              </div>
              <div class="step-2-header" v-if="form.currentStep == 2">
                <div class="headline">
                  <h2
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.title.mobile"
                  ></h2>
                  <h2 v-else v-html="data.content.stepTwo.title.desktop"></h2>
                </div>
                <div class="subHeadline">
                  <p
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.subTitle.mobile"
                  ></p>
                  <p v-else v-html="data.content.stepTwo.subTitle.desktop"></p>
                </div>
              </div>

              <div
                class="progress-bar"
                v-show="!this.data.techSettings.formStepOverride"
              >
                <div
                  :class="[
                    `progress-bar-fg ${data.techSettings.progressBarColor}-theme`,
                    {
                      'one-third': form.currentStep == 1,
                      'two-third': form.currentStep == 2
                    }
                  ]"
                ></div>
              </div>
              <div :class="[fade(1), 'step-1-container']">
                <!-- ORIGINAL DROPDOWN -->
                <!-- Fields Group -->
                <div class="fields-container">
                </div>
                <!-- END OF ORIGINAL DROPDOWN -->

                <div class="submit-container text-center">
                  <button
                    :class="`submit-button col-7 col-sm-6 col-md-7 col-lg-6 my-3 step-one-submit ${data.techSettings.submitButtonColor}-theme`"
                    @click="engaged('StepOneSubmit')"
                  >
                    <span
                      v-if="isMobile()"
                      v-html="data.content.stepOne.CTA.mobile"
                    ></span>
                    <span
                      v-else
                      v-html="data.content.stepOne.CTA.desktop"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div :class="[fade(2), 'step-2-container']">
              <!-- Fields Group -->
              <div class="fields-container">
                  <div class="row">
                    <div class="col-12">
                      <div class="aoi-select">
                        <label
                          for="aoiSelection"
                          :class="{ hasError: $v.form.AOI.$error }"
                          >What program are you interested in?
                          <template v-if="$v.form.AOI.$error"
                            ><img src="" alt=""
                          /></template>
                        </label>
                        <div>
                          <b-form-select
                            name="interest"
                            id="aoiSelection"
                            v-model="form.AOI"
                            :class="{ isInvalidInput: $v.form.AOI.$error }"
                            @blur="$v.form.AOI.$touch()"
                            @input="formStartEvent()"
                            @focus="engaged('AOISelection')"
                            @change="selected('AOI')"
                          >
                            <option disabled value="">-- Select --</option>

                            <option
                              v-for="(aoi, index) in validateAOIS()"
                              :key="index"
                              :value="`${aoi.shortCode}`"
                            >
                              {{ aoi.name }}
                            </option>
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="row">
                  <div class="col-6">
                    <label
                      for="firstNameInput"
                      :class="['mr-2', { hasError: $v.form.FirstName.$error }]"
                      >First Name</label
                    >
                    <div>
                      <b-form-input
                        type="text"
                        id="firstNameInput"
                        name="firstName"
                        placeholder="First Name"
                        maxlength="30"
                        v-model.trim="form.FirstName"
                        :class="{ isInvalidInput: $v.form.FirstName.$error }"
                        :state="getContextualState('FirstName', $v)"
                        @keydown="validateCharacter($event)"
                        @blur="$v.form.FirstName.$touch(), formStartEvent()"
                        @focus="engaged('FirstName')"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="lastNameInput"
                      :class="{ hasError: $v.form.LastName.$error }"
                      >Last Name</label
                    >
                    <div>
                      <b-form-input
                        type="text"
                        id="lastNameInput"
                        name="lastName"
                        placeholder="Last Name"
                        maxlength="30"
                        v-model.trim="form.LastName"
                        :class="{ isInvalidInput: $v.form.LastName.$error }"
                        :state="getContextualState('LastName', $v)"
                        @keydown="validateCharacter($event)"
                        @blur="$v.form.LastName.$touch()"
                        @focus="engaged('LastName')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label
                      for="ageSelection"
                      :class="{ hasError: $v.form.Age.$error }"
                      >Age</label
                    >
                    <div>
                      <select
                        name="age"
                        id="ageSelection"
                        v-model.number="form.Age"
                        :class="{ isInvalidInput: $v.form.Age.$error }"
                        @blur="$v.form.Age.$touch()"
                        @focus="engaged('Age')"
                        @change="selected('Age')"
                      >
                        <option disabled value="">Age</option>
                        <option
                          v-for="(age, index) in 67"
                          :key="index"
                          :value="`${age + 13}`"
                        >
                          {{ age + 13 }}
                        </option>
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="zipCodeInput"
                      :class="{ hasError: $v.form.ZipCode.$error }"
                      >Zip</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="zipCodeInput"
                        name="zipCode"
                        placeholder="90210"
                        :minlength="getInputLength('zip', 'min')"
                        :maxlength="getInputLength('zip', 'max')"
                        v-model="form.ZipCode"
                        @keydown="validateNumber($event)"
                        @input="formatZip()"
                        :class="{ isInvalidInput: $v.form.ZipCode.$error }"
                        :state="getContextualState('ZipCode', $v)"
                        @blur="$v.form.ZipCode.$touch()"
                        @focus="engaged('Zip')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="form.Age !== '' && form.Age < 20" class="col-6">
                    <label
                      for="graduationYearInput"
                      :class="{ hasError: $v.form.GradYear.$error }"
                      >High School <br />Graduation Year</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="graduationYearInput"
                        name="graduationYearInput"
                        placeholder="YYYY"
                        maxlength="4"
                        v-model.number="form.GradYear"
                        @keydown="validateNumber($event)"
                        :class="{ isInvalidInput: $v.form.GradYear.$error }"
                        :state="getContextualState('GradYear', $v)"
                        @blur="$v.form.GradYear.$touch()"
                        @focus="engaged('HighSchoolGradYear')"
                      ></b-form-input>
                    </div>
                  </div>
                  <div
                    :class="
                      form.Age !== '' && form.Age < 20 ? 'col-6' : 'col-12'
                    "
                  >
                    <label
                      for="countryInput"
                      :class="{ hasError: $v.form.Country.$error }"
                      ><br
                        v-if="form.Age !== '' && form.Age < 20"
                        class="col-6"
                      />Country</label
                    >

                    <div>
                      <select
                        name="countryInput"
                        id="countryInput"
                        v-model="form.Country"
                        :class="{ isInvalidInput: $v.form.Country.$error }"
                        @blur="$v.form.Country.$touch()"
                        @focus="engaged('Country')"
                        @change="selected('Country')"
                      >
                        <option
                          v-for="(country, index) in getCOUNTRIES()"
                          :key="index"
                          :value="`${country.code}`"
                        >
                          {{ country.country }}
                        </option>
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label
                      for="phoneInput"
                      :class="{ hasError: $v.form.PhoneNumber.$error }"
                      >Phone</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="phoneInput"
                        name="phone_number"
                        placeholder="( ___ ) ___-____"
                        :class="{ isInvalidInput: $v.form.PhoneNumber.$error }"
                        @blur="$v.form.PhoneNumber.$touch()"
                        v-model="form.PhoneNumber"
                        :minlength="getInputLength('phone', 'min')"
                        :maxlength="getInputLength('phone', 'max')"
                        :state="getContextualState('PhoneNumber', $v)"
                        @focus="engaged('Phone')"
                        @input="formatPhoneNumber()"
                        @keydown="validateNumber($event)"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="emailInput"
                      :class="{ hasError: $v.form.email.$error }"
                      >Email</label
                    >
                    <div>
                      <b-form-input
                        type="email"
                        id="emailInput"
                        name="email"
                        placeholder="____@____.___"
                        maxlength="100"
                        v-model.trim="form.email"
                        :class="{ isInvalidInput: $v.form.email.$error }"
                        :state="getContextualState('email', $v)"
                        @blur="$v.form.email.$touch()"
                        @focus="engaged('Email')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- Personal Info -->
                <div class="row">
                  <div class="col-12" v-if="form.Age >= 18">
                    <input
                      type="checkbox"
                      id="isMilitaryCheckBox"
                      name="isMilitaryCheckBox"
                      class="mr-2"
                      v-model="form.isMilitary"
                      @click="engaged('IsMilitary')"
                    /><label for="isMilitaryCheckBox" style="display: inline"
                      >Are you current or former U.S. Military, Guard, or
                      Reserve?</label
                    >
                  </div>
                  <div class="col-6 mt-2" v-if="form.isMilitary">
                    <label
                      for="militaryReleaseYearInput"
                      :class="{ hasError: $v.form.MilitarySeparation.$error }"
                      >Military Release Date</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="militaryReleaseYearInput"
                        name="militaryReleaseYearInput"
                        v-model="form.MilitarySeparation"
                        @keydown="validateNumber($event)"
                        @input="formatMilitaryRelease()"
                        placeholder="MM/YYYY"
                        maxlength="7"
                        :class="{
                          isInvalidInput: $v.form.MilitarySeparation.$error
                        }"
                        :state="getContextualState('MilitarySeparation', $v)"
                        @blur="$v.form.MilitarySeparation.$touch()"
                        @focus="engaged('MilitaryReleaseDate')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Disclosure Group -->
              <div class="ewc disclosure-container bodySmall my-4">
                <p>
                  By submitting this form, I agree that Universal Technical
                  Institute, Inc., Custom Training Group, Inc., and their
                  representatives may email, call, and / or text me with
                  marketing messages about educational programs and services, as
                  well as for school-related communications, at any phone number
                  I provide, including a wireless number, using prerecorded
                  calls or automated technology. I understand that my consent is
                  not required to apply, enroll or make any purchase, and that I
                  may instead call 1-800-913-7524 to request admission
                  information. I understand that if I do submit this form, I may
                  unsubscribe within marketing emails or opt-out of text
                  messages at any time by replying “STOP.” Standard text message
                  and data rates may apply.
                </p>
                <p>
                  By submitting this form, I further understand and agree that
                  all information provided is subject to UTI’s Privacy Policy
                  available at
                  <a href="https://www.uti.edu/privacy-policy" target="_blank" rel="noopener">www.uti.edu/privacy-policy</a>
                  and UTI's SMS Terms and Conditions available at 
                  <a href='https://www.uti.edu/sms-terms-conditions' target="_blank" rel="noopener">www.uti.edu/sms-terms-conditions</a>.
                </p>
              </div>
              <!-- Submit Group -->
              <div class="submit-container text-center">
                <button
                  :class="`submit-button final-submit-button my-3 col-7 col-sm-6 col-md-7 col-lg-6 ${data.techSettings.submitButtonColor}-theme`"
                  @click="engaged('StepTwoSubmit')"
                >
                  <span
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.CTA.mobile"
                  ></span>
                  <span v-else v-html="data.content.stepTwo.CTA.desktop"></span>
                </button>
                <div v-show="!this.data.techSettings.formStepOverride">
                  <a href="" v-on:click.prevent="previousStep">Previous Step</a>
                </div>
              </div>
            </div>
            <!-- <SchoolLogoBar :data="SchoolLogoBar.data"/> -->
          </form>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
// Info on the template above. :v and $v are used to bind the components value for validation. $v is a global object that can be console logged or displayed on the DOM
//$v contains all the validation that is used for the forms such as required:true/false if it is dirty or not.....etc
//For more info on the $v object a link for vuelidate is here. https://vuelidate.js.org/

///Validation Imports
import {
  required,
  requiredIf,
  email,
  between,
  maxLength,
  minLength,
  maxValue,
  numeric
} from "vuelidate/lib/validators"
//import  SchoolLogoBar  from "../Footer/SchoolLogoBar.vue";

const usPhoneLength = (value, data) => {
  if (data.Country == "US") {
    return minLength(16);
  } else return minLength(0);
}

export default {
  name: "SelectForm",
  props: ["data","AcquisitionPoint"],
  components: {
    ...mapGetters([
      "AOIS",
      "COUNTRIES",
      "DIPLOMAS",
      "CURRENT_BREAKPOINT",
      "CAMPAIGN",
      "HIDDENCAMPAIGNAOIS",
      "CAMPUSES",
      "RESTRICTED_EMAIL_DOMAINS_DATA",
      "ACQUISITIONPOINT"
    ])
    //SchoolLogoBar,
  },
  data() {
    return {
      form: {
        AOI: "",
        AcquisitionPoint: this.AcquisitionPoint || "UTIFORM039/SELECTFORM",
        FirstName: "",
        LastName: "",
        email: "",
        Education: "",
        GradYear: "",
        ZipCode: "",
        Age: "",
        Country: "",
        PhoneNumber: "",
        isMilitary: false,
        MilitarySeparation: "",
        currentStep: 1,
        EWC: "",
        Campus: ""
      },
      SchoolLogoBar: {
        data: {
          techSettings: {
            backgroundColor: "white"
          },
          title:
            "UTI technical schools include training programs in Automotive, Collision Repair, Diesel, Marine, Motorcycle, NASCAR, CNC Machining and Welding.",
          schools: [
            {
              name: "Motorcycle Mechanics Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-motorcycle-light-transparent.png",
              alt: "Logo for Motorcycle Mechanics Institute, MMI"
            },
            {
              name: "Marine Mechanics Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-marine-light-transparent.png",
              alt: "Logo for Marine Mechanics Institute, MMI"
            },
            {
              name: "NASCAR Technical Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-NASCAR-light-transparent.png",
              alt: "Logo for NASCAR Technical Institute, NTI"
            }
          ]
        }
      },
      groupAOIS: [],
      aoiValue: "",
      campusLocations: [],
      hideOption: true,
      allCampusLocations: [],
      programOptions: []
    }
  },
  beforeMount() {
    if (this.data.techSettings.formStepOverride) {
      this.form.currentStep = this.data.techSettings.formStepOverride
        .currentStep
        ? this.data.techSettings.formStepOverride.currentStep
        : 1
      this.form.AOI = this.data.techSettings.formStepOverride.defaultAOI
        ? this.data.techSettings.formStepOverride.defaultAOI
        : ""
    }
    this.initializeInquiryPayload(this.$route.query)
  },
  mounted() {
    this.getEwc()
    // this.createLeadGUID();
    // this.getGAClient();
    //this.createQueryParams(this.$route.query);
    window.dataLayer = window.dataLayer || []
  },
  computed: {
    getDesktopFormAlignment() {
      //let alignment = this.data.techSettings.desktopFormAlignment

      // if (alignment) {
      //   return "desktopFormAlign-" + alignment
      // } else {
      //   return "desktopFormAlign-right"
      // }

      return ""
    },
    getDesktopTextAlignment() {
      // let alignment = this.data.techSettings.desktopFormAlignment
      // if (alignment == "right") {
      //   return "desktopTextAlign-left"
      // } else if (alignment == "left") {
      //   return "desktopTextAlign-right"
      // } else if (alignment == "center") {
      //   return "desktopTextAlign-center"
      // } else {
      //   return ""
      // }
      return ""
    },
    formOverlap() {
      let isOverlap = this.data.techSettings.formOverlap
      if (isOverlap) {
        return "formOverlap"
      } else return "formExpand"
    }
  },
  methods: {
    initializeInquiryPayload: function (route) {
      /// lead guid, GA Client Id, UTMs, conent page for conversion, previous page, incoming query string, IP, etc
      this.$store.dispatch("INITIALIZE_INQUIRY_PAYLOAD_ACTION", route)
    },
    // createLeadGUID: function () {
    //   this.$store.dispatch("CREATE_LEADGUID_ACTION");
    // },
    // getGAClient: function () {
    //   this.$store.dispatch("SET_GACLIENTID_ACTION");
    // },
    // createQueryParams: function (route) {
    //   this.$store.dispatch("SET_QUERY_PARAMS_ACTION", route);
    // },
    getEwc() {
      let ewc = document.getElementsByClassName("ewc")
      let concantEwc = ewc[0].innerHTML
      let finalEwc = encodeURIComponent(concantEwc)
      //this.$store.dispatch("SET_EWC_ACTION", finalEwc);
      this.form.EWC = finalEwc
    },
    isRestrictedDomain(domain) {
      let domains = this.$store.getters.RESTRICTED_EMAIL_DOMAINS_DATA;
      
      return domains.includes(domain);
    },
    getVISIBLECAMPAIGNAOIS: function() {
      let visibleCampaignAOIS = this.$store.getters.VISIBLECAMPAIGNAOIS
      return visibleCampaignAOIS
    },
    removeFromArray(originalArray, nested, removeArray) {
      return originalArray.filter(value => removeArray.includes(value[nested]))
    },
    optimizely(apiName) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
    },
    engaged(type) {
      this.optimizely(`engaged${type}`)
    },
    selected(type) {
      this.optimizely(`selected${type}`)
    },
    submitted(type) {
      switch(type) {
        case 'UTIFORM039/TWOSTEPFORM':
          type = "TwoStepForm";
          break;
        case 'UTIFORM039/SELECTFORM':
          type = "SelectForm";
          break;
        case 'UTIFORM039/SINGLESTEPFORM':
          type = "SingleStepForm";
          break;
        case 'UTIFORM039/MODALFORM':
          type = "ModalForm";
          break;
        case 'UTIFORM039/ICONFORM':
          type = "IconForm";
          break;
      }

      this.optimizely(`submitted${type}`)
      console.log(`optimizely: submitted${type}`)
    },
    getCAMPAIGN: function () {
      let campaign = this.$store.getters.CAMPAIGN
      return campaign
    },
    getHIDDENCAMPAIGNAOIS: function () {
      let hiddenCampaignAOIS = this.$store.getters.HIDDENCAMPAIGNAOIS
      return hiddenCampaignAOIS
    },
    getHiddenAOIS: function () {
      let hiddenAOIS = this.data.techSettings.hiddenAOIS
      return hiddenAOIS
    },
    getAOIS: function () {
      let storeAOIS = this.$store.getters.AOIS
      return storeAOIS
    },
    getACQUISITIONPOINT() {
      return this.$store.getters.ACQUISITIONPOINT
    },
    getCOUNTRIES() {
      return this.$store.getters.COUNTRIES
    },
    getDIPLOMAS() {
      return this.$store.getters.DIPLOMAS
    },
    getContextualState: function (x, v) {
      let key = x

      if (v.form[key].$dirty == false) {
        //console.log("not dirty");
        return null
      } else {
        //console.log("dirty");
        //console.log("valid or invalid: ", !v.form[key].$error);
        return !v.form[key].$error
      }
    },
    getInputLength: function (field, type) {
      if (this.form.Country == "US") {
        if (field == "zip") {
          if (type == "min") {
            return 5
          } else if (type == "max") {
            return 5
          }
        } else if (field == "phone") {
          if (type == "min") {
            return 16
          } else if (type == "max") {
            return 16
          }
        }
      }
    },
    getAllCampuses(allCampusLocations) {
      //let allCampusLocations = [];
      axios
        .get(
          `https://enrollnowapidev.azurewebsites.net/api/campus?code=i5Y98Y186ThAiUoDPqvzOAcIx1VWFATGCQ6XlPpE0/8gchuSVaA42g==&aoi=`
        )
        .then((response) => {
          //console.log(`response data`, response.data)
          this.allCampusLocations = response.data

          console.log(`campusLocations`, this.allCampusLocations[0])
          return allCampusLocations
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getPrograms() {
      let campusSelected = this.form.Campus
      //console.log(`campus selected `, campusSelected)
      axios
        .get(
          `https://enrollnowapidev.azurewebsites.net/api/aoi?code=i5Y98Y186ThAiUoDPqvzOAcIx1VWFATGCQ6XlPpE0/8gchuSVaA42g==&campuscode=${campusSelected}`
        )
        .then((response) => {
          console.log(`response data`, response.data)
          this.programOptions = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getCAMPUSES(campusLocations) {
      let aoiSelected = this.form.AOI
      console.log(aoiSelected)
      axios
        .get(
          `https://utienrollnowapi.azurewebsites.net/api/campus?code=bDeN0oLeTsR1vRII7lVh0HGaDiYLaDv//VavJtW6PzMOXZpf7yDmAg==&aoi=${aoiSelected}`
        )
        .then((response) => {
          // console.log(`response data`, response.data)
          this.campusLocations = response.data

          //console.log(`campusLocations`,this.campusLocations[0])
          return campusLocations
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    validateAOIS: function () {
      let storeAOIS = this.getAOIS()
      let hiddenVariationAOIS = this.getHiddenAOIS()
      let hiddenCampaignAOIS = this.getHIDDENCAMPAIGNAOIS()
      let visibleCampaignAOIS = this.getVISIBLECAMPAIGNAOIS();
      let currentCampaign = this.getCAMPAIGN()
      let isHiddenCampaign = hiddenCampaignAOIS.filter(
        (item) => item.campaign == currentCampaign
      )
      let isVisibleCampaign = visibleCampaignAOIS.filter(
        (item) => item.campaign == currentCampaign
      )
      //let groupedAOIS = this.getGroupedAOIS()
      // console.log("")
      // console.log(`Store AOIS: `, storeAOIS);
      // console.log(`Hidden Variations AOIS: `, hiddenVariationAOIS);
      // console.log("")
      // console.log(`Current Campaign: `, currentCampaign)
      // console.log(`Current Campaign Has Hidden AOIS `, isHiddenCampaign.length > 0)
      // console.log(`Total Hidden Campaign AOIS: `, hiddenCampaignAOIS)
      // console.log("")
      // console.log(`Current Campaign Has visible AOIS `, isVisibleCampaign.length > 0)
      // console.log(`Total Visible Campaign AOIS: `, visibleCampaignAOIS)
      //-------------
      //check if current campaign has any hidden aois, if so this takes precendent, and filter only those

      // 
      // if (isHiddenCampaign.length > 0) {
      //   let returnedAOIS = storeAOIS.filter(
      //     (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
      //   )
      //   return returnedAOIS
      // }
      // // else current campaign does not have hidden aois 
      // else {
      //   
      //   if (hiddenVariationAOIS) {
      //     let returnedAOIS = storeAOIS.filter(
      //       (item) => !hiddenVariationAOIS.includes(item.shortCode)
      //     )
      //     return returnedAOIS
      //   } 
      //   // else current variation does not have hidden aois
      //   else {
      //     return storeAOIS
      //   }
      // }
      //-----

      // if current campaign has hidden aois
      if (isHiddenCampaign.length > 0) {
        //console.log(`has hidden campaign aois`, isHiddenCampaign[0].aois)
        storeAOIS = storeAOIS.filter(
          (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
        )
      } 
      // if current variation has hidden aois
      if (hiddenVariationAOIS) {
        //console.log(`has hidden variations aois`, hiddenVariationAOIS)
        storeAOIS = storeAOIS.filter(
          (item) => !hiddenVariationAOIS.includes(item.shortCode)
        )
      } 

      // if current campaign has explicitly visible aois
      if (isVisibleCampaign.length > 0) {
        //console.log(`has visible campaign aois`, isVisibleCampaign[0].aois)
        let baseCampaignData = this.getAOIS();
        let visibleCampaignAOIShortCodes = isVisibleCampaign[0].aois;
        let visibleCampaignAOIData = this.removeFromArray(baseCampaignData, "shortCode", visibleCampaignAOIShortCodes);
        
        visibleCampaignAOIData.forEach((item) => {
          storeAOIS.push(item);
        });
      }
      return storeAOIS.sort((a, b) => {
        let textA = a.shortCode;
        let textB = b.shortCode;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    checkAOIS(index) {
      let storeAOIS = this.validateAOIS()
      let aoiList = this.validateAOIS(index).filter((aoi) => aoi.group == index)

      const groupAOIS = storeAOIS.reduce((acc, value) => {
        if (!acc[value.group]) {
          acc[value.group] = []
        }
        acc[value.group].push(value)
        return acc
      }, {})

      if (index) {
        console.log(`aoiList`, aoiList)
        return aoiList
      } else {
        console.log(`groupAOIS`, groupAOIS)
        return groupAOIS
      }

      // <!-- <b-form-select-option-group  v-for="(aoi, index) in checkAOIS()" :key="index" :label="`${index}`">
      //         <b-form-select-option v-for="(aoi, index) in checkAOIS(index)" :key="index" :value="`${aoi.shortCode}`">
      //           {{ aoi.name }}
      //         </b-form-select-option>
      //     </b-form-select-option-group> -->
    },
    validateCharacter(e) {
      let key = e.key

      if (/^\d+/g.test(key)) {
        e.preventDefault()
      }
    },
    validateNumber(e) {
      let key = e.key
      let goodKeys = [8, 9, 37, 38, 39, 40, 46]

      if (this.form.Country == "US") {
        //prevents non numbers
        if (!/^\d+/g.test(key)) {
          if (!goodKeys.includes(e.keyCode)) {
            // lets you backspace and use keyboard arrows
            e.preventDefault()
          }
        }
      }
    },
    formatZip() {
      let mask = this.form.ZipCode

      if (this.form.Country == "US") {
        //("country is us");
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        let formattedNumber = mask.replace(/[\D._\-()]+/g, "")

        // 2. Format
        mask = formattedNumber.substring(0, 5)
        //console.log("mask", mask);

        // 3. return the phone number
        this.form.ZipCode = mask
      }
    },
    formatMilitaryRelease() {
      let length = null
      let mask = this.form.MilitarySeparation

      // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
      let formattedNumber = mask.replace(/[\D._\-()]+/g, "")

      // 2. Get length of all digits without numeric characters
      length = mask.replace(/\D/g, "").toString().length

      // 3. Format
      if (length > 2) {
        mask =
          formattedNumber.substring(0, 2) +
          "/" +
          formattedNumber.substring(2, 6)
        this.form.MilitarySeparation = mask
      }

      // 4. return the phone number
      this.form.MilitarySeparation = mask
    },
    formatPhoneNumber() {
      let length = null
      let phoneNumber = this.form.PhoneNumber

      if (this.form.Country == "US") {
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        // 2. Clear out a leading 0 or 1
        let formattedNumber = phoneNumber
          .replace(/[\D._\-()]+/g, "")
          .replace(/^[0|1]/, "")
        //console.log(`formattedNumber:`, formattedNumber)

        // 3. Get length of all digits without numeric characters
        length = formattedNumber.replace(/\D/g, "").toString().length

        // 4. Format
        if (length > 0 && length < 4) {
          formattedNumber = "(" + formattedNumber
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 4 && length <= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6)
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6) +
            " - " +
            formattedNumber.substring(6, 10)
          this.form.PhoneNumber = formattedNumber
        }

        // 5. return the phone number
        this.form.PhoneNumber = formattedNumber
      }
    },
    formStartEvent() {
      this.$store.dispatch("DATA_LAYER_FORM_START_ACTION")
    },
    nextStep() {
      this.form.currentStep++
      //console.log(`moved to step ${this.form.currentStep}`);
    },
    previousStep() {
      this.form.currentStep--
      window.scrollTo(top)
    },
    submit() {
      // if (this.$v.$invalid) {
      // 	//  for (let key in Object.keys(this.$v.form)) {
      // 	//  const input = Object.keys(this.$v.form)[key];
      // 	// if (!this.$v.form[input].$error) {
      // 	// console.log("inside focus", this.$refs[input]);
      // 	// console.log(this.$refs[input]);
      // 	// this.$refs[input].focus();
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	console.log(`error submitting form`);
      // 	this.$router.push({ path: "/thankyou" });
      // 	// break;
      // 	// }
      // 	//}
      // } else {
      // 	console.log(this.form);
      // 	console.log(this.$store.state.formData);
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	this.$router.push({ path: "/thankyou" });
      // }
      let currentStep = this.form.currentStep
      event.preventDefault()
      //console.log(`submitting form step ${currentStep}`);
      if (currentStep == 1) {
        if (!this.$v.form.AOI.$invalid) {
          //console.log("form step 1 submission is valid");
          this.nextStep()
        } else {
          //console.log("form step 1 submission is invalid");
          this.$v.form.AOI.$touch()
        }
      } else if (currentStep == 2) {
        this.$v.$touch()

        /// Form-Submit DL Event
        window.dataLayer.push({
          event: "form-submit",
          formId: this.$store.state.formData.AcquisitionPoint,
          age: this.form.Age,
          areaOfInterest: this.form.AOI,
          campus: this.form.Campus,
          //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
          //'email': dataPayload.EmailAddresses[0].Addresses,
          //'errorMessage': errMsg,
          //'formName': 'Paid Media Landing Page' + formName,
          formName: "PMLPv2 Two Step Form",
          formType: "inquiry",
          //'formValidationStatus': "",
          inquiryDate: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
          isMilitary: this.form.isMilitary,
          leadId: this.$store.state.formData.inqId,
          //'numberOfInquiries': inqcount != undefined ? inqcount : "",
          referralPage: document.referrer,
          stepNumber: this.form.currentStep
          //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
        })

        if (!this.$v.$invalid) {
          //console.log("form step 2 submission is valid");
          //console.log(`form`, this.form);
          let domain = this.form.email.split('@').pop().toLowerCase();

          if(this.form.email && this.isRestrictedDomain(domain)){ 
           this.$store.dispatch("SET_FORM_COMPLETE", {...this.form,markComplete:true});
           this.$router.push({ path: "/thankyou" });
           return;
          }
          //---- Update Form Acquisition Point ----//
          //this.$store.dispatch("SET_ACQUISITION_POINT_ACTION", this.form.AcquisitionPoint)
          this.submitted(this.form.AcquisitionPoint);
          //console.log(`acquisitionpoint:`, this.getACQUISITIONPOINT())
          //---------------------------------------//
          this.$store.dispatch("SET_FORM_DATA_ACTION", this.form)
          this.$router.push({ path: "/thankyou" })
        } else {
          // console.log("form step 2 submission is invalid");
          //console.log(`form invalid`, this.form);
        }
      }
    },
    isMobile() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      // console.log(`current breakpoint: `, currentBreakpoint)
      if (
        currentBreakpoint == "xs" ||
        currentBreakpoint == "sm" ||
        currentBreakpoint == "md"
      ) {
        return true
      } else {
        return false
      }
    },
    responsiveImage() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let content = this.data.content
      let mobileImg = ""
      let desktopImg = ""

      mobileImg = content.mobileImg
      desktopImg = content.desktopImg

      if (
        mobileImg !== "" &&
        (currentBreakpoint == "xs" || currentBreakpoint == "sm")
      ) {
        return mobileImg
      } else {
        return desktopImg
      }
    },
    responsiveGradient() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let techSettings = this.data.techSettings
      let mobileGradient = Boolean
      let desktopGradient = Boolean

      mobileGradient = techSettings.mobileGradientOverlay
      desktopGradient = techSettings.desktopGradientOverlay

      if (currentBreakpoint == "xs" || currentBreakpoint == "sm") {
        // console.log(`mobile gradient: `, mobileGradient)
        return mobileGradient
      } else {
        // console.log(`desktop gradient: `, desktopGradient)
        return desktopGradient
      }
    },
    fade(step) {
      let currentStep = this.form.currentStep
      if (step == 1) {
        if (currentStep == 1) {
          return "fadeIn"
        } else {
          return "fadeOut"
        }
      } else if (step == 2) {
        if (currentStep == 2) {
          return "fadeIn"
        } else {
          return "fadeOut"
        }
      } else {
        return null
      }
    }
  },
  validations: {
    form: {
      AOI: { required },
      FirstName: { required, maxLength: maxLength(30) },
      LastName: { required, maxLength: maxLength(30) },
      Age: { required, between: between(14, 80) },
      ZipCode: { required, minLength: minLength(5), maxLength: maxLength(15) },
      Country: { required, maxLength: maxLength(100) },
      GradYear: {
        required: requiredIf(function () {
          let condition = this.form.Age >= 14 && this.form.Age < 20
          return condition
        }),
        maxValue: maxValue(new Date().getFullYear() + 10),
        minLength: minLength(4),
        maxLength: maxLength(4),
        numeric
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      PhoneNumber: {
        required,
        minLength: usPhoneLength,
        maxLength: maxLength(20)
      },
      MilitarySeparation: {
        required: requiredIf(function () {
          return this.form.isMilitary
        }),
        minLength: minLength(7),
        maxLength: maxLength(7)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.aoi-select{
      margin-bottom: 1rem;
    margin-top: 1rem;
}
.fadeOut {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  bottom: 0px;
  overflow: hidden;
  //transition: visibility 0s linear 300ms, opacity 300ms;
}

.fadeIn {
  visibility: visible;
  position: static;
  opacity: 1;
  transition: all 0s, opacity 300ms;
}

.option-select-text {
  /* Change program to see other campuses. */
  height: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Off Black - 800 */

  color: #25292b;
}

.option-selection {
  margin-top: 30px;
}

.two-step-form {
  @media screen and (min-width: 768px) {
    &.formOverlap {
      position: absolute;
      z-index: 99;
    }
  }
  @media screen and (min-width: 992px) {
    form {
      min-width: 385px;
    }
  }
  form {
    background-color: #f6f6fb;
    border: 1px solid #949494;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.20);

    &.light-blue-theme {
    background-color: #F6F6FB;
    color: black;
    }

    &.white-theme {
    background-color: white;
    color: black;
    }

    &.grey-theme, &.gray-theme, &.undefined-theme {
    background-color: #f2f5f7;
    color: black;
    }

    h2 {
      text-transform: uppercase;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #003653;
      text-align: center;
    }
  }

  input:not([type="checkbox"]),
  select {
    width: 100%;
    font-size: 16px;
  }

  [type="color"],
  [type="date"],
  [type="datetime-local"],
  [type="datetime"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 0.6rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px #{'hsl(0deg 0% 4% / 10%)'};
    color: #0a0a0a;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  select {
    height: 2.4375rem;
    margin: 0 0 0.6rem;
    padding: 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    font-family: inherit;
    font-size: 1rem;
    line-height: normal;
    color: #0a0a0a;
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding-right: 1.5rem;
    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  label {
    margin-bottom: 5px;
  }

  .field-row {
    display: inline-block;
  }

  .disclosure-container {
    font-size: 9px;
  }

  .progress-bar {
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin: 20px 0;
    background-color: #fff;
    height: 20px;
    overflow: hidden;
    width: 100%;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.10);

    .progress-bar-fg {
      height: 20px;
      transition: all linear 0.5s;

      &.blue-theme,
      &.-theme,
      &.undefined-theme {
        background-color: #0086d2;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#0086d2),
          to(#196bb8)
        );
        background-image: -webkit-linear-gradient(top, #0086d2, #196bb8);
        background-image: -moz-linear-gradient(top, #0086d2, #196bb8);
        // background-image: -ms-linear-gradient(top, #0086d2, #196bb8);
        // background-image: -o-linear-gradient(top, #0086d2, #196bb8);
        // background-image: linear-gradient(top, #0086d2, #196bb8);
      }

      &.red-theme {
        background-color: #d50019;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#d50019),
          to(#b60317)
        );
        background-image: -webkit-linear-gradient(top, #d50019, #b60317);
        background-image: -moz-linear-gradient(top, #d50019, #b60317);
        // background-image: -ms-linear-gradient(top, #d50019, #b60317);
        // background-image: -o-linear-gradient(top, #d50019, #b60317);
        // background-image: linear-gradient(top, #d50019, #b60317);
      }

      &.green-theme {
        background-color: #0c3;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#0c3),
          to(rgb(0, 163, 41))
        );
        background-image: -webkit-linear-gradient(top, #0c3, rgb(0, 163, 41));
        background-image: -moz-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: -ms-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: -o-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: linear-gradient(top, #0c3, rgb(0, 163, 41));
      }

      &.one-third {
        width: 33%;
      }

      &.two-third {
        width: 66%;
      }
    }
  }

  .submit-button {
    //border: 1px solid #0c3;
    text-align: center;
    position: relative;
    color: #fff;
    overflow: hidden;
    //background-color: #0c3 !important;
    padding: 0 28px;
    font-size: 22px;
    width: 100%;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    line-height: 31px;

    &:before {
      //background-color: #f6001d 1%;
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(#f6001d 1%),
      //   to(#b60002 100%)
      // );
      // background-image: -webkit-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -moz-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -ms-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -o-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: linear-gradient(top, #f6001d 1%, #b60002 100%);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all linear 0.15s;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      transition-delay: 0s;
    }

    &:after {
      content: "\f054";
      font-family: FontAwesome;
      padding-left: 5px;
      font-size: 16px;
      vertical-align: middle;
      position: absolute;
      top: 0;
    }

    &:hover {
      background: #fff !important;
      // color: #0c3!important;
      // box-shadow: 0 0 5px #0c3;
    }

    &.red-theme,
    &.-theme,
    &.undefined-theme {
      border: 1px solid #d50019;
      background-color: #d50019;

      &:before {
        background-color: #f6001d 1%;
      }

      &:hover {
        color: #d50019 !important;
        box-shadow: 0 0 5px #d50019;
      }

      &:focus {
        outline: 1px auto #d50019;
      }
    }

    &.green-theme {
      border: 1px solid #0c3;
      background-color: #0c3;

      &:before {
        background-color: #0c3 1%;
      }

      &:hover {
        color: #0c3 !important;
        box-shadow: 0 0 5px #0c3;
      }

      &:focus {
        outline: 1px auto #0c3;
      }
    }

    &.blue-theme {
      border: 1px solid #008ed4;
      background-color: #008ed4;

      &:before {
        background-color: #008ed4 1%;
      }

      &:hover {
        color: #008ed4 !important;
        box-shadow: 0 0 5px #008ed4;
      }

      &:focus {
        outline: 1px auto #008ed4;
      }
    }
  }

  .form-control {
    padding-right: 2rem;
  }
}
</style>

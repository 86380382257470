var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'navbar-container '},[_c('b-navbar',{style:({ backgroundColor: _vm.data.color.background }),attrs:{"fixed":_vm.isSticky}},[_c('b-container',{attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('b-row',{staticClass:"mx-0",attrs:{"align-h":"between"}},[_c('b-col',{staticClass:"logo-container px-0",attrs:{"cols":"3"}},[_c('img',{staticClass:"ml-0 mr-0 pl-0 pr-0",attrs:{"src":_vm.data.logo,"alt":_vm.data.alt || 'This is a logo'}})]),_c('b-col',{staticClass:"cto-container px-0 my-auto",attrs:{"cols":"8"}},[_c('div',{staticClass:"cto-headline d-none d-md-block",style:({ color: _vm.data.color.headline })},[_vm._v(" "+_vm._s(_vm.data.headline)+" ")]),_c('div',{staticClass:"cto-number"},[(
                _vm.data.cto.mobile &&
                (_vm.activeBreakpoint('xs') || _vm.activeBreakpoint('sm'))
              )?_c('a',{class:{ ctoBorder: _vm.data.cto.borderMobile },style:({
                color: _vm.data.color.cto,
                borderColor: _vm.data.color.ctoBorder
              }),attrs:{"href":'tel:' + _vm.data.phoneNumber.replace(/\D/g, '')},on:{"click":function($event){return _vm.engaged()}}},[_c('b-icon-telephone-fill',{staticClass:"cto-icon mr-1"}),_vm._v(_vm._s(_vm.data.cto.mobile))],1):_c('a',{class:{ ctoBorder: _vm.data.cto.borderDesktop },style:({
                color: _vm.data.color.cto,
                borderColor: _vm.data.color.ctoBorder
              }),attrs:{"href":'tel:' + _vm.data.phoneNumber.replace(/\D/g, '')},on:{"click":function($event){return _vm.engaged()}}},[_c('b-icon-telephone-fill',{staticClass:"cto-icon mr-1"}),_vm._v(_vm._s(_vm.data.cto.desktop))],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
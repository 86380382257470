<template>
  <div :class="'ordered-list container-fluid pr-4 pb-1 uti-component ' + data.techSettings.backgroundColor + '-theme'">
    <h2 :class="'text-' + data.techSettings.titleAlignment + ' title pt-2 font-weight-bold'">{{ data.title }}</h2>
    <ol class="text-left pl-4">
      <li v-for="(item, index) in data.list" :key="index" v-html="item" class="pl-0"></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "OrderedList",
  props: ["data"]
}
</script>

<style lang="scss" scoped>
.ordered-list {
  &.uti-component {
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    .container {
      padding: 0;
    }
  }

  &.blue-theme,
  &.-theme {
    background-color: #003653;

    h2,
    h3,
    p {
      color: #fff;
    }

    svg.accessory {
      color: #b2b2b2;
    }
  }

  &.white-theme {
    background-color: #fff;

    h2,
    h3,
    p {
      color: #000;
    }

    svg.accessory {
      color: #737373;
    }
  }

  &.gray-theme,
  &.grey-theme {
    background-color: #f2f5f7;
    color: #003653;

    h2,
    h3,
    p {
      color: #003653;
    }

    svg.accessory {
      color: #0086d2;
    }
  }
}
h2 {
  font-family: Tungsten-Semibold;
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 400;
}
li {
  font-size: 18px;
  line-height: 22px;
  padding: 7px;
}
</style>

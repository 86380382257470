<template>
  <b-container
    fluid
    :class="['content-block', this.data.techSettings.backgroundColor + '-theme', this.hasPaddingY, 'uti-component', this.formOverlap]"
    id="content-block"
  >
    <b-container :fluid="data.techSettings.fullWidth">
      <b-row class="paragraphs-container">
        <b-col
          v-for="(item, index) in data.paragraphs"
          :key="index"
          v-html="item.html"
          :class="`vhtml paragraph text-${item.textAlign} col-${data.techSettings.xsColumns} col-md-${data.techSettings.mdColumns}`"
        >
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "ContentBlock",
  props: ["data"],
  mounted: function() {
    this.data.techSettings.srcScripts.forEach(arg => {
      this.injectJS('SRC', arg);
    });
    this.data.techSettings.exeScripts.forEach(arg => {
      this.injectJS('EXE', arg);
    });
  },
  computed: {
    formOverlap() {
      let isOverlap = this.data.techSettings.formOverlap
      if (isOverlap) {
        return "formOverlap"
      } else return "formExpand"
    }
  },
  methods: {
    hasPaddingY() {
      if (this.data.techSettings.componentPadding == true) {
        return "py-5"
      } else {
        return ""
      }
    },
    injectJS(type, arg) {
      let injectDiv = document.getElementById('content-block');
      let newScript = document.createElement('script');

      if (type == 'SRC') {
        newScript.src = arg;
        injectDiv.appendChild(newScript);
      } else if ( type == "EXE") {
        window.addEventListener('load', () => {
          newScript.text = arg;
          injectDiv.appendChild(newScript)
        })
      } else return;
    }
  }
}
</script>

<style lang="scss" scoped>
.content-block {
  &.blue-theme {
    background-color: #003653;
    color: #fff;

    .accessory {
      color: #b2b2b2;
    }
  }

  &.white-theme,
  &.-theme {
    background-color: #fff;
    color: #000;

    .accessory {
      color: #737373;
    }
  }

  &.gray-theme,
  &.grey-theme {
    background-color: #f2f5f7;
    color: #003653;

    .accessory {
      color: #0086d2;
    }
  }


  @media screen and (min-width: 768px) {
    &.formOverlap {
      position: absolute;
      z-index: 99;
    }
  }
}

.paragraph >>> {
  p {
    line-height: 24px;
    font-size: 20px !important;
    font-weight: 400;
  }

  h2 {
    font-family: Tungsten-Semibold;
    font-size: 36px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  li {
    font-size: 18px;
    line-height: 22px;
    padding: 7px;
  }
}
</style>
<template>
  <div>
    <!-- Mobile Hero -->
    <b-container
      v-if="data.content.title"
      fluid
      :style="[
        'background-image: url(' + responsiveImage() + ');',
        data.techSettings.stackedMobileHero ? 'min-height: 250px;' : '',
        xsImageAnchor,
        smImageAnchor,
        mdImageAnchor,
        lgImageAnchor,
        xlImageAnchor
      ]"
      :class="`mobile hero-with-image ${data.techSettings.backgroundColor}-theme px-0 d-lg-none`"
    >
      <div
        :class="[{ 'hero-overlay': responsiveGradient() }, 'd-lg-none']"
      ></div>
      <b-container
        :class="[
          this.$store.getters.CURRENT_BREAKPOINT == 'lg' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'xl'
            ? 'desktop'
            : '',
          'px-sm-0',
          'hero-container'
        ]"
      >
        <div
          :style="[
            xsHeroTextWidth,
            smHeroTextWidth,
            mdHeroTextWidth,
            lgHeroTextWidth,
            xlHeroTextWidth
          ]"
          :class="[
            'hero-text',
            'col-12',
            'py-5',
            'px-0',
            `text-${data.techSettings.mobileTextAlignment}`,
            this.getDesktopTextAlignment
          ]"
        >
          <h1
            v-if="data.content.title && !data.techSettings.stackedMobileHero"
            class="h2"
            v-html="data.content.title"
          ></h1>
          <p
            v-if="data.content.copy && !data.techSettings.stackedMobileHero"
            class="hero-copy d-none d-md-block"
            v-html="data.content.copy"
          ></p>
          <p
            v-if="data.content.subCopy && !data.techSettings.stackedMobileHero"
            class="hero-sub-copy d-none d-md-block"
            v-html="data.content.subCopy"
          ></p>
          <ul
            v-if="data.content.list[0] && !data.techSettings.stackedMobileHero"
            :class="[
              {
                hiddenBullets: !data.techSettings.listBullets
              },
              'hero-list',
              'd-none',
              'd-md-block'
            ]"
          >
            <li
              v-for="(item, index) in data.content.list"
              :key="index"
              v-html="item"
            ></li>
          </ul>
        </div>
      </b-container>
    </b-container>
    <!-- Mobile Stacked Container -->
    <b-container
      class="d-block d-lg-none mobile-stacked-container my-3"
      v-if="data.techSettings.stackedMobileHero"
    >
      <b-container
        :fluid="data.techSettings.fullWidth"
        class="px-md-0 hero-container"
      >
        <div
          :class="`bottom-stack py-3 text-${data.techSettings.mobileTextAlignment}`"
        >
          <h1
            v-if="data.content.title"
            class="mb-4"
            v-html="data.content.title"
          ></h1>
          <p
            v-if="data.content.copy"
            class="hero-copy"
            v-html="data.content.copy"
          ></p>
          <p
            v-if="data.content.subCopy"
            class="hero-sub-copy"
            v-html="data.content.subCopy"
          ></p>
          <ul
            v-if="data.content.list[0]"
            :class="[
              {
                hiddenBullets: !data.techSettings.listBullets
              },
              'hero-list',
              'mb-0'
            ]"
          >
            <li
              v-for="(item, index) in data.content.list"
              :key="index"
              v-html="item"
              class="mb-2"
            ></li>
          </ul>
        </div>
      </b-container>
    </b-container>
    <!-- Desktop Hero -->
    <b-container
      fluid
      :style="[
        'background-image: url(' + responsiveImage() + ');',
        xsImageAnchor,
        smImageAnchor,
        mdImageAnchor,
        lgImageAnchor,
        xlImageAnchor
      ]"
      :class="`desktop hero-with-image ${data.techSettings.backgroundColor}-theme px-0`"
    >
      <div :class="{ 'hero-overlay': responsiveGradient() }"></div>
      <b-container
        :fluid="
          this.$store.getters.CURRENT_BREAKPOINT == 'xs' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'sm' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'md'
            ? true
            : false
        "
        :class="[
          this.$store.getters.CURRENT_BREAKPOINT == 'lg' ||
          this.$store.getters.CURRENT_BREAKPOINT == 'xl'
            ? 'desktop'
            : '',
          'px-0',
          'hero-container'
        ]"
      >
        <div
          :style="[
            xsHeroTextWidth,
            smHeroTextWidth,
            mdHeroTextWidth,
            lgHeroTextWidth,
            xlHeroTextWidth
          ]"
          :class="[
            'hero-text',
            'col-12',
            'py-5',
            'px-0',
            `text-${data.techSettings.desktopTextAlignment}`,
            this.getDesktopTextAlignment,
            'd-none',
            'd-lg-block'
          ]"
        >
          <h1
            v-if="data.content.title"
            class="h2"
            v-html="data.content.title"
          ></h1>
          <p
            v-if="data.content.copy"
            class="hero-copy d-none d-sm-block"
            v-html="data.content.copy"
          ></p>
          <p
            v-if="data.content.subCopy"
            class="hero-sub-copy d-none d-sm-block"
            v-html="data.content.subCopy"
          ></p>
          <ul
            v-if="data.content.list[0]"
            :class="[
              {
                hiddenBullets: !data.techSettings.listBullets
              },
              'hero-list',
              'd-none',
              'd-sm-block'
            ]"
          >
            <li
              v-for="(item, index) in data.content.list"
              :key="index"
              v-html="item"
            ></li>
          </ul>
        </div>
        <div :class="['two-step-form', this.formOverlap]">
          <form
            action=""
            :class="[
              this.$store.getters.CURRENT_BREAKPOINT == 'xs' ||
              this.$store.getters.CURRENT_BREAKPOINT == 'sm' ||
              this.$store.getters.CURRENT_BREAKPOINT == 'md'
                ? 'col-12'
                : 'col-5',
              'gray-theme',
              'py-3',
              this.getDesktopFormAlignment
            ]"
            @click="engaged('Form')"
            @submit.prevent="submit"
          >
            <div class="header-container text-center">
              <div class="step-1-header" v-if="form.currentStep == 1">
                <div class="headline">
                  <h2
                    v-if="isMobile()"
                    v-html="data.content.stepOne.title.mobile"
                  ></h2>
                  <h2 v-else v-html="data.content.stepOne.title.desktop"></h2>
                </div>
                <div class="subHeadline">
                  <p
                    v-if="isMobile()"
                    v-html="data.content.stepOne.subTitle.mobile"
                  ></p>
                  <p v-else v-html="data.content.stepOne.subTitle.desktop"></p>
                </div>
              </div>
              <div class="step-2-header" v-if="form.currentStep == 2">
                <div class="headline">
                  <h2
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.title.mobile"
                  ></h2>
                  <h2 v-else v-html="data.content.stepTwo.title.desktop"></h2>
                </div>
                <div class="subHeadline">
                  <p
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.subTitle.mobile"
                  ></p>
                  <p v-else v-html="data.content.stepTwo.subTitle.desktop"></p>
                </div>
              </div>

              <div
                class="progress-bar"
                v-show="!this.data.techSettings.formStepOverride"
              >
                <div
                  :class="[
                    `progress-bar-fg ${data.techSettings.progressBarColor}-theme`,
                    {
                      'one-third': form.currentStep == 1,
                      'two-third': form.currentStep == 2
                    }
                  ]"
                ></div>
              </div>
              <div :class="[fade(1), 'step-1-container']">
                <!-- Fields Group -->
                <div class="fields-container">
                  <div class="row">
                    <div class="col-12">
                      <div class="">
                        <label
                          for="aoiSelection"
                          :class="{ hasError: $v.form.AOI.$error }"
                          >What are you interested in?
                          <template v-if="$v.form.AOI.$error"
                            ><img src="" alt=""
                          /></template>
                        </label>
                        <div>
                          <b-form-select
                            name="interest"
                            id="aoiSelection"
                            v-model="form.AOI"
                            :class="{ isInvalidInput: $v.form.AOI.$error }"
                            @blur="$v.form.AOI.$touch()"
                            @input="formStartEvent()"
                            @focus="engaged('AOISelection')"
                            @change="selected('AOI')"
                          >
                            <option disabled value="">-- Select --</option>
                            <!-- NEW HOTNESS -->
                            <!-- <b-form-select-option-group  v-for="(aoi, index) in checkAOIS()" :key="index" :label="`${index}`">
                                    <b-form-select-option v-for="(aoi, index) in checkAOIS(index)" :key="index" :value="`${aoi.shortCode}`">
                                      {{ aoi.name }}
                                    </b-form-select-option>
                                </b-form-select-option-group> -->

                            <!-- Keep untill otherwise told -->
                            <option
                              v-for="(aoi, index) in validateAOIS()"
                              :key="index"
                              :value="`${aoi.shortCode}`"
                            >
                              {{ aoi.name }}
                            </option>
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Submit Group -->
                <div class="submit-container text-center">
                  <button
                    :class="`submit-button col-6 my-3 step-one-submit ${data.techSettings.submitButtonColor}-theme`"
                    @click="engaged('StepOneSubmit')"
                  >
                    <span
                      v-if="isMobile()"
                      v-html="data.content.stepOne.CTA.mobile"
                    ></span>
                    <span
                      v-else
                      v-html="data.content.stepOne.CTA.desktop"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div :class="[fade(2), 'step-2-container']">
              <!-- Fields Group -->
              <div class="fields-container">
                <div class="row">
                  <div class="col-6">
                    <label
                      for="firstNameInput"
                      :class="['mr-2', { hasError: $v.form.FirstName.$error }]"
                      >First Name</label
                    >
                    <div>
                      <b-form-input
                        type="text"
                        id="firstNameInput"
                        name="firstName"
                        placeholder="First Name"
                        maxlength="30"
                        v-model.trim="form.FirstName"
                        :class="{ isInvalidInput: $v.form.FirstName.$error }"
                        :state="getContextualState('FirstName', $v)"
                        @keydown="validateCharacter($event)"
                        @blur="$v.form.FirstName.$touch(), formStartEvent()"
                        @focus="engaged('FirstName')"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="lastNameInput"
                      :class="{ hasError: $v.form.LastName.$error }"
                      >Last Name</label
                    >
                    <div>
                      <b-form-input
                        type="text"
                        id="lastNameInput"
                        name="lastName"
                        placeholder="Last Name"
                        maxlength="30"
                        v-model.trim="form.LastName"
                        :class="{ isInvalidInput: $v.form.LastName.$error }"
                        :state="getContextualState('LastName', $v)"
                        @keydown="validateCharacter($event)"
                        @blur="$v.form.LastName.$touch()"
                        @focus="engaged('LastName')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label
                      for="ageSelection"
                      :class="{ hasError: $v.form.Age.$error }"
                      >Age</label
                    >
                    <div>
                      <select
                        name="age"
                        id="ageSelection"
                        v-model.number="form.Age"
                        :class="{ isInvalidInput: $v.form.Age.$error }"
                        @blur="$v.form.Age.$touch()"
                        @focus="engaged('Age')"
                        @change="selected('Age')"
                      >
                        <option disabled value="">Age</option>
                        <option
                          v-for="(age, index) in 67"
                          :key="index"
                          :value="`${age + 13}`"
                        >
                          {{ age + 13 }}
                        </option>
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="zipCodeInput"
                      :class="{ hasError: $v.form.ZipCode.$error }"
                      >Zip</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="zipCodeInput"
                        name="zipCode"
                        placeholder="90210"
                        :minlength="getInputLength('zip', 'min')"
                        :maxlength="getInputLength('zip', 'max')"
                        v-model="form.ZipCode"
                        @keydown="validateNumber($event)"
                        @input="formatZip()"
                        :class="{ isInvalidInput: $v.form.ZipCode.$error }"
                        :state="getContextualState('ZipCode', $v)"
                        @blur="$v.form.ZipCode.$touch()"
                        @focus="engaged('Zip')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="form.Age !== '' && form.Age < 20" class="col-6">
                    <label
                      for="graduationYearInput"
                      :class="{ hasError: $v.form.GradYear.$error }"
                      >High School <br />Graduation Year</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="graduationYearInput"
                        name="graduationYearInput"
                        placeholder="YYYY"
                        maxlength="4"
                        v-model.number="form.GradYear"
                        @keydown="validateNumber($event)"
                        :class="{ isInvalidInput: $v.form.GradYear.$error }"
                        :state="getContextualState('GradYear', $v)"
                        @blur="$v.form.GradYear.$touch()"
                        @focus="engaged('HighSchoolGradYear')"
                      ></b-form-input>
                    </div>
                  </div>
                  <div
                    :class="
                      form.Age !== '' && form.Age < 20 ? 'col-6' : 'col-12'
                    "
                  >
                    <label
                      for="countryInput"
                      :class="{ hasError: $v.form.Country.$error }"
                      ><br
                        v-if="form.Age !== '' && form.Age < 20"
                        class="col-6"
                      />Country</label
                    >

                    <div>
                      <select
                        name="countryInput"
                        id="countryInput"
                        v-model="form.Country"
                        :class="{ isInvalidInput: $v.form.Country.$error }"
                        @blur="$v.form.Country.$touch()"
                        @focus="engaged('Country')"
                        @change="selected('Country')"
                      >
                        <option
                          v-for="(country, index) in getCOUNTRIES()"
                          :key="index"
                          :value="`${country.code}`"
                        >
                          {{ country.country }}
                        </option>
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label
                      for="phoneInput"
                      :class="{ hasError: $v.form.PhoneNumber.$error }"
                      >Phone</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="phoneInput"
                        name="phone_number"
                        placeholder="( ___ ) ___-____"
                        :class="{ isInvalidInput: $v.form.PhoneNumber.$error }"
                        @blur="$v.form.PhoneNumber.$touch()"
                        v-model="form.PhoneNumber"
                        :minlength="getInputLength('phone', 'min')"
                        :maxlength="getInputLength('phone', 'max')"
                        :state="getContextualState('PhoneNumber', $v)"
                        @focus="engaged('Phone')"
                        @input="formatPhoneNumber()"
                        @keydown="validateNumber($event)"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6">
                    <label
                      for="emailInput"
                      :class="{ hasError: $v.form.email.$error }"
                      >Email</label
                    >
                    <div>
                      <b-form-input
                        type="email"
                        id="emailInput"
                        name="email"
                        placeholder="____@____.___"
                        maxlength="100"
                        v-model.trim="form.email"
                        :class="{ isInvalidInput: $v.form.email.$error }"
                        :state="getContextualState('email', $v)"
                        @blur="$v.form.email.$touch()"
                        @focus="engaged('Email')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- Personal Info -->
                <div class="row">
                  <div class="col-12" v-if="form.Age >= 18">
                    <input
                      type="checkbox"
                      id="isMilitaryCheckBox"
                      name="isMilitaryCheckBox"
                      class="mr-2"
                      v-model="form.isMilitary"
                      @click="engaged('IsMilitary')"
                    /><label for="isMilitaryCheckBox" style="display: inline"
                      >Are you current or former U.S. Military, Guard, or
                      Reserve?</label
                    >
                  </div>
                  <div class="col-6 mt-2" v-if="form.isMilitary">
                    <label
                      for="militaryReleaseYearInput"
                      :class="{ hasError: $v.form.MilitarySeparation.$error }"
                      >Military Release Date</label
                    >
                    <div>
                      <b-form-input
                        type="tel"
                        id="militaryReleaseYearInput"
                        name="militaryReleaseYearInput"
                        v-model="form.MilitarySeparation"
                        @keydown="validateNumber($event)"
                        @input="formatMilitaryRelease()"
                        placeholder="MM/YYYY"
                        maxlength="7"
                        :class="{
                          isInvalidInput: $v.form.MilitarySeparation.$error
                        }"
                        :state="getContextualState('MilitarySeparation', $v)"
                        @blur="$v.form.MilitarySeparation.$touch()"
                        @focus="engaged('MilitaryReleaseDate')"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Disclosure Group -->
              <div class="ewc disclosure-container bodySmall my-4">
                <p>
                  By submitting this form, I agree that Universal Technical
                  Institute, Inc., Custom Training Group, Inc., and their
                  representatives may email, call, and / or text me with
                  marketing messages about educational programs and services, as
                  well as for school - related communications, at any phone
                  number I provide, including a wireless number, using
                  prerecorded calls or automated technology. I understand that
                  my consent is not required to apply, enroll or make any
                  purchase.
                </p>
                <p>
                  By submitting this form, I further understand and agree that
                  all information provided is subject to UTI’s Privacy Policy
                  available at
                  <a href="https://www.uti.edu/privacy-policy" target="_blank" rel="noopener">www.uti.edu/privacy-policy</a>
                  and UTI's SMS Terms and Conditions available at 
                  <a href='https://www.uti.edu/sms-terms-conditions' target="_blank" rel="noopener">www.uti.edu/sms-terms-conditions</a>.
                </p>
              </div>
              <!-- Submit Group -->
              <div class="submit-container text-center">
                <button
                  :class="`submit-button final-submit-button my-3 col-6 ${data.techSettings.submitButtonColor}-theme`"
                  @click="engaged('StepTwoSubmit')"
                >
                  <span
                    v-if="isMobile()"
                    v-html="data.content.stepTwo.CTA.mobile"
                  ></span>
                  <span v-else v-html="data.content.stepTwo.CTA.desktop"></span>
                </button>
                <div v-show="!this.data.techSettings.formStepOverride">
                  <a href="" v-on:click.prevent="previousStep">Previous Step</a>
                </div>
              </div>
            </div>
            <!-- <SchoolLogoBar :data="SchoolLogoBar.data"/> -->
          </form>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
// Info on the template above. :v and $v are used to bind the components value for validation. $v is a global object that can be console logged or displayed on the DOM
//$v contains all the validation that is used for the forms such as required:true/false if it is dirty or not.....etc
//For more info on the $v object a link for vuelidate is here. https://vuelidate.js.org/

///Validation Imports
import {
  required,
  requiredIf,
  email,
  between,
  maxLength,
  minLength,
  maxValue,
  numeric
} from "vuelidate/lib/validators"
//import  SchoolLogoBar  from "../Footer/SchoolLogoBar.vue";

const usPhoneLength = (value, data) => {
  if (data.Country == "US") {
    return minLength(16);
  } else return minLength(0);
}

export default {
  name: "HeroForm",
  props: ["data"],
  components: {
    ...mapGetters([
      "AOIS",
      "COUNTRIES",
      "DIPLOMAS",
      "CURRENT_BREAKPOINT",
      "CAMPAIGN",
      "HIDDENCAMPAIGNAOIS",
      "RESTRICTED_EMAIL_DOMAINS_DATA"
    ])
    //SchoolLogoBar,
  },
  data() {
    return {
      form: {
        AOI: "",
        FirstName: "",
        LastName: "",
        email: "",
        Education: "",
        GradYear: "",
        ZipCode: "",
        Age: "",
        Country: "",
        PhoneNumber: "",
        isMilitary: false,
        MilitarySeparation: "",
        currentStep: 1,
        EWC: ""
      },
      SchoolLogoBar: {
        data: {
          techSettings: {
            backgroundColor: "white"
          },
          title:
            "UTI technical schools include training programs in Automotive, Collision Repair, Diesel, Marine, Motorcycle, NASCAR, CNC Machining and Welding.",
          schools: [
            {
              name: "Motorcycle Mechanics Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-motorcycle-light-transparent.png",
              alt: "Logo for Motorcycle Mechanics Institute, MMI"
            },
            {
              name: "Marine Mechanics Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-marine-light-transparent.png",
              alt: "Logo for Marine Mechanics Institute, MMI"
            },
            {
              name: "NASCAR Technical Institute",
              img: "https://utieducdn.blob.core.windows.net/cdn/PMLP-rev.uti.edu/logo-NASCAR-light-transparent.png",
              alt: "Logo for NASCAR Technical Institute, NTI"
            }
          ]
        }
      },
      groupAOIS: []
    }
  },
  beforeMount() {
    if (this.data.techSettings.formStepOverride) {
      this.form.currentStep = this.data.techSettings.formStepOverride
        .currentStep
        ? this.data.techSettings.formStepOverride.currentStep
        : 1
      this.form.AOI = this.data.techSettings.formStepOverride.defaultAOI
        ? this.data.techSettings.formStepOverride.defaultAOI
        : ""
    }
    console.log(this.$route.query);
    this.initializeInquiryPayload(this.$route.query)
  },
  mounted() {
    this.getEwc()
    // this.createLeadGUID();
    // this.getGAClient();
    //this.createQueryParams(this.$route.query);
    window.dataLayer = window.dataLayer || []
  },
  computed: {
    xsImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let statement = ""
      let hero = breakpoint.hero
      let positionType = hero.positionType
      let height = hero.height

      if (positionType == "px") {
        statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
      } else {
        statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
      }

      return {
        "--xs-image-anchor": statement,
        "--xs-hero-height": height
      }
    },
    smImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let statement = ""
      let hero = breakpoint.hero
      let positionType = hero.positionType
      let height = hero.height

      if (positionType == "px") {
        statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
      } else {
        statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
      }

      return {
        "--sm-image-anchor": statement,
        "--sm-hero-height": height
      }
    },
    mdImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let statement = ""
      let hero = breakpoint.hero
      let positionType = hero.positionType
      let height = hero.height

      if (positionType == "px") {
        statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
      } else {
        statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
      }

      return {
        "--md-image-anchor": statement,
        "--md-hero-height": height
      }
    },
    lgImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let statement = ""
      let hero = breakpoint.hero
      let positionType = hero.positionType
      let height = hero.height

      if (positionType == "px") {
        statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
      } else {
        statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
      }

      return {
        "--lg-image-anchor": statement,
        "--lg-hero-height": height
      }
    },
    xlImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let statement = ""
      let hero = breakpoint.hero
      let positionType = hero.positionType
      let height = hero.height

      if (positionType == "px") {
        statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
      } else {
        statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
      }

      return {
        "--xl-image-anchor": statement,
        "--xl-hero-height": height
      }
    },
    xsHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--xs-hero-text-width": statement
      }
    },
    smHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--sm-hero-text-width": statement
      }
    },
    mdHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--md-hero-text-width": statement
      }
    },
    lgHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--lg-hero-text-width": statement
      }
    },
    xlHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--xl-hero-text-width": statement
      }
    },
    getDesktopFormAlignment() {
      let alignment = this.data.techSettings.desktopFormAlignment

      if (alignment) {
        return "desktopFormAlign-" + alignment
      } else {
        return "desktopFormAlign-right"
      }
    },
    getDesktopTextAlignment() {
      let alignment = this.data.techSettings.desktopFormAlignment

      if (alignment == "right") {
        return "desktopTextAlign-left"
      } else if (alignment == "left") {
        return "desktopTextAlign-right"
      } else if (alignment == "center") {
        return "desktopTextAlign-center"
      } else {
        return ""
      }
    },
    formOverlap() {
      let isOverlap = this.data.techSettings.formOverlap
      if (isOverlap) {
        return "formOverlap"
      } else return "formExpand"
    }
  },
  methods: {
    initializeInquiryPayload: function (querystring) {
      /// lead guid, GA Client Id, UTMs, conent page for conversion, previous page, incoming query string, IP, etc
      console.log(querystring);
      this.$store.dispatch("INITIALIZE_INQUIRY_PAYLOAD_ACTION", querystring)
    },
    // createLeadGUID: function () {
    //   this.$store.dispatch("CREATE_LEADGUID_ACTION");
    // },
    // getGAClient: function () {
    //   this.$store.dispatch("SET_GACLIENTID_ACTION");
    // },
    // createQueryParams: function (route) {
    //   this.$store.dispatch("SET_QUERY_PARAMS_ACTION", route);
    // },
    getEwc() {
      let ewc = document.getElementsByClassName("ewc")
      let concantEwc = ewc[0].innerHTML
      let finalEwc = encodeURIComponent(concantEwc)
      //this.$store.dispatch("SET_EWC_ACTION", finalEwc);
      this.form.EWC = finalEwc
    },
    isRestrictedDomain(domain) {
      let domains = this.$store.getters.RESTRICTED_EMAIL_DOMAINS_DATA;
      console.log(`checking if ${domain} is restricted...` + domains.includes(domain))
      return domains.includes(domain);
    },
    getVISIBLECAMPAIGNAOIS: function() {
      let visibleCampaignAOIS = this.$store.getters.VISIBLECAMPAIGNAOIS
      return visibleCampaignAOIS
    },
    removeFromArray(originalArray, nested, removeArray) {
      return originalArray.filter(value => removeArray.includes(value[nested]))
    },
    optimizely(apiName) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
    },
    engaged(type) {
      this.optimizely(`engaged${type}`)
    },
    selected(type) {
      this.optimizely(`selected${type}`)
    },
    submitted(type) {
      this.optimizely(`submitted${type}`)
    },
    getCAMPAIGN: function () {
      let campaign = this.$store.getters.CAMPAIGN
      return campaign
    },
    getHIDDENCAMPAIGNAOIS: function () {
      let hiddenCampaignAOIS = this.$store.getters.HIDDENCAMPAIGNAOIS
      return hiddenCampaignAOIS
    },
    getHiddenAOIS: function () {
      let hiddenAOIS = this.data.techSettings.hiddenAOIS
      return hiddenAOIS
    },
    // getAOIGroup: function () {
    //   console.log("Hi Mom!")
    //   let aoiGroup = this.$store.getters.AOIGROUP;
    //     console.log(aoiGroup)
    //   return aoiGroup;
    // },
    getAOIS: function () {
      let storeAOIS = this.$store.getters.AOIS
      return storeAOIS
    },
    getCOUNTRIES() {
      return this.$store.getters.COUNTRIES
    },
    getDIPLOMAS() {
      return this.$store.getters.DIPLOMAS
    },
    getContextualState: function (x, v) {
      let key = x

      if (v.form[key].$dirty == false) {
        //console.log("not dirty");
        return null
      } else {
        //console.log("dirty");
        //console.log("valid or invalid: ", !v.form[key].$error);
        return !v.form[key].$error
      }
    },
    getInputLength: function (field, type) {
      if (this.form.Country == "US") {
        if (field == "zip") {
          if (type == "min") {
            return 5
          } else if (type == "max") {
            return 5
          }
        } else if (field == "phone") {
          if (type == "min") {
            return 16
          } else if (type == "max") {
            return 16
          }
        }
      }
    },
    validateAOIS: function () {
      let storeAOIS = this.getAOIS()
      let hiddenVariationAOIS = this.getHiddenAOIS()
      let hiddenCampaignAOIS = this.getHIDDENCAMPAIGNAOIS()
      let visibleCampaignAOIS = this.getVISIBLECAMPAIGNAOIS();
      let currentCampaign = this.getCAMPAIGN()
      let isHiddenCampaign = hiddenCampaignAOIS.filter(
        (item) => item.campaign == currentCampaign
      )
      let isVisibleCampaign = visibleCampaignAOIS.filter(
        (item) => item.campaign == currentCampaign
      )
      //let groupedAOIS = this.getGroupedAOIS()
      // console.log("")
      // console.log(`Store AOIS: `, storeAOIS);
      // console.log(`Hidden Variations AOIS: `, hiddenVariationAOIS);
      // console.log("")
      // console.log(`Current Campaign: `, currentCampaign)
      // console.log(`Current Campaign Has Hidden AOIS `, isHiddenCampaign.length > 0)
      // console.log(`Total Hidden Campaign AOIS: `, hiddenCampaignAOIS)
      // console.log("")
      // console.log(`Current Campaign Has visible AOIS `, isVisibleCampaign.length > 0)
      // console.log(`Total Visible Campaign AOIS: `, visibleCampaignAOIS)
      //-------------
      //check if current campaign has any hidden aois, if so this takes precendent, and filter only those

      // 
      // if (isHiddenCampaign.length > 0) {
      //   let returnedAOIS = storeAOIS.filter(
      //     (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
      //   )
      //   return returnedAOIS
      // }
      // // else current campaign does not have hidden aois 
      // else {
      //   
      //   if (hiddenVariationAOIS) {
      //     let returnedAOIS = storeAOIS.filter(
      //       (item) => !hiddenVariationAOIS.includes(item.shortCode)
      //     )
      //     return returnedAOIS
      //   } 
      //   // else current variation does not have hidden aois
      //   else {
      //     return storeAOIS
      //   }
      // }
      //-----

      // if current campaign has hidden aois
      if (isHiddenCampaign.length > 0) {
        //console.log(`has hidden campaign aois`, isHiddenCampaign[0].aois)
        storeAOIS = storeAOIS.filter(
          (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
        )
      } 
      // if current variation has hidden aois
      if (hiddenVariationAOIS) {
        //console.log(`has hidden variations aois`, hiddenVariationAOIS)
        storeAOIS = storeAOIS.filter(
          (item) => !hiddenVariationAOIS.includes(item.shortCode)
        )
      } 

      // if current campaign has explicitly visible aois
      if (isVisibleCampaign.length > 0) {
        //console.log(`has visible campaign aois`, isVisibleCampaign[0].aois)
        let baseCampaignData = this.getAOIS();
        let visibleCampaignAOIShortCodes = isVisibleCampaign[0].aois;
        let visibleCampaignAOIData = this.removeFromArray(baseCampaignData, "shortCode", visibleCampaignAOIShortCodes);
        
        visibleCampaignAOIData.forEach((item) => {
          storeAOIS.push(item);
        });
      }
      return storeAOIS.sort((a, b) => {
        let textA = a.shortCode;
        let textB = b.shortCode;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    checkAOIS(index) {
      let storeAOIS = this.validateAOIS()
      let aoiList = this.validateAOIS(index).filter((aoi) => aoi.group == index)

      const groupAOIS = storeAOIS.reduce((acc, value) => {
        if (!acc[value.group]) {
          acc[value.group] = []
        }
        acc[value.group].push(value)
        return acc
      }, {})

      if (index) {
        return aoiList
      } else {
        return groupAOIS
      }
    },
    validateCharacter(e) {
      let key = e.key

      if (/^\d+/g.test(key)) {
        e.preventDefault()
      }
    },
    validateNumber(e) {
      let key = e.key
      let goodKeys = [8, 9, 37, 38, 39, 40, 46]

      if (this.form.Country == "US") {
        //prevents non numbers
        if (!/^\d+/g.test(key)) {
          if (!goodKeys.includes(e.keyCode)) {
            // lets you backspace and use keyboard arrows
            e.preventDefault()
          }
        }
      }
    },
    formatZip() {
      let mask = this.form.ZipCode

      if (this.form.Country == "US") {
        //("country is us");
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        let formattedNumber = mask.replace(/[\D._\-()]+/g, "")

        // 2. Format
        mask = formattedNumber.substring(0, 5)
        //console.log("mask", mask);

        // 3. return the phone number
        this.form.ZipCode = mask
      }
    },
    formatMilitaryRelease() {
      let length = null
      let mask = this.form.MilitarySeparation

      // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
      let formattedNumber = mask.replace(/[\D._\-()]+/g, "")

      // 2. Get length of all digits without numeric characters
      length = mask.replace(/\D/g, "").toString().length

      // 3. Format
      if (length > 2) {
        mask =
          formattedNumber.substring(0, 2) +
          "/" +
          formattedNumber.substring(2, 6)
        this.form.MilitarySeparation = mask
      }

      // 4. return the phone number
      this.form.MilitarySeparation = mask
    },
    formatPhoneNumber() {
      let length = null
      let phoneNumber = this.form.PhoneNumber

      if (this.form.Country == "US") {
        // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
        // 2. Clear out a leading 0 or 1
        let formattedNumber = phoneNumber
          .replace(/[\D._\-()]+/g, "")
          .replace(/^[0|1]/, "")
        //console.log(`formattedNumber:`, formattedNumber)

        // 3. Get length of all digits without numeric characters
        length = formattedNumber.replace(/\D/g, "").toString().length

        // 4. Format
        if (length > 0 && length < 4) {
          formattedNumber = "(" + formattedNumber
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 4 && length <= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6)
          this.form.PhoneNumber = formattedNumber
        } else if (length >= 6) {
          formattedNumber =
            "(" +
            formattedNumber.substring(0, 3) +
            ") " +
            formattedNumber.substring(3, 6) +
            " - " +
            formattedNumber.substring(6, 10)
          this.form.PhoneNumber = formattedNumber
        }

        // 5. return the phone number
        this.form.PhoneNumber = formattedNumber
      }
    },
    formStartEvent() {
      this.$store.dispatch("DATA_LAYER_FORM_START_ACTION")
    },
    nextStep() {
      this.form.currentStep++
      //console.log(`moved to step ${this.form.currentStep}`);
    },
    previousStep() {
      this.form.currentStep--
      window.scrollTo(top)
    },
    submit() {
      // if (this.$v.$invalid) {
      // 	//  for (let key in Object.keys(this.$v.form)) {
      // 	//  const input = Object.keys(this.$v.form)[key];
      // 	// if (!this.$v.form[input].$error) {
      // 	// console.log("inside focus", this.$refs[input]);
      // 	// console.log(this.$refs[input]);
      // 	// this.$refs[input].focus();
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	console.log(`error submitting form`);
      // 	this.$router.push({ path: "/thankyou" });
      // 	// break;
      // 	// }
      // 	//}
      // } else {
      // 	console.log(this.form);
      // 	console.log(this.$store.state.formData);
      // 	this.$store.dispatch("SET_FORM_DATA_ACTION", this.form);
      // 	this.$router.push({ path: "/thankyou" });
      // }
      let currentStep = this.form.currentStep
      event.preventDefault()
      //console.log(`submitting form step ${currentStep}`);
      if (currentStep == 1) {
        if (!this.$v.form.AOI.$invalid) {
          //console.log("form step 1 submission is valid");
          this.nextStep()
        } else {
          //console.log("form step 1 submission is invalid");
          this.$v.form.AOI.$touch()
        }
      } else if (currentStep == 2) {
        this.$v.$touch()

        /// Form-Submit DL Event
        window.dataLayer.push({
          event: "form-submit",
          formId: this.$store.state.formData.AcquisitionPoint,
          age: this.form.Age,
          areaOfInterest: this.form.AOI,
          //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
          //'email': dataPayload.EmailAddresses[0].Addresses,
          //'errorMessage': errMsg,
          //'formName': 'Paid Media Landing Page' + formName,
          formName: "PMLPv2 Two Step Form",
          formType: "inquiry",
          //'formValidationStatus': "",
          inquiryDate: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
          isMilitary: this.form.isMilitary,
          leadId: this.$store.state.formData.inqId,
          //'numberOfInquiries': inqcount != undefined ? inqcount : "",
          referralPage: document.referrer,
          stepNumber: this.form.currentStep
          //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
        })

        if (!this.$v.$invalid) {
          //console.log("form step 2 submission is valid");
          //console.log(`form`, this.form);
          let domain = this.form.email.split('@').pop().toLowerCase();

          if(this.form.email && this.isRestrictedDomain(domain)){ 
           this.$store.dispatch("SET_FORM_COMPLETE", {...this.form,markComplete:true});
           this.$router.push({ path: "/thankyou" });
           return;
          }
          this.$store.dispatch("SET_FORM_DATA_ACTION", this.form)
          this.$router.push({ path: "/thankyou" })
        } else {
          // console.log("form step 2 submission is invalid");
          //console.log(`form invalid`, this.form);
        }
      }
    },
    isMobile() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      // console.log(`current breakpoint: `, currentBreakpoint)
      if (
        currentBreakpoint == "xs" ||
        currentBreakpoint == "sm" ||
        currentBreakpoint == "md"
      ) {
        return true
      } else {
        return false
      }
    },
    responsiveImage() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let content = this.data.content
      let mobileImg = ""
      let desktopImg = ""

      mobileImg = content.mobileImg
      desktopImg = content.desktopImg

      if (
        mobileImg !== "" &&
        (currentBreakpoint == "xs" || currentBreakpoint == "sm")
      ) {
        return mobileImg
      } else {
        return desktopImg
      }
    },
    responsiveGradient() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let techSettings = this.data.techSettings
      let mobileGradient = Boolean
      let desktopGradient = Boolean

      mobileGradient = techSettings.mobileGradientOverlay
      desktopGradient = techSettings.desktopGradientOverlay

      if (currentBreakpoint == "xs" || currentBreakpoint == "sm") {
        // console.log(`mobile gradient: `, mobileGradient)
        return mobileGradient
      } else {
        // console.log(`desktop gradient: `, desktopGradient)
        return desktopGradient
      }
    },
    fade(step) {
      let currentStep = this.form.currentStep
      if (step == 1) {
        if (currentStep == 1) {
          return "fadeIn"
        } else {
          return "fadeOut"
        }
      } else if (step == 2) {
        if (currentStep == 2) {
          return "fadeIn"
        } else {
          return "fadeOut"
        }
      } else {
        return null
      }
    }
  },
  validations: {
    form: {
      AOI: { required },
      FirstName: { required, maxLength: maxLength(30) },
      LastName: { required, maxLength: maxLength(30) },
      Age: { required, between: between(14, 80) },
      ZipCode: { required, minLength: minLength(5), maxLength: maxLength(15) },
      Country: { required, maxLength: maxLength(100) },
      GradYear: {
        required: requiredIf(function () {
          let condition = this.form.Age >= 14 && this.form.Age < 20
          return condition
        }),
        maxValue: maxValue(new Date().getFullYear() + 10),
        minLength: minLength(4),
        maxLength: maxLength(4),
        numeric
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      PhoneNumber: {
        required,
        minLength: usPhoneLength,
        maxLength: maxLength(20)
      },
      MilitarySeparation: {
        required: requiredIf(function () {
          return this.form.isMilitary
        }),
        minLength: minLength(7),
        maxLength: maxLength(7)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fadeOut {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  bottom: 0px;
  overflow: hidden;
  //transition: visibility 0s linear 300ms, opacity 300ms;
}

.fadeIn {
  visibility: visible;
  position: static;
  opacity: 1;
  transition: all 0s, opacity 300ms;
}
.white-theme,
.-theme,
.undefined-theme {
  background-color: white;
  color: black;

  span >>> a {
    color: #007bff;
  }
}

.blue-theme {
  background-color: #003653;
  color: white;

  span >>> a {
    color: white;
    text-decoration-line: underline;
  }
}

.grey-theme,
.gray-theme {
  background-color: #f2f5f7;
  color: #003653;

  span >>> a {
    color: #007bff;
  }
}

.mobile {
  &.hero-with-image {
    background-size: cover;
  }
}

.desktop {
  @media screen and (max-width: 991px) {
    &.hero-with-image {
      background-image: none !important;
      background-color: #f2f5f7;
    }
  }
}
.hero-with-image {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  h1 {
    font-family: Tungsten-Semibold;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  ul {
    margin-top: 24px;
  }

  .hero-copy,
  .hero-sub-copy {
    margin-top: 24px;
    margin-bottom: 0px;
    line-height: 22px;
    font-size: 18px;
  }

  @media screen and (min-width: 992px) {
    background-size: cover;
  }
}

.hero-container {
  &.desktop {
    position: relative;

    .two-step-form {
      &.formExpand {
        padding-top: 30px;
        padding-bottom: 30px;

        form {
          position: absolute;
        }
      }
      &.formOverlap {
        form {
          position: absolute;
          top: 0;
          width: 400px;
          margin-top: 30px;
          z-index: 99;
        }
      }

      form {
        &.desktopFormAlign-right {
          right: 0;
        }
        &.desktopFormAlign-left {
          left: 0;
        }
        &.desktopFormAlign-center {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .hero-text {
      &.desktopTextAlign-left {
        position: absolute;
        left: 0;
      }
      &.desktopTextAlign-right {
        position: absolute;
        right: 0;
      }
      &.desktopTextAlign-center {
        position: absolute;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}

.hiddenBullets {
  list-style: none;
  padding-left: 0;
}

.two-step-form {
  form {
    background-color: #f6f6fb;
    border: 1px solid #949494;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.20);

    h2 {
      text-transform: uppercase;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #003653;
      text-align: center;
    }
  }

  input:not([type="checkbox"]),
  select {
    width: 100%;
    font-size: 16px;
  }

  [type="color"],
  [type="date"],
  [type="datetime-local"],
  [type="datetime"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 0.6rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px #{'hsl(0deg 0% 4% / 10%)'};
    color: #0a0a0a;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  select {
    height: 2.4375rem;
    margin: 0 0 0.6rem;
    padding: 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    font-family: inherit;
    font-size: 1rem;
    line-height: normal;
    color: #0a0a0a;
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding-right: 1.5rem;
    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  label {
    margin-bottom: 5px;
  }

  .field-row {
    display: inline-block;
  }

  .disclosure-container {
    font-size: 9px;
  }

  .progress-bar {
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin: 20px 0;
    background-color: #fff;
    height: 20px;
    overflow: hidden;
    width: 100%;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.10);

    .progress-bar-fg {
      height: 20px;
      transition: all linear 0.5s;

      &.blue-theme,
      &.-theme,
      &.undefined-theme {
        background-color: #0086d2;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#0086d2),
          to(#196bb8)
        );
        background-image: -webkit-linear-gradient(top, #0086d2, #196bb8);
        background-image: -moz-linear-gradient(top, #0086d2, #196bb8);
        // background-image: -ms-linear-gradient(top, #0086d2, #196bb8);
        // background-image: -o-linear-gradient(top, #0086d2, #196bb8);
        // background-image: linear-gradient(top, #0086d2, #196bb8);
      }

      &.red-theme {
        background-color: #d50019;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#d50019),
          to(#b60317)
        );
        background-image: -webkit-linear-gradient(top, #d50019, #b60317);
        background-image: -moz-linear-gradient(top, #d50019, #b60317);
        // background-image: -ms-linear-gradient(top, #d50019, #b60317);
        // background-image: -o-linear-gradient(top, #d50019, #b60317);
        // background-image: linear-gradient(top, #d50019, #b60317);
      }

      &.green-theme {
        background-color: #0c3;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#0c3),
          to(rgb(0, 163, 41))
        );
        background-image: -webkit-linear-gradient(top, #0c3, rgb(0, 163, 41));
        background-image: -moz-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: -ms-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: -o-linear-gradient(top, #0c3, rgb(0, 163, 41));
        // background-image: linear-gradient(top, #0c3, rgb(0, 163, 41));
      }

      &.one-third {
        width: 33%;
      }

      &.two-third {
        width: 66%;
      }
    }
  }

  .submit-button {
    //border: 1px solid #0c3;
    text-align: center;
    position: relative;
    color: #fff;
    overflow: hidden;
    //background-color: #0c3 !important;
    padding: 0 28px;
    font-size: 22px;
    width: 100%;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    line-height: 31px;

    &:before {
      //background-color: #f6001d 1%;
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(#f6001d 1%),
      //   to(#b60002 100%)
      // );
      // background-image: -webkit-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -moz-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -ms-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -o-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: linear-gradient(top, #f6001d 1%, #b60002 100%);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all linear 0.15s;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      transition-delay: 0s;
    }

    &:after {
      content: "\f054";
      font-family: FontAwesome;
      padding-left: 5px;
      font-size: 16px;
      vertical-align: middle;
      position: absolute;
      top: 0;
    }

    &:hover {
      background: #fff !important;
      // color: #0c3!important;
      // box-shadow: 0 0 5px #0c3;
    }

    &.red-theme,
    &.-theme,
    &.undefined-theme {
      border: 1px solid #d50019;
      background-color: #d50019;

      &:before {
        background-color: #f6001d 1%;
      }

      &:hover {
        color: #d50019 !important;
        box-shadow: 0 0 5px #d50019;
      }

      &:focus {
        outline: 1px auto #d50019;
      }
    }

    &.green-theme {
      border: 1px solid #0c3;
      background-color: #0c3;

      &:before {
        background-color: #0c3 1%;
      }

      &:hover {
        color: #0c3 !important;
        box-shadow: 0 0 5px #0c3;
      }

      &:focus {
        outline: 1px auto #0c3;
      }
    }

    &.blue-theme {
      border: 1px solid #008ed4;
      background-color: #008ed4;

      &:before {
        background-color: #008ed4 1%;
      }

      &:hover {
        color: #008ed4 !important;
        box-shadow: 0 0 5px #008ed4;
      }

      &:focus {
        outline: 1px auto #008ed4;
      }
    }
  }

  .form-control {
    padding-right: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .hero-with-image {
    min-height: 368px;
  }
}

// XS BreakPoint
@media screen and (min-width: 0px) {
  .hero-with-image {
    background-position: var(--xs-image-anchor);
    height: var(--xs-hero-height);
    min-height: 168px;

    .hero-container .hero-text {
      width: var(--xs-hero-text-width);
    }
  }
}

// SM BreakPoint
@media screen and (min-width: 576px) {
  .hero-with-image {
    background-position: var(--sm-image-anchor);
    height: var(--sm-hero-height);

    .hero-container .hero-text {
      width: var(--sm-hero-text-width);
    }
  }
}

// MD BreakPoint
@media screen and (min-width: 768px) {
  .hero-with-image {
    background-position: var(--md-image-anchor);
    height: var(--md-hero-height);

    .hero-container .hero-text {
      width: var(--md-hero-text-width);
    }
  }
}

// LG BreakPoint
@media screen and (min-width: 992px) {
  .hero-with-image {
    background-position: var(--lg-image-anchor);
    height: var(--lg-hero-height);
    min-height: 368px;

    .hero-container .hero-text {
      width: var(--lg-hero-text-width);
    }
  }
}

// xl BreakPoint
@media screen and (min-width: 1200px) {
  .hero-with-image {
    background-position: var(--xl-image-anchor);
    height: var(--xl-hero-height);

    .hero-container .hero-text {
      width: var(--xl-hero-text-width);
    }
  }
}
</style>

import { render, staticRenderFns } from "./BasicList.vue?vue&type=template&id=5135d87f&scoped=true&"
import script from "./BasicList.vue?vue&type=script&lang=js&"
export * from "./BasicList.vue?vue&type=script&lang=js&"
import style0 from "./BasicList.vue?vue&type=style&index=0&id=5135d87f&lang=scss&scoped=true&"
import style1 from "./BasicList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5135d87f",
  null
  
)

export default component.exports
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    class="marine-icon"
  >
    <path
      d="M35.73,22.55a7.91,7.91,0,0,0-3.8-5.09l-.24.42h0l.24-.43a8.3,8.3,0,0,0-8.32.05l-.16.1-.08-.19A5.32,5.32,0,0,0,21.32,15l-.09-.06,0-.12a3,3,0,0,0,0-.57,6.53,6.53,0,0,1,5.17-6.57,2.71,2.71,0,0,0,2.09-2.14,2.52,2.52,0,0,0-1.18-2.69A15.89,15.89,0,0,0,19.22.5,8.73,8.73,0,0,0,13,2.87,8,8,0,0,0,10.4,8.78,8.4,8.4,0,0,0,14.58,16l.17.1-.12.15a5,5,0,0,0-1.06,3v.1l-.08.05-.19.08a1.3,1.3,0,0,0-.31.16,6.65,6.65,0,0,1-6.72.14,6.67,6.67,0,0,1-1.59-1.29,2.62,2.62,0,0,0-2-.93A2.62,2.62,0,0,0,0,20.05,16.23,16.23,0,0,0,2.1,28.28a8.64,8.64,0,0,0,7.54,4.6,8,8,0,0,0,4-1.07,8.32,8.32,0,0,0,4.14-7.2v-.2l.2,0a3.77,3.77,0,0,0,.69,0,4.85,4.85,0,0,0,2.41-.61l.1-.05.57.41a6.48,6.48,0,0,1,3.42,5.7,7.68,7.68,0,0,1-.3,2,2.67,2.67,0,0,0,2.5,3.53,2.72,2.72,0,0,0,1.24-.32,16.39,16.39,0,0,0,6.09-5.93A8.71,8.71,0,0,0,35.73,22.55ZM12.8,30.25a6.11,6.11,0,0,1-3.12.82,6.81,6.81,0,0,1-6-3.64,14.45,14.45,0,0,1-1.87-7.31.89.89,0,0,1,1.57-.51,8.36,8.36,0,0,0,2,1.65,8.28,8.28,0,0,0,8.31-.05l.17-.1.07.18a4.72,4.72,0,0,0,2,2.33l.07,0v.09a6.62,6.62,0,0,1-3.2,6.49ZM19,2.28h.19a14.34,14.34,0,0,1,7.28,2.05.88.88,0,0,1,.42.84.84.84,0,0,1-.73.78,7.41,7.41,0,0,0-2.43,1,8.35,8.35,0,0,0-4.14,7.2v.2l-.2,0a3.72,3.72,0,0,0-.69,0,5,5,0,0,0-2.32.57l-.08,0-.07,0a6.55,6.55,0,0,1-4-6,6.24,6.24,0,0,1,2-4.6A7,7,0,0,1,19,2.28ZM18.67,16a3.34,3.34,0,1,1-3.33,3.33A3.34,3.34,0,0,1,18.67,16Zm9.16,17.56-.14.06a.79.79,0,0,1-.32.07,1,1,0,0,1-.7-.33l0-.06a1.09,1.09,0,0,1-.1-.85A7.55,7.55,0,0,0,27,29.89a8.38,8.38,0,0,0-4.18-7.2l-.17-.1.12-.15a5.19,5.19,0,0,0,1.06-2.86V19.5l.06-.05a6.59,6.59,0,0,1,7.24-.52,6.22,6.22,0,0,1,3,3.95,7.15,7.15,0,0,1-.87,5.41h0A14.75,14.75,0,0,1,27.83,33.56Z"
    />
  </svg>
</template>

<script>
export default {
  name: "marine"
}
</script>
<style scoped>
.marine-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
.stroke {
  font-size: 300%;
  stroke-width: 1%;
}
</style>

<template>
    <div :id="this.data.id" :class="`component-iconform container-fluid ${data.rootfluidClass ? data.rootfluidClass : 'my-5'}`" :style="data.style" >
        <b-container :class="`${data.rootContainerClass}`" :fluid="data.rootContainerFluid">
            <b-col :class="`${data.rootColClass}`">
                <b-container class=" iconForm-inner" :fluid="data.iconFormFluid" :style="data.iconFormStyle">
                    <b-row class=" col-12 ml-auto mr-auto">
                        <div class="headerContainer col-12 px-0 py-3 text-center" v-if="(this.header.length > 0) || (this.subHeader.length > 0)">
                            <h2 class="header" v-html="this.header"></h2>
                            <p class="subHeader mt-3" v-html="this.subHeader"></p>
                        </div>
                    </b-row>
                    <b-row :class="[`iconContainer col-12 ml-auto mr-auto px-0 justify-content-center`, data.modalDisabled ? 'modalDisabled' : '']" :cols="data.smCols || 3" :cols-md="data.mdCols || 6">
                        <b-col v-for="(aoi, index) in this.AOIList" :key="index" class="text-center iconBlock"  :value="aoi.shortCode" @click="boxClick(aoi.shortCode, data.modalData.id)">
                                <img :src="aoi.icon" class="icon" />
                                <p class="content">{{aoi.shortName}}</p>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-container>
        <Modal :data="data" :selectedAOI ="this.selectedAOI" AcquisitionPoint="UTIFORM039/ICONFORM"></Modal>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import Modal from "@/components/Content/Modal";
    
    export default {
        name: "IconForm",
        props: ["data"],
        components: {
        ...mapGetters([
            "AOIS",
            "COUNTRIES",
            "DIPLOMAS",
            "CURRENT_BREAKPOINT",
            "CAMPAIGN",
            "HIDDENCAMPAIGNAOIS",
            "VISIBLECAMPAIGNAOIS",
            "CAMPUSES",
            "RESTRICTED_EMAIL_DOMAINS_DATA"
            ]),
        Modal
        },
        data() {
            return {
                AOIList: this.validateAOIS(),
                header: this.data.content.header,
                subHeader: this.data.content.subHeader,
                selectedAOI: ''
            }
        },
        methods: {
            getAOIS: function () {
                return this.$store.getters.AOIS;
            },
            getCAMPAIGN: function () {
                let campaign = this.$store.getters.CAMPAIGN
                return campaign
            },
            getHIDDENCAMPAIGNAOIS: function () {
                let hiddenCampaignAOIS = this.$store.getters.HIDDENCAMPAIGNAOIS
                return hiddenCampaignAOIS
            },
            getVISIBLECAMPAIGNAOIS: function() {
                let visibleCampaignAOIS = this.$store.getters.VISIBLECAMPAIGNAOIS
                return visibleCampaignAOIS
            },
            getHiddenAOIS: function () {
                let hiddenAOIS = this.data.formData.data.techSettings.hiddenAOIS
                return hiddenAOIS
            },
            validateAOIS: function () {
                let storeAOIS = this.getAOIS()
                let hiddenVariationAOIS = this.getHiddenAOIS()
                let hiddenCampaignAOIS = this.getHIDDENCAMPAIGNAOIS()
                let visibleCampaignAOIS = this.getVISIBLECAMPAIGNAOIS();
                let currentCampaign = this.getCAMPAIGN()
                let isHiddenCampaign = hiddenCampaignAOIS.filter(
                    (item) => item.campaign == currentCampaign
                )
                let isVisibleCampaign = visibleCampaignAOIS.filter(
                    (item) => item.campaign == currentCampaign
                )
                //let groupedAOIS = this.getGroupedAOIS()
                // console.log("")
                // console.log(`Store AOIS: `, storeAOIS);
                // console.log(`Hidden Variations AOIS: `, hiddenVariationAOIS);
                // console.log("")
                // console.log(`Current Campaign: `, currentCampaign)
                // console.log(`Current Campaign Has Hidden AOIS `, isHiddenCampaign.length > 0)
                // console.log(`Total Hidden Campaign AOIS: `, hiddenCampaignAOIS)
                // console.log("")
                // console.log(`Current Campaign Has visible AOIS `, isVisibleCampaign.length > 0)
                // console.log(`Total Visible Campaign AOIS: `, visibleCampaignAOIS)
                //-------------
                //check if current campaign has any hidden aois, if so this takes precendent, and filter only those

                // 
                // if (isHiddenCampaign.length > 0) {
                //   let returnedAOIS = storeAOIS.filter(
                //     (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
                //   )
                //   return returnedAOIS
                // }
                // // else current campaign does not have hidden aois 
                // else {
                //   
                //   if (hiddenVariationAOIS) {
                //     let returnedAOIS = storeAOIS.filter(
                //       (item) => !hiddenVariationAOIS.includes(item.shortCode)
                //     )
                //     return returnedAOIS
                //   } 
                //   // else current variation does not have hidden aois
                //   else {
                //     return storeAOIS
                //   }
                // }
                //-----

                // if current campaign has hidden aois
                if (isHiddenCampaign.length > 0) {
                    //console.log(`has hidden campaign aois`, isHiddenCampaign[0].aois)
                    storeAOIS = storeAOIS.filter(
                    (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
                    )
                } 
                // if current variation has hidden aois
                if (hiddenVariationAOIS) {
                    //console.log(`has hidden variations aois`, hiddenVariationAOIS)
                    storeAOIS = storeAOIS.filter(
                    (item) => !hiddenVariationAOIS.includes(item.shortCode)
                    )
                } 

                // if current campaign has explicitly visible aois
                if (isVisibleCampaign.length > 0) {
                    //console.log(`has visible campaign aois`, isVisibleCampaign[0].aois)
                    let baseCampaignData = this.getAOIS();
                    let visibleCampaignAOIShortCodes = isVisibleCampaign[0].aois;
                    let visibleCampaignAOIData = this.removeFromArray(baseCampaignData, "shortCode", visibleCampaignAOIShortCodes);
                    
                    visibleCampaignAOIData.forEach((item) => {
                    storeAOIS.push(item);
                    });
                }
                return storeAOIS.sort((a, b) => {
                    let textA = a.shortCode;
                    let textB = b.shortCode;
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            },
            boxClick: function(aoi, modalId) {
                this.selectedAOI = aoi; 
                //console.log(`exists1`, this.data.techSettings.modalDisabled)
                //console.log(`doesn't exists1`, !this.data.techSettings.modalDisabled)
                if (!this.data.modalDisabled) {
                    this.$bvModal.show(`modal-${modalId}`)
                    this.engaged("IconForm")
                    this.engaged(`${aoi}`)
                } 
                
            },
            optimizely(apiName) {
                this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
            },
            engaged(type) {
                this.optimizely(`click${type}`)
            },
        },
        mounted() {
            //console.log(this.validateAOIS());
        }
    }
</script>

<style lang="scss" scoped>
    .component-iconform {
        .iconForm-inner {
            background-color: #25292B;
            color: white;
            border-radius: 8px;
            padding: 15px;
            .iconContainer {
                //display: contents;
                &.modalDisabled .iconBlock {
                    cursor: auto;
                }
                .iconBlock {
                    //aspect-ratio: 1/1;
                    //align-items: center;
                    //padding: 1%;
                    //border: 1px gray solid;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;

                    .icon {
                        width: 50%;
                        height: 50%;
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                        //object-position: center;
                    }
                    p.content {
                        text-transform: uppercase;
                        font-family: Tungsten-Semibold;
                        font-size: 18px;
                        letter-spacing: 1px;
                        display: flex;
                        justify-content: center;
                        min-height: 45px;
                    }
                }
            }

        }
    }
    @media (max-width: 990px) {
        .icon {
            width: 83% !important;
            height: 83% !important;
        }
    }

</style>
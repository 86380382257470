<template>
<svg width="49" height="49" viewBox="0 0 49 49" fill="none" class="suitcases-icon" xmlns="http://www.w3.org/2000/svg">
<path d="M48.1033 20.5408L43.7727 16.2092C43.1985 15.635 42.4194 15.3125 41.6069 15.3125H36.75V7.65625C36.75 5.12299 34.6895 3.0625 32.1562 3.0625H16.8437C14.3105 3.0625 12.25 5.12299 12.25 7.65625V15.3125H7.39307C6.58055 15.3125 5.80248 15.635 5.2273 16.2092L0.896738 20.5408C0.322519 21.115 0 21.894 0 22.7065V42.875C0 44.5661 1.37143 45.9375 3.0625 45.9375H45.9375C47.6286 45.9375 49 44.5661 49 42.875V22.7056C49 21.894 48.6775 21.115 48.1033 20.5408ZM15.3125 7.65625C15.3125 6.81119 16.0006 6.125 16.8437 6.125H32.1562C32.9994 6.125 33.6875 6.81119 33.6875 7.65625V15.3125H15.3125V7.65625ZM45.9375 42.875H3.0625V33.6875H12.25V35.9844C12.25 36.4074 12.5926 36.75 13.0156 36.75H14.5469C14.9699 36.75 15.3125 36.4074 15.3125 35.9844V33.6875H33.6875V35.9844C33.6875 36.4074 34.0301 36.75 34.4531 36.75H35.9844C36.4074 36.75 36.75 36.4074 36.75 35.9844V33.6875H45.9375V42.875ZM36.75 30.625V28.3281C36.75 27.9051 36.4074 27.5625 35.9844 27.5625H34.4531C34.0301 27.5625 33.6875 27.9051 33.6875 28.3281V30.625H15.3125V28.3281C15.3125 27.9051 14.9699 27.5625 14.5469 27.5625H13.0156C12.5926 27.5625 12.25 27.9051 12.25 28.3281V30.625H3.0625V22.7056L7.39307 18.375H41.606L45.9375 22.7056V30.625H36.75Z" fill="#25292B"/>
</svg>
</template>

<script>
export default {
  name: "suitcasesIcon"
}
</script>

<style scoped>
.suitcases-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
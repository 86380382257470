<template>
<b-container fluid>
<div class='row d-flex justify-content-center pb-5 '>
<div class='col-lg-4 px-0 '  v-for="(item,index) in data.content" :key="index">
<b-card
    overlay
    :img-src="item.image"
    img-alt="Card Image"
    style="border-radius: 0%;"
  >
    <b-card-text>
    <div class='d-flex justify-content-center '>
    <h6>{{item.title}}</h6>
    </div>
    </b-card-text>
  </b-card>
</div>
</div>
</b-container>
</template>

<script>
import {  BCard, BCardText } from "bootstrap-vue"
export default {
    name:"CampusLocation",
    props: ["data"],
    components: {
      BCard,
      BCardText
    }
}
</script>
<style>
img{
  border-radius:0px !important;
}
.card-img{
    height:35vh;
}
.card-body{
    display:flex;
    align-items: end;
    justify-content: center;
    -webkit-align-items:flex-end
}
h6{
    color:#fff;
    font-family: Tungsten-Semibold, sans-serif;
    font-size: 32px !important;
    line-height: 32px !important;
    letter-spacing: 1px;
}
.card{
  border: 2.5px solid #fff !important;
}
@media (max-width: 990px){
  h6{
    color:#fff;
    font-family: Tungsten-Semibold, sans-serif;
    font-size: 36px !important;
    line-height: 36px !important;
    letter-spacing: 1px;
}
}
</style>
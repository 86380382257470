import Vue from "vue";
import VueRouter from "vue-router";
import SnapChatApp from "../views/SnapChatApp.vue";
const ThankYou = () => import('../views/ThankYou/ThankYouView');
import NotFoundComponent from "../views/404/NotFoundComponent"
 //import ThankYou from "../views/ThankYou/ThankYouView";


Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Snapchat",
    component: SnapChatApp,
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    // route level code-splitting
    // this generates a separate chunk (vendor.js) for this route
    // which is lazy-loaded when the route is visited.
    component: ThankYou,
  },
  {
    path: '*',
    component: NotFoundComponent
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

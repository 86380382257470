var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:((_vm.data.techSettings.rootClasses) + " " + (_vm.data.techSettings.backgroundColor) + "-theme icons-unordered-list px-md-0"),attrs:{"id":this.data.id,"fluid":""}},[_c('b-container',{staticClass:"px-0",attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('b-row',{class:("" + (_vm.data.techSettings.fullWidth ? '' : 'mx-auto')),style:(("" + (_vm.data.techSettings.fullWidth ? '' : 'max-width: 1140px'))),attrs:{"align-v":"center"}},[_c('b-col',{class:("header-container px-0 " + (_vm.data.techSettings.contentOrientation == 'left' ||
          _vm.data.techSettings.contentOrientation == 'right'
            ? 'col-12 col-lg-5'
            : 'col-12')),attrs:{"order":("" + (_vm.data.techSettings.contentOrientation == 'left' ||
          _vm.data.techSettings.contentOrientation == 'center'
            ? 1
            : 2))}},[(_vm.data.title)?_c('h2',{class:("vhtml pt-2 text-" + (_vm.data.techSettings.headerAlignment)),domProps:{"innerHTML":_vm._s(_vm.data.title)}}):_vm._e(),(_vm.data.subTitle)?_c('p',{class:("vhtml text-" + (_vm.data.techSettings.headerAlignment)),domProps:{"innerHTML":_vm._s(_vm.data.subTitle)}}):_vm._e()]),_c('b-col',{class:("content-container m-0 p-0 " + (_vm.data.techSettings.contentOrientation == 'left' ||
          _vm.data.techSettings.contentOrientation == 'right'
            ? 'col-12 col-lg-7'
            : 'col-12')),attrs:{"order":("" + (_vm.data.techSettings.contentOrientation == 'left' ||
          _vm.data.techSettings.contentOrientation == 'center'
            ? 2
            : 1))}},[(_vm.data.list)?_c(("" + (_vm.data.techSettings.listType)),{tag:"component",class:"icon-list mt-4 pl-0"},[_c('b-row',{class:["mx-auto", _vm.data.techSettings.rowClasses],attrs:{"cols":_vm.data.techSettings.xsColumns,"cols-md":_vm.data.techSettings.mdColumns}},_vm._l((_vm.data.list),function(item,index){return _c('b-col',{key:index,staticClass:"px-0 li-container"},[(!_vm.data.iconsList)?_c('li',{class:[_vm.data.liClasses, _vm.data.techSettings.bulletPlacement == 'top' ? 'bullet-top' : ''],style:([_vm.justifyContent(_vm.data.techSettings.listAlignment)])},[(_vm.data.iconsList)?_c('div',{staticClass:"iconContainer mr-3"},[_c('img',{attrs:{"src":_vm.data.iconsList[index]}})]):_vm._e(),(_vm.data.icon !== undefined)?_c(_vm.data.icon.name,{tag:"component",staticClass:"icon mr-3 accessory",attrs:{"font-scale":_vm.data.icon.scale}}):_vm._e(),_c('div',{staticClass:"htmlContainer"},[_c('span',{staticClass:"vhtml item",staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(item)}})])],1):_vm._e(),(_vm.data.iconsList)?_c('li',{class:[_vm.data.liClasses, _vm.data.techSettings.bulletPlacement == 'top' ? 'bullet-top' : ''],style:([{'--url': ("url(" + (_vm.data.iconsList[index]) + ")")},_vm.justifyContent(_vm.data.techSettings.listAlignment)])},[(_vm.data.iconsList)?_c('div',{staticClass:"iconContainer mr-3"},[_c('img',{attrs:{"src":_vm.data.iconsList[index]}})]):_vm._e(),(_vm.data.icon !== undefined)?_c(_vm.data.icon.name,{tag:"component",staticClass:"icon mr-3 accessory",attrs:{"font-scale":_vm.data.icon.scale}}):_vm._e(),_c('div',{staticClass:"htmlContainer"},[_c('span',{staticClass:"vhtml item",staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(item)}})])],1):_vm._e()])}),1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container fluid class="footer-hero px-0">
    <b-row v-if="data.title" class="headline-container mx-0">
      <span class="h2 ml-2">{{ data.title }}</span>
    </b-row>
    <b-row
      class="footer-container"
      :style="'background-image: url(' + data.img + ');'"
    >
      <div class="footer-content">
        <span class="">{{ data.headline }}</span>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Brands",
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.h2 {
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: #003653;
  text-align: center;
}

.footer-hero {
  .headline-container span {
    display: block;
  }
  .footer-container {
    height: 250px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: top;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  .footer-content {
    color: #fff;
    font-style: italic;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    font-family: Oswald, sans-serif !important;
    text-transform: uppercase;
    height: unset;
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>

<template>
    <div :id="this.data.id" :class="`component-icongrid container-fluid ${data.rootfluidClass ? data.rootfluidClass : 'my-5'}`" :style="data.style" >
        <b-container :class="`${data.rootContainerClass}`" :fluid="data.rootContainerFluid">
            <b-col :class="`${data.rootColClass}`">
                <b-container class=" iconGrid-inner" :fluid="data.iconGridFluid" :style="data.iconGridStyle">
                    <b-row class=" col-12 ml-auto mr-auto">
                        <div class="headerContainer col-12 px-0 py-3 text-center" v-if="(this.header.length > 0) || (this.subHeader.length > 0)">
                            <h2 class="header" v-html="this.header"></h2>
                            <p class="subHeader mt-3" v-html="this.subHeader"></p>
                        </div>
                    </b-row>
                   
                    <b-row :class="[`iconContainer col-12 ml-auto mr-auto px-0 justify-content-center`, data.modalDisabled ? 'modalDisabled' : '']" :cols="data.smCols || 3" :cols-md="data.mdCols || 6">
                        <b-col v-for="(item, index) in this.iconList" :key="index" class="text-center iconBlock"  :value="item.icon" @click="boxClick(item.icon, data.modalData.id, index)">
                            <img :src="icons[`${item.icon}`]" class="icon" />
                            <p class="content">{{getAOIS().filter((i) => i.shortCode == `${item.icon}`)[0].shortName}}</p>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-container>
        <Modal :data="data" :selectedAOI="this.selectedIcon" carousel="true" :carouselData="data.icons" AcquisitionPoint="UTIFORM039/ICONFORM"></Modal>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import Modal from "@/components/Content/Modal";
    
    export default {
        name: "IconGrid",
        props: ["data"],
        components: {
        ...mapGetters([
            "AOIS",
            "COUNTRIES",
            "DIPLOMAS",
            "CURRENT_BREAKPOINT",
            "CAMPAIGN",
            "HIDDENCAMPAIGNAOIS",
            "VISIBLECAMPAIGNAOIS",
            "CAMPUSES",
            "RESTRICTED_EMAIL_DOMAINS_DATA"
            ]),
        Modal
        },
        data() {
            return {
                iconList: this.data.icons,
                header: this.data.content.header,
                subHeader: this.data.content.subHeader,
                selectedIcon: '',
                carouselIndex: 0,
                icons: {
                    AP:this.getAOIS().filter((item) => item.shortCode == 'AP')[0].icon,
                    AU:this.getAOIS().filter((item) => item.shortCode == 'AU')[0].icon,
                    AV:this.getAOIS().filter((item) => item.shortCode == 'AV')[0].icon,
                    CNC:this.getAOIS().filter((item) => item.shortCode == 'CNC')[0].icon,
                    CR:this.getAOIS().filter((item) => item.shortCode == 'CR')[0].icon,
                    DI:this.getAOIS().filter((item) => item.shortCode == 'DI')[0].icon,
                    ET:this.getAOIS().filter((item) => item.shortCode == 'ET')[0].icon,
                    HVAC:this.getAOIS().filter((item) => item.shortCode == 'HVAC')[0].icon,
                    IM:this.getAOIS().filter((item) => item.shortCode == 'IM')[0].icon,
                    MA:this.getAOIS().filter((item) => item.shortCode == 'MA')[0].icon,
                    MO:this.getAOIS().filter((item) => item.shortCode == 'MO')[0].icon,
                    NASCAR:this.getAOIS().filter((item) => item.shortCode == 'NASCAR')[0].icon,
                    NDT:this.getAOIS().filter((item) => item.shortCode == 'NDT')[0].icon,
                    RA:this.getAOIS().filter((item) => item.shortCode == 'RA')[0].icon,
                    WELD:this.getAOIS().filter((item) => item.shortCode == 'WELD')[0].icon,
                    WP:this.getAOIS().filter((item) => item.shortCode == 'WP')[0].icon,
                    U:"",
                }
            }
        },
        methods: {
            getAOIS: function () {
                return this.$store.getters.AOIS;
            },
            // getCAMPAIGN: function () {
            //     let campaign = this.$store.getters.CAMPAIGN
            //     return campaign
            // },
            // getHIDDENCAMPAIGNAOIS: function () {
            //     let hiddenCampaignAOIS = this.$store.getters.HIDDENCAMPAIGNAOIS
            //     return hiddenCampaignAOIS
            // },
            // getVISIBLECAMPAIGNAOIS: function() {
            //     let visibleCampaignAOIS = this.$store.getters.VISIBLECAMPAIGNAOIS
            //     return visibleCampaignAOIS
            // },
            // getHiddenAOIS: function () {
            //     let hiddenAOIS = this.data.formData.data.techSettings.hiddenAOIS
            //     return hiddenAOIS
            // },
            // validateAOIS: function () {
            //     let storeAOIS = this.getAOIS()
            //     let hiddenVariationAOIS = this.getHiddenAOIS()
            //     let hiddenCampaignAOIS = this.getHIDDENCAMPAIGNAOIS()
            //     let visibleCampaignAOIS = this.getVISIBLECAMPAIGNAOIS();
            //     let currentCampaign = this.getCAMPAIGN()
            //     let isHiddenCampaign = hiddenCampaignAOIS.filter(
            //         (item) => item.campaign == currentCampaign
            //     )
            //     let isVisibleCampaign = visibleCampaignAOIS.filter(
            //         (item) => item.campaign == currentCampaign
            //     )
            //     //let groupedAOIS = this.getGroupedAOIS()
            //     // console.log("")
            //     // console.log(`Store AOIS: `, storeAOIS);
            //     // console.log(`Hidden Variations AOIS: `, hiddenVariationAOIS);
            //     // console.log("")
            //     // console.log(`Current Campaign: `, currentCampaign)
            //     // console.log(`Current Campaign Has Hidden AOIS `, isHiddenCampaign.length > 0)
            //     // console.log(`Total Hidden Campaign AOIS: `, hiddenCampaignAOIS)
            //     // console.log("")
            //     // console.log(`Current Campaign Has visible AOIS `, isVisibleCampaign.length > 0)
            //     // console.log(`Total Visible Campaign AOIS: `, visibleCampaignAOIS)
            //     //-------------
            //     //check if current campaign has any hidden aois, if so this takes precendent, and filter only those

            //     // 
            //     // if (isHiddenCampaign.length > 0) {
            //     //   let returnedAOIS = storeAOIS.filter(
            //     //     (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
            //     //   )
            //     //   return returnedAOIS
            //     // }
            //     // // else current campaign does not have hidden aois 
            //     // else {
            //     //   
            //     //   if (hiddenVariationAOIS) {
            //     //     let returnedAOIS = storeAOIS.filter(
            //     //       (item) => !hiddenVariationAOIS.includes(item.shortCode)
            //     //     )
            //     //     return returnedAOIS
            //     //   } 
            //     //   // else current variation does not have hidden aois
            //     //   else {
            //     //     return storeAOIS
            //     //   }
            //     // }
            //     //-----

            //     // if current campaign has hidden aois
            //     if (isHiddenCampaign.length > 0) {
            //         //console.log(`has hidden campaign aois`, isHiddenCampaign[0].aois)
            //         storeAOIS = storeAOIS.filter(
            //         (item) => !isHiddenCampaign[0].aois.includes(item.shortCode)
            //         )
            //     } 
            //     // if current variation has hidden aois
            //     if (hiddenVariationAOIS) {
            //         //console.log(`has hidden variations aois`, hiddenVariationAOIS)
            //         storeAOIS = storeAOIS.filter(
            //         (item) => !hiddenVariationAOIS.includes(item.shortCode)
            //         )
            //     } 

            //     // if current campaign has explicitly visible aois
            //     if (isVisibleCampaign.length > 0) {
            //         //console.log(`has visible campaign aois`, isVisibleCampaign[0].aois)
            //         let baseCampaignData = this.getAOIS();
            //         let visibleCampaignAOIShortCodes = isVisibleCampaign[0].aois;
            //         let visibleCampaignAOIData = this.removeFromArray(baseCampaignData, "shortCode", visibleCampaignAOIShortCodes);
                    
            //         visibleCampaignAOIData.forEach((item) => {
            //         storeAOIS.push(item);
            //         });
            //     }
            //     return storeAOIS.sort((a, b) => {
            //         let textA = a.shortCode;
            //         let textB = b.shortCode;
            //         return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            //     });
            // },
            changeSelectedAOI(data) {
                //console.log('changing aoi: ', data)
                this.$root.$emit('changeSelectedAOI', data);
            },
            boxClick: function(icon, modalId, index) {
                //console.log('Box Clicked!', icon, 'at location ', index)
                this.selectedIcon = icon; 
                //console.log(`exists1`, this.data.techSettings.modalDisabled)
                //console.log(`doesn't exists1`, !this.data.techSettings.modalDisabled)
                //console.log(!this.data.modalDisabled)
                if (!this.data.modalDisabled) {
                    this.carouselIndex = index;
                    //console.log(this)
                    //console.log('in function')
                    for (let child of this.$children) {
                        child.$children[0].$children[0].setSlide(index)
                    }
                    this.changeSelectedAOI(icon)
                    this.$bvModal.show(`modal-${modalId}`)
                    this.engaged("IconGrid")
                    this.engaged(`${icon}`)
                } 
                
            },
            optimizely(apiName) {
                this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
            },
            engaged(type) {
                this.optimizely(`click${type}`)
            },
        },
        mounted() {
            //console.log(this.validateAOIS());
        }
    }
</script>

<style lang="scss" scoped>
    .component-icongrid {
        .iconGrid-inner {
            background-color: #25292B;
            color: white;
            border-radius: 8px;
            padding: 15px;
            .iconContainer {
                //display: contents;
                &.modalDisabled .iconBlock {
                    cursor: auto;
                }
                .iconBlock {
                    //aspect-ratio: 1/1;
                    //align-items: center;
                    //padding: 1%;
                    //border: 1px gray solid;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;

                    .icon {
                        width: 50%;
                        height: 50%;
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                        //object-position: center;
                    }
                    p.content {
                        text-transform: uppercase;
                        font-family: Tungsten-Semibold;
                        font-size: 18px;
                        letter-spacing: 1px;
                        display: flex;
                        justify-content: center;
                        min-height: 45px;
                    }
                }
            }

        }
    }
    @media (max-width: 990px) {
        .icon {
            width: 83% !important;
            height: 83% !important;
        }
    }

</style>
<template>
  <b-container
    fluid
    :class="`${data.techSettings.backgroundColor}-theme small-picture-testimonials uti-component`"
  >

    <b-container :fluid="data.techSettings.fullWidth" class="">
      <b-row
        v-if="data.testimonials"
        class="testimonial-list text-center"
      >
        <b-col
          v-for="(item, index) in data.testimonials"
          :key="index"
          class="testimonial-container pb-4"
          :cols="data.techSettings.xsColumns"
          :md="data.techSettings.mdColumns"
        >
          <img 
            v-if="item.img"
            :src="item.img"
            :alt="item.imgAlt"
            class="mb-3"
          />
          <blockquote v-if="item.testimonial" class="testimonial mb-3 p-0">
            "{{ item.testimonial }}"
          </blockquote>
          <div class="testimonial-info accessory">
            <p v-if="item.name" v-html="item.name"></p>
            <p v-if="item.title" v-html="item.title"></p>
        
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "QuoteBlock",
  props: ["data"]
}
</script>

<style lang="scss" scoped>
.small-picture-testimonials {
  &.blue-theme {
    background-color: #003653;

    h2,
    blockquote.testimonial {
      color: #fff;
    }

    .accessory {
      p {
        color: #b2b2b2;
      }
    }
  }

  &.white-theme,
  &.-theme {
    background-color: #fff;

    h2,
    blockquote.testimonial {
      color: #000;
    }

    .accessory {
      p {
        color: #737373;
      }
    }
  }

  &.gray-theme,
  &.grey-theme {
    background-color: #f2f5f7;
    color: #003653;

    h2,
    blockquote.testimonial {
      color: #003653;
    }

    .accessory {
      p {
        color: #0086d2;
      }
    }
  }

  .testimonial-list {
    .testimonial-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        border-radius: 50%;
        width: 90px;
        height: 90px;
      }
      blockquote {
        // color: #000;
        line-height: 22px;
        font-size: 18px;
      }

      .testimonial-info {
        align-items: flex-end;
      }

      .testimonial-info p {
        // color: #737373;
        line-height: 20px;
        font-size: 16px;
      }
    }
  }

  p {
    margin-bottom: 0;
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
  }

  h2 {
    font-family: Tungsten-Semibold;
    font-size: 36px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  li {
    font-size: 18px;
    line-height: 22px;
    padding: 7px;
  }
}
</style>
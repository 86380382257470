<template>
<svg width="54" height="54" viewBox="0 0 54 54" class="buildings-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.5714 11.4941V15.791C20.5714 16.5029 19.9958 17.0801 19.2857 17.0801H15C14.29 17.0801 13.7143 16.5029 13.7143 15.791V11.4941C13.7143 10.7823 14.29 10.2051 15 10.2051H19.2857C19.9958 10.2051 20.5714 10.7823 20.5714 11.4941ZM33 10.2051H28.7143C28.0042 10.2051 27.4286 10.7823 27.4286 11.4941V15.791C27.4286 16.5029 28.0042 17.0801 28.7143 17.0801H33C33.71 17.0801 34.2857 16.5029 34.2857 15.791V11.4941C34.2857 10.7823 33.71 10.2051 33 10.2051ZM19.2857 20.5176H15C14.29 20.5176 13.7143 21.0948 13.7143 21.8066V26.1035C13.7143 26.8154 14.29 27.3926 15 27.3926H19.2857C19.9958 27.3926 20.5714 26.8154 20.5714 26.1035V21.8066C20.5714 21.0948 19.9958 20.5176 19.2857 20.5176ZM33 20.5176H28.7143C28.0042 20.5176 27.4286 21.0948 27.4286 21.8066V26.1035C27.4286 26.8154 28.0042 27.3926 28.7143 27.3926H33C33.71 27.3926 34.2857 26.8154 34.2857 26.1035V21.8066C34.2857 21.0948 33.71 20.5176 33 20.5176ZM19.2857 30.8301H15C14.29 30.8301 13.7143 31.4073 13.7143 32.1191V36.416C13.7143 37.1279 14.29 37.7051 15 37.7051H19.2857C19.9958 37.7051 20.5714 37.1279 20.5714 36.416V32.1191C20.5714 31.4073 19.9958 30.8301 19.2857 30.8301ZM33 30.8301H28.7143C28.0042 30.8301 27.4286 31.4073 27.4286 32.1191V36.416C27.4286 37.1279 28.0042 37.7051 28.7143 37.7051H33C33.71 37.7051 34.2857 37.1279 34.2857 36.416V32.1191C34.2857 31.4073 33.71 30.8301 33 30.8301ZM48 52.8516V55H0V52.8516C0 52.1397 0.575679 51.5625 1.28571 51.5625H3.42857V2.57812C3.42857 1.15425 4.57982 0 6 0H42C43.4202 0 44.5714 1.15425 44.5714 2.57812V51.5625H46.7143C47.4243 51.5625 48 52.1397 48 52.8516ZM41.1429 51.5625V3.4375H6.85714V51.5625H20.5714V42.4316C20.5714 41.7198 21.1471 41.1426 21.8571 41.1426H26.1429C26.8529 41.1426 27.4286 41.7198 27.4286 42.4316V51.5625H41.1429Z" fill="#25292B"/>
</svg>
</template>


<script>
export default {
  name: "buildingsIcon"
}
</script>

<style scoped>
.buildings-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
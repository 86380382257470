<template>
  <div>
    <!--Protecting Legacy Cards-->
    <b-container v-if="!data.purpose">
      <div>
        <b-card-group deck>
          <b-card class='border-0' v-for="(item,index) in data.content" :key="index" :img-src="item.image" img-alt="Image" img-top>
            <b-card-text >
            <h3>{{item.title}}</h3>
              <p>{{item.description}}</p>
            </b-card-text>
            <template #footer >
              <b-button block variant="outline-dark">{{item.button_text}}</b-button>
            </template>
          </b-card>
        </b-card-group>
      </div>
    </b-container>
    <!--Testimonial Cards-->
    <b-container v-if="data.purpose == 'testimonial'" :class="['testimonialCard', data.rootClasses]" :id="data.rootID">
      <div>
        <b-card-group deck>
          <b-card class='border-0' :bg-variant='item.variant' v-for="(item,index) in data.content" :key="index" :img-src="item.image" img-alt="Image" img-top>
            <b-card-text >
              <div class="vhtml" v-html="item.html"></div>
              <b-col class="text-center mt-4 mb-3">  
                <b-button class="py-2 px-3" v-if="item.button_text" :variant='item.button_variant || "primary"' :href="item.buttonURL">{{ item.button_text }}</b-button>
                <div
                  v-for="(nestedComponent, nColIndex) in item.nestedComponents"
                  v-bind:key="nColIndex"
                >
                  <component
                    v-if="nestedComponent.data !== null"
                    v-bind:is="nestedComponent.type"
                    :data="nestedComponent.data"
                    :class="['gridColNested', nestedComponent.classes]"
                  ></component>
                </div>
              </b-col>
              <b-row v-if="item.authorName">
                <b-col class="authorContainer" style="display: flex;">
                  <div class="authorImageContainer" v-if="item.authorImage">
                    <img :src="item.authorImage" class="authorImage">
                  </div>
                  <div class="authorInfo">
                    <strong><p class='mb-1'>{{ item.authorName }}</p></strong>
                    <p class='mb-0'>{{ item.authorTitle }} {{ item.authorHandle }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>            
          </b-card>
        </b-card-group>
      </div>
    </b-container>
  </div>
</template>

<script>
import {  BCard, BCardGroup, BCardText } from "bootstrap-vue"
import Modal from "@/components/Content/Modal";
export default {
    name:"CardGroup",
    props: ["data"],
    components: {
      BCard,
      BCardGroup,
      BCardText,
      Modal
    }
}
</script>

<style scoped>
.card-footer{
  border:none !important;
}

.authorContainer {
  display: flex;
  align-items: center;
}
.authorImageContainer {
  display: inline-block;
  width: 10%;
  margin-right: 10px;
}

.testimonialCard {
  border-radius: 10px;
}

.authorImage {
    width: 100%;
    
  }
  .authorInfo {
    display: inline-block;
    width: 80%
  }
</style>
<template>
  <div class="SnapChatApp">
    <div sticky class="globalHeader sticky-top">
      <GlobalAlert v-if="this.pageData.globalalert"
      :globalalert="this.pageData.globalalert"
      />
      <div v-for="(item, index) in this.pageData.header" v-bind:key="index">
        <component v-bind:is="item.type" :data="item.data"></component>
      </div>
    </div>
    <div v-for="(item, index) in this.pageData.data" v-bind:key="index">
      <component v-bind:is="item.type" :data="item.data"></component>
    </div>
    <Copyright
      v-if="this.pageData.copyright"
      :copyright="this.pageData.copyright"
    />
    <BackToTopButton
      v-if="this.pageData.backToTopButton"
      :backToTopButton="this.pageData.backToTopButton"
    />
  </div>
</template>

<script>
import CollapseSection from '@/components/Collapse/collapseSection'
import TabComponent from "@/components/TabComponent"
import ThisPageComponent from "@/components/ThisPageComponent"
import TopNav from "@/components/TopNav"
import BottomNav from "@/components/Footer/BottomNav"
import TopNavDynamic from "@/components/TopNavDynamic"
import HeroImage from "@/components/Hero/HeroImage"
import HeroContentSection from "@/components/Hero/HeroContentSection"
import HeaderCta from "@/components/Header/HeaderCta"
// import HeroImageWText from "@/components/Hero/HeroImageWText";
import VideoWHeader from "@/components/Content/VideoWHeader"
import Video from "@/components/Content/Video"
import BasicList from "@/components/Content/BasicList"
import OrderedList from "@/components/Content/OrderedList"
import UnorderedListIcons from "@/components/Content/UnorderedListIcons"
import UnorderedListFacts from "@/components/Content/UnorderedListFacts"
import SmallTestimonialWithPictures from "@/components/Content/SmallTestimonialWithPictures"
// import OrderedList from "@/components/Content/OrderedList";
import HeroWithImage from "@/components/Hero/HeroWithImage"
// import HeaderCta from "@/components/Header/HeaderCta";
import Brands from "@/components/Hero/Brands"
import ContentBlock from "@/components/Content/ContentBlock"
import SchoolLogoBar from "@/components/Footer/SchoolLogoBar"
import AOISelectForm from "./FormOptions/AOISelectForm"
import Disclaimer from "@/components/Footer/Disclaimer"
import Copyright from "@/components/Footer/Copyright"
import BackToTopButton from "@/components/BackToTopButton"
import TwoStepForm from "@/components/Form/TwoStepForm"
import HeroForm from "@/components/Form/HeroForm"
import Alert from "@/components/Content/Alert"
import GlobalAlert from "@/components/Header/GlobalAlert"
import ImageBlock from "@/components/Content/ImageBlock"
import UpcomingEvent from "@/components/Content/UpcomingEvent"
import WYSIWYG from "@/components/Content/WYSIWYG"
import Grid from "@/components/Layout/Grid"
import SelectForm from "@/components/Form/SelectForm";
import AoiCampusTwoStep from "@/components/Form/AoiCampusTwoStep";
import CampusAoiTwoStep from "@/components/Form/CampusAoiTwoStep";
import CollapsedForm from "@/components/Form/CollapsedForm";
import NumbersFacts from "@/components/Content/NumbersFacts";
import ProgramInfo from "@/components/Content/ProgramInfo";
import QuoteBlock from "@/components/Content/QuoteBlock";
import GoogleMap from "@/components/Content/GoogleMap";
import GoogleMapiFrame from "@/components/Content/GoogleMapiFrame";
import GoogleRecaptchaV2 from "@/components/Form/GoogleRecaptchaV2"
import GoogleRecaptchaV3 from "@/components/Form/GoogleRecaptchaV3"
import Tabs from "@/components/Content/Tabs";
import Disclaimers from '@/components/Footer/Disclaimers';
import SelectionForm from "@/components/Form/SelectionForm";
import SingleStepForm from "@/components/Form/SingleStepForm";
import AccordionButton from "@/components/Content/AccordionButton";
import IconForm from "@/components/Form/IconForm";
import IconGrid from "@/components/Content/IconGrid";
import Modal from "@/components/Content/Modal";
import LocalAlert from "@/components/Header/LocalAlert";
import CampusLocation from "@/components/Campus/CampusLocation";
import SelectOption from "@/components/Select/SelectOption"
import CardGroup from "@/components/Content/CardGroup";
import ImageGrid from "@/components/Content/ImageGrid";
import Button from "@/components/Content/Button";
import CampusTourForm from "../components/Form/CampusTourForm.vue"
import MenuBar from "../components/Content/MenuBar.vue"
import MarketoForm from "../components/Form/MarketoForm.vue"
import StartDateCarousel from "../components/Content/StartDateCarousel.vue"


export default {
  name: "SnapChatApp",
  props: ["pageData"],
  components: {
    SelectOption,
    CampusLocation,
    ImageGrid,
    CardGroup,
    SingleStepForm,
    SelectionForm,
    Disclaimers,
    HeroWithImage,
    CollapseSection,
    TabComponent,
    ThisPageComponent,
    TopNav,
    TopNavDynamic,
    BottomNav,
    HeroImage,
    HeroForm,
    CampusTourForm,
    //HeroImageWText,
    HeroContentSection,
    AOISelectForm,
    HeaderCta,
    Brands,
    VideoWHeader,
    Video,
    BasicList,
    UnorderedListIcons,
    UnorderedListFacts,
    SmallTestimonialWithPictures,
    ContentBlock,
    Copyright,
    SchoolLogoBar,
    Disclaimer,
    BackToTopButton,
    OrderedList,
    TwoStepForm,
    CollapsedForm,
    SelectForm,
    NumbersFacts,
    ProgramInfo,
    QuoteBlock,
    Grid,
    ImageBlock,
    UpcomingEvent,
    WYSIWYG,
    Alert,
    GlobalAlert,
    AoiCampusTwoStep,
    CampusAoiTwoStep,
    GoogleMap,
    GoogleMapiFrame,
    GoogleRecaptchaV2,
    GoogleRecaptchaV3,
    Tabs,
    AccordionButton,
    IconForm,
    IconGrid,
    Modal,
    LocalAlert,
    Button,
    MenuBar,
    MarketoForm,
    StartDateCarousel
  },
  data: function () {
    return {
      scrollTwentyFive: true,
      scrollFifty: true,
      scrollSeventyFive: true,
      scrollOneHundred: true,
      scrolledPastFold: false,
      foldHeight: window.innerHeight * 0.8,
      h: document.documentElement,
      b: document.body,
      st: "scrollTop",
      sh: "scrollHeight",
      highlighters: [],
      components: [],
      headerRect: {},

    }
  },
  beforeMount() {
    
  },
  mounted() {
    window.setTimeout(() => {
      this.optimizely("duration20")
    }, 20000)
    window.setTimeout(() => {
      this.optimizely("duration60")
    }, 60000)
    window.addEventListener("scroll", this.scroll)
    window.addEventListener("load", () => {
      this.highlighters = [...document.querySelectorAll('.vhtml .highlight-gold')];
      this.scroll()
    })
    

    
  },
  beforeUpdate() {
    //Page components now available
    //iterates and stores all componets on page
    this.pageData.data.forEach((component) => {
      this.components[component.type] = component
    })
    //console.log(this.components)
    //console.log([...document.querySelectorAll('.vhtml .highlight-gold')]);
  },
  methods: {
    console(string) {
      console.log(string)
    },
    optimizely(apiName) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        rect.width > 0 && rect.height > 0
      );
    },
    toggleClass(c, e) {
      if (this.isInViewport(e)) {
        e.classList.add(c);
      } else {
        e.classList.remove(c);
      }
    },
    scroll() {
      window.scrollPercent =
        ((this.h[this.st] || this.b[this.st]) /
          ((this.h[this.sh] || this.b[this.sh]) - this.h.clientHeight)) *
        100
      // console.log("scroll percent", window.scrollPercent)

      if (window.scrollPercent >= 25 && this.scrollTwentyFive) {
        this.optimizely("scroll25")
        this.scrollTwentyFive = false
        // console.log("scrolled 25%")
      } else if (window.scrollPercent >= 50 && this.scrollFifty) {
        this.optimizely("scroll50")
        this.scrollFifty = false
        // console.log("scrolled 50%")
      } else if (window.scrollPercent >= 75 && this.scrollSeventyFive) {
        this.optimizely("scroll75")
        this.scrollSeventyFive = false
        // console.log("scrolled 75%")
      } else if (window.scrollPercent == 100 && this.scrollOneHundred) {
        this.optimizely("scroll100")
        this.scrollOneHundred = false
        // console.log("scrolled 100%")
      }

      if (window.scrollY > this.foldHeight && !this.scrolledPastFold) {
        this.scrolledPastFold = true
        this.optimizely("scrolledBelowFold")
      }

      //active-text highlight toggle
      if (this.highlighters.length > 0) {
        this.highlighters.forEach(element => {
          this.toggleClass('active-text', element)
        });
      } 

      //toggle sticky footer
      if (this.components.BottomNav) {
        let bottomNav = document.querySelector('.bottom-nav .navbar.fixed-bottom');
        let disclaimers = [...document.querySelectorAll('.disclaimer')];
        disclaimers.forEach((disclaimer) => {
          let width = disclaimer.getBoundingClientRect().width;
          let height = disclaimer.getBoundingClientRect().height;
          if (width > 0 || height > 0) {
            if(this.isInViewport(disclaimer)) {
            bottomNav.classList.add('hidden')

          } else {
            
            bottomNav.classList.remove('hidden');
          }
          }
          
        })
      }

    },
  }
}
</script>

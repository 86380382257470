<template>
    <div :class="['WYSIWYGComponent', data.techSettings.rootClasses]" :style="data.techSettings.style" :id="data.id">
      <b-container fluid class="px-0">
        <b-container
          :fluid="data.techSettings.fullWidth"
          :class="['htmlContainer','text-' + data.techSettings.textAlignment, data.techSettings.classes]"
        >
        <h2 v-if="data.title" v-html="data.title"
        :class="`py-2 mb-3 font-weight-bold text-${data.techSettings.headerAlignment}`"></h2>
        <b-row :no-gutters="!data.techSettings.gutters" :class="data.techSettings.rowClasses">
            <div v-for="(item,index) in data.questionsData" class="w-100 col-tab py-3" :key="index">
            <div class='d-flex justify-content-between align-items-center'>
              <p :class="[data.techSettings.questionColor]" style="text-align: left;">{{item.question}}</p>
              <p class="action-button" v-b-toggle="`collapse-${index}`"><span class="when-closed">+</span><span class="when-opened">-</span></p>
            </div>
            <b-collapse :id="`collapse-${index}`" class="mt-2 text-left" visible accordion="my-accordion">
              <p  v-html="item.html" :class="data.techSettings.colClasses"> </p>
            </b-collapse>
            </div>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "CollapseSection",
    props: ["data"]
  }
  </script>
  
  <style scoped lang="scss">

  .col-tab{
    border-top: 1px solid #CACACA;
    p{
      margin-bottom: 0%;
    }
  }
  .col-tab:last-of-type{
    border-bottom: 1px solid #CACACA;
  }
  // .col-tab:nth-child()
  .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }

    .action-button{
      font-size: 2rem;
      color:#25292B;
    }
  </style>
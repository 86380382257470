export function dlEventCheck(eventname, acquisitionpoint) {
  try {
    let result = window.dataLayer.find(function(obj) {
      return (
        obj["event"] === eventname &&
        obj["formId"].indexOf(acquisitionpoint) > -1
      );
    });
    return result !== undefined;
  } catch (err) {
    return true;
  }
}

export function getCookieByName(name) {
  if (cookiesEnabled()) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    var result = "";
  
    if (parts.length === 2) {
      result = parts.pop().split(";").shift();
    }
  
    return result;
  } else {
    return 'cookies not enabled - ' + name;
  }
}

export function cookiesEnabled() {
       var cookiesEnabled = (navigator.cookieEnabled) ? true : false;
     if (typeof navigator.cookieEnabled === undefined && !cookiesEnabled) {
           document.cookie = "testcookie";
           cookiesEnabled = (document.cookie.indexOf("testcookie") !== -1) ? true : false;
       }
      return (cookiesEnabled) ? true : false;
   }

export default {
    
    state: {
        cssData: {
            windowWidth: window.innerWidth,
            breakPoints: {
              sm: 576,
              md: 768,
              lg: 992,
              xl: 1200
            },
            activeBreakPoint: ""
          }
    },
    mutations: {
        SET_WINDOW_WIDTH_MUTATION: state => {
            state.cssData.windowWidth = window.innerWidth
            let breakPoints = state.cssData.breakPoints
            let breakPoint = ""
      
            if (window.innerWidth < state.cssData.breakPoints.sm) {
              breakPoint = "xs"
            } else if (
              window.innerWidth >= breakPoints.sm &&
              window.innerWidth < breakPoints.md
            ) {
              breakPoint = "sm"
            } else if (
              window.innerWidth >= breakPoints.md &&
              window.innerWidth < breakPoints.lg
            ) {
              breakPoint = "md"
            } else if (
              window.innerWidth >= breakPoints.lg &&
              window.innerWidth < breakPoints.xl
            ) {
              breakPoint = "lg"
            } else {
              breakPoint = "xl"
            }
      
            state.cssData.activeBreakPoint = breakPoint
          },
    },
    actions: {
        SET_WINDOW_WIDTH_ACTION: context => {
            context.commit("SET_WINDOW_WIDTH_MUTATION")
            context.commit
          },
    },
    getters: {
        WINDOW_WIDTH: state => {
            return state.cssData.windowWidth
          },
        CURRENT_BREAKPOINT: state => {
            return state.cssData.activeBreakPoint
          },
        BREAKPOINTS: state => {
            return state.cssData.breakPoints
          },
    }
}
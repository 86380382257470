<template>
  <b-container :id="this.data.id"
    fluid
    :class="`${data.techSettings.rootClasses} ${data.techSettings.backgroundColor}-theme icons-unordered-list px-md-0`"
  >
    <b-container :fluid="data.techSettings.fullWidth" class="px-0">
      <b-row
        align-v="center"
        :style="`${data.techSettings.fullWidth ? '' : 'max-width: 1140px'}`"
        :class="`${data.techSettings.fullWidth ? '' : 'mx-auto'}`"
      >
        <b-col
          :class="`header-container px-0 ${
            data.techSettings.contentOrientation == 'left' ||
            data.techSettings.contentOrientation == 'right'
              ? 'col-12 col-lg-5'
              : 'col-12'
          }`"
          :order="`${
            data.techSettings.contentOrientation == 'left' ||
            data.techSettings.contentOrientation == 'center'
              ? 1
              : 2
          }`"
        >
          <h2
            v-if="data.title"
            :class="`vhtml pt-2 text-${data.techSettings.headerAlignment}`"
            v-html="data.title"
          >
          </h2>
          <p
            v-if="data.subTitle"
            v-html="data.subTitle"
            :class="`vhtml text-${data.techSettings.headerAlignment}`"
          ></p>
        </b-col>
        <b-col
          :class="`content-container m-0 p-0 ${
            data.techSettings.contentOrientation == 'left' ||
            data.techSettings.contentOrientation == 'right'
              ? 'col-12 col-lg-7'
              : 'col-12'
          }`"
          :order="`${
            data.techSettings.contentOrientation == 'left' ||
            data.techSettings.contentOrientation == 'center'
              ? 2
              : 1
          }`"
        >
          <component :is="`${data.techSettings.listType}`" v-if="data.list" :class="`icon-list mt-4 pl-0`">
            <b-row
              :cols="data.techSettings.xsColumns"
              :cols-md="data.techSettings.mdColumns"
              :class='["mx-auto", data.techSettings.rowClasses]'
            >
              <b-col v-for="(item, index) in data.list" :key="index" class="px-0 li-container">
                <li v-if="!data.iconsList" :style="[justifyContent(data.techSettings.listAlignment)]" :class="[data.liClasses, data.techSettings.bulletPlacement == 'top' ? 'bullet-top' : '']">
                  <div v-if="data.iconsList" class="iconContainer mr-3">
                    <img :src="data.iconsList[index]">
                  </div>
                  <component
                    v-bind:is="data.icon.name"
                    v-if="data.icon !== undefined"
                    class="icon mr-3 accessory"
                    :font-scale="data.icon.scale"
                  ></component>
                  <div class="htmlContainer"><span class="vhtml item" v-html="item" style="display: inline-block;"></span></div>
                
                </li>
                <li v-if="data.iconsList" :style="[{'--url': `url(${data.iconsList[index]})`},justifyContent(data.techSettings.listAlignment)]" :class="[data.liClasses, data.techSettings.bulletPlacement == 'top' ? 'bullet-top' : '']">
                  <div v-if="data.iconsList" class="iconContainer mr-3">
                    <img :src="data.iconsList[index]">
                  </div>
                  <component
                    v-bind:is="data.icon.name"
                    v-if="data.icon !== undefined"
                    class="icon mr-3 accessory"
                    :font-scale="data.icon.scale"
                  ></component>
                  <div class="htmlContainer"><span class="vhtml item" v-html="item" style="display: inline-block;"></span></div>
                
                </li>
              </b-col>
            </b-row>
          </component>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { BIconCheckCircle, BIconCircleFill } from "bootstrap-vue"

export default {
  name: "UnorderedListIcons",
  props: ["data"],
  components: {
    BIconCheckCircle,
    BIconCircleFill
  },
  methods: {
    justifyContent(arg) {
      if (arg == "left") {
        return "justify-content: flex-start"
      } else if (arg == "center" || arg == "middle") {
        return "justify-content: center"
      } else if (arg == "right" || arg == "end") {
        return "justify-content: flex-end"
      } else {
        return "justify-content: flex-start"
      }
    },
    
  },
  computed: {
    cssVars(argument) {
      return {
        '--url': argument,
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.flex {
  display: flex
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}
.component-name {
  font-weight: bold;
  text-align: center;
}

.uti-component {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.blue-theme {
  background-color: #003653;

  h2,
  p {
    color: #fff;
  }

  svg.accessory {
    color: #b2b2b2;
  }
}

.white-theme {
  background-color: #fff;

  h2,
  p {
    color: #25292B;
  }

  svg.accessory {
    color: #737373;
  }
}

.gray-theme,
.grey-theme {
  background-color: #f2f5f7;
  color: #003653;

  h2,
  p {
    color: #003653;
  }

  svg.accessory {
    color: #0086d2;
  }
}

.icons-unordered-list {
  ul {
    list-style-type: none;

    .icon-list {

      ul {
        li, .icon, .item {
        display: flex;
      }
      }
      
    }

    li.test {
      display: flex;
      
      //list-style-image: var(--url);
      // &::marker {
      //   //display: inline-block;
      // //background-size: contain;
      // height: 30px!important;
      // width: 30px!important;
      // }
      
      //content: 'fdsafs';
    }
  }

  ol {
    list-style: none;
    counter-reset: steps;

    li {
      counter-increment: steps;

      span.item {
        display: flow-root;
        margin-left: 75px;
        //margin-bottom: 10px;
        h2 {
          margin-bottom: 15px;
        }
      }
    }

    li::before {
      content: counter(steps);
      margin-right: 0.5rem;
      background: #0086d2;
      color: white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-grid;
      place-items: center;
      line-height: 30px;
      position: absolute;
      margin-top: 12px;
    }
    
    li.squareBullet{
      &::before{
        content: counter(steps);
        margin-right: 0.5rem;
        background: #003653;
        color: white;
        width: 40px;
        height: 37px;
        border-radius: 0px;
        display: inline-grid;
        place-items: center;
        line-height: 30px;
        position: absolute;
        margin-top: 0px;
        transform: skewX(-12deg);
      }
      &.light-blue {
        &::before {
          background: #0086d2;
        }
      }
    }

    li.bullet-vertical-middle::before {
      margin-top: 0;
      transform: translateY(-50%);
      top: 50%;
    }

    li.bullet-top {
      &::before {
        vertical-align: top;
        position: initial;
        margin-bottom: 15px;
        margin-right: 0;
      }
      display: block;
      span.item {
        margin: 0!important;
      }
    }
  }
}

li {
  font-size: 18px;
  line-height: 22px;
  padding: 7px;
}

@media screen and (max-width: 990px)  {
  ol {
    li {
      span.item {
        display: flow-root;
        margin-left: 60px !important;
      }
    }
    li.squareBullet::before{
      content: counter(steps);
      margin-right: 0.5rem;
      background: #0086d2;
      color: white;
      width: 40px;
      height: 37px;
      border-radius: 0px;
      display: inline-grid;
      place-items: center;
      line-height: 30px;
      position: absolute;
      margin-top: 5px !important;
      transform: skewX(-12deg);
    }
  }
}


</style>
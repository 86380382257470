<template>
  <div>
    <b-form-group label="Diploma or Certificate Type" label-class="text-left" :class="{ hasError: v.$error }">
      <b-form-select
        name="edu-type"
        v-model="Education"
        :value="value"
        :options="options"
        :class="{ isInvalidInput: v.$error }"
        @focus="optEvent('engagedEducationType')"
        @change="optEvent('selectedEducationType')"
        ref="Education"
      >
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "EducationTypeSelect",
  props: {
    v: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: null,
      options: [
        {
          value: "HS Grad",
          text: "High School Diploma"
        },
        {
          value: "GED",
          text: "GED"
        },
        {
          value: "Home School",
          text: "Home School Diploma"
        },
        {
          value: "HS Grad",
          text: "None, I'm in High School"
        },
        {
          value: "GED",
          text: "None, I'm getting my GED"
        },
        {
          value: "Home School",
          text: "None, I'm being Home Schooled"
        }
      ],
      value: ""
    };
  },
  computed: {
    Education: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.value = value;
        this.$emit("send-updated-education", value);
        //console.log(this.value)
        this.$store.dispatch("SET_EDUCATION_ACTION", value);
        // console.log("SET_EDUCATION_ACTION was called")
      }
    }
  },
  methods: {
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event);
    }
  }
};
</script>

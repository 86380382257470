<template>
  <div id="app">
    <router-view :pageData="pageData" />
  </div>
</template>

<script>
import axios from "axios"
import config from "@/config/index"
export default {
  name: "Pre-form",
  data() {
    return {
      pageData: ""
    }
  },
  created() {
    document.addEventListener("DOMContentLoaded", () => {
      this.$store.dispatch("SET_WINDOW_WIDTH_ACTION")
    })
    window.addEventListener("resize", () => {
      this.$store.dispatch("SET_WINDOW_WIDTH_ACTION");
    })
    this.$cookies.set("uti_pmlp", "true", "1d", null, ".uti.edu");
  },
  beforeCreate() {
    axios
      .post(config.api, this.$route.query)
      .then(ret => {
        this.pageData = ret.data
        this.$store.dispatch("SET_SERVERDATA_ACTION", ret.data)
        // this.ret.data.data.optimizely == true
      })
      .catch(error => console.log(error))
  }
}
</script>

<style lang="scss"></style>

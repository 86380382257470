<template>
    <b-container fluid :class="['slick-root-container start-date-carousel-component', data.classes.rootFluidContainer]">
        <b-container :class="['slick-container', data.classes.rootContainer]">
            <div class="slider-controls">
                <button @click="showPrev()" class='chevron left' ></button>    
                <div class="slide-count px-2 my-2">{{ this.innerSlideData.currentSlide + 1 }}/{{ this.innerSlideData.slideCount }}</div>
                <button @click="showNext()" class='chevron right' ></button>
            </div>
            
            <VueSlickCarousel v-bind="data.techSettings" ref="carousel" @beforeChange="slideChange" @showPrevSlide="showPrev" @showNextSlide="showNext">
                
                
                <!-- <div class="slideCard" style="width: 50vw" v-for="(card, index) in data.cards" v-bind:key="index">
                    <h3 v-html=card.header class="text-left card-header p-0"></h3>
                    <div v-html=card.paragraph class="text-left card-paragraph my-3"></div>
                    <div v-html=card.cta.text class="text-left card-cta-text"></div>
                </div> -->
                
                <div v-for="(card, index) in this.cardObject" v-bind:key="index"  :style="{maxWidth: card.width}" :class="['slick-card']">
                    <h3 v-if="card.header" v-html=card.header class="text-left slick-card-header p-0"></h3>
                    <p v-if="card.paragraph" v-html=card.paragraph class="text-left slick-card-paragraph my-3"></p>
                    <b-button v-if="card.cta && card.cta.text" v-html=card.cta.text class="text-left slick-card-cta-text px-0" @click="openModal(card.cta.openModalID, card.cta.AOI)" @touch="openModal(card.cta.openModalID, card.cta.AOI)"></b-button>
                </div>
                
                <template #prevArrow="arrowOption">
                    <div class="custom-arrow">
                        <span>{{ arrowOption.currentSlide+1 }}/{{ arrowOption.slideCount }}</span>
                    </div>
                </template>
                <template #customPaging="page">
                    <div class="custom-dot">
                        {{ page + 1 }}
                    </div>
                </template>
            </VueSlickCarousel>
            
        </b-container>
    </b-container>
</template>

<script>
    // https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md#props
    //import {nextTick} from 'vue'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    //import Modal from "@/components/Content/Modal";
    import { BButton } from "bootstrap-vue"

    export default {
        name: "StartDateCarousel",
        props: ["data"],
        components: {
            VueSlickCarousel,
            BButton,
            //Modal
        },
        data() {
            return {
                //now: new Date(),
                cardObject: this.filterCards(),
                innerSlideData: {},
                
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.innerSlideData = this.$refs.carousel.$children[0]._data;
                //this.sendData(this.cardObject, this.innerSlideData)
                this.$emit('sendData', this.cardObject, this.innerSlideData)
                //this.$emit('sendData', "Sending")
            })
        },
        methods: {
            openModal: function(id, aoi) {
            //console.log(`openModal: `, id, aoi);
            //console.log(this.$refs.slickModal)
            this.$root.$emit('changeSelectedAOI', aoi);
            this.$bvModal.show(`modal-${id}`)
            //this.$refs.slickModal.open(id)
            
            },
            isActiveEvent(arg) {
                const timeOffset = 24*60*60*1000;
                let Now = new Date();
                let eventDay = new Date(arg);
                let eventExpiration = new Date(new Date().setTime(eventDay.getTime()));
                let deltaDays = (eventExpiration - Now) / timeOffset;
                //console.log('event day: ',eventDay)
                //console.log('event expiration: ',eventExpiration)
                //console.log('now:  ', Now)
                //console.log('delta: ',deltaDays)
                if ( deltaDays < 0) {
                //console.log("event is less than 10 days but greater than 0 days in the future");
                return false
                } else {
                    return true;
                }

                
                

            },
            filterCards() {
                const {cards} = this.data;
                //let filteredCards;
                //console.log(`original Cards: `,cards)

                let filteredCards = cards.filter((card) => {
                    //console.log(this.isActiveEvent(card.expiration))
                    if( this.isActiveEvent(card.expiration) ) {
                        return card
                    } 
                });
                //console.log(`filteredCards: `, filteredCards)

                return filteredCards;
            },
            showNext() {
                this.$refs.carousel.next();
            },
            showPrev() {
                this.$refs.carousel.prev();
            },
            slideChange(old, arg) {
                this.$emit('slideChange', `${arg + 1} / ${this.innerSlideData.slideCount}`)
            },
            
        }
    }
</script>

<style lang="scss">
// [aria-hidden='true'] {
//   display: none!important;
// }    
        .start-date-carousel-component {
            
            .slider-controls {
                display: flex;
                flex-direction: row;
                align-items: center;
                .chevron {
                    display: inline-block;
                    filter: invert(29%) sepia(17%) saturate(239%) hue-rotate(162deg) brightness(94%) contrast(98%);
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat!important;
                }
                .chevron.right {
                    background: url("https://res.cloudinary.com/utidinary/image/upload/f_auto/q_auto/Solid-Chevron.svg")
                }
                .chevron.left {
                    background: url("https://res.cloudinary.com/utidinary/image/upload/f_auto/q_auto/a_180/Solid-Chevron.svg")
                }
                
                .slide-count {
                    display: inline-block;
                    min-width: 50px;
                    text-align: center;
                }
            }
            
        }
        .slick-container {
            padding: 0 2rem!important;

        }
        .slick-track {
            position:unset!important;
            display: flex!important;
            flex-direction: row!important;
            align-items: stretch!important;
            //width: unset!important;
        }

        .slick-slide {
            display: flex!important;
            height: unset!important;
            min-height: unset!important;

            // flex: 0 0 68%;
            // max-width: 68%
        }

        .slick-active .slick-card {
            margin-left: 0px;
        }
        .slick-active:not(.slick-current) .slick-card {
            margin-right: 0px;
        }

        .slick-card {
            display: grid!important;
            //flex-direction: column;
            height: 100%;
            width: unset!important;
            background-color: #333232;
            color: #8a8a8a;
            padding: 10px;
            margin: 0 5px;
        

            &.expiredEvent {
                display: none!important;
            }

            .slick-card-header {
                color: white;
                font-size: 28px;
                line-height: 32px;
                font-weight: 400;
            }

            .slick-card-header2 {
                color: #ffd506;
                font-size: 28px;
                line-height: 32px;
                font-weight: 400;
            }

            .slick-card-paragraph {
                color: white;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
            }

            .slick-card-cta-text {
                color: white;
                font-size: 16px!important;
                line-height: 26px!important;
                font-weight: 900!important;
                text-decoration: underline;
                align-self: end;
                background-color: transparent!important;
                border-color: transparent!important;
            }
        }
    // .slideCard {
    //     background-color: #333232;
    //     color: #8a8a8a;
    //     display: flex!important;
    //     flex-direction: column;
    //     //justify-content: center;
    //     align-items: stretch;
    //     padding: 10px;
    //     //margin: 10px!important;

    //     .card-header {
    //         color: white;
    //         font-size: 28px;
    //         line-height: 32px;
    //         font-weight: 400;
    //     }

    //     .card-header2 {
    //         color: #ffd506;
    //         font-size: 28px;
    //         line-height: 32px;
    //         font-weight: 400;
    //     }

    //     .card-paragraph {
    //         color: white;
    //         font-size: 14px;
    //         line-height: 20px;
    //         font-weight: 400;
    //     }

    //     .card-cta-text {
    //         color: white;
    //         font-size: 16px;
    //         line-height: 26px;
    //         font-weight: 900px;
    //         text-decoration: underline;
    //     }
    // }

    // .slick-slide {
    //     margin:10px;
    // }
        

    .slick-prev:before, .slick-next:before {
        //font-weight: 300;
        color: #333232!important;
        opacity: 1!important;
    }
</style>
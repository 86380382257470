<template>
  <div>
    <b-form-group
      label="Country"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-select
        v-model="country"
        name="country"
        :value="value"
        :options="options"
        :class="{ isInvalidInput: v.$error }"
        @focus="optEvent('engagedCountry')"
        @change="optEvent('selectedCountry')"
        ref="Country"
      >
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "CountrySelect",
  props: ["v"],
  data() {
    return {
      selected: null,
      value: "US",
      options: [
        {
          value: "US",
          text: "United States"
        }
      ]
    }
  },
  computed: {
    country: {
      get() {
        return this.value
      },
      set(value) {
        this.v.$touch()
        this.value = value
        this.$emit("send-updated-country", value)
        //console.log(`input value:${this.value}`);
      }
    }
  },
  optEvent(event) {
    this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
  }
}
</script>

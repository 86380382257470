<template>
  <div
    :class="[
      'imageBlockContainer',
      'my-' + data.techSettings.rootMarginY,
      'mx-' + data.techSettings.rootMarginX,
      data.techSettings.rootClasses
    ]"
    :id="data.techSettings.rootID"
  >
    <b-container
      :class="['px-0', `${data.techSettings.backgroundColor}-theme`]"
      :style="'background-color:'+data.techSettings.backgroundColorHex"
      fluid
    >
      <b-container :fluid="data.techSettings.fullWidthContainer">
        <b-row
          v-if="data.headline || data.subHeadline || data.body"
          class="headerContent"
        >
          <b-col class="text-center" style="padding-bottom: 40px">
            <span
              class="vhtml headline"
              v-if="data.headline"
              v-html="data.headline"
            ></span>
            <span
              class="vhtml subHeadline h3"
              v-if="data.subHeadline"
              v-html="data.subHeadline"
            ></span>
            <span class="vhtml body" v-if="data.body" v-html="data.body"></span>
          </b-col>
        </b-row>
        <b-row
          :class="['imageBlockRow', data.techSettings.type]"
          :cols="
            data.techSettings.type == 'square'
              ? data.techSettings.blockSettings.mobileCols
              : ''
          "
          :cols-md="
            data.techSettings.type == 'square'
              ? data.techSettings.blockSettings.desktopCols
              : ''
          "
          :align-h="
            data.techSettings.type == 'square'
              ? data.techSettings.blockSettings.horizontalAlignment
              : ''
          "
        >
          <b-col
            :class="[
              'imageBlockWrapper',
              block.blockContent ? 'hasBlockContent' : ''
            ]"
            v-for="(block, index) in data.blocks"
            :key="index"
          >
            <div class="imageBlock backgroundImage" @click="(block.data && block.data.modalData) ? boxClick(block.data.modalData.id, index) : () => {}">
              
              <b-row
                :class="['imageBlockSquareSpacer',`${data.techSettings.squareSpacerClasses}`]"
                :style="getResponsibleImageAnchorStyles()"
              > 

                <video :class="['videoembed', block.backgroundImageClass, 'video-'+block.backgroundVideoPosition]" v-if="block.backgroundType == 'video'" :poster="block.posterUrl ? block.posterUrl : null" autoplay muted loop playsinline preload="auto">
                  <source :src="block.backgroundVideo"  type="video/mp4">
                </video>

                <img
                  :class="['imageBlockImage', block.backgroundImageClass]"
                  v-if="block.backgroundType == 'gif'"
                  :src="block.backgroundGif"
                />

                <img
                  :class="['imageBlockImage', block.backgroundImageClass]"
                  v-if="block.backgroundType == 'image'"
                  :src="block.backgroundImage"
                  :alt="block.backgroundImageAlt"
                />
                
                <div
                  v-if="data.techSettings.overlay.enabled"
                  :class="[
                    'imageBlockOverlay',
                    block.backgroundType,
                    block.backgroundOverlayClass,
                    data.techSettings.overlay.formExpands ? 'formExpands': null,
                    getOverlayClass(),
                    { hoverChange: data.techSettings.overlay.hoverChange }
                  ]"
                ></div>
                <div
                  v-if="block.blockContent.nestedComponents"
                  :class="[
                    'imageBlockNestedContent',
                    'nested',
                    'align-self-' + block.blockContent.alignment || 'top',
                    'nested',
                    block.blockContent.nestedComponentClasses
                  ]"
                  :style="block.blockContent.style"
                >
                  <div
                    v-for="(item, index) in block.blockContent.nestedComponents"
                    v-bind:key="index"
                  >
                    <component
                      v-bind:is="item.type"
                      :data="item.data"
                      class="blockNested"
                    ></component>
                  </div>
                </div>
                <div
                  v-if="
                    block.blockContent.headline ||
                    block.blockContent.subHeadline ||
                    block.blockContent.body ||
                    block.blockContent.button
                  "
                  class="imageBlockContent align-self-middle text-center"
                >
                  <p
                    v-if="block.blockContent.headline"
                    class="vhtml h2"
                    v-html="block.blockContent.headline"
                  ></p>
                  <p
                    v-if="block.blockContent.subHeadline"
                    class="vhtml h3"
                    v-html="block.blockContent.subHeadline"
                  ></p>
                  <p
                    v-if="block.blockContent.body"
                    class="vhtml body"
                    v-html="block.blockContent.body"
                  ></p>
                  <a
                    class="btn ltr"
                    v-if="
                      block.blockContent.button &&
                      block.blockContent.button.cta &&
                      block.blockContent.button.url
                    "
                    :href="block.blockContent.button.url"
                    >{{ block.blockContent.button.cta }}</a
                  >
                  <a
                    v-if="block.blockUrl"
                    class="imageBlockLink"
                    :href="block.blockUrl"
                    target="_blank"
                    >{{ block.blockUrl }} s</a
                  >
                </div>
              </b-row>
            </div>
            <Modal v-if="block.data && block.data.modalData" :data="block.data" :selectedAOI ="'AU'" :carousel="data.techSettings.carousel ? true : false" :carouselData="carouselObject" :carouselIndex="carouselIndex"></Modal>
            <b-row
              v-if="block.bottomContent"
              class="imageBlockBottomContent text-center"
            >
              <p
                v-if="block.bottomContent.headline"
                class="vhtml h2"
                v-html="block.bottomContent.headline"
              ></p>
              <p
                v-if="block.bottomContent.subHeadline"
                class="vhtml h3"
                v-html="block.bottomContent.subHeadline"
              ></p>
              <p
                v-if="block.bottomContent.body"
                class="vhtml body"
                v-html="block.bottomContent.body"
              ></p>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UpcomingEvent from "@/components/Content/UpcomingEvent"
import Modal from "@/components/Content/Modal";
import WYSIWYG from "@/components/Content/WYSIWYG";


export default {
  name: "ImageBlock",
  props: ["data"],
  components: {
    ...mapGetters(["CURRENT_BREAKPOINT"]),
    UpcomingEvent,
    Modal,
    Grid: () => import("@/components/Layout/Grid"),
    WYSIWYG,
  },
  data() {
      return {
          carouselObject: this.getCarousel(),
          carouselIndex: 0,
      }
  },
  computed: {
    xsImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let image = breakpoint.image

      return {
        "--xs-image-anchor-left-edge-offset": `${image.leftEdgeOffset}`,
        "--xs-image-anchor-top-edge-offset": `${image.topEdgeOffset}`,
        "--xs-image-anchor-zoom": `${image.zoom}`,
        "--xs-image-anchor-min-height": `${image.minHeight}`
      }
    },
    smImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let image = breakpoint.image

      return {
        "--sm-image-anchor-left-edge-offset": `${image.leftEdgeOffset}`,
        "--sm-image-anchor-top-edge-offset": `${image.topEdgeOffset}`,
        "--sm-image-anchor-zoom": `${image.zoom}`,
        "--sm-image-anchor-min-height": `${image.minHeight}`
      }
    },
    mdImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let image = breakpoint.image

      return {
        "--md-image-anchor-left-edge-offset": `${image.leftEdgeOffset}`,
        "--md-image-anchor-top-edge-offset": `${image.topEdgeOffset}`,
        "--md-image-anchor-zoom": `${image.zoom}`,
        "--md-image-anchor-min-height": `${image.minHeight}`
      }
    },
    lgImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let image = breakpoint.image

      return {
        "--lg-image-anchor-left-edge-offset": `${image.leftEdgeOffset}`,
        "--lg-image-anchor-top-edge-offset": `${image.topEdgeOffset}`,
        "--lg-image-anchor-zoom": `${image.zoom}`,
        "--lg-image-anchor-min-height": `${image.minHeight}`
      }
    },
    xlImageAnchor() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let image = breakpoint.image

      return {
        "--xl-image-anchor-left-edge-offset": `${image.leftEdgeOffset}`,
        "--xl-image-anchor-top-edge-offset": `${image.topEdgeOffset}`,
        "--xl-image-anchor-zoom": `${image.zoom}`,
        "--xl-image-anchor-min-height": `${image.minHeight}`
      }
    },
    xsHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let imageText = breakpoint.imageText
      let statement = ""

      statement = `${imageText.widthPercent}%`

      return {
        "--xs-image-text-width": statement
      }
    },
    smHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let imageText = breakpoint.imageText
      let statement = ""

      statement = `${imageText.widthPercent}%`

      return {
        "--sm-image-text-width": statement
      }
    },
    mdHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let imageText = breakpoint.imageText
      let statement = ""

      statement = `${imageText.widthPercent}%`

      return {
        "--md-image-text-width": statement
      }
    },
    lgHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let imageText = breakpoint.imageText
      let statement = ""

      statement = `${imageText.widthPercent}%`

      return {
        "--lg-image-text-width": statement
      }
    },
    xlHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let imageText = breakpoint.imageText
      let statement = ""

      statement = `${imageText.widthPercent}%`

      return {
        "--xl-image-text-width": statement
      }
    }
  },
  methods: {
    getCarousel() {
      const {blocks} = this.data;
      let carouselFilter;
      let carouselData;
      // get carousel data: 
      carouselFilter = blocks.filter((block) => {
        
        if('data' in block && 'msatBlock' in block.data) {
          return block.data.msatBlock
        } 
          
      })
      carouselData = carouselFilter.map((item) => {
        if('data' in item && 'msatBlock' in item.data) {
          return item.data.msatBlock
        } 
      })
      //sconsole.log(`carouselData`, carouselData)
      return carouselData;
      
    },
    getOverlayClass() {
      let type = this.data.techSettings.overlay.type
      let color = this.data.techSettings.overlay.color

      return type + "-" + color
    },
    getResponsibleImageAnchorStyles() {
      if (this.data.techSettings.type == "hero") {
        return [
          this.xsImageAnchor,
          this.smImageAnchor,
          this.mdImageAnchor,
          this.lgImageAnchor,
          this.xlImageAnchor
        ]
      }
    },
    isMobile() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      // console.log(`current breakpoint: `, currentBreakpoint)
      if (
        currentBreakpoint == "xs" ||
        currentBreakpoint == "sm" ||
        currentBreakpoint == "md"
      ) {
        return true
      } else {
        return false
      }
    },
    responsiveImage() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let content = this.data.content
      let mobileImg = ""
      let desktopImg = ""

      mobileImg = content.mobileImg
      desktopImg = content.desktopImg

      if (
        mobileImg !== "" &&
        (currentBreakpoint == "xs" || currentBreakpoint == "sm")
      ) {
        return mobileImg
      } else {
        return desktopImg
      }
    },
    responsiveGradient() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let techSettings = this.data.techSettings
      let mobileGradient = Boolean
      let desktopGradient = Boolean

      mobileGradient = techSettings.mobileGradientOverlay
      desktopGradient = techSettings.desktopGradientOverlay

      if (currentBreakpoint == "xs" || currentBreakpoint == "sm") {
        // console.log(`mobile gradient: `, mobileGradient)
        return mobileGradient
      } else {
        // console.log(`desktop gradient: `, desktopGradient)
        return desktopGradient
      }
    },
    boxClick: function(id, index) {
      console.log('Box Clicked!', id, 'at location ', index)
      // console.log([...document.querySelectorAll('.modal.show')].length)
      //console.log('Modal Disabled: ', this.data.modalDisabled)
      if (!this.data.modalDisabled) {
        console.log("inside", this.$children)
        if(this.data.techSettings.carousel) {
          this.carouselIndex = index;
          //console.log(this)
          for (let child of this.$children) {
            child.$children[0].$children[0].setSlide(index)
          }
          
        }
        //console.log(this.$children[index])
        this.$children[index].open(id)
        //this.$bvModal.show(`modal-${id}`)
          // console.log(this.$bvModal)
          //this.engaged("IconForm")
          //console.log(this.$bvModalEvent.shown())
        
      } 
    }
  }
}
</script>

<style lang="scss" scoped>
.imageBlockContainer {
  .blue-theme {
    background-color: #003653;
    color: white;

    span >>> a {
      color: white;
      text-decoration-line: underline;
    }
  }

  .grey-theme{
    background-color: rgb(234, 234, 234);
  }

  .white-theme{
    background-color: white;
    color: black!important;
  }
  .imageBlockRow {
    &.hero {
      .imageBlockSquareSpacer {
        padding-bottom: unset;
        margin: 0;
        //overflow: hidden;

        &::after {
          content: none;
        }

        img {
          width: 100%;
        }
      }
    }
    &.square {
      img {
        position: absolute;
      }
    }
  }

  .imageBlockWrapper {
    padding: 0;

    &:hover {
      .imageBlockOverlay {
        visibility: visible;

        &.gradient-darkBlue {
          background-image: linear-gradient(
            to bottom,
            rgba(0, 42, 60, 0),
            #002a3c
          );
        }

        &.gradient-lightBlue {
          background-image: linear-gradient(
            to bottom,
            rgba(0, 42, 60, 0),
            #01567e
          );
        }

        &.solid-darkBlue.hoverChange {
          background-color: #002a3c;
          opacity: 0.5;
        }

        &.solid-lightBlue.hoverChange {
          background-color: #016493;
          opacity: 0.5;
        }
      }
    }
  }

  .imageBlockSquareSpacer {
    justify-content: center;
    position: relative;
    margin: 5px;

    &::after {
      content: "";
      padding-bottom: 100%;
    }
  }

  .hasBlockContent {
    .imageBlockImage {
      position: absolute;
    }
  }

  .imageBlockImage {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;

    &.contain {
      object-fit: contain;
      position: static;
    }
  }

  .hasBlockContent {
    .videoembed {
      position: absolute;
      right: 0;

      &.video-left {
        left: 0;
        right: unset;

        +.video.solid-darkBlue {
        background: linear-gradient(to left, rgba(0, 42, 60, 1) 55%, rgba(0, 42, 60, 0) 100%);
        }

        +.video.mobile.solid-darkBlue {
        background: linear-gradient(to left, rgba(0, 42, 60, 1) 0%, rgba(0, 42, 60, 0) 130%);
      }
      }
    }
  }

  .videoembed {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  .btn {
    position: relative;
    color: #fff;
    padding: 0.9rem;
    margin: 0 1rem 0;
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
    border: 1px solid #fff;
    text-transform: uppercase;
    line-height: 1.4;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 0.8888888889rem;
    border-width: 1px;
    z-index: 1;
    white-space: nowrap;

    &.ltr {
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;

      &:before {
        background-image: linear-gradient(to bottom, #d50019, #a70e13);
      }

      &:hover {
        &:before {
          top: 0;
          left: 0;
          bottom: 0;
          right: auto;
          width: 100%;
          height: 100%;
        }
      }
    }
    &:before {
      display: block;
      position: absolute;
      z-index: -1;
      content: "";
      background-image: linear-gradient(to bottom, #d50019, #a70e13);
      top: 0;
      left: auto;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 0;
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
    }

    &:hover {
      background-color: transparent !important;
      text-shadow: none;
    }
  }

  .imageBlockNestedContent {
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    //color: white;
    //overflow: hidden;

    
  }
  .imageBlockContent {
    margin: 0 auto;
    max-width: 90%;
    width: 90%;
    z-index: 2;
    color: white;
    overflow: hidden;

    .body {
      color: white;
    }
  }

  .imageBlockOverlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;

    &.gradient-darkBlue {
      background-image: linear-gradient(to bottom, rgba(0, 56, 82, 0), #003852);
    }

    &.gradient-lightBlue {
      background-image: linear-gradient(
        to bottom,
        rgba(15, 63, 85, 0),
        #016493
      );
    }

    &.solid-darkBlue {
      background-color: #002a3c;
      opacity: 0.6;
    }

    &.video.solid-darkBlue {
      background: linear-gradient(to right, rgba(0, 42, 60, 1) 55%, rgba(0, 42, 60, 0) 100%);
      opacity: 1;
    }

    &.video.formExpands.solid-darkBlue {
      background: linear-gradient(to right, rgba(0, 42, 60, 1) 15%, rgba(0, 42, 60, 0) 100%);
      opacity: 1;
    }
    

    &.video.mobile.solid-darkBlue {
      background: linear-gradient(to right, rgba(0, 42, 60, 1) 0%, rgba(0, 42, 60, 0) 130%);
      opacity: 1;
    }

    &.solid-lightBlue {
      background-color: #016493;
      opacity: 0.6;
    }
  }

  .imageBlockLink {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    position: absolute;
  }

  .imageBlockBottomContent {
    display: block;
    max-width: 85%;
    margin: 0 auto 50px;

    .h2 {
      margin: 25px 0;
      font-size: 2rem;
      line-height: 1;
      letter-spacing: -0.01rem;
    }
  }
}

// XS BreakPoint
@media screen and (min-width: 0px) {
  .imageBlockRow.hero {
    .imageBlockSquareSpacer {
      min-height: var(--xs-image-anchor-min-height);
      img {
        object-position: var(--xs-image-anchor-left-edge-offset)
          var(--xs-image-anchor-top-edge-offset);
        min-height: var(--xs-image-anchor-zoom);
      }
    }
  }
  .hero-with-image {
    background-position: var(--xs-image-anchor);

    .hero-container .hero-text {
      width: var(--xs-hero-text-width);
    }
  }
}

// SM BreakPoint
@media screen and (min-width: 576px) {
  .imageBlockRow.hero {
    .imageBlockSquareSpacer {
      min-height: var(--sm-image-anchor-min-height);
      img {
        object-position: var(--sm-image-anchor-left-edge-offset)
          var(--sm-image-anchor-top-edge-offset);
        min-height: var(--sm-image-anchor-zoom);
      }
    }
  }

  .hero-with-image {
    background-position: var(--sm-image-anchor);

    .hero-container .hero-text {
      width: var(--sm-hero-text-width);
    }
  }
}

// MD BreakPoint
@media screen and (min-width: 768px) {
  .imageBlockRow.hero {
    .imageBlockSquareSpacer {
      min-height: var(--md-image-anchor-min-height);
      img {
        object-position: var(--md-image-anchor-left-edge-offset)
          var(--md-image-anchor-top-edge-offset);
        min-height: var(--md-image-anchor-zoom);
      }
    }
  }

  .hero-with-image {
    background-position: var(--md-image-anchor);

    .hero-container .hero-text {
      width: var(--md-hero-text-width);
    }
  }
}

// LG BreakPoint
@media screen and (min-width: 992px) {
  .imageBlockRow.hero {
    .imageBlockSquareSpacer {
      min-height: var(--lg-image-anchor-min-height);
      img {
        object-position: var(--lg-image-anchor-left-edge-offset)
          var(--lg-image-anchor-top-edge-offset);
        min-height: var(--lg-image-anchor-zoom);
      }
    }
  }

  .hero-with-image {
    background-position: var(--lg-image-anchor);

    .hero-container .hero-text {
      width: var(--lg-hero-text-width);
    }
  }
}

// xl BreakPoint
@media screen and (min-width: 1200px) {
  .imageBlockRow.hero {
    .imageBlockSquareSpacer {
      min-height: var(--xl-image-anchor-min-height);
      img {
        object-position: var(--xl-image-anchor-left-edge-offset)
          var(--xl-image-anchor-top-edge-offset);
        min-height: var(--xl-image-anchor-zoom);
      }
    }
  }

  .hero-with-image {
    background-position: var(--xl-image-anchor);

    .hero-container .hero-text {
      width: var(--xl-hero-text-width);
    }
  }
}

@media screen and (min-width: 992px) {
  .hero-with-image {
    min-height: 368px;
  }
}
</style>
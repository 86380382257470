<template>
  <div>
    <b-row class="col-12 py-2 pt-4">
      <h5 class="text-muted mb-0 pb-0 font-weight-light">Interests</h5>
    </b-row>
    <hr color="grey" class="mt-0" />
    <b-row class="col-12 py-2">
      <h5 :class="{ hasError: v.$error }" ref="AOI">
        What <strong>training</strong> are you interested in?
      </h5>
    </b-row>
    <div class="aoiContainer">
      <label v-for="item in options" v-bind:key="item.value">
        <span class="outline-primary">{{ item.text }}</span>
        <input
          v-on:click="selected($event, item)"
          type="checkbox"
          :id="item.value"
          :value="item.value"
          @change="optEvent('selectedAOI')"
          @click="optEvent('engagedAOISelection')"
        />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "aoiSection",
  props: ["v"],
  data() {
    return {
      current: "",
      options: [
        { text: "Automotive Technician", value: "AU" },
        { text: "Collision and Auto Body Repair", value: "CR" },
        { text: "Diesel/Industrial", value: "DI" },
        { text: "Marine Mechanic", value: "MA" },
        { text: "Motorcycle Mechanic", value: "MO" },
        { text: "NASCAR Technician and Pit Crew", value: "NASCAR" }
      ]
    }
  },
  methods: {
    selected(e) {
      if (this.current) {
        let old = document.getElementById(this.current).parentNode
        old.className = ""
        this.current = e.target.value
        e.target.parentNode.className = "selected"
      } else {
        this.current = e.target.value
        e.target.parentNode.className = "selected"
      }
      this.v.$touch()
      this.$emit("send-updated-aoi", e.target.value)
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>

<style lang="scss" scoped>
.aoiContainer {
  display: flex;
  flex-flow: column;
  text-align: center;
  margin: 1em 0;

  label {
    width: fit-content;
    color: black;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    border: 1px solid #007bff;
    transition: background-color 0.2s linear;
    border-radius: 50rem;
    padding: 10px;
    &.selected {
      color: white;
      background-color: #007bff;
    }
  }

  input {
    display: none;
  }
}
</style>
  <template>
      <div class="marketo-form-component">
        <b-row class="mx-0">
          <b-col cols="12" :class="`py-3 container content-block gray-theme formOverlap gridColNested`" style="max-width:576px;">
              <header v-if="this.data.techSettings.modalCloseButton" class="modalButton" @click="hideModal()"> 
                <button type="button" aria-label="Close" class="close" id="modalBtnClose">x</button>
              </header>
              <form :id="'mktoForm_' + data.mktoExe" class="mktoForm mx-auto py-3"></form>
          </b-col>
        </b-row>
      </div>
</template>
<script>

  export default {
    name: 'MarketoForm',
    props: ["data","modalID"],
    data() {
      return {     
        mktoObjects: [],
      }
    },
    beforeMount() {
    },
    mounted: function() {
      this.loadMkto();
      this.initMkto();
    },
    methods: {
      initMkto() {
        console.log('initMKTO')
        this.injectJS('SRC','https://info.uti.edu/js/forms2/js/forms2.min.js');
      },
      loadMkto() {
        console.log('loadMKTO')
        let mktoEmbed = 'MktoForms2.loadForm("https://info.uti.edu", "198-VVS-594",'+ this.data.mktoExe + ');\n';
        this.injectJS('EXE', mktoEmbed);
      },
      isMobile() {
        let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
        // //console.log(`current breakpoint: `, currentBreakpoint)
        if (
          currentBreakpoint == "xs" ||
          currentBreakpoint == "sm" ||
          currentBreakpoint == "md"
        ) {
          return true
        } else {
          return false
        }
      },
      injectJS(type, arg) {
      let newScript = document.createElement('script');

      if (type == 'SRC') {
        newScript.src = arg;
        document.head.prepend(newScript);
        //console.log('inject js - src')
      } else if ( type == "EXE") {
        window.addEventListener('load', () => {
          //console.log('inject js - exe')
          newScript.text = arg;
          document.head.prepend(newScript);
          //console.log('Marketo Loaded')
        })
      } else return;
      },
      hideModal() {
      this.$root.$emit('bv::hide::modal', `modal-${this.modalID}`, '#modalBtnClose')
      //console.log(`close ${this.modalID}`)
    },
    }
    
  }
</script>

<style lang="scss" scoped>
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
    top: 2.5rem !important;
  }
  .card-border{
    border: 2px solid #969A9C!important;
    cursor: pointer;
  }
  .card-border-active{
    border: 2px solid #0086D2!important;
    background: #F6F6FB;
  }
  .card-border:hover{
    border: 2px solid #0086D2;
    background: #F6F6FB;
  }
  .day-head {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-bottom: 0px;
  }

  .day-timezone {
    font-weight: bold;
  }

  .thankyou-text{
    font-size: 2.875rem;
    color: #25292B;
    margin-bottom: 0%;
    text-align: center;
  }
  .thankyou-para{
    font-size: 1.125rem;
    color:#25292B;
    text-align: center;
  }
  .disclaimer-text{
    font-size: 1.125rem;
    color:#25292B;
  }

  .mktoForm {
    /* */
    &.blue-theme {
      background-color: #003653;
      color: #fff;

      .accessory {
        color: #b2b2b2;
      }
    }

    &.white-theme,
    &.-theme {
      background-color: #fff;
      color: #000;

      .accessory {
        color: #737373;
      }
    }

    &.gray-theme,
    &.grey-theme {
      background-color: #f2f5f7;
      color: #003653;

      .accessory {
        color: #0086d2;
      }
    }


    @media screen and (min-width: 768px) {
      &.formOverlap {
        position: absolute;
        z-index: 99;
      }
    }
  }
  .expiredEvent, .futureEvent {
    display: none!important;
  }

  th {
    padding: 2px 10px;
  }
</style>
<style lang="scss">
  .selectionForm-component {
    .form-group {
      tr:first-child {
        background-color: lightgray!important;
      }

      th {
        border: 1px solid!important;;
        font-weight: normal!important;
        padding: 5px;
      }
    }
  }
</style>
<template>
  <div>
    <b-form-group
      label="Last Name"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-input
        placeholder="Last Name"
        type="text"
        name="last-name"
        v-model="LastName"
        :formatter="formatter"
        @input="v.$touch()"
        @focus="optEvent('engagedLastName')"
        :class="{ isInvalidInput: v.$error }"
        ref="LastName"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "LastNameInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    LastName: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
        console.log("emit LastName event fired")
      }
    }
  },
  methods: {
    formatter: function (value) {
      //return value.replace(/[\D\._\-\(\)]+/g, "");
      return value.replace(/[^a-zA-Z\s'-]/gi, "")
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>

export default {
  state: {
    disclaimerData: [
      "UTI is an educational institution and cannot guarantee employment or salary.",
      "For program outcome information and other disclosures, visit <a href='https://www.uti.edu/disclosures' target='_blank'>www.uti.edu/disclosures</a>.",
      "Approximately 8,000 of the 8,400 UTI graduates in 2019 were available for employment. At the time of reporting, approximately 6,700 were employed within one year of their graduation date, for a total of 84%. This rate excludes graduates not available for employment because of continuing education, military service, health, incarceration, death or international student status. The rate includes graduates who completed manufacturer-specific advanced training programs and those employed in positions that were obtained before or during their UTI education, where the primary job duties after graduation align with the educational and training objectives of the program. UTI is an educational institution and cannot guarantee employment or salary.",
      "UTI’s Automotive Technology program is 51 weeks. Core program length varies by subject. For example, Diesel & Industrial Technology is 45 weeks and Automotive/Diesel Technology is 75 weeks.",
      "UTI programs prepare graduates for careers in industries using the provided training, primarily as automotive, diesel, collision repair, motorcycle and marine technicians. Some UTI graduates get jobs within their field of study in positions other than as a technician, such as: parts associate, service writer, fabricator, paint and paint prep, and shop owner/operator. UTI is an educational institution and cannot guarantee employment or salary.",
      "UTI graduates’ achievements may vary. Individual circumstances and wages depend on personal credentials and economic factors. Work experience, industry certifications, the location of the employer and their compensation programs affect wages. UTI is an educational institution and cannot guarantee employment or salary.",
      "Some programs may require longer than one year to complete.",
      "Does not include time required to complete a qualifying prerequisite program such as UTI’s 51-week Automotive Technology program.",
      "Does not include time required to complete a qualifying prerequisite program such as UTI’s 45-week Diesel Technology program.",
      "Financial aid, scholarships and grants are available to those who qualify. Awards vary due to specific conditions, criteria and state.",
      "See program details for eligibility requirements and conditions that may apply.",
      "Based on data compiled from the U.S. Bureau of Labor Statistics, Employment Projections (2019-2029), <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed September 8, 2020. The projected average number of annual job openings, by job classification is: Automotive Service Technicians and Mechanics, 61,700; Bus and Truck Mechanics and Diesel Engine Specialists, 24,500; and Automotive Body and Related Repairers, 13,600. Job openings include openings due to growth and net replacements. UTI is an educational institution and cannot guarantee employment or salary.",
      "Federal Highway Administration, Office of Highway Policy Information, Highway Statistics 2019, number of state motor vehicle registrations, <a href='https://www.fhwa.dot.gov/policyinformation/statistics/2019/mv1.cfm' target='_blank'>https://www.fhwa.dot.gov/policyinformation/statistics/2019/mv1.cfm</a>",
      "Incentive programs and employee eligibility are at the discretion of the employer and available at select locations. Special conditions may apply. Talk to potential employers to learn more about the programs available in your area. UTI is an educational institution and cannot guarantee employment or salary.",
      "Manufacturer-paid advanced training programs are conducted by UTI on behalf of manufacturers who determine acceptance criteria and conditions. These programs are not part of UTI’s accreditation. Programs available at select locations.",
      "Not all programs are accredited by the ASE Education Foundation.",
      "Program start dates vary by campus.",
      "UTI now offers all of its automotive, diesel, motorcycle and marine technician training in a blended learning format consisting of online lecture courses along with in-person, hands-on lab training.",
      "Students who are near graduation, have no outstanding obligations to the school, and who have not previously taken part in a tool voucher program on any UTI/MMI/NASCAR Tech affiliated campus may be eligible to participate in the UTI Tool Voucher Program.",
      "VA benefits may not be available at all campus locations.",
      "GI Bill® is a registered trademark of the U.S. Department of Veterans Affairs (VA). More information about education benefits offered by VA is available at the official <a href='http://www.benefits.va.gov/gibill' target='_blank'>U.S. government website</a>.",
      "Salute to Service Grant is available to all eligible veterans at all campus locations. The Yellow Ribbon program is approved at our Avondale, Dallas/Fort Worth, Long Beach, Orlando, Rancho Cucamonga and Sacramento campus locations.",
      "Salute to Service Grant is available to all eligible veterans at all campus locations. The Yellow Ribbon program is approved at our Avondale, Dallas/Fort Worth, Long Beach, Orlando, Rancho Cucamonga and Sacramento campus locations.",
      "Prior to starting or re-enrolling, Tennessee residents attending the Orlando or Houston campus must provide official high school transcripts, GED scores or state-authorized equivalent exam scores. In addition, all students attending the NASCAR Tech campus must provide official high school transcripts, GED scores or state-authorized equivalent exam scores prior to starting or re-enrolling.",
      "NASCAR Technical Institute prepares graduates to work as entry-level automotive service technicians. Some graduates who take NASCAR-specific electives also may have job opportunities in racing-related industries. NASCAR Tech is an educational institution and cannot guarantee employment or salary.",
      "UTI’s Automotive Technology program prepares graduates for entry-level positions using the provided training, primarily as automotive technicians. Estimated annual salary is for Automotive Service Technicians and Mechanics as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for UTI graduates. UTI is an educational institution and cannot guarantee employment or salary. UTI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some UTI graduates get jobs within their field of study in positions other than as an automotive technician, such as service writer, smog inspector, and parts associate. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Automotive Service Technicians and Mechanics (49-3023) in the Commonwealth of Massachusetts is $32,140 to $53,430 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled automotive technicians in North Carolina is $20.59 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Automotive Service Technicians and Mechanics). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $14.55 and $11.27, respectively.",
      "UTI’s Welding Technology Training program prepares graduates for entry-level positions using the provided training, primarily as welders. Estimated annual salary is for Welders, Cutters, Solderers, and Brazers as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for UTI graduates. UTI is an educational institution and cannot guarantee employment or salary. UTI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some UTI graduates get jobs within their field of study in positions other than as a welding technician, such as inspector and quality control. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Welders, Cutters, Solderers, and Brazers (51-4121) in the Commonwealth of Massachusetts is $36,160 to $50,810 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled diesel technicians in North Carolina is $20.28 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Welders, Cutters, Solderers, and Brazers). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $16.97 and $14.24, respectively.",
      "Does not include time required to complete a qualifying prerequisite program of 18 weeks plus an additional 12 weeks or 24 weeks in manufacturer-specific training, depending on the manufacturer.",
      "UTI’s Collision Repair & Refinishing Technology (CRRT) program prepares graduates for entry-level positions using the provided training, primarily as collision repair technicians. Estimated annual salary is for Automotive Body and Related Repairers as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for UTI graduates. UTI is an educational institution and cannot guarantee employment or salary. UTI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some UTI graduates get jobs within their field of study in positions other than as a collision repair technician, such as appraiser, estimator and inspector. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Automotive Body and Related Repairers (49-3021) in the Commonwealth of Massachusetts is $30,400 to $34,240 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled collision repair technicians in North Carolina is $23.40 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Automotive Body and Related Repairers). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $17.94 and $13.99, respectively.",
      "UTI’s Diesel Technology program prepares graduates for entry-level positions using the provided training, primarily as diesel technicians. Estimated annual salary is for Bus and Truck Mechanics and Diesel Engine Specialists as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for UTI graduates. UTI is an educational institution and cannot guarantee employment or salary. UTI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some UTI graduates get jobs within their field of study in positions other than as diesel truck technicians, including in industries such as heavy equipment repair, power generation, and agriculture. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Bus and Truck Mechanics and Diesel Engine Specialists (49-3031) in the Commonwealth of Massachusetts is $32,360 to $94,400 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled diesel technicians in North Carolina is $23.20 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Bus and Truck Mechanics and Diesel Engine Specialists). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $19.41 and $16.18, respectively.",
      "MMI’s Motorcycle Technician Training program prepares graduates for entry-level positions using the provided training, primarily as motorcycle technicians. Estimated annual salary is for Motorcycle Mechanics as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for MMI graduates. MMI is an educational institution and cannot guarantee employment or salary. MMI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some MMI graduates get jobs within their field of study in positions other than as a motorcycle technician, such as service writer, equipment maintenance, and parts associate. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary for persons employed as Motorcycle Mechanic (49-3052) in the Commonwealth of Massachusetts is $30,660 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled motorcycle technicians in North Carolina is $15.94 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Motorcycle Mechanics). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $12.31 and $10.56, respectively.",
      "MMI’s Marine Technician Specialist program prepares graduates for entry-level positions using the provided training, primarily as marine technicians. Estimated annual salary is for Motorboat Mechanics and Service Technicians as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for MMI graduates. MMI is an educational institution and cannot guarantee employment or salary. MMI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some MMI graduates get jobs within their field of study in positions other than as a marine technician, such as equipment maintenance, inspector, and part associate. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Motorboat Mechanics and Service Technicians (49-3051) in the Commonwealth of Massachusetts is $32,760 to $42,570 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled marine technicians in North Carolina is $18.61 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Motorboat Mechanics and Service Technicians). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $15.18 and $12.87, respectively.",
      "Ignite participants must satisfy standard eligibility criteria prior to acceptance into UTI",
      "Courses vary by campus. For details, contact the program representative at the campus you are interested in attending.",
      "UTI’s CNC Machining Technology program prepares graduates for entry-level positions using the provided training, primarily as CNC machinists. Estimated annual salary is for Computer Numerically Controlled Tool Operators as published in the U.S. Bureau of Labor Statistics’ May 2020 Occupational Employment and Wages. Entry-level salaries are lower for UTI graduates. UTI is an educational institution and cannot guarantee employment or salary. UTI graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some UTI graduates get jobs within their field of study in positions other than as a CNC technician, such as CNC operator, apprentice machinist, and machined parts inspector. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary for persons employed as Computer-Controlled Machine Tool Operators, Metal and Plastic (51-4011) in the Commonwealth of Massachusetts is $35,140 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled CNC machinists in North Carolina is $20.24 (Bureau of Labor Statistics, U.S. Department of Labor, May 2020 Occupational Employment and Wages, Computer Numerically Controlled Tool Operators). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentiles of hourly earnings in North Carolina are $16.56 and $13.97, respectively.",
      "UTI is committed to making reasonable, appropriate and effective modifications in policies, practices, and procedures for qualified individuals with disabilities. If you need specific accommodations, please notify Student Services in advance of your arrival.",
      "Students enrolled in select UTI programs are eligible to apply for the Early Employment Program. Participating employers will contact selected applicants to conduct interviews. Hiring, employee retention and compensation decisions are made solely by the prospective employer. Employer participation and program details are subject to change. For additional information, please contact Career Services. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment in each of the following occupations by 2030 will be: Automotive Service Technicians and Mechanics, 705,900; Welders, Cutters, Solderers, and Brazers, 452,400; Bus and Truck Mechanics and Diesel Engine Specialists, 296,800; Automotive Body and Related Repairers, 161,800; and Computer Numerically Controlled Tool Operators, 154,500.  See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "Refresher training available to graduates only if the course is still available and space is available. Students are responsible for any other costs such as lab fees associated with the course.",
      "Current students not attending on an M-1 visa may be eligible to convert to an AST program depending on certain factors, including how far a student has progressed in the original program. For details on programs that grant an AST, see the course catalog. UTI is an educational institution and cannot guarantee employment or salary.",
      "For Automotive Service Technicians and Mechanics, the U.S. Bureau of Labor Statistics projects an annual average of 69,000 job openings between 2020 and 2030. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2020–30, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "For Welders, Cutters, Solderers, and Brazers, the U.S. Bureau of Labor Statistics projects an annual average of 47,600 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021–31, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed October 13, 2022. UTI is an educational institution and cannot guarantee employment or salary.",
      "For Bus and Truck Mechanics and Diesel Engine Specialists, the U.S. Bureau of Labor Statistics projects an annual average of 28,100 job openings between 2020 and 2030. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2020–30, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "For Automotive Body and Related Repairers, the U.S. Bureau of Labor Statistics projects an annual average of 15,200 job openings between 2020 and 2030. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2020–30, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "For Computer Numerically Controlled Tool Operators, the U.S. Bureau of Labor Statistics projects an annual average of 16,500 job openings between 2020 and 2030. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2020–30, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "Students must maintain a minimum 3.5 GPA and 95% attendance rate.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Automotive Service Technicians and Mechanics will be 705,900 by 2030. See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Bus and Truck Mechanics and Diesel Engine Specialists will be 296,800 by 2030. See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Automotive Body and Related Repairers will be 161,800 by 2030 See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Welders, Cutters, Solderers, and Brazers will be 452,400 by 2030. See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Computer Numerically Controlled Tool Operators will be 154,500 by 2030. See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects the national average annual job openings in each of the following occupations between 2020 and 2030 will be: Automotive Service Technicians and Mechanics, 69,000; Bus and Truck Mechanics and Diesel Engine Specialists, 28,100; and Welders, Cutters, Solderers, and Brazers, 49,200. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2020–30, U.S. Bureau of Labor Statistics, <a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment in each of the following occupations by 2030 will be: Automotive Service Technicians and Mechanics, 705,900; Welders, Cutters, Solderers, and Brazers, 452,400; Bus and Truck Mechanics and Diesel Engine Specialists, 296,800. See <a href='https://www.bls.gov/emp/tables/emp-by-detailed-occupation.htm' target='_blank'>Table 1.2 Employment by detailed occupation, 2020 and projected 2030</a>, U.S. Bureau of Labor Statistics, <a href='<a href='https://www.bls.gov' target='_blank'>www.bls.gov</a>' target='_blank'><a href='https://www.bls.gov' target='_blank'>www.bls.gov</a></a>, viewed November 18, 2021. UTI is an educational institution and cannot guarantee employment or salary.",
      "Statement based on ACCSC School of Excellence/Distinction and UTI and MIAT’s number of program offerings and campus locations nationwide. UTI is an educational institution and cannot guarantee employment or salary.",
      "U.S. Department of Transportation, Federal Highway Administration, Highway Statistics (Washington, DC: Annual Issues), number of automobile registrations as of Feb. 17, 2022.",
      "Military Friendly® Schools designation applies to specific campuses. Check with the campus for details.",
      "The Airframe & Powerplant and Aviation Maintenance Technology programs prepare graduates for entry-level positions using the provided training, primarily as aviation maintenance technicians. Estimated annual salary is for Aircraft Mechanics and Service Technicians as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as aviation maintenance technicians, such as turbine technicians or field technicians. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Aircraft Mechanics and Service Technicians (49-3011) in the Commonwealth of Massachusetts is $45,600 to $67,950 (Massachusetts Labor and Workforce Development, May 2020 data <a href='https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#' target='_blank'>https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#</a>). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled aviation technicians in North Carolina is $28.90 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Aircraft Mechanics and Service Technicians). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $23.54 and $18.25, respectively.",
      "The Heating, Ventilation, Air Conditioning and Refrigeration (HVACR) program prepares graduates for entry-level positions using the provided training, primarily as HVACR technicians. Estimated annual salary is for Heating, Air Conditioning, and Refrigeration Mechanics and Installers as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as HVACR technicians, such as installation technicians and refrigeration technicians. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Heating, Air Conditioning, and Refrigeration Mechanics and Installers (49-9021) in the Commonwealth of Massachusetts is $42,100 to $58,670 (Massachusetts Labor and Workforce Development, May 2020 data https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled HVACR technicians in North Carolina is $22.76 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Heating, Air Conditioning, and Refrigeration Mechanics and Installers). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $17.96 and $14.56, respectively.",
      "The Robotics & Automation program prepares graduates for entry-level positions using the provided training, primarily as robotics & automation technicians. Estimated annual salary is for Electro-Mechanical and Mechatronics Technologists and Technicians as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as robotics & automation technicians, such as control systems technicians and maintenance technicians. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Electro-Mechanical and Mechatronics Technologists and Technicians (17-3024) in the Commonwealth of Massachusetts is $43,670 to $52,960 (Massachusetts Labor and Workforce Development, May 2020 data https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled robotics & automation technicians in North Carolina is $26.76 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Electro-Mechanical and Mechatronics Technologists and Technicians). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $22.71 and $17.96, respectively.",
      "The Energy Technology and Industrial Maintenance Technician programs prepare graduates for entry-level positions using the provided training, primarily as wind power technicians or industrial maintenance technicians. Estimated annual salary is for Industrial Machinery Mechanics as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as wind power technicians and industrial maintenance technicians, such as installation technicians, field service technicians, boiler technicians and plant technicians. Salary information for the Commonwealth of Massachusetts: The average annual entry-level salary range for persons employed as Industrial Machinery Mechanics (49-9041) in the Commonwealth of Massachusetts is $37,420 to $55,050 (Massachusetts Labor and Workforce Development, May 2020 data https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#). Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled wind power technicians and industrial maintenance technicians in North Carolina is $27.11 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Industrial Machinery Mechanics). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $22.36 and $17.74, respectively.",
      "The Wind Technician training program prepares graduates for entry-level positions using the provided training, primarily as wind power technicians. Estimated annual salary is for Wind Turbine Service Technicians as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as wind power technicians, such as installation technicians and field service technicians. Salary information for the Commonwealth of Massachusetts is available at https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#. Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled wind turbine technicians in North Carolina is $28.46 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Wind Turbine Service Technicians). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $28.45 and $22.42, respectively.",
      "The Non-Destructive Testing (NDT) program prepares graduates for entry-level positions using the provided training, primarily as non-destructive testing technicians. Estimated annual salary is for Engineering Technologists and Technicians, Except Drafters, All Other as published in the U.S. Bureau of Labor Statistics’ May 2021 Occupational Employment and Wages. Entry-level salaries are lower for graduates. UTI and MIAT are educational institutions and cannot guarantee employment or salary. Graduates’ achievements may vary. Individual circumstances and wages depend on economic factors, personal credentials, work experience, industry certifications, the location of the employer, and their compensation programs. Some graduates get jobs within their field of study in positions other than as non-destructive testing technicians, such as quality control technicians and inspectors. Salary information for the Commonwealth of Massachusetts is available at https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#. Salary information for North Carolina: The U.S. Department of Labor estimates the hourly median wage for skilled non-destructive testing technicians in North Carolina is $32.29 (Bureau of Labor Statistics, U.S. Department of Labor, May 2021 Occupational Employment and Wages, Engineering Technologists and Technicians, Except Drafters, All Other). The Bureau of Labor Statistics does not publish entry-level salary data. However, the 25th and 10th percentile of hourly earnings in North Carolina are $22.71 and $17.04, respectively.",
      "Bureau of Labor Statistics (BLS), U.S. Department of Labor, Occupational Outlook Handbook, Wind Turbine Technicians, https://www.bls.gov/ooh/installation-maintenance-and-repair/wind-turbine-technicians.htm. BLS projects total employment will increase from 11,100 in 2021 to 16,100 in 2031. UTI and MIAT are educational institutions and cannot guarantee employment or salary. For program outcome information and other disclosures, visit www.uti.edu/disclosures for UTI and www.miat.edu/disclosures for MIAT.",
      "Bureau of Labor Statistics (BLS), U.S. Department of Labor, Occupational Outlook Handbook, Industrial Machinery Mechanics, https://www.bls.gov/ooh/installation-maintenance-and-repair/industrial-machinery-mechanics-and-maintenance-workers-and-millwrights.htm. BLS projects total employment will increase from 384,800 in 2021 to 447,900 in 2031. UTI and MIAT are educational institutions and cannot guarantee employment or salary. For program outcome information and other disclosures, visit www.uti.edu/disclosures for UTI and www.miat.edu/disclosures for MIAT.",
      "For Aircraft Mechanics and Service Technicians, the U.S. Bureau of Labor Statistics projects an annual average of 11,500 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "For Heating, Air Conditioning, and Refrigeration Mechanics and Installers, the U.S. Bureau of Labor Statistics projects an annual average of 40,100 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "For Electro-Mechanical and Mechatronics Technologists and Technicians, the U.S. Bureau of Labor Statistics projects an annual average of 1,100 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "For Industrial Machinery Mechanics, the U.S. Bureau of Labor Statistics projects an annual average of 42,500 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "For Wind Turbine Service Technicians, the U.S. Bureau of Labor Statistics projects an annual average of 1,900 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "For Engineering Technologists and Technicians, Except Drafters, All Other, the U.S. Bureau of Labor Statistics projects an annual average of 7,800 job openings between 2021 and 2031. Job openings include openings due to net employment changes and net replacements. See Table 1.10 Occupational separations and openings, projected 2021-31, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Aircraft Mechanics and Service Technicians will be 139,800 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Heating, Air Conditioning, and Refrigeration Mechanics and Installers will be 414,400 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Electro-Mechanical and Mechatronics Technologists and Technicians will be 11,600 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Industrial Machinery Mechanics will be 447,900 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Wind Turbine Service Technicians will be 16,100 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "The U.S. Bureau of Labor Statistics projects that total national employment for Engineering Technologists and Technicians, Except Drafters, All Other will be 78,900 by 2031. See Table 1.2 Employment by detailed occupation, 2021 and projected 2031, U.S. Bureau of Labor Statistics, www.bls.gov, viewed October 13, 2022. The Universal Technical Institute family of schools are educational institutions and cannot guarantee employment or salary.",
      "UTI is an educational institution and cannot guarantee employment or salary. UTI prepares graduates for entry-level careers using the provided training. UTI graduates’ achievements may vary. Some UTI graduates get jobs within their field of study in positions other than those listed.",
      "MIAT is an educational institution and cannot guarantee employment or salary. MIAT prepares graduates for entry-level careers using the provided training. MIAT graduates’ achievements may vary. Some MIAT graduates get jobs within their field of study in positions other than those listed.",
      "Pending FAA approval, UTI’s aviation maintenance programs prepare graduates to apply & test for FAA mechanic certification. Graduates who don’t obtain certification may have fewer career opportunities. Some graduates get jobs other than aviation maintenance technician such as turbine or field technicians. UTI is an educational institution and cannot guarantee employment, salary, or FAA certification.",
      "MIAT’s aviation program prepares graduates to apply and test for FAA mechanic certification. Graduates who do not obtain certification may have fewer career opportunities. Some graduates get jobs other than aviation maintenance technician such as turbine or field technicians. MIAT is an educational institution and cannot guarantee employment, salary, or FAA certification.",
      "Due to the unprecedented impact of the COVID-19 pandemic, UTI has made adjustments to its program delivery in order to protect the health and safety of our students, visitors and employees. Beginning in March 2020, UTI instituted changes that include providing our programs through a hybrid delivery model (online lectures, in-person hands-on labs), as well as adjusting on-campus scheduling and rearranging space on campus to allow for appropriate group sizes. The program anticipates maintaining the blended delivery model when the national emergency ends, as applicable. Students will be notified and provided an updated program outline at that time. For additional information, please visit https://www.uti.edu/covid-19.",
      "Universal Technical Institute was founded in 1965, and since then, has delivered more than 220,000 graduates."
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    DISCLAIMER_DATA: state => {
      return state.disclaimerData
    }
  }
}

<template>
  <div class="">
    <!-- Unstacked -->
    <b-container
      v-if="!data.techSettings.stacked == true"
      fluid
      :style="[
        'background-image: url(' + responsiveImage() + ');',
        xsImageAnchor,
        smImageAnchor,
        mdImageAnchor,
        lgImageAnchor,
        xlImageAnchor
      ]"
      :class="`unstacked hero-with-image ${data.techSettings.unstackedSettings.backgroundColor}-theme px-0`"
    >
      <div
        v-if="!data.techSettings.stacked"
        :class="{ 'hero-overlay': responsiveGradient() }"
      ></div>
      <b-container
        :fluid="data.techSettings.fullWidth"
        class="px-sm-0 hero-container"
      >
        <div
          :style="[
            xsHeroTextWidth,
            smHeroTextWidth,
            mdHeroTextWidth,
            lgHeroTextWidth,
            xlHeroTextWidth
          ]"
          :class="`hero-text col-12 py-5 px-0 text-${data.techSettings.unstackedSettings.textAlignment}`"
        >
          <h1
            v-if="data.unstackedContent.title"
            class=""
            v-html="data.unstackedContent.title"
          ></h1>
          <p
            v-if="data.unstackedContent.copy"
            class="hero-copy d-none d-sm-block"
            v-html="data.unstackedContent.copy"
          ></p>
          <p
            v-if="data.unstackedContent.subCopy"
            class="hero-sub-copy d-none d-sm-block"
            v-html="data.unstackedContent.subCopy"
          ></p>
          <ul
            v-if="data.unstackedContent.list[0]"
            :class="[
              {
                hiddenBullets: !data.techSettings.unstackedSettings.listBullets
              },
              'hero-list',
              'd-none',
              'd-sm-block'
            ]"
          >
            <li
              v-for="(item, index) in data.unstackedContent.list"
              :key="index"
              v-html="item"
            ></li>
          </ul>
        </div>
      </b-container>
    </b-container>

    <!-- Stacked -->
    <div
      class="stacked hero-with-image py-2"
      v-if="data.techSettings.stacked == true"
    >
      <b-container
        fluid
        :style="[
          'background-image: url(' + responsiveImage() + ');',
          xsImageAnchor,
          smImageAnchor,
          mdImageAnchor,
          lgImageAnchor,
          xlImageAnchor
        ]"
        :class="`${data.techSettings.topStackSettings.backgroundColor}-theme top-stack px-0`"
      >
        <div
          v-if="
            data.techSettings.stacked &&
            data.techSettings.topStackSettings.gradientOverlay
          "
          class="hero-overlay"
        ></div>
        <b-container
          :fluid="data.techSettings.fullWidth"
          class="px-md-0 hero-container"
        >
          <div class="hero-text col-12 px-0 mx-auto">
            <div
              :class="`top-stack py-3 text-${data.techSettings.topStackSettings.textAlignment}`"
            >
              <h1
                v-if="data.topStackContent.title"
                class=""
                v-html="data.topStackContent.title"
              ></h1>
              <p
                v-if="data.topStackContent.copy"
                class="hero-copy"
                v-html="data.topStackContent.copy"
              ></p>
              <p
                v-if="data.topStackContent.subCopy"
                class="hero-sub-copy"
                v-html="data.topStackContent.subCopy"
              ></p>
              <ul
                v-if="data.topStackContent.list[0]"
                :class="[
                  {
                    hiddenBullets:
                      !data.techSettings.topStackSettings.listBullets
                  },
                  'hero-list'
                ]"
              >
                <li
                  v-for="(item, index) in data.topStackContent.list"
                  :key="index"
                  v-html="item"
                ></li>
              </ul>
            </div>
          </div> </b-container
      ></b-container>
      <b-container
        fluid
        :style="`background-image: url('${data.bottomStackContent.img}');`"
        :class="`${data.techSettings.bottomStackSettings.backgroundColor}-theme bottom-stack px-0`"
      >
        <b-container
          :fluid="data.techSettings.fullWidth"
          class="px-md-0 hero-container"
        >
          <div
            :class="`bottom-stack py-3 text-${data.techSettings.bottomStackSettings.textAlignment}`"
          >
            <h1
              v-if="data.bottomStackContent.title"
              class=""
              v-html="data.bottomStackContent.title"
            ></h1>
            <p
              v-if="data.bottomStackContent.copy"
              class="hero-copy"
              v-html="data.bottomStackContent.copy"
            ></p>
            <p
              v-if="data.bottomStackContent.subCopy"
              class="hero-sub-copy"
              v-html="data.bottomStackContent.subCopy"
            ></p>
            <ul
              v-if="data.bottomStackContent.list[0]"
              :class="[
                {
                  hiddenBullets:
                    !data.techSettings.bottomStackSettings.listBullets
                },
                'hero-list'
              ]"
            >
              <li
                v-for="(item, index) in data.bottomStackContent.list"
                :key="index"
                v-html="item"
              ></li>
            </ul>
          </div> </b-container
      ></b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "HeroImageWText",
  components: {
    ...mapGetters(["CURRENT_BREAKPOINT", "BREAKPOINTS"])
  },
  computed: {
    xsImageAnchor() {
      let isStacked = this.data.techSettings.stacked
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let statement = ""

      if (isStacked) {
        let hero = breakpoint.stackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      } else {
        let hero = breakpoint.unstackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      }
      return {
        "--xs-image-anchor": statement
      }
    },
    smImageAnchor() {
      let isStacked = this.data.techSettings.stacked
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let statement = ""

      if (isStacked) {
        let hero = breakpoint.stackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      } else {
        let hero = breakpoint.unstackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      }
      return {
        "--sm-image-anchor": statement
      }
    },
    mdImageAnchor() {
      let isStacked = this.data.techSettings.stacked
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let statement = ""

      if (isStacked) {
        let hero = breakpoint.stackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      } else {
        let hero = breakpoint.unstackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      }
      return {
        "--md-image-anchor": statement
      }
    },
    lgImageAnchor() {
      let isStacked = this.data.techSettings.stacked
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let statement = ""

      if (isStacked) {
        let hero = breakpoint.stackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      } else {
        let hero = breakpoint.unstackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      }
      return {
        "--lg-image-anchor": statement
      }
    },
    xlImageAnchor() {
      let isStacked = this.data.techSettings.stacked
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let statement = ""

      if (isStacked) {
        let hero = breakpoint.stackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      } else {
        let hero = breakpoint.unstackedHero
        let positionType = hero.positionType

        if (positionType == "px") {
          statement = `${hero.xHeroOffset}px ${hero.yHeroOffset}px`
        } else {
          statement = `${hero.xHeroEdge} ${hero.xHeroOffset}% ${hero.yHeroEdge} ${hero.yHeroOffset}%`
        }
      }
      return {
        "--xl-image-anchor": statement
      }
    },
    xsHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xs
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--xs-hero-text-width": statement
      }
    },
    smHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.sm
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--sm-hero-text-width": statement
      }
    },
    mdHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.md
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--md-hero-text-width": statement
      }
    },
    lgHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.lg
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--lg-hero-text-width": statement
      }
    },
    xlHeroTextWidth() {
      let breakpointSettings = this.data.techSettings.breakpointSettings
      let breakpoint = breakpointSettings.xl
      let heroText = breakpoint.heroText
      let statement = ""

      statement = `${heroText.widthPercent}%`

      return {
        "--xl-hero-text-width": statement
      }
    }
  },
  methods: {
    responsiveImage() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let isStacked = this.data.techSettings.stacked
      let unstackedContent = this.data.unstackedContent
      let topStackContent = this.data.topStackContent
      let mobileImg = ""
      let desktopImg = ""

      if (isStacked == true) {
        mobileImg = topStackContent.mobileImg
        desktopImg = topStackContent.desktopImg

        if (
          mobileImg !== "" &&
          (currentBreakpoint == "xs" || currentBreakpoint == "sm")
        ) {
          return mobileImg
        } else {
          return desktopImg
        }
      } else {
        mobileImg = unstackedContent.mobileImg
        desktopImg = unstackedContent.desktopImg

        if (
          mobileImg !== "" &&
          (currentBreakpoint == "xs" || currentBreakpoint == "sm")
        ) {
          return mobileImg
        } else {
          return desktopImg
        }
      }
    },
    responsiveGradient() {
      let currentBreakpoint = this.$store.getters.CURRENT_BREAKPOINT
      let isStacked = this.data.techSettings.stacked
      let unstackedSettings = this.data.techSettings.unstackedSettings
      let topStackSettings = this.data.techSettings.topStackSettings
      let mobileGradient = Boolean
      let desktopGradient = Boolean

      if (isStacked == true) {
        mobileGradient = topStackSettings.mobileGradientOverlay
        desktopGradient = topStackSettings.desktopGradientOverlay

        if (currentBreakpoint == "xs" || currentBreakpoint == "sm") {
          return mobileGradient
        } else {
          return desktopGradient
        }
      } else {
        mobileGradient = unstackedSettings.mobileGradientOverlay
        desktopGradient = unstackedSettings.desktopGradientOverlay

        if (currentBreakpoint == "xs" || currentBreakpoint == "sm") {
          return mobileGradient
        } else {
          return desktopGradient
        }
      }
    }
  },
  props: ["data"]
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.hiddenBullets {
  list-style: none;
  padding-left: 0;
}

.hero-with-image {
  width: 100%;
  background-size: cover;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  max-width: none;

  &.white-theme,
  &.-theme,
  .white-theme,
  .-theme {
    background-color: white;
    color: black;

    span >>> a {
      color: #007bff;
    }
  }

  &.blue-theme,
  .blue-theme {
    background-color: #003653;
    color: white;

    span >>> a {
      color: white;
      text-decoration-line: underline;
    }
  }

  &.grey-theme,
  &.gray-theme,
  .grey-theme,
  .gray-theme {
    background-color: #f2f5f7;
    color: #003653;

    span >>> a {
      color: #007bff;
    }
  }

  @media screen and (min-width: 640px) {
    .hero-with-image {
      min-height: 365px;
    }

    @media screen and (min-width: 576px) {
      .hero-with-image {
        h1 {
          font-size: 54px;
          line-height: 54px;
          width: 100%;
        }
      }
    }
  }
  // h1 {
  //   font-family: Tungsten-Semibold;
  //   letter-spacing: 1px;
  //   line-height: 30px;
  //   font-size: 30px;
  //   text-transform: uppercase;
  //   margin-bottom: 0;
  // }

  ul {
    margin-top: 24px;
  }

  .hero-copy,
  .hero-sub-copy {
    margin-top: 24px;
    margin-bottom: 0px;
    line-height: 22px;
    font-size: 18px;
  }
}

// .unstacked.hero-with-image {
//   h1,
//   p {
//     width: 70%;
//   }

//   @media screen and (min-width: 640px) {
//     .hero-copy,
//     .hero-sub-copy {
//       max-width: 570px;
//     }
//   }
// }

// XS BreakPoint
@media screen and (min-width: 0px) {
  .unstacked.hero-with-image {
    background-position: var(--xs-image-anchor);

    .hero-container .hero-text {
      width: var(--xs-hero-text-width);
    }
  }

  .stacked.hero-with-image {
    .top-stack {
      background-position: var(--xs-image-anchor);
    }

    .hero-container .hero-text {
      width: var(--xs-hero-text-width);
    }
  }
}

// SM BreakPoint
@media screen and (min-width: 576px) {
  .unstacked.hero-with-image {
    background-position: var(--sm-image-anchor);

    .hero-container .hero-text {
      width: var(--sm-hero-text-width);
    }
  }

  .stacked.hero-with-image {
    .top-stack {
      background-position: var(--sm-image-anchor);
    }

    .hero-container .hero-text {
      width: var(--sm-hero-text-width);
    }
  }
}

// MD BreakPoint
@media screen and (min-width: 768px) {
  .unstacked.hero-with-image {
    background-position: var(--md-image-anchor);

    .hero-container .hero-text {
      width: var(--md-hero-text-width);
    }
  }

  .stacked.hero-with-image {
    .top-stack {
      background-position: var(--md-image-anchor);
    }

    .hero-container .hero-text {
      width: var(--md-hero-text-width);
    }
  }
}

// LG BreakPoint
@media screen and (min-width: 992px) {
  .unstacked.hero-with-image {
    background-position: var(--lg-image-anchor);

    .hero-container .hero-text {
      width: var(--lg-hero-text-width);
    }
  }

  .stacked.hero-with-image {
    .top-stack {
      background-position: var(--lg-image-anchor);
    }

    .hero-container .hero-text {
      width: var(--lg-hero-text-width);
    }
  }
}

// xl BreakPoint
@media screen and (min-width: 1200px) {
  .unstacked.hero-with-image {
    background-position: var(--xl-image-anchor);

    .hero-container .hero-text {
      width: var(--xl-hero-text-width);
    }
  }

  .stacked.hero-with-image {
    .top-stack {
      background-position: var(--xl-image-anchor);
    }

    .hero-container .hero-text {
      width: var(--xl-hero-text-width);
    }
  }
}

@media screen and (min-width: 768px) {
  .hero-with-image {
    min-height: 368px;
  }
}
</style>

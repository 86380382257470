var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(!_vm.data.techSettings.stacked == true)?_c('b-container',{class:("unstacked hero-with-image " + (_vm.data.techSettings.unstackedSettings.backgroundColor) + "-theme px-0"),style:([
      'background-image: url(' + _vm.responsiveImage() + ');',
      _vm.xsImageAnchor,
      _vm.smImageAnchor,
      _vm.mdImageAnchor,
      _vm.lgImageAnchor,
      _vm.xlImageAnchor
    ]),attrs:{"fluid":""}},[(!_vm.data.techSettings.stacked)?_c('div',{class:{ 'hero-overlay': _vm.responsiveGradient() }}):_vm._e(),_c('b-container',{staticClass:"px-sm-0 hero-container",attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('div',{class:("hero-text col-12 py-5 px-0 text-" + (_vm.data.techSettings.unstackedSettings.textAlignment)),style:([
          _vm.xsHeroTextWidth,
          _vm.smHeroTextWidth,
          _vm.mdHeroTextWidth,
          _vm.lgHeroTextWidth,
          _vm.xlHeroTextWidth
        ])},[(_vm.data.unstackedContent.title)?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.data.unstackedContent.title)}}):_vm._e(),(_vm.data.unstackedContent.copy)?_c('p',{staticClass:"hero-copy d-none d-sm-block",domProps:{"innerHTML":_vm._s(_vm.data.unstackedContent.copy)}}):_vm._e(),(_vm.data.unstackedContent.subCopy)?_c('p',{staticClass:"hero-sub-copy d-none d-sm-block",domProps:{"innerHTML":_vm._s(_vm.data.unstackedContent.subCopy)}}):_vm._e(),(_vm.data.unstackedContent.list[0])?_c('ul',{class:[
            {
              hiddenBullets: !_vm.data.techSettings.unstackedSettings.listBullets
            },
            'hero-list',
            'd-none',
            'd-sm-block'
          ]},_vm._l((_vm.data.unstackedContent.list),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e()])])],1):_vm._e(),(_vm.data.techSettings.stacked == true)?_c('div',{staticClass:"stacked hero-with-image py-2"},[_c('b-container',{class:((_vm.data.techSettings.topStackSettings.backgroundColor) + "-theme top-stack px-0"),style:([
        'background-image: url(' + _vm.responsiveImage() + ');',
        _vm.xsImageAnchor,
        _vm.smImageAnchor,
        _vm.mdImageAnchor,
        _vm.lgImageAnchor,
        _vm.xlImageAnchor
      ]),attrs:{"fluid":""}},[(
          _vm.data.techSettings.stacked &&
          _vm.data.techSettings.topStackSettings.gradientOverlay
        )?_c('div',{staticClass:"hero-overlay"}):_vm._e(),_c('b-container',{staticClass:"px-md-0 hero-container",attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('div',{staticClass:"hero-text col-12 px-0 mx-auto"},[_c('div',{class:("top-stack py-3 text-" + (_vm.data.techSettings.topStackSettings.textAlignment))},[(_vm.data.topStackContent.title)?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.data.topStackContent.title)}}):_vm._e(),(_vm.data.topStackContent.copy)?_c('p',{staticClass:"hero-copy",domProps:{"innerHTML":_vm._s(_vm.data.topStackContent.copy)}}):_vm._e(),(_vm.data.topStackContent.subCopy)?_c('p',{staticClass:"hero-sub-copy",domProps:{"innerHTML":_vm._s(_vm.data.topStackContent.subCopy)}}):_vm._e(),(_vm.data.topStackContent.list[0])?_c('ul',{class:[
                {
                  hiddenBullets:
                    !_vm.data.techSettings.topStackSettings.listBullets
                },
                'hero-list'
              ]},_vm._l((_vm.data.topStackContent.list),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e()])])])],1),_c('b-container',{class:((_vm.data.techSettings.bottomStackSettings.backgroundColor) + "-theme bottom-stack px-0"),style:(("background-image: url('" + (_vm.data.bottomStackContent.img) + "');")),attrs:{"fluid":""}},[_c('b-container',{staticClass:"px-md-0 hero-container",attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('div',{class:("bottom-stack py-3 text-" + (_vm.data.techSettings.bottomStackSettings.textAlignment))},[(_vm.data.bottomStackContent.title)?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.data.bottomStackContent.title)}}):_vm._e(),(_vm.data.bottomStackContent.copy)?_c('p',{staticClass:"hero-copy",domProps:{"innerHTML":_vm._s(_vm.data.bottomStackContent.copy)}}):_vm._e(),(_vm.data.bottomStackContent.subCopy)?_c('p',{staticClass:"hero-sub-copy",domProps:{"innerHTML":_vm._s(_vm.data.bottomStackContent.subCopy)}}):_vm._e(),(_vm.data.bottomStackContent.list[0])?_c('ul',{class:[
              {
                hiddenBullets:
                  !_vm.data.techSettings.bottomStackSettings.listBullets
              },
              'hero-list'
            ]},_vm._l((_vm.data.bottomStackContent.list),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e()])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
    <div :class="data.rootClasses" >
        <b-button v-if="data.modalData && data.modalData.buttonData" :class="[data.modalData.buttonData.classes,'py-2 flex align-items-center justify-content-center']"
            v-b-modal="`modal-${data.modalData.openModal ? data.modalData.openModal : data.modalData.id}`" :variant="data.modalData.buttonData.variant"
            :chevron="data.modalData.buttonData.chevron" @click="(event) => {engaged('CTAModal');}">
                <img v-if="data.modalData.buttonData.playIcon" class='mr-1' src='https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/play-circle-icon.svg'/>
                {{data.modalData.buttonData.CTA}}
                <img v-if="data.modalData.buttonData.chevron" class='ml-1' src='https://res.cloudinary.com/utidinary/image/upload/w_20/f_auto,q_auto/Solid-Chevron.svg' />
            </b-button>
            
        <div v-if="data.modalData && data.modalData.buttonData && data.modalData.buttonData.postCTA"
            v-html="data.modalData.buttonData.postCTA"></div>
            
        <BModal :id="`modal-${data.modalData.id}`" :static="data.modalData.static" :lazy="data.modalData.lazyLoad" :content-class="[data.msatBlock || data.icons ? 'container px-0 carousel' : '',]"
            :hide-footer="data.formData ? true : data.modalData.hideBottomClose ? true : false"
            :hide-header="data.formData ? true : data.modalData.hideTopClose ? true : false"            
            > 
            
            <component v-if="data.formData && !carousel" v-bind:is="data.formData.type" :AcquisitionPoint="AP || this.AcquisitionPoint" :data="data.formData.data"
                :selectedAOI="selectedAOI" :modalID="data.modalData.id"></component>

            <!-- MSAT Block -->
            <div v-if="(data.msatBlock && !data.msatBlock.carousel && !carousel)">
                <div v-if="data.msatBlock.img" style='border-radius: 10px; overflow: hidden;'>
                    <img v-if="data.msatBlock.img || data.icons.img" class="msat-hero-image" :src="data.msatBlock.img" alt="Alt Tag" />
                </div>
                <div v-if="data.msatBlock.alertIcon || data.msatBlock.alertHTML" role="alert" aria-live="polite"
                    aria-atomic="true" class="alert alert-success alertContent my-3 px-2 py-0" style="width: fit-content">
                    <img v-if="data.msatBlock.alertIcon" :src='data.msatBlock.alertIcon'
                        style='height: 15px; display: inline-block' />
                    <div v-if="data.msatBlock.alertHTML" v-html="data.msatBlock.alertHTML" style='display: inline-block'>
                    </div>
                </div>
                <div v-if="data.msatBlock.headerHTML" v-html="data.msatBlock.headerHTML"></div>
                <div v-if="data.msatBlock.paragraphHTML" v-html="data.msatBlock.paragraphHTML"></div>
            </div>
             <!-- carousel -->
            <b-carousel ref="myCarousel" v-if="(data.msatBlock && data.msatBlock.carousel) || carousel" indicators controls :interval="0" @sliding-start="onSlideStart()" @sliding-end="onSlideEnd()">
                    <b-carousel-slide v-for="(item, index) in carouselData" v-bind:key="index" :img-src="item.img" :value="item.icon || item.aoi" >
                        <div v-if="item.alertIcon || item.alertHTML" role="alert" aria-live="polite"
                            aria-atomic="true" class="alert alert-success alertContent mt-3 px-2 py-0" style="width: fit-content">
                            <img v-if="item.alertIcon" :src='item.alertIcon'
                                style='height: 15px; display: inline-block' />
                            <div v-if="item.alertHTML" v-html="item.alertHTML" style='display: inline-block'>
                            </div>
                        </div>
                        <div v-if="item.headerHTML" v-html="item.headerHTML" class="mt-3"></div>
                        <div v-if="item.paragraphHTML" v-html="item.paragraphHTML"></div> 
                    </b-carousel-slide>
                </b-carousel>

            <!-- Video -->
            <b-container v-if="data.type == 'video'">
                <b-row>
                    <b-col :class="['text-center video-container html-video py-3 pt-md-0 mx-auto', data.techSettings.videoContainerClasses]">   
                        <div class="video-responsive-padding">
                            <div class="video-responsive-wrapper">
                            <video
                                class="responsive-video"
                                controls
                                @click="optEvent('clickVideo')"
                                preload="none"
                                :poster="data.videoSettings.posterUrl"
                            >
                                <source
                                :src="`${data.videoSettings.url}`"
                                type="video/mp4"
                                />
                            </video>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            

            <div v-for="(nestedComponent, index) in data.nestedComponents" v-bind:key="index" class="nestedModal">
                <component v-if="nestedComponent" v-bind:is="nestedComponent.type" :data="nestedComponent.data"
                    :modalID="data.modalData.id"></component>
            </div>
            
            <template #modal-footer="{}" v-if="data.modalData && data.modalData.modalFooter">
                <div class="w-100 text-center flex justify-content-center">

                    <b-button variant="primary" size="md" class="px-4 py-2" v-html="data.modalData.modalFooter.open.text"
                        @click="open(data.modalData.modalFooter.open.modalId)">

                    </b-button>
                    <b-button size="md" variant="outline-secondary" class="ml-5 px-5"
                        v-html="data.modalData.modalFooter.close" @click="hide(data.modalData.id)">

                    </b-button>
                </div>
            </template>

            
        </BModal>
    </div>
</template>

<script>
    import { BModal, BCarousel, BCarouselSlide, } from 'bootstrap-vue'
    import TwoStepForm from "@/components/Form/TwoStepForm"
    import WYSIWYG from "@/components/Content/WYSIWYG"
    //import ImageBlock from "@/components/Content/ImageBlock"
    import MarketoForm from "@/components/Form/MarketoForm.vue"

    export default {
        name: "Modal",
        props: ["data", "selectedAOI", "AcquisitionPoint", "carouselData", "carouselIndex", "carousel"],
        components: {
            BModal,
            BCarousel,
            BCarouselSlide,
            Grid: () => import("@/components/Layout/Grid"),
            TwoStepForm,
            //ImageBlock,
            WYSIWYG,
            MarketoForm
        },
        data() {
            return {
                AP: this.AcquisitionPoint || "UTIFORM039/MODALFORM",
                slideIndex: 0,
                selectedIcon: "",
                
            }
        },
        mounted() {
            this.$root.$on('changeSelectedAOI', data => {
                //console.log('receiving change request', data)
                this.selectedAOI = data;

            })
            // this.$nextTick(() => {
            //     console.log(this.$refs)
            // }).bind(this)
            //this.$refs.myCarousel.setSlide(this.carouselIndex)

            //console.log(this.$refs)
            
        },
        methods: {
            onSlideStart() {
                //console.log('On slide start')
                this.slideIndex = this.$children[0].$children[0].index
                //console.log(`slide index: `, this.slideIndex)
                this.selectedIcon = this.carouselData[this.slideIndex].icon || this.carouselData[this.slideIndex].aoi || ""
                //console.log(this.selectedIcon)
                //console.log(`1`,this.carouselData)
                //console.log(this.slideIndex, this.selectedIcon)
                this.$root.$emit('changeSelectedAOI', this.selectedIcon);
                //console.log('starting slide', this.slideIndex)
            },
            onSlideEnd() {
                //console.log('ending slide',index)
            },
            optimizely(apiName) {
                this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
            },
            engaged(type) {
                this.optimizely(`engaged${type}`)
                //console.log(`this.acquisitionpoint: `, this.AcquisitionPoint);
                //console.log(`data.acquisitionpoint: `, this.data.AcquisitionPoint);
            },
            open(id) {
                //console.log(this.$bvModal)
                //console.log(this.carousel)
                //console.log(this.msatBlock.carousel)
                //console.log(this.carousel)
                console.log(id)
                if(this.carousel) {
                    this.onSlideStart();
                }
                this.$bvModal.show(`modal-${id}`)
            },
            hide(id) {
                this.$bvModal.hide(`modal-${id}`)
            },
            
        }
    }
</script>

<style lang="scss">
.modal-content {
    background-color: #fff;
    // border:none !important;
}
.btn.btn-primary {
    background-color: #007CC2 !important
}

.modal-dialog {
    max-width: none !important;
}

.modal-backdrop {
    opacity: .8 !important;
}

.modal {
    .modal-content:has(> .modal-body .twoStepFormComponent) {
        background-color: transparent !important;
        border: none !important;

        header {
            border: none;

            button.close {
                color: black;
                opacity: 1;
            }
        }
    }

    .modal-content:has(> .modal-body .html-video) {
        background-color: transparent !important;
        border: none !important;

        header {
            border: none;

            button.close {
                color: black;
                opacity: 1;
            }
        }
    }

    .modal-content:has(> .modal-body .marketo-form-component) {
        background-color: transparent !important;
        border: none !important;

        header {
            border: none;

            button.close {
                color: black;
                opacity: 1;
            }
        }
    }

    .modal-body {
        padding: 15px;
        height: max-content;


    }

    .modal-footer {
        border-top: none;
    }

    .msat-hero-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    @media (min-width: 992px) {
        .msat-hero-image {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

    .carousel-caption {
        position: static;
        text-align: left;
        padding-top: 0;
        color: black;
    }

    .carousel-indicators {
        //position: static;
        margin-bottom: 0;
        margin-top: 10px;

        li {
            background-color: #000;
            border-radius: 50%;
            height: 12px;
            width: 12px;
            margin-right: 1px;
            margin-left: 2px;
        }
    }
    .carousel-control-prev, .carousel-control-next {
        align-items: end;
        color: #000;
        opacity: 1;
        bottom: 6px;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
    }

    .modal-content.carousel {
        max-width: 500px;
        height: 670px;

        .carousel.slide {
            height: 100%;
        }
    }

    @media screen and (min-width: 768px){
        .modal-content.carousel {
            max-width: 500px;
            height: 680px;
        }
    }


}

.video-responsive-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-responsive-padding {
  padding: 56.25% 0 0 0;
  position: relative;
}

.responsive-video {
  width: 100%;
  height: 100%;
}


</style>
<template>
<svg width="54" height="43" viewBox="0 0 25 23" fill="none" class="speedoMeterIcon" xmlns="http://www.w3.org/2000/svg">
<path d="M5.20833 13.8889C5.20833 14.4644 4.74219 14.9306 4.16667 14.9306C3.59115 14.9306 3.125 14.4644 3.125 13.8889C3.125 13.3134 3.59115 12.8472 4.16667 12.8472C4.74219 12.8472 5.20833 13.3134 5.20833 13.8889ZM12.5 6.59725C13.0755 6.59725 13.5417 6.1311 13.5417 5.55558C13.5417 4.98006 13.0755 4.51392 12.5 4.51392C11.9245 4.51392 11.4583 4.98006 11.4583 5.55558C11.4583 6.1311 11.9245 6.59725 12.5 6.59725ZM18.4028 9.0278C18.9783 9.0278 19.4444 8.56166 19.4444 7.98614C19.4444 7.41062 18.9783 6.94447 18.4028 6.94447C17.8273 6.94447 17.3611 7.41062 17.3611 7.98614C17.3611 8.56166 17.8273 9.0278 18.4028 9.0278ZM21.5182 13.7752C21.582 14.1537 21.3255 14.5109 20.947 14.5742L15.2526 15.5235C15.1272 16.9401 13.9501 18.0556 12.5 18.0556C10.9657 18.0556 9.72222 16.8121 9.72222 15.2778C9.72222 13.7435 10.9657 12.5 12.5 12.5C13.6324 12.5 14.6024 13.1797 15.0352 14.1515L20.7196 13.204C21.0898 13.1393 21.4544 13.3967 21.5182 13.7752ZM13.8889 15.2778C13.8889 14.5109 13.2669 13.8889 12.5 13.8889C11.7331 13.8889 11.1111 14.5109 11.1111 15.2778C11.1111 16.0447 11.7331 16.6667 12.5 16.6667C13.2669 16.6667 13.8889 16.0447 13.8889 15.2778ZM6.59722 6.94447C6.0217 6.94447 5.55556 7.41062 5.55556 7.98614C5.55556 8.56166 6.0217 9.0278 6.59722 9.0278C7.17274 9.0278 7.63889 8.56166 7.63889 7.98614C7.63889 7.41062 7.17274 6.94447 6.59722 6.94447ZM25 13.8889C25 16.1806 24.3815 18.3273 23.3047 20.1736C23.0612 20.5912 22.5972 20.8334 22.1137 20.8334H2.88628C2.40278 20.8334 1.9388 20.5912 1.69531 20.1736C0.61849 18.3273 0 16.1806 0 13.8889C0 6.98527 5.59635 1.38892 12.5 1.38892C19.4036 1.38892 25 6.98527 25 13.8889ZM23.6111 13.8889C23.6111 7.76218 18.6267 2.7778 12.5 2.7778C6.37326 2.7778 1.38889 7.76218 1.38889 13.8889C1.38889 15.8533 1.90972 17.7843 2.89496 19.474L22.1137 19.4445C23.0903 17.7843 23.6111 15.8533 23.6111 13.8889Z"  fill="#737373"/>
</svg>

</template>

<script>
export default {
  name: "speedoMeterIcon"
}
</script>

<style scoped>
.speedoMeterIcon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}

</style>

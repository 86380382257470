<template>
  <b-container
    fluid
    :class="
      data.techSettings.backgroundColor +
      '-theme ' +
      'facts-unordered-list ' + data.techSettings.overrideClasses
    "
  >
    <b-container :fluid="data.techSettings.fullWidth">
      <h2
        v-if="data.title"
        :class="`text-${data.techSettings.titleAlignment} title pt-2 font-weight-bold p-2`"
        v-html="data.title"
      ></h2>
      <p v-if="data.subTitle" class="sub-title" v-html="data.subTitle"></p>
      <ul
        v-if="data.facts"
        :class="'fact-list text-' + data.techSettings.itemAlignment + ' p-0 m-0'"
      >
        <b-row
          :cols="data.techSettings.xsColumns"
          :cols-md="data.techSettings.mdColumns"
          :class="[data.techSettings.containerRowClasses,'pt-4']"
        >
          <b-col v-for="(item, index) in data.facts" :key="index" :class="[data.techSettings.listContainerClasses,'col-md mx-auto']">
            <li
              v-if="
                data.techSettings.itemAlignment &&
                data.techSettings.itemAlignment != 'center'
              "
              :class="
                'fact-group mb-4 item-' +
                data.techSettings.itemAlignment +
                '-aligned'
              "
            >
              <b-row :class=data.techSettings.iconRowClasses>
                <b-col
                  :order="alignOrder()"
                  align-self="center"
                  class="col-12 text-center px-0 mx-auto"
                  ><component
                    v-if="data.facts[index].bootstrapIcon"
                    v-bind:is="data.facts[index].bootstrapIcon"
                    class="fact-icon accessory"
                    font-scale="3"
                  ></component
                ></b-col>
                <b-col cols="10" class="facts-content mx-auto flex-col">
                  <h3
                    v-if="data.facts[index].headline"
                    :class="['fact-headline mt-3']"
                  >
                    {{ data.facts[index].headline }}
                  </h3>
                  <p
                    v-if="data.facts[index].subHeadline"
                    class="fact-subHeadline mt-3"
                    v-html="data.facts[index].subHeadline"
                  ></p
                ></b-col>
              </b-row>
              <!-- 
               -->
            </li>
            <li
              v-if="
                data.techSettings.itemAlignment &&
                data.techSettings.itemAlignment == 'center'
              "
              :class="
                'fact-group mb-4 item-' +
                data.techSettings.itemAlignment +
                '-aligned'
              "
            >
              <component
                v-if="data.facts[index].bootstrapIcon"
                v-bind:is="data.facts[index].bootstrapIcon"
                :class="[data.icon.iconColor,'fact-icon accessory']"
                :font-scale="data.icon.scale"
              ></component>
              <h3
                v-if="data.facts[index].headline"
                :class="[data.headlineColor,'fact-headline mt-3 mb-3']"
              >
                {{ data.facts[index].headline }}
              </h3>
              <p
                v-if="data.facts[index].subHeadline"
                class="fact-subHeadline"
                v-html="data.facts[index].subHeadline"
              ></p>
            </li>
          </b-col>
        </b-row>
      </ul>
      <p v-if="data.copy" class="copy" v-html="data.copy"></p>
      <div
            v-for="(nestedComponent, index) in data.nestedComponents"
            v-bind:key="index"
          >
            <component
              v-if="nestedComponent.data !== null"
              v-bind:is="nestedComponent.type"
              :data="nestedComponent.data"
              :class="['unorderedListFactsNested', nestedComponent.classes]"
            ></component>
          </div>
    </b-container>
  </b-container>
</template>

<script>
import Grid from "@/components/Layout/Grid"
import Modal from "@/components/Content/Modal";
import Button from "@/components/Content/Button";
import TwoStepForm from "@/components/Form/TwoStepForm"
import carIcon from "./svg/Car.vue"
import briefcaseIcon from "./svg/BriefCase.vue"
import handIcon from "./svg/Hand.vue"
import graduationIcon from "./svg/Graduation.vue"
import weldIcon from "./svg/Weld"
import marineIcon from "./svg/Marine.vue"
import autoIcon from "./svg/Auto.vue"
import motoIcon from "./svg/Moto.vue"
import handCashIcon from "./svg/HandCash.vue"
import gradCapIcon from "./svg/GradCap.vue"
import twoCarsIcon from "./svg/TwoCars.vue"
import dollahIcon from "./svg/Dollar.vue"
import bookIcon from "./svg/bookIcon.vue"
import calendarIcon from "./svg/calendarIcon.vue"
import speedoMeterIcon from "./svg/speedoMeterIcon.vue"
import buildingsIcon from "./svg/buildings"
import moneyIcon from "./svg/money"
import suitcasesIcon from "./svg/suitcases"
import testimonialIcon from "./svg/testimonial"
import toolsIcon from "./svg/tools"
import checkmarkIcon from "./svg/checkmark"
import school1Icon from "./svg/school-1"
import school2Icon from "./svg/school-2"
import schoolIcon from "./svg/school"
import certificationsIcon from "./svg/certifications"
import facilitiesIcon from "./svg/facilities"
import wrenchIcon from "./svg/wrench"

import {
  BIconCalendarWeek,
  BIconPeople,
  BIconCashStack,
  BIconMap,
  BIconBook,
  BIconWallet2,
  BIconBriefcase,
  BIconTools,
  BIconGraphUp,
  BIconBuilding,
  BIconClock
} from "bootstrap-vue"


export default {
  name: "UnorderedListFacts",
  props: ["data"],
  components: {
    BIconCalendarWeek,
    BIconPeople,
    BIconCashStack,
    BIconMap,
    BIconBook,
    BIconWallet2,
    BIconBriefcase,
    BIconTools,
    BIconGraphUp,
    BIconBuilding,
    BIconClock,
    carIcon,
    briefcaseIcon,
    graduationIcon,
    handIcon,
    weldIcon,
    marineIcon,
    autoIcon,
    motoIcon,
    dollahIcon,
    handCashIcon,
    gradCapIcon,
    twoCarsIcon,
    bookIcon,
    calendarIcon,
    speedoMeterIcon,
    buildingsIcon,
    moneyIcon,
    suitcasesIcon,
    testimonialIcon,
    toolsIcon,
    checkmarkIcon,
    school1Icon,
    school2Icon,
    schoolIcon,
    wrenchIcon,
    Grid,
    Modal,
    Button,
    TwoStepForm,
    certificationsIcon,
    facilitiesIcon
  },
  methods: {
    alignOrder() {
      if (this.data.techSettings.itemAlignment == "right") {
        return 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.facts-unordered-list {
  color: #fff;

  &.uti-component {
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    .container {
      padding: 0;
    }
  }

  .facts-content {
    display: flex;
    align-items: center;

    .fact-subHeadline {
      font-size: 18px;
      line-height: 22px;
    }
  }

  ul {
    list-style-type: none;
  }

  svg.accessory {
    min-height: 60px;
  }

  &.blue-theme,
  &.-theme {
    background-color: #003653;

    h2,
    h3,
    p {
      color: #fff;
    }

    svg.accessory {
      //color: #b2b2b2;
      color: white;
    }
  }

  &.white-theme {
    background-color: #fff;

    h2,
    h3,
    p {
      color: #000;
    }

    svg.accessory {
      color: #737373;
    }
  }

  &.gray-theme,
  &.grey-theme {
    background-color: #f2f5f7;
    color: #003653;

    h2,
    h3,
    p {
      color: #003653;
      &.fact-headline.red {
        color: #D50019;
      }
    }

    svg.accessory {
      color: #0086d2;
      &.dark-blue {
        color: #003653;
      }
    }
  }

  &.px-0 {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.p15 {
    padding-top: 15px !important;
  }
}

p {
  margin-bottom: 0;
  line-height: 24px;
  font-size: 20px;
  font-weight: 400;
}

h2 {
  font-family: Tungsten-Semibold;
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}
li {
  font-size: 18px;
  line-height: 22px;
  padding: 7px;
}



@media screen and (min-width: 992px) {
  h2 {
    font-size: 54px;
    line-height: 54px;
  }
}
</style>
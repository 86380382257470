<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="43" viewBox="0 0 54 43" class="dollah-icon" fill="none">
<path d="M27 12.0938C22.5264 12.0938 18.9 16.3047 18.9 21.5C18.9 26.6944 22.5264 30.9062 27 30.9062C31.4719 30.9062 35.1 26.6961 35.1 21.5C35.1 16.3047 31.4736 12.0938 27 12.0938ZM27 28.2188C24.0224 28.2188 21.6 25.2046 21.6 21.5C21.6 17.7954 24.0224 14.7812 27 14.7812C29.9776 14.7812 32.4 17.7954 32.4 21.5C32.4 25.2046 29.9776 28.2188 27 28.2188ZM52.4104 4.57379C49.1375 3.20736 45.862 2.6875 42.5883 2.6875C32.1958 2.68666 21.8042 7.92309 11.4117 7.92309C5.93494 7.92309 3.95719 6.63477 2.72109 6.63477C1.269 6.63477 0 7.75344 0 9.30631V35.9512C0 37.0119 0.610031 38.0172 1.58963 38.4254C4.86253 39.7926 8.13797 40.3125 11.4117 40.3125C21.8042 40.3125 32.1958 35.0761 42.5883 35.0761C48.0651 35.0761 50.042 36.3652 51.2789 36.3652C52.731 36.3652 54 35.2466 54 33.6937V7.04881C54 5.98725 53.39 4.98279 52.4104 4.57379ZM2.78016 9.3441C4.48622 9.88328 6.24206 10.2285 8.06794 10.4267C7.90172 13.2326 5.60166 15.4691 2.76159 15.5018L2.78016 9.3441ZM2.7 35.9512L2.71097 32.3785C5.54428 32.3844 7.84772 34.5881 8.05866 37.3697C6.16444 37.1144 4.37738 36.66 2.7 35.9512ZM51.2198 33.6559C49.5188 33.1184 47.7689 32.7732 45.9489 32.575C46.1928 29.8455 48.454 27.6922 51.2384 27.6603L51.2198 33.6559ZM51.246 24.9602C47.0272 24.9887 43.6025 28.2624 43.2658 32.4012C37.298 32.2811 31.6912 33.7298 26.3402 35.0778C20.0062 36.6735 15.6634 37.6989 10.7806 37.5864C10.6768 33.2175 7.11197 29.691 2.71856 29.6809L2.75316 18.2028C7.02675 18.1742 10.492 14.8165 10.7502 10.6005C16.7012 10.7189 22.3113 9.27104 27.659 7.92393C33.9694 6.33326 38.3316 5.30193 43.2025 5.41363C43.2177 9.85893 46.8315 13.4711 51.2806 13.482L51.246 24.9602ZM51.289 10.7836C48.4 10.7777 46.0544 8.48746 45.9245 5.62863C47.8254 5.88311 49.6184 6.3383 51.3 7.04881L51.289 10.7836Z" fill="#737373"/>
</svg>
</template>

<script>
export default {
  name: "dollahIcon"
}
</script>

<style scoped>
.dollah-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}

</style>

<template>
<div class="container-fluid">
    <h2 class="font-weight-bold text-left pt-3">{{data.title}}</h2>
    <h5 class="text-left pl-0" v-html="data.copy"></h5>
</div>
</template>

<script>
export default {
    name: "HeaderCta",
    props: ["data"]
};
</script>

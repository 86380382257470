<template>
  <b-navbar toggleable variant="light" :fixed="isSticky" :class='["menu-navbar", data.techSettings.hidden ? "invisible" : "" , data.techSettings.classes]'>
    <div class="container px-0">
      <b-navbar-brand href="#" 
        ><img class="img-fluid logo-img" :src="data.logo ? data.logo : 'https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/UTI_Trademark_RGB.png'"
                        @click="data.logoModal ? openModal(data.logoModal.modalData.id) : ()=> {}" 
                        @touch="data.logoModal ? openModal(data.logoModal.modalData.id) : ()=> {}" />
      </b-navbar-brand>

      <b-navbar-toggle target="navbar-toggle-collapse" @click="toggleNav">
        <template>
          <i class="fa-solid fa-bars"></i>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav v-if="isNavOpen">
        <!-- <h1 @click="closeNav">hello</h1> -->
        <div v-for="(item, index) in data.list" :key="index">
          <div @click="closeNav">
            <p @click="btnClick(item.goto)" class="list">{{ item.title }}</p>
          </div>
        </div>
      </b-collapse>
    </div>
    <!-- Logo Modal -->
    <Modal v-if="data.logoModal && data.logoModal.modalData" :data="data.logoModal" ></Modal>
  </b-navbar>
</template>

<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BCollapse } from "bootstrap-vue";
import Modal from "@/components/Content/Modal";
export default {
  name: "MenuBar",
  props: ["data"],
  components: {
    "b-navbar": BNavbar,
    "b-navbar-brand": BNavbarBrand,
    "b-navbar-toggle": BNavbarToggle,
    "b-collapse": BCollapse,
    Modal,
  },
  data: function () {
    return {
      isNavOpen: false,
    };
  },

  computed: {
    isSticky() {
      if (this.data.techSettings) {
        let stickyMobile = "";
        let stickyDesktop = "";

        if (this.data.techSettings.stickyMobile) {
          if (this.data.techSettings.stickyLocation) {
            stickyMobile = this.data.techSettings.stickyLocation;
          } else {
            stickyMobile = "top";
          }
        } else {
          stickyMobile = "";
        }

        if (this.data.techSettings.stickyDesktop) {
          if (this.data.techSettings.stickyLocation) {
            stickyDesktop = this.data.techSettings.stickyLocation;
          } else {
            stickyDesktop = "top";
          }
        } else {
          stickyDesktop = "";
        }

        if (window.innerWidth >= 576) {
          return stickyDesktop;
        } else {
          return stickyMobile;
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    btnClick: function (goto) {
      console.log("goto", goto);
      const element = document.querySelector(goto);

        function pageScrollHandler (ofSet) {
          let elemPos = element.getBoundingClientRect().top;
          var offSetPost = elemPos + window.pageYOffset - ofSet;
          window.scrollTo({
            top: offSetPost,
            behavior: "smooth",
          });
        }
        let desktopOffset = (this.data.techSettings.stickyDesktop && this.data.techSettings.stickyMobile) ? 80 : 210;
        pageScrollHandler(desktopOffset )
        
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    openModal(id) {
      this.$bvModal.show(`modal-${id}`)
    }
  },
};
</script>
<style scoped>
p:hover {
  text-decoration: underline;
  cursor: pointer;
}
.logo-img {
  width: 100%;
  max-width: 153px;
  max-height: 50px;
  object-fit: contain;
}
/* .navbar-toggler {
    background-color: #F3F4F6 !important;
} */
.navbar-light .navbar-toggler {
  color: #000000 !important;
  border-color: transparent !important;
}
button:focus {
  outline: none !important;
}

.list {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 990px) {
  p:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

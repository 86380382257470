import { render, staticRenderFns } from "./bookIcon.vue?vue&type=template&id=0613ff60&scoped=true&"
import script from "./bookIcon.vue?vue&type=script&lang=js&"
export * from "./bookIcon.vue?vue&type=script&lang=js&"
import style0 from "./bookIcon.vue?vue&type=style&index=0&id=0613ff60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0613ff60",
  null
  
)

export default component.exports
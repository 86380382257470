<template>
  <div :class="'bottom-nav navbar-container '">
    <header>
      <component
      v-if="data.nestedComponents !== undefined"
      v-bind:is="data.nestedComponents.type"
      :data="data.nestedComponents.data"
      :class="['alertNested', data.nestedComponents.rootClasses]"
      ></component>
    </header>
    
    <b-navbar
      :style="{ backgroundColor: data.color.background }"
      :class="data.techSettings.navbarClasses"
      :fixed="isSticky"
    >
      <b-container :fluid="data.techSettings.fullWidth" class="">     
        <b-row class="mx-0" :align-h="data.techSettings.alignment ? data.techSettings.alignment : 'between'">
          <b-col class="logo-container px-0 flex align-items-center" v-if="data.logo" :cols="data.cto != undefined ? 3 : 12">
            <img :class="['ml-0 mr-0 pl-0 pr-0', data.cto == undefined ? 'fullWidthLogo' : '']" :src="data.logo" :alt="data.alt || 'This is a logo'"/>
          </b-col>
          <b-col class="cto-container px-0 my-auto" :class="data.cto.classes" :cols="data.cto.columns ? data.cto.columns : '7'">
            <div
              class="cto-headline d-none d-md-block"
              :style="{ color: data.color.headline }"
            >
              {{ data.headline }}
            </div>
            <div
            v-if="data.ctoVHTML"
              :class="[data.vhtmlClasses, 'cto-vhtml']"
              v-html="data.ctoVHTML"
              :style="{ color: data.color.headline }"
            >
            </div>
            <div v-if="data.phoneNumber" class="cto-number">
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  ...data.style,
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderMobile }"
                v-if="
                  data.cto.mobile &&
                  (activeBreakpoint('xs') || activeBreakpoint('sm'))
                "
                ><b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.mobile }}</a
              >
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderDesktop }"
                v-else
                ><b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.desktop }}</a
              >
            </div>
            <div
            v-for="(nestedComponent, index) in data.nestedComponents"
            v-bind:key="index"
            :class="[nestedComponent.rootClasses,]"
          >
            <component
              v-if="nestedComponent.data !== null"
              v-bind:is="nestedComponent.type"
              :data="nestedComponent.data"
              :class="['gridColNested', nestedComponent.classes]"
            ></component>
          </div>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { BIconTelephoneFill, BNavbar } from "bootstrap-vue"
import GlobalAlert from "@/components/Header/GlobalAlert"
import Grid from "@/components/Layout/Grid";
import Modal from "@/components/Content/Modal";
import TwoStepForm from "@/components/Form/TwoStepForm"
import WYSIWYG from "@/components/Content/WYSIWYG"

export default {
  name: "BottomNav",
  props: ["data"],
  components: {
    BIconTelephoneFill,
    'b-navbar': BNavbar,
    Grid,
    Modal,
    TwoStepForm,
    GlobalAlert,
    WYSIWYG,
    ...mapGetters(["CURRENT_BREAKPOINT"])
  },
  computed: {
    isSticky() {
      if (this.data.techSettings) {
        let stickyMobile = ""
        let stickyDesktop = ""

        if (this.data.techSettings.stickyMobile) {
          if (this.data.techSettings.stickyLocation) {
            stickyMobile = this.data.techSettings.stickyLocation;
          } else {
            stickyMobile = "top"
          }
        } else {
          stickyMobile = ""
        }

        if (this.data.techSettings.stickyDesktop) {
          if (this.data.techSettings.stickyLocation) {
            stickyDesktop = this.data.techSettings.stickyLocation;
          } else {
            stickyDesktop = "top"
          }
        } else {
          stickyDesktop = ""
        }

        if (window.innerWidth >= 576) {
          return stickyDesktop
        } else {
          return stickyMobile
        }
      } else {
        return ""
      }
    },
    isTransparent() {
      if (this.topNav.techSettings.transparent) {
        return true
      } else {
        return ""
      }
    }
  },
  methods: {
    activeBreakpoint(arg) {
      if (this.$store.getters.CURRENT_BREAKPOINT == arg) {
        return true
      } else {
        return false
      }
    },
    engaged() {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedCallButton")
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 50px;
  width: 109.89px;
  
}

img.fullWidthLogo {
  width: 100%;
  object-fit: contain;
}

nav .row {
  width: 100%;
}

.navbar-expand {
  width: 100%;
}

.dropshadow-top {
  filter: drop-shadow(0px 4px 10px #25292b)
}
.navbar-container {
  width: 100%;
  //height: 66px;
  &.transparentDrop {
    position: absolute;
    .cto-number a {
      color: white;
      border: 1px solid white;
    }
    .cto-headline {
      color: black;
    }
  }
}

.cto-container {
  text-align: right;

  .cto-number a {
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    font-family: Montserrat;
    line-height: 25px;
    text-decoration: none;
  }

  .ctoBorder {
    border: 1px solid;
    padding: 8px;
  }

  @media (min-width: 768px) {
    .cto-number a {
      font-size: 20px;
    }
  }
}

@media (min-width: 992px) {
  img.fullWidthLogo {
    width: auto;
  }
}
.cto-container.div {
  display: block;
}
</style>
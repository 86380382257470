<template>
    <div :class="['ThisPageComponent', data.techSettings.rootClasses]" :style="data.techSettings.style">
        <b-container fluid class="px-0">
            <b-container
            :fluid="data.techSettings.fullWidth"
            :class="['htmlContainer','text-' + data.techSettings.textAlignment, data.techSettings.classes]"
            >
            <b-row :no-gutters="!data.techSettings.gutters" :class="data.techSettings.rowClasses">
                <div class="title-area">
                    <h1 class="page-title">{{ data.content.title }}</h1>
                    <hr/>
                </div>
            <b-col v-html="data.html" :class="data.techSettings.colClasses"></b-col>

            
        </b-row>
    </b-container>
    <b-container>
        <div class="collapse-area">
            <div v-for="(link,index) in data.content.links" :key="index">
                <a v-if="index < 2" :href="link.href" style="color: #007CC2;">{{ link.text }}</a>
            </div>    
            <b-collapse id="my-collapse">
                <b-card>
                    <div v-for="(link,index) in data.content.links" :key="index">
                        <a v-if="index > 1" :href="link.href" style="color: #007CC2;">{{ link.text }}</a>
                    </div>
                </b-card>
            </b-collapse>
            <div class="mt-3 d-flex justify-content-center">
                <button v-b-toggle.my-collapse class="btn">
                    <span class="when-opened">
                        <BIconChevronUp style="color: #25292B"/>
                    </span>
                    <span class="when-closed">
                        <BIconChevronDown style="color: #25292B"/>
                    </span>
                </button>
            </div>
        </div>
    </b-container>
      </b-container>
    </div>
  </template>
  
  <script>
  import { BIconChevronDown, BIconChevronUp } from "bootstrap-vue"
  
  export default {
    name: "ThisPageComponent",
    props: ["data"],
    components: {
        BIconChevronDown,
        BIconChevronUp
    },
    methods: {
        // toggleHandler: function() {
        //     let button = document.getElementsByClassName('moreless-button')[0]

        //     document.getElementsByClassName('moretext')[0].slideToggle()            
        //     console.log("button", button)
        // }
    },
    mounted: function() {
    }
  }
  </script>
  
  <style lang="scss" >
  html{
    scroll-behavior: smooth;
  }
  .ThisPageComponent {
    .flex {
      display: flex;
    }
  
    .flex-column {
      flex-direction: column;
    }
  
    .justify-content-space-between {
      justify-content: space-between;
    }
    .title-area{
        width: 100%;
        position: relative;
        .page-title{
            font-size: 2.25rem;
            text-align: start;
            color: #003653;
            padding-right: 5px;
            z-index: 99999999 !important;
            position: inherit;
            display: table;
            background-color: #fff;
        }
        hr{
            border-top: 2px solid #C4C4C4;
            position: absolute;
            z-index: 1;
            top: 1.2rem;
            left: 0;
            width: 100%;
        }
    }
    .collapse-area{
        border-bottom: 2px solid #C4C4C4    ;
        margin-bottom: 1rem;
        a{
            display: block;
            padding: 0.5rem 0;
        }
        button{
            &:focus{
                box-shadow: none;
            }
        }
    }
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
  }
  
  </style>
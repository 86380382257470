import { dlEventCheck } from "../globalFunctions/datalayer.js"

export default {
    actions: {
        DATA_LAYER_TY_PAGEVIEW_ACTION: function () {
            if(!dlEventCheck("ty-pageview")){
                window.dataLayer.push({
                event: 'ty-pageview', //trigger in GTM
                pageUrl: "https://rev.uti.edu/thankyou",
              })
            }
          },
      
          DATA_LAYER_FORM_START_ACTION: function () {
           
            if (!dlEventCheck("form-start", this.state.formData.AcquisitionPoint)) {
              window.dataLayer.push({
                event: "form-start",
                formId: this.state.formData.AcquisitionPoint
              });
            }
    },
    DATA_LAYER_SET_EXPVAR_ACTION: function (context, payload) {
            
             let variation =  payload.expvar !== undefined ? payload.expvar : "def"
     // console.log(`This was called value ${JSON.stringify(variation)}`);

               window.dataLayer.push({
                'expVar': variation
               });
      
    },
          
    }
}
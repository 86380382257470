<template>
  <b-container fluid :class="[
    data.techSettings.classes,
    `${data.techSettings.backgroundColor}-theme `,
    'basic-list pt-3 pb-3'
    ]">
    <b-row :fluid="data.techSettings.fullWidth" :style="`${data.techSettings.fullWidth ? '' : 'max-width: 1140px'}`"
      :class="`${data.techSettings.fullWidth ? '' : 'mx-auto'}`">
      <b-col class="col-12 px-0" order="2" order-lg="1">
        <b-col :class="`header-container px-0 ${data.techSettings.contentOrientation == 'left' || data.techSettings.contentOrientation == 'right' ? 'col-12 col-lg-5' : `col-xs-${data.techSettings.xsColWidth}`}`" 
              :order="`${data.techSettings.contentOrientation == 'left' || data.techSettings.contentOrientation == 'center' ? 1 : 2}`">
          <h2 v-if="data.title" v-html="data.title"
            :class="`pt-2 font-weight-bold text-${data.techSettings.headerAlignment}`"></h2>
          <p v-if="data.subTitle" v-html="data.subTitle" :class="`text-${data.techSettings.headerAlignment}`">
          </p>
        </b-col>
        <b-col v-if="data.list[0]" :class="`content-container ${data.techSettings.contentOrientation == 'left' ||
          data.techSettings.contentOrientation == 'right' ? 'col-12' : 'col-12' }`" :order="`${data.techSettings.contentOrientation == 'left' || data.techSettings.contentOrientation == 'center' ? 2 : 1 }`">
          <ul :style="data.techSettings.style" v-if="data.techSettings.listType === 'ul'"
            :class="`list mt-3 mb-4 pl-3 text-${data.techSettings.listAlignment} col-xs-${data.techSettings.xsColWidth}`">
            <b-row :cols="data.techSettings.xsColumns" :cols-md="data.techSettings.mdColumns">
              <b-col :class="data.techSettings.colclass" v-for="(item, index) in data.list" :key="index">
                <li :class="data.techSettings.liclass" class="accessory px-0">
                  <p v-html="item"></p>
                </li>
              </b-col>
            </b-row>
          </ul>
          <ol :style="data.techSettings.style" v-else-if="data.techSettings.listType === 'ol'"
            :class="`list mt-3 mb-4 pl-3 text-${data.techSettings.listAlignment} col-xs-${data.techSettings.xsColWidth} col-lg-${data.techSettings.mdColWidth}`">
            <b-row :cols="data.techSettings.xsColumns" :cols-md="data.techSettings.mdColumns">
              <b-col :class="data.techSettings.colclass" v-for="(item, index) in data.list" :key="index">
                <li :class="data.techSettings.liclass" class="accessory">
                  <p v-html="item"></p>
                </li>
              </b-col>
            </b-row>
          </ol>
          <ul :style="data.techSettings.style" v-else class="list text-left mt-3 mb-4 pl-3">
            <b-row :cols="data.techSettings.xsColumns" :cols-md="data.techSettings.mdColumns">
              <b-col :class="data.techSettings.colclass" v-for="(item, index) in data.list" :key="index">
                <li :class="data.techSettings.liclass" class="accessory">
                  <p v-html="item"></p>
                </li>
              </b-col>
            </b-row>
          </ul>
        </b-col>
        <b-col v-if="data.paragraph" v-html="data.paragraph"
          :class="['paragraph-container', data.techSettings.paragraphClasses , data.techSettings.colclass]">
        </b-col>
      </b-col>
      <b-col v-if="
        data.nestedComponents &&
        data.nestedComponents.filter((c) => c.location == 'sideBar').length >
        0
      " class="col-12 col-lg-5 contentSidebar" order="1" order-lg="2">
        <div v-for="(item, index) in this.data.nestedComponents" v-bind:key="index">
          <component v-bind:is="item.type" :data="item.data" class="basicListNested"></component>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Alert from "@/components/Content/Alert"

export default {
  name: "BasicList",
  props: ["data"],
  components: {
    Alert
  }
}
</script>

<style lang="scss" scoped>
.component-name {
  font-weight: bold;
  text-align: center;
}

.blue-theme {
  background-color: #003653;

  h2,
  h4,
  p {
    color: #fff;
  }

  ::marker {
    color: #b2b2b2;
  }
}

.white-theme {
  background-color: #fff;

  h2,
  h4,
  p {
    color: #25292B;
  }

  ::marker {
    color: #25292B;
  }
}

.transparent-theme {
  background-color: transparent;

  ::marker {
    color: #737373;
  }
}

.gray-theme,
.grey-theme {
  background-color: #f2f5f7;
  color: #003653;

  h2,
  h4,
  p {
    color: #003653;
  }

  ::marker {
    color: #0086d2;
  }
}

li p {
  margin-bottom: 0;
  // line-height: 24px;
  // font-size: 20px;
  // font-weight: 400;
}

// h2 {
//   font-family: Tungsten-Semibold;
//   font-size: 36px;
//   margin-bottom: 15px;
//   font-weight: 400;
// }

// h4 {
//   font-family: Montserrat, sans-serif;
//   font-size: 1.2rem;
//   margin-bottom: 15px;
//   font-weight: 700;
//   letter-spacing: 0.2px;
// }

li {
  font-size: 18px;
  line-height: 22px;
  padding: 7px;
}

// Up to LG BreakPoint
@media screen and (max-width: 991px) {
  .alertComponent.container-fluid {
    max-width: initial;
    padding: 0;
  }

  .contentSidebar {
    padding: 0 !important;
  }
}

// LG BreakPoint
@media screen and (min-width: 992px) {
  .alertComponent {
    position: absolute;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
    right: 0;
    width: 400px;
  }
}
</style>

<style lang="scss">
.basicListNested .alertBody {
  font-size: 15px !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:_vm.data.techSettings.backgroundColor +
    '-theme ' +
    'basic-list pt-3 pb-3 uti-component',attrs:{"fluid":""}},[_c('b-row',{class:("" + (_vm.data.techSettings.fullWidth ? '' : 'mx-auto')),style:(("" + (_vm.data.techSettings.fullWidth ? '' : 'max-width: 1140px'))),attrs:{"fluid":_vm.data.techSettings.fullWidth,"align-v":"center"}},[_c('b-col',{class:("header-container " + (_vm.data.techSettings.contentOrientation == 'left' ||
        _vm.data.techSettings.contentOrientation == 'right'
          ? 'col-12 col-lg-5'
          : ("col-xs-" + (_vm.data.techSettings.xsColWidth) + " col-lg-" + (_vm.data.techSettings.mdColWidth)))),attrs:{"order":("" + (_vm.data.techSettings.contentOrientation == 'left' ||
        _vm.data.techSettings.contentOrientation == 'center'
          ? 1
          : 2))}},[_c('h2',{class:("pt-2 program-name font-weight-bold text-" + (_vm.data.techSettings.headerAlignment))},[_vm._v(" "+_vm._s(_vm.getProgram())+" ")]),(_vm.data.proTitle)?_c('h3',{class:("pt-2 font-weight-bold text-" + (_vm.data.techSettings.headerAlignment))},[_vm._v(" "+_vm._s(_vm.data.proTitle)+" ")]):_vm._e(),_c('p',{class:("text-" + (_vm.data.techSettings.headerAlignment))},[_vm._v(" "+_vm._s(_vm.getContent())+" ")])]),_c('b-col',{class:("content-container " + (_vm.data.techSettings.contentOrientation == 'left' ||
        _vm.data.techSettings.contentOrientation == 'right'
          ? 'col-12 col-lg-7'
          : 'col-12')),attrs:{"order":("" + (_vm.data.techSettings.contentOrientation == 'left' ||
        _vm.data.techSettings.contentOrientation == 'center'
          ? 2
          : 1))}},[(_vm.data.techSettings.listType === 'ul')?_c('ul',{class:("list mt-3 mb-4 pl-3 text-" + (_vm.data.techSettings.listAlignment) + " col-xs-" + (_vm.data.techSettings.xsColWidth) + " col-lg-" + (_vm.data.techSettings.mdColWidth))},[_c('b-row',{attrs:{"cols":_vm.data.techSettings.xsColumns,"cols-md":_vm.data.techSettings.mdColumns}},_vm._l((_vm.getList()),function(item,index){return _c('b-col',{key:index},[_c('li',{staticClass:"accessory"},[_c('p',{domProps:{"innerHTML":_vm._s(item)}})])])}),1)],1):_vm._e(),(_vm.data.paragraph)?_c('p',{class:("bodyBold text-" + (_vm.data.techSettings.paragraphAlignment)),domProps:{"innerHTML":_vm._s(_vm.data.paragraph)}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
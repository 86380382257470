<template>
  <b-container
    fluid
    :class="'school-logo-bar py-3 ' + backgroundColorData + '-theme'"
  >
    <b-container class="px-0">
      <b-row class="mt-2 mx-auto logos" :style="getLogoWidth()">
        <b-col class="logo-container text-center px-0">
          <img
            v-for="(school, index) in data.schools"
            :key="index"
            :src="school.img"
            :alt="school.alt"
            class="school-logo mx-1 my-1"
            :style="getLogoHeight()"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center logo-title" cols="12">
          <p v-html="data.title" class="mb-0"></p>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "SchoolLogoBar",
  props: ["data"],
  computed: {
    backgroundColorData() {
      if (this.data.techSettings) {
        return this.data.techSettings.backgroundColor
      } else {
        return ""
      }
    },
    logoWidth() {
      let width = this.data.techSettings.width !== undefined ? this.data.techSettings.width : "500px"

      return {
        "--width": width
      }
    },
    logoHeight() {
      let height = this.data.techSettings.height;
      let mobileHeight = (height && height.mobile !== undefined) ? height.mobile : "35px";
      let desktopHeight =  (height && height.desktop !== undefined) ? height.desktop : "50px";

      return {
        "--mobileHeight": mobileHeight,
        "--desktopHeight": desktopHeight
      }
      
    }
  },
  methods: {
    getLogoHeight() {
      return [
        this.logoHeight
      ]
    },
    getLogoWidth() {
      return [
        this.logoWidth
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.school-logo-bar {
  &.white-theme {
    background: white;
    color: #737373;
  }

  &.blue-theme {
    background: #003653;
    color: white;
  }

  &.grey-theme,
  &.gray-theme {
    background: #f2f5f7;
    color: #003653;
  }
}

.logos {
  max-width: var(--width);
}
.school-logo {
  height: var(--mobileHeight);
  width: auto;

  @media screen and (min-width: 768px) {
    height: var(--desktopHeight);
  }
}

.logo-title {
  letter-spacing: -0.4px;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 10px;
  line-height: 11px;
}
</style>
<template>
    <b-container fluid :class="['tabsComponent', data.techSettings.borderColor]">
        <b-container :fluid="data.techSettings.fluid" class="" >
            <b-row>
                <b-col class="px-0">
                    <b-tabs content-class="" :justified="data.techSettings.type" :lazy="data.techSettings.lazyLoaded" :class="[data.techSettings.activeBackground ?'showBg': '', data.techSettings.borderColor]" :style="data.techSettings.style">
                        <div v-for="(tab, index) in data.tabs" :key="index" class="tabWrapper" >
                            <b-tab :title="tab.title" :disabled="tab.disabled" :lazy="tab.lazyLoaded"  @click="optimizely(`clickTab`, index) ">
                                <b-container v-if="tab.innerContent" v-html="tab.innerContent">

                                </b-container>
                                <b-container  class="px-md-3 px-0" v-for="(nestedComponent, cIndex) in tab.nestedComponents" :key='cIndex' :id="`tabNestedComponent-${index}-${cIndex}`">
                                    <component
                                        v-if="nestedComponent.data !== null"
                                        v-bind:is="nestedComponent.type"
                                        :data="nestedComponent.data"
                                        :class="['tabNestedComponent', nestedComponent.classes]"
                                        ></component> 
                                </b-container>
                            </b-tab>
                        </div>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import Grid from "@/components/Layout/Grid"
import ImageBlock from "@/components/Content/ImageBlock"
import Video from "@/components/Content/Video"
import WYSIWYG from "@/components/Content/WYSIWYG"
import TwoStepForm from "@/components/Form/TwoStepForm"
import Modal from "@/components/Content/Modal";
import Button from "@/components/Content/Button";
    export default {
        name: "Tabs",
        props: ["data"],
        components: {
            Grid,
            Video,
            ImageBlock,
            WYSIWYG,
            TwoStepForm,
            Modal,
            Button
        },
        mounted: function() {
                 setTimeout(()=>{
                    this.optimizely(false, 0);
                },1000);
        },
        methods: {
            optimizely(event, index) {
                if(this.data.tabs[index].bg_image){
                    for (let index = 0; index < document.getElementsByClassName('pageContent').length; index++) {
                        document.getElementsByClassName('pageContent')[index].style.display ='none';
                        
                    }
                    document.getElementsByClassName('pageContent')[index].style.display ='block';

                    for (let key in document.getElementsByClassName('imageBlockImage')) {
                        if(window.innerWidth> 600 && document.getElementsByClassName('imageBlockImage')[key] && document.getElementsByClassName('imageBlockImage')[key].src){
                            document.getElementsByClassName('imageBlockImage')[key].src=this.data.tabs[index].bg_image;
                        }
                    
                    }
                }
                if(event==false){
                    return
                }

                if (index == undefined) {
                    this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
                } else {
                    this.$store.dispatch("SET_OPTIMIZELY_ACTION", this.tabCount(event, index))
                }
                
            },
            tabCount(event, index) {
                let result = event + (index+1)
                return result
            }
        }
    }
</script>

<style lang="scss">
.tabsComponent {
    border-top: 1px solid #949494;
    &.blue {
        //border-top: 1px solid #0086D2;
        .nav-tabs {
            border: 1px solid #0086D2;
            border-bottom: 2px solid #0086D2;
            .nav-link {
                border: 1px solid #0086D2
            }
        }
        .tab-content {
            border: none;
        }
    }
    .h3 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
    }
    .nav-tabs {
        .nav-link {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border: 1px solid #949494;
            border-top: 0px;
            color: #25292B;
            min-height: 96px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 18px;

            &.active {  
                -webkit-box-shadow:inset 0px -2px 0px 4px #0086D2;
                -moz-box-shadow:inset 0px -6px 0px 0px #0086D2;
                box-shadow:inset 0px -6px 0px 0px #0086D2;
                // background-color: #0086D2;
                // color: white;
                border-left: 1px solid #949494;
                border-right: 1px solid #949494;
                border-bottom: 1px solid #949494;
            }
        }
    }

    .showBg{
        .nav-tabs {
        .nav-link {
            &.active {  
                background-color: #0086D2;
                box-shadow:none;
                color: #fff;
            }
        }
    }
    }

    .tab-content {
        padding-top: 30px;
        padding-bottom: 30px;
        color: #25292B;
        li {
            font-size: 18px;
            line-height: 22px;
        }
    }

    
}
@media screen and (max-width: 990px) {
    .tabsComponent {
        .tab-content {
            border: 1px solid #949494;
            padding-top: 0%;
            padding-bottom: 0px;
        }
    }
}

</style>
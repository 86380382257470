<template>
    <button :class="['g-recaptcha', this.classes,]" id="g-recaptcha" :data-sitekey="getGoogleSiteKey()" data-callback='onSubmit' data-action="submit"><span>{{ this.cta }}</span></button>
</template>

<script>
    import axios from "axios"
    import config from "@/config/index"

    export default {
        name: "GoogleRecaptchaV3",
        props: ["data", "classes", "cta"],
        data() {
            return {
                valid: false
            }
        },
        beforeCreate: function() {
            const script = document.createElement('script');
            script.setAttribute('src','https://www.google.com/recaptcha/api.js');
            document.head.append(script);
        },
        methods: {
            getGoogleSiteKey() {
                let key = process.env.VUE_APP_GOOGLE_RECAPTCHAV3_SITEKEY
                return key
            },
            validate(bool) {
                this.valid = bool;
                
                this.$emit('success', this.valid);
            },
            recaptchaCallback: function(res) {
                axios.post(`${config.api}/recaptchaV3/validate`,{response: res})
                .then((response) => {
                    
                    const success = response.data.success;                  
                    this.validate(success);
                })
                .catch(error => {
                    console.log(error);
                })
           },
           expiredCallback: function() {
               this.validate(false);
           },
           onSubmit: function(token) {
                
                this.recaptchaCallback(token);
                
           },
        //    onClick(e) {
        //     e.preventDefault();
        //     grecaptcha.ready(function() {
        //         grecaptcha.execute(`${process.env.VUE_APP_GOOGLE_RECAPTCHAV3_SITEKEY},{action: 'submit'}`)
        //         .then(function(token) {
        //             console.log(`token`,token);
        //         })
        //     })
        //    }
        },
        mounted: function() {
            window.recaptchaCallback = this.recaptchaCallback;
            window.expiredCallback = this.expiredCallback;
            window.onSubmit = this.onSubmit;
        }
    }
</script>

<style lang="scss" scoped>

.submit-button {
    //border: 1px solid #0c3;
    text-align: center;
    position: relative;
    color: #fff;
    overflow: hidden;
    //background-color: #0c3 !important;
    padding: 0 28px;
    font-size: 22px;
    width: 100%;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    line-height: 31px;

    &.isInvalidInput {
        color: #d50019!important;
    }

    &:before {
      //background-color: #f6001d 1%;
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(#f6001d 1%),
      //   to(#b60002 100%)
      // );
      // background-image: -webkit-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -moz-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -ms-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: -o-linear-gradient(top, #f6001d 1%, #b60002 100%);
      // background-image: linear-gradient(top, #f6001d 1%, #b60002 100%);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all linear 0.15s;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      transition-delay: 0s;
    }

    &:after {
      content: "\f054";
      font-family: FontAwesome;
      padding-left: 5px;
      font-size: 16px;
      vertical-align: middle;
      position: absolute;
      top: 0;
    }

    &:hover {
      background: #fff !important;
      // color: #0c3!important;
      // box-shadow: 0 0 5px #0c3;
    }

    &.red-theme,
    &.-theme,
    &.undefined-theme {
      border: 1px solid #d50019;
      background-color: #d50019;

      &:before {
        background-color: #f6001d 1%;
      }

      &:hover {
        color: #d50019 !important;
        box-shadow: 0 0 5px #d50019;
      }

      &:focus {
        outline: 1px auto #d50019;
      }
    }

    &.green-theme {
      border: 1px solid #0c3;
      background-color: #0c3;

      &:before {
        background-color: #0c3 1%;
      }

      &:hover {
        color: #0c3 !important;
        box-shadow: 0 0 5px #0c3;
      }

      &:focus {
        outline: 1px auto #0c3;
      }
    }

    &.blue-theme {
      border: 1px solid #008ed4;
      background-color: #008ed4;

      &:before {
        background-color: #008ed4 1%;
      }

      &:hover {
        color: #008ed4 !important;
        box-shadow: 0 0 5px #008ed4;
      }

      &:focus {
        outline: 1px auto #008ed4;
      }
    }
  }
</style>


<template>
  <div>
    <b-row>
      <b-col class="pb-3 pr-5 pl-5">
        <b-btn
          block
          variant="primary"
          size="lg"
          v-on:click="submit"
          :disabled="this.v.$valid == true"
          >Get Info</b-btn
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  props: ["v"],

  methods: {
    submit() {
      console.log("comp submit click")
      this.$emit("submit")
      console.log("post emit")
      this.v.$touch()
      this.optimizely("engagedStepOneSubmit")
      this.optimizely("engagedStepTwoSubmit")
      this.optimizely("submittedStepOne")
      this.optimizely("submittedStepTwo")
      return true
    },
    optimizely(apiName) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", apiName)
    }
  }
}
</script>

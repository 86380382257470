<template>
  <div  :class='["topnav navbar-container", data.techSettings.hidden ? "invisible" : "" , data.techSettings.classes]'>
    <header>
      <component
      v-if="data.nestedComponents !== undefined"
      v-bind:is="data.nestedComponents.type"
      :data="data.nestedComponents.data"
      :class="['alertNested', data.nestedComponents.rootClasses]"
      ></component>
    </header>
    
    <b-navbar
      :style="{ backgroundColor: data.color.background }"
      :class="data.techSettings.navbarClasses"
      :fixed="isSticky"
    >
      <b-container :fluid="data.techSettings.fullWidth" class="">     
        <b-row class="mx-0" :align-h="data.techSettings.alignment ? data.techSettings.alignment : 'between'">
          <div ref='logo' 
                        @click="data.logoModal ? openModal(data.logoModal.modalData.id) : data.image ? btnClick(data.image) : ()=> {}" 
                        @touch="data.logoModal ? openModal(data.logoModal.modalData.id) : data.image ? btnClick(data.image) : ()=> {}" 
                        class="logo-container px-0 flex align-items-center" 
                        v-if="data.logo" >
            <img :class="['ml-0 mr-0 pl-0 pr-0 uti-logo', data.cto == undefined ? 'fullWidthLogo' : '', data.logoClasses, data.image ? 'pointer' : null]" :src="data.logo" :alt="data.alt || 'This is a logo'" />
          </div>
          <b-col v-if='!data.floatingCallButton' class="cto-container px-0 my-auto" :class="data.cto.classes" :cols="data.cto.columns ? data.cto.columns : '6'">
            <div
              :class="[`cto-headline d-none d-md-block`, data.cto.ctoHeadlineClasses]"
              :style="{ color: data.color.headline }"
            >
              {{ data.headline }}
            </div>
            <div
            v-if="data.ctoVHTML"
              class="cto-vhtml"
              v-html="data.ctoVHTML"
              :style="{ color: data.color.headline }"
            >
            </div>
            <div v-if="data.phoneNumber && !data.floatingCallButton" :class="['cto-number', data.cto.ctoNumberClasses]">
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  ...data.style,
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderMobile }"
                v-if="
                  data.cto.mobile &&
                  (activeBreakpoint('xs') || activeBreakpoint('sm'))
                "
                ><a :href="'tel:'" v-if="data.cto.icon === true"><img class="mobile-icon"  src="https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/phone.svg"/></a>
                <a v-else><b-icon-telephone-fill class="cto-icon mr-1"></b-icon-telephone-fill>{{ data.cto.mobile }}</a>
                <!-- <b-icon-telephone-fill v-else
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.mobile }} -->
                </a
              >
              <!-- <img src="https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/phone.svg"/> -->

              <!-- <b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.mobile }} -->
             
              <a
                :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"
                :style="{
                  color: data.color.cto,
                  borderColor: data.color.ctoBorder
                }"
                @click="engaged()"
                :class="{ ctoBorder: data.cto.borderDesktop }"
                v-else
                ><b-icon-telephone-fill
                  class="cto-icon mr-1"
                ></b-icon-telephone-fill
                >{{ data.cto.desktop }}</a
              >

             
              
              
            </div>
            <div
            v-for="(nestedComponent, index) in data.nestedComponents"
            v-bind:key="index"
          >
            <component
              v-if="nestedComponent.data !== null"
              v-bind:is="nestedComponent.type"
              :data="nestedComponent.data"
              :class="['gridColNested', nestedComponent.classes]"
            ></component>
          </div>
          </b-col>
          <div v-if='data.floatingCallButton' class="float-call-button"><a :href="'tel:' + data.phoneNumber.replace(/\D/g, '')"><b-icon-telephone-fill class="cto-icon"></b-icon-telephone-fill></a></div>
        </b-row>
      </b-container>
    </b-navbar>
    <!-- Logo Modal -->
    <Modal v-if="data.logoModal && data.logoModal.modalData" :data="data.logoModal" ></Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { BIconTelephoneFill, BNavbar } from "bootstrap-vue"
import GlobalAlert from "@/components/Header/GlobalAlert"
import Alert from "@/components/Content/Alert"
import Modal from "@/components/Content/Modal";
import TwoStepForm from "@/components/Form/TwoStepForm"
import WYSIWYG from "@/components/Content/WYSIWYG"
import Button from "@/components/Content/Button"

export default {
  name: "TopNav",
  props: ["data"],
  components: {
    BIconTelephoneFill,
    'b-navbar': BNavbar,
    Modal,
    TwoStepForm,
    GlobalAlert,
    Alert,
    WYSIWYG,
    Button,
    ...mapGetters(["CURRENT_BREAKPOINT"])
  },
  computed: {
    isSticky() {
      if (this.data.techSettings) {
        let stickyMobile = ""
        let stickyDesktop = ""

        if (this.data.techSettings.stickyMobile) {
          if (this.data.techSettings.stickyLocation) {
            stickyMobile = this.data.techSettings.stickyLocation;
          } else {
            stickyMobile = "top"
          }
        } else {
          stickyMobile = ""
        }

        if (this.data.techSettings.stickyDesktop) {
          if (this.data.techSettings.stickyLocation) {
            stickyDesktop = this.data.techSettings.stickyLocation;
          } else {
            stickyDesktop = "top"
          }
        } else {
          stickyDesktop = ""
        }

        if (window.innerWidth >= 576) {
          return stickyDesktop
        } else {
          return stickyMobile
        }
      } else {
        return ""
      }
    },
    isTransparent() {
      if (this.topNav.techSettings.transparent) {
        return true
      } else {
        return ""
      }
    }
  },
  methods: {
    btnClick: function(image) {
                console.log("image", image)
                const element = document.querySelector(image)
                function pageScrollHandler (ofSet) {
          let elemPos = element.getBoundingClientRect().top;
          var offSetPost = elemPos + window.pageYOffset - ofSet;
          window.scrollTo({
            top: offSetPost,
            behavior: "smooth",
          });
        }
        let desktopOffset = (this.data.techSettings.stickyDesktop && this.data.techSettings.stickyMobile) ? 65 : 0;
        pageScrollHandler(desktopOffset )
                // let headerOffset = 55;

                // let elemPos = element.getBoundingClientRect().top
                // var offSetPost = elemPos + window.pageYOffset - headerOffset

                // window.scrollTo({
                //   top: offSetPost,
                //   behavior: "smooth"
                // })
            },
    activeBreakpoint(arg) {
      if (this.$store.getters.CURRENT_BREAKPOINT == arg) {
        return true
      } else {
        return false
      }
    },
    engaged() {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", "engagedCallButton")
    },
    openModal(id) {
      this.$bvModal.show(`modal-${id}`)
    }
  }
}
</script>

<style lang="scss" >
.topnav {
  &.navbar-container {
    width: 100%;
    //height: 66px;
    &.transparentDrop {
      position: absolute;
      .cto-number a {
        color: white;
        border: 1px solid white;
      }
      .cto-headline {
        color: black;
      }
    }
  }

  .gridColNested button {
  margin-left: auto;
}
  img {
    max-height: 50px;
    //width: 109.89px;
    
  }
  .uti-logo.pointer{
    cursor: pointer;
  }

  .uti-logo {
    max-width: 153px;
  }

  img.fullWidthLogo {
    width: 100%;
    object-fit: contain;
  }

  nav .row {
    width: 100%;
  }

  .navbar-expand {
    width: 100%;
  }

  .dropshadow-top {
    filter: drop-shadow(0px 4px 10px #25292b)
  }


  .float-call-button {
    background-color: #007CC2;
    color: white;
    min-height: 50px;
    min-width: 50px;
    position: fixed;
    right: 0;
    top: 25%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px;
    text-orientation: sideways;
    writing-mode: vertical-lr;

    .mobile-icon{
      height: 25px;
      width: 25px;
    }

    a {
      color: white;
      text-decoration: none;
      min-height: 50px;
      width: 50px;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }

  .cto-container {
    text-align: right;

    &.div {
      display: block;
    }

    .cto-number a {
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      display: inline-block;
      font-family: Montserrat;
      line-height: 25px;
      text-decoration: none;
    }

    .ctoBorder {
      border: 1px solid;
      padding: 8px;
    }
    .mobile-icon{
      height: 14px;
      width: 14px;
    }

    @media (min-width: 768px) {
      .cto-number a {
        font-size: 20px;
      }
    }
  }
}


@media (min-width: 992px) {
  .topnav {
    img.fullWidthLogo {
      width: auto;
    }
  }
  
}


</style>
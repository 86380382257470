<template>
<svg width="60" height="54" viewBox="0 0 60 54" fill="none" class="school-icon" xmlns="http://www.w3.org/2000/svg">
<path d="M53.6365 3.33332H53.6198C51.1115 3.34582 38.5417 4.25312 30.749 8.3427C30.3271 8.56353 29.6729 8.56353 29.251 8.3427C21.4573 4.25312 8.8875 3.34582 6.38021 3.33332H6.36354C2.85417 3.33332 0 6.09062 0 9.4802V40.3864C0 43.6583 2.64688 46.3531 6.02604 46.5219C9.64792 46.7052 18.7375 47.3823 24.9729 49.6854C25.5219 49.8885 26.0813 50 26.6656 50H33.3323C33.9177 50 34.4771 49.8875 35.026 49.6854C41.2625 47.3823 50.351 46.7052 53.976 46.5219C57.3521 46.3531 59.999 43.6594 59.999 40.3875V9.4802C60 6.09062 57.1458 3.33332 53.6365 3.33332ZM28.3333 45.1042C28.3333 46.001 27.5896 46.6802 26.7438 46.6802C26.5594 46.6802 26.3698 46.6479 26.1823 46.5781C19.6771 44.1604 10.6625 43.4187 6.19167 43.1917C4.58854 43.1115 3.33333 41.8792 3.33333 40.3864V9.4802C3.33333 7.92916 4.69375 6.6677 6.36354 6.6677C8.37188 6.67812 19.1198 7.43645 26.376 10.6562C27.549 11.1771 28.3177 12.2969 28.3198 13.5562L28.3333 24.0062V45.1042V45.1042ZM56.6667 40.3864C56.6667 41.8792 55.4115 43.1115 53.8073 43.1917C49.3365 43.4187 40.3219 44.1604 33.8167 46.5781C33.6292 46.6479 33.4396 46.6802 33.2552 46.6802C32.4104 46.6802 31.6656 46.001 31.6656 45.1042V24.0062L31.6792 13.5562C31.6802 12.2969 32.45 11.1771 33.6229 10.6562C40.8792 7.43645 51.6271 6.67812 53.6354 6.6677C55.3052 6.6677 56.6656 7.92916 56.6656 9.4802V40.3864H56.6667Z" fill="#25292B"/>
</svg>
</template>




<script>
export default {
  name: "schoolIcon"
}
</script>

<style scoped>
.school-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import config from "@/config/index"
import datalayermodule from "./datalayermodule"
import cssmodule from "./cssmodule"
import optimizelymodule from "./optimizelymodule"
import formsetupmodule from "./formsetupmodule"
import dataModules from "./dataModule"
import googleModule from "./googleModule"
import disclaimerModule from "./disclaimerModule"

Vue.use(Vuex)
Vue.config.devtools = true

//I added a file called datamanger.js which is a copy of prod so we have a reference for it to look at the functions. It is located in the store folder. I had to comment the whole file

export default new Vuex.Store({
  state: {
    //Creating a base formData object and we can do the whole additional info packaging on the server.
    ////****** VUEX does not have direct access to this.$route.query. To set the utm_medium....etc it must be done from the component or on the server side. I will
    //******create an Action and Mutation for now to at least get the values in the keys.
    aoiData: dataModules.AOI,
    campusData: dataModules.campusData,
    countryData: dataModules.countryData,
    diplomaData: dataModules.diplomaData,
    hiddenAOIByCampaignData: dataModules.hiddenAOIByCampaignData,
    visibleAOIByCampaignData: dataModules.visibleAOIbyCampaignData,
    disclaimerData: [],
    formData: {
      //* Quick data access without looping through dataPayload array junk stuff *//
      AcquisitionPoint: "UTIFORM039",
      Age: null,
      AOI: "UNDECIDED",
      Comments: "",
      ContentPageForConversion: "",
      country: "",
      DateOfBirth: "",
      device: "",
      Education: null,
      email: "",
      EWC_Language: "",
      FirstName: "",
      GradMonth: "",
      GradYear: "",
      HighSchoolCity: "",
      HighSchoolID: null,
      HighSchoolState: "",
      inqId: "",
      IsMilitary: false,
      incomingQS: "",
      IsComplete: false,
      LastName: "",
      leadGuid: "",
      MilitaryState: "",
      MilitaryID: "",
      MilitarySeparation: "",
      phoneNumber: "",
      userAgent: window.navigator.userAgent,
      utm_campaign: "CMP-01032-Q0V1H0",
			utm_medium: "direct",
			utm_source: "pmlp",
      utm_content: "",
      utm_term: "",
      utm_adid: "",
      utm_campaignname: "",
      utm_adsetname: "",
      utm_placement: "",
      zipCode: "",
      Campus: "",
      //$(form).find('input[name=phone_number]').val().trim().replace(/\D/g, "") This is the example that is used...in the mutation for phone number I will have to trim the value so there is no masking.
      //one mutation called SET_QUERY_PARAMS_MUTATION will be created and the action will be dispatched after the Form component has mounted for the following:
      //end
      dataPayload: {
        //***For some reason this is an Array and not an object in production. This is probably due to the backend Leads Collection Store and CRM API's.
        //***I will verify to see if this is needed or it can be changed. If it stays like this we will need to use Filter to change the state in our Mutations.
        AdditionalInfo: [
          //Mutation for AOI is called ADD_AOI. The action for this will come from the Aoi.vue component.
          {
            Key: "AOI",
            Value: ""
          },

          //***AcquistionPoint for Snap is UTIFORM038 will need to verify if this can just be default state value so no mutations are needed or we need to create a new
          //AcquisitionPoint for the specific application.
          //TY is UTIFORM035
          //
          {
            Key: "AcquisitionPoint",
            Value: "UTIFORM039"
          },

          //Eventually we will add a form id to additional info. Action and Mutation will be created to submit a Form.ID wihen a form is loaded.

          //variation id will eventually be added this will be equal to the JSON object main key

          //Mutation for AGE is called ADD_AGE. This Value will come from the Age field on the form.
          {
            Key: "Age",
            Value: ""
          }, //$('form').find('select[name=age]').val() },

          {
            Key: "CollectionDate",
            Value: ""
          },

          //***Not sure this is needed as there is no value in the payload will need to verifiy.
          //Not listed HS or military Base from the thank you form.
          {
            Key: "Comments",
            Value: ""
          }, //dataManager.ijmData() },

          //This will equal the intial lead URL example start.uti.edu/snap

          //default this vaule to utm_source else default to refererr. This was requested by Don A it was only relevent to UTI.edu
          {
            Key: "ContentPageForConversion",
            Value: ""
          }, // dataManager.conversionURL() },

          //This value will equal the url where the last lead was submitted. So if the user completes the thank you page. It would be start.uti.edu/thank-you or it would be the intial
          //url start.uti.edu/snap.

          //this could be equal to this.$route to string
          {
            Key: "conversionurl",
            Value: ""
          }, //Value: window.location.href.substring(0, 499) }, // dataManager.conversionURL() },


          // This value is added to replace GCLID with the change from SA360 to CM360; for the time being GCLID will be kept in payload
          {
            Key: "dclid",
            Value: ""
          }, 

          //This value will be if the user was on Mobile. We could probably just set the default state to "Mobile/mobile is true" since snap is mobile only.

          //This will be a function for breakpoint for mobile tablet and desktop.

          {
            Key: "device",
            Value: ""
          }, // dataManager.userAgent().Device },

          //This value will have a mutation called ADD_EWC. This mutation will need to encode the ewc text in the EWC.vue component.
          {
            Key: "EWC_Language",
            Value: ""
          }, //encodeURIComponent($('.ewc').html()) },

          //***This key is probably not need since this is snap only. We could make a mutation for it if facebook ads run through this app also or just have it setup for the
          //facebook specific app.
          //equal utm_adid need to add this to our query string mutation
          {
            Key: "facebookuserid",
            Value: ""
          }, //dataManager.fbId() },

          //This value will need to be created a mutation for this will be ADD_GCLID. This is used for google analytics.

          ///Ask Eric and David about this. This is the query parameter gclid
          {
            Key: "gclid",
            Value: ""
          }, //dataManager.gclId() },

          //This is a value that is needed for google analytics. The mutation for this can be called ADD_GACLIENTID.
          {
            Key: "googleanalyticsclientid",
            Value: ""
          }, //dataManager.gaClientID() },

          //This value comes for a datamanger get request using Ipify. This will need a Get request and a mutation. Code for this can be found in datamanger and I also have it below:
          //getIp: function() {
          //         return new Promise(function (resolve, reject) {
          //             if (typeof jQuery !== 'undefined') {
          //                 $(function () {
          //                     try {
          //                         $.getJSON('https://api.ipify.org?format=jsonp&callback=?',
          //                             function (JSON) {
          //                                 resolve(JSON.ip);
          //                             }
          //                         );
          //                     } catch (err) { reject; }
          //                 });
          //             }
          //         });
          //     },
          //Currently not used in CRM but can be added. Due to GA having this.

          {
            Key: "incomingQueryString",
            Value: ""
          },

          //inquiryid is the same mutation as CREATE_LEADGUID since it is the same value.
          {
            Key: "inquiryid",
            Value: ""
          },

          {
            Key: "IpAddress",
            Value: ""
          },

          // IsComplete value will only change to True when the user has completed ALL the TY form questions. So TY form submission will change this not intial form submission.the lead will decorate after 15 mins and be submitted to CRM.
          {
            Key: "IsComplete",
            Value: false
          },

          //Mutation for LeadGuid Value is CREATE_LEADGUID
          {
            Key: "LeadGuid",
            Value: ""
          },

          // This value is for marketo only so not sure it will be needed for snapchat.
          ///Added the cookie functions for this in the globalfunctions folder currently named datalayer.js
          {
            Key: "marketocookieid",
            Value: ""
          }, // dataManager.mktoID() },

          //The mutation for this will be called ADD_OS. This value will be what OS type the user has. Will be helpful to know what they are using.
          //This could be used in the future. Currently GA is handeling this.
          // { Key: "OS", Value: "" }, //dataManager.userAgent().OS.Type + ' ' + dataManager.userAgent().OS.Version },

          //This is for optimizely not needed unless we run it on the project.
          // { Key: "opt_accountid", Value: "" }, //dataManager.optAcctID() },

          //This is for optimizely not needed unless we run it on the project.
          // { Key: "opt_projectid", Value: "" }, //dataManager.optProjID() },

          //This is for optimizely not needed unless we run it on the project.
          // { Key: "opt_expid_varid", Value: "" }, //dataManager.optExpVarIDs() },

          //This value will be equal to the domain the lead came from. Example value is https://start.uti.edu/
          {
            Key: "PreviousPage",
            Value: ""
          }, //dataManager.referrer() },

          //The current value of this the same as the Previous Page Key. I beleive this should be equal to the whole URL including query parameters but there is an issue with
          //document.referrer not including the query parameters. I have tested this in the console.
          {
            Key: "referrer",
            Value: document.referrer
          },

          //This is just equal to the browser user agent may need to call a mutation to set this if the default value does not run right. Tested this in the console
          //in the browser and it gives me a value.
          {
            Key: "userAgent",
            Value: window.navigator.userAgent
          },

          //*** Just making a note. It is now 4:15AM so I may need to double check the following due to limited sleep. ******

          // This value will be equal the value of the query parameter utm_campaign. If there is no campaign code the default that should be used is CMP-01032-Q0V1H0.
          // I will check with Eric to see if there is a campaign code he would like it to default to since CMP-01032-Q0V1H0 is a direct source and not related to snapchat.
          //12/2/2020 Per Erik default will be CMP-01016-S2L0G5 if there is no campaign code in the url. Just doing this to cover our basis.
          {
            Key: "utm_campaign",
            Value: "CMP-01016-S2L0G5"
          }, //dataManager.campaign() },

          //This value will come from the query string of utm_medium
          {
            Key: "utm_medium",
            Value: ""
          }, //dataManager.medium() },

          // Per Erik this should always be hyfn. So we can default it to hyfn without a mutation. hyfn is used for snapchat, Facebook and Instagram.
          {
            Key: "utm_source",
            Value: ""
          }, //dataManager.source() },

          //This value will come from the query string value in the URL utm_content.
          {
            Key: "utm_content",
            Value: ""
          }, //dataManager.content() },

          //This value will come from the query string value in the URL utm_term.
          {
            Key: "utm_term",
            Value: ""
          },

          //This value will come from the query string value in the URL utm_term.
          {
            Key: "utm_adid",
            Value: ""
          },

          //This value will come from the query string value in the URL utm_term.
          {
            Key: "utm_campaignname",
            Value: ""
          },

          //This value will come from the query string value in the URL utm_term.
          {
            Key: "utm_adsetname",
            Value: ""
          },

          //This value will come from the query string value in the URL utm_term.
          {
            Key: "utm_placement",
            Value: ""
          },

          //*** This does not have a value currently but I will check with mark on the function of this. This may just be the entire query string in the URL.
          //", Value: "" }, //dataManager.getDataByName("uti_incomingQS").substring(0, 99) }

          //Need campus ID for CRM possibly
          {
            Key: "campusOfInterestNameId",
            Value: ""
          },
          //campus of interest for GA tracking
          {
            Key: "campusOfInterestName",
            Value: ""
          },
          //This is the vendor for CAPI. This will contain the source or medium through an action to populate this field
          {
            Key: "uti_socialmediavendor",
            Value: ""
          },
          //This is for the ID that is pulled from the query string if there is one..Such as the FBCLID or TTCLID for capi. This is based on the social
          //media vendor.
          {
            Key: "uti_socialmediaid",
            Value: ""
          },
          //This is for if the user OPT's OUT of CAPI the base value will be false.
          {
            Key: "uti_socialmediadatasharingoptout",
            Value: "false"
          }
        ],
        Addresses: [
          {
            //This is the default value for AddressType. It does not change.
            AddressType: "L",

            //This is equal to the Country that is selected from the form. Mutation will be ADD_COUNTRY.
            Country: "",

            //This is equal to the zipcode that is entered from the form. Mutation will be ADD_POSTALCODE.
            PostalCode: ""
          }
        ],

        AreasOfInterest: [
          {
            //This Code key value aoi is going to be equal to the form AOI value above in state. This will just need to be added to the Mutation ADD_AOI
            Code: "",
            Description: null,
            Type: "AOI",
            IsPrimary: true
          }
        ],

        DateOfBirth: "",

        // This is if they are a HS GRAD. Mutation for this will be ADD_EDUCATION
        //*** I will need to verifiy with Mark under what conditions this is acutally set.
        Education: "", //edu,

        EmailAddresses: [
          {
            //This is the value of the emailaddress on the form. Mutation for this will be ADD_EMAILADDRESS
            Address: "",
            Type: "P"
          }
        ],

        //This value will come from the form input First Name. Mutation for this will be ADD_FIRSTNAME
        FirstName: "", //$('form').find('input[name=first_name]').val().trim(),

        //This value is default to 6 if Grad Year is entered. This could be added to the mutation ADD_GRADYEAR
        GradMonth: "", //gradmonth,

        //This will be the value of the Grad Year entered from the form. Mutation will be ADD_GRADYEAR
        GradYear: "", //$('form').find('input[name=grad_date]').val().trim(),

        HighSchoolCity: "",

        HighSchoolID: null,

        HighSchoolState: "",

        //For some reason this is another spot for the AOI value This will be added to the ADD_AOI mutation or we can set it equal to this.state.AOI?
        InterestCode: "", //$('form').attr('data-area-of-interest'),

        IPAddress: "",

        //This value will be true or false depending if they checked the is military checkbox. Mutation will be ADD_ISMILITARY. Set default state to false.
        IsMilitary: false, //$('form').find('input[name=military]').is(':checked'),

        //This value will come from the Last Name field in the form. Mutation will be called ADD_LASTNAME.
        LastName: "", //$('form').find('input[name=last_name]').val().trim(),

        MilitaryState: "",

        MilitaryID: "",

        //This will be the Date entered format is 12/2/2020 if IsMilitary is true. Mutation will be ADD_MILITARYSEPERATIONDATE.
        MilitarySeparation: "", //$('form').find('input[name=release_date]').val().trim(),

        PhoneNumbers: [
          {
            // This date should show the current date with this format 12/2/2020. Mutation ADD_EWCDATE.
            EWCDate: "", //date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear(),

            //This shows a value of "Form" need to look at the jquery value function to find this attribuite or add the attribute to the ewc component. Mutation ADD_EWCDISCLAIMERREFID
            EWCDisclaimerRefId: "Form", //$('.ewc').attr('data-dis-id'),

            //*** These values are set to true if they submit the first lead. Need to figure out the attribute.
            EWCFlag: true, // $('.ewc').attr('data-dis-id') !== undefined ? $('.ewc').attr('data-dis-id').length > 0 ? true : false : false,
            IsPrimary: true,
            OkToText: true,

            //This is the phone number value field from the form. The final input state does not include -'s. So a phone number looks like 6125448520. Mutation would be ADD_PHONENUMBER.
            //Type is also equal to "C".
            Number: "", //$('form').find('input[name=phone_number]').val().trim().replace(/\D/g, ""),
            Type: "C"
          }
        ]
        //This is used for the main PMLP page for reps. This will not be needed for Snapchat or any paid social or search.
        //RepCode: "" //dataManager.repCode()
      }
    },
    serverData: {
      //this is the vuex state for the json object coming from the server. This state can be used for the TY page also
    }
  },

  //This is the Start of the mutations that are needed to modify the above state/payload.

  mutations: {
    CREATE_LEADGUID_MUTATION: state => {
      formsetupmodule.CREATE_LEADGUID_MUTATION(state)
    },
    SET_CONVERSIONURL_MUTATION(state) {
      /// When IncomingQueryString param gets added to CRM, can limit this value to window.location.origin
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "conversionurl"
      })[0].Value = window.location.href.substring(0, 499)
    },
    SET_INCOMING_QUERYSTRING_MUTATION(state) {
      formsetupmodule.SET_INCOMING_QUERYSTRING_MUTATION(state)
    },
    SET_UTM_PARAMS_MUTATION: (state, route) => {
      formsetupmodule.SET_UTM_PARAMS_MUTATION(state, route)
    },
    SET_VENDOR_CAPI_MUTATION: (state, route) => {
      formsetupmodule.SET_VENDOR_CAPI_MUTATION(state, route)
    },
    SET_GACLIENTID_MUTATION: state => {
      formsetupmodule.SET_GACLIENTID_MUTATION(state)
    },
    SET_IP_MUTATION(state) {
      formsetupmodule.SET_IP_MUTATION(state)
    },
    SET_CONTENT_PAGE_FOR_CONVERSION_MUTATION(state) {
      formsetupmodule.SET_CONTENT_PAGE_FOR_CONVERSION_MUTATION(state)
    },
    SET_PREVIOUS_PAGE_MUTATION(state) {
      formsetupmodule.SET_PREVIOUS_PAGE_MUTATION(state)
    },
    SET_SERVERDATA_MUTATION: (state, payload) => {
      state.serverData = payload
    },
    async SET_FORM_DATA_MUTATION(state, payload) {
      state.formData.EWC_Language = payload.EWC
      let currentDate = new Date().toLocaleDateString("en-US")
      state.formData.dataPayload.PhoneNumbers[0].EWCDate = currentDate
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "EWC_Language"
      })[0].Value = payload.EWC

      state.formData.FirstName = payload.FirstName
      state.formData.dataPayload.FirstName = payload.FirstName

      state.formData.LastName = payload.LastName
      state.formData.dataPayload.LastName = payload.LastName

      state.formData.email = payload.email
      state.formData.dataPayload.EmailAddresses.filter(item => {
        return item
      })[0].Address = payload.email

      state.formData.Age = payload.Age
      payload.markComplete ? (state.formData.IsComplete = true) : null
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "Age"
      })[0].Value = payload.Age

      state.formData.country = payload.Country
      state.formData.dataPayload.Addresses.filter(item => {
        return item
      })[0].Country = payload.Country

      state.formData.zipCode = payload.ZipCode
      state.formData.dataPayload.Addresses.filter(item => {
        return item
      })[0].PostalCode = payload.ZipCode

      state.formData.AOI = payload.AOI
      state.formData.dataPayload.AreasOfInterest.filter(item => {
        return item
      })[0].Code = payload.AOI
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "AOI"
      })[0].Value = payload.AOI
      state.formData.dataPayload.InterestCode = payload.AOI

      //addition of campus id info
      state.formData.campusOfInterestNameId = payload.campusOfInterestNameId
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "campusOfInterestNameId"
      })[0].Value = payload.campusOfInterestNameId

      //addition of campus name info
      state.formData.campusOfInterestName = payload.campusOfInterestName
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "campusOfInterestName"
      })[0].Value = payload.campusOfInterestName

      state.formData.dataPayload.Education = state.formData.Education

      state.formData.GradYear = payload.GradYear
      state.formData.dataPayload.GradYear = payload.GradYear

      state.formData.GradMonth = payload.GradYear == "" ? "" : "6"
      state.formData.dataPayload.GradMonth = payload.GradYear == "" ? "" : "6"

      state.formData.phoneNumber = payload.PhoneNumber.trim().replace(/\D/g, "")
      state.formData.dataPayload.PhoneNumbers.filter(item => {
        return item
      })[0].Number = payload.PhoneNumber.trim().replace(/\D/g, "")

      state.formData.MilitarySeparation = payload.MilitarySeparation
      state.formData.dataPayload.MilitarySeparation = payload.MilitarySeparation

      state.formData.IsMilitary = payload.isMilitary
      state.formData.dataPayload.IsMilitary = payload.isMilitary

      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "CollectionDate"
      })[0].Value = new Date(new Date().toString().split("GMT")[0] + " UTC")
        .toISOString()
        .split(".")[0]
    },
    async SET_TYFORM_DATA_MUTATION(state, payload) {
      let date = payload.DateOfBirth.split("-")
      let finalDate = date[1] + "/" + date[2] + "/" + date[0]
      state.formData.DateOfBirth = finalDate
      state.formData.dataPayload.DateOfBirth = finalDate

      if (this.state.formData.Age < 20) {
        state.formData.Education = payload.HighSchoolEduType
        state.formData.dataPayload.Education = payload.HighSchoolEduType
      }

      if (
        this.state.formData.Age < 20 &&
        payload.HighSchoolEduType == "HS Grad"
      ) {
        state.formData.HighSchoolState = payload.HighSchoolState
        state.formData.dataPayload.HighSchoolState = payload.HighSchoolState
        state.formData.HighSchoolCity = payload.HighSchoolCity
        state.formData.dataPayload.HighSchoolCity = payload.HighSchoolCity
        state.formData.HighSchoolID = payload.HighSchoolID
        state.formData.dataPayload.HighSchoolID = payload.HighSchoolID
      }

      if (this.state.formData.Age >= 20) {
        state.formData.Education =
          payload.AdultEducationHS == "HS Grad"
            ? "HS Grad"
            : payload.AdultEducationGED == "GED"
            ? "GED"
            : "No Equivalent"
        state.formData.dataPayload.Education =
          payload.AdultEducationHS == "HS Grad"
            ? "HS Grad"
            : payload.AdultEducationGED == "GED"
            ? "GED"
            : "No Equivalent"
      }

      if (this.state.formData.IsMilitary == true) {
        state.formData.MilitaryState = payload.MilitaryState
        state.formData.dataPayload.MilitaryState = payload.MilitaryState
        state.formData.MilitaryID = payload.MilitaryID
        state.formData.dataPayload.MilitaryID = payload.MilitaryID
        state.formData.Comments += " " + payload.Comments
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "Comments"
        })[0].Value += " " + payload.Comments
      }

      state.formData.IsComplete = true
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "IsComplete"
      })[0].Value = true
    },
    SET_ACQUISITION_POINT_MUTATION(state, payload) {
      state.formData.AcquisitionPoint = payload
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "AcquisitionPoint"
      })[0].Value = payload
    },
    SET_PROGRAM_MUTATION(state, payload) {
      state.formData.AOI = payload
    },
    SET_AGE_MUTATION: (state, payload) => {
      state.formData.Age = payload
    },
    SET_CAPI_OPTOUT_MUTATION: (state, payload) => {
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "uti_socialmediadatasharingoptout"
      })[0].Value = payload
    },
    SET_EDUCATION_MUTATION: (state, payload) => {
      state.formData.Education = payload
      state.formData.dataPayload.Education = payload
    },
    SET_EWC_MUTATION: (state, payload) => {
      let currentDate = new Date().toLocaleDateString()
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "EWC_Language"
      })[0].Value = payload
      state.formData.dataPayload.PhoneNumbers.filter(item => {
        return item
      })[0].EWCDate = currentDate
      state.formData.EWC_Language = payload
    }
  },
  actions: {
    async SUBMIT_FORMDATA_ACTION(context) {
      window.dataLayer = window.dataLayer || []
      console.log(`state: form`, this.state.formData)
      await window.dataLayer.push({
        event: "form-success",
        formId: this.state.formData.AcquisitionPoint
      })
      //TODO: Encode for transfer
      await axios
        .post(`${config.api}/interested`, context)
        .then(function() {
          //  console.log("FORM-SUCCESS was Fired!")
          //console.log("SUBMIT_FORMDATA_ACTION was called in store")
          //console.log(response)
        })
        .then(function() {
          //Optimizely Event - Submit Any Form
          context.dispatch("SET_OPTIMIZELY_ACTION", "submittedForm", {
            root: true
          });
        })
    },
    INITIALIZE_INQUIRY_PAYLOAD_ACTION: (context, querystring) => {
      //let query = querystring
      context.commit("CREATE_LEADGUID_MUTATION")
      context.commit("SET_CONVERSIONURL_MUTATION")
      context.commit("SET_INCOMING_QUERYSTRING_MUTATION")
      context.commit("SET_UTM_PARAMS_MUTATION", querystring)
      //context.commit("SET_GACLIENTID_MUTATION")
      context.commit("SET_IP_MUTATION")
      context.commit("SET_CONTENT_PAGE_FOR_CONVERSION_MUTATION")
      context.commit("SET_PREVIOUS_PAGE_MUTATION")
      context.dispatch("DATA_LAYER_SET_EXPVAR_ACTION", querystring)
      context.commit("SET_VENDOR_CAPI_MUTATION", querystring)
    },
    SET_SERVERDATA_ACTION: (context, payload) => {
      context.commit("SET_SERVERDATA_MUTATION", payload)
    },
    async SET_FORM_COMPLETE(context, payload) {
      await context.commit("SET_FORM_DATA_MUTATION", payload)
    },
    async SET_FORM_DATA_ACTION(context, payload) {
      await context.commit("SET_GACLIENTID_MUTATION")
      await context.commit("SET_FORM_DATA_MUTATION", payload)
      await context.dispatch("SUBMIT_FORMDATA_ACTION")
      await context.dispatch("CREATE_COOKIE_ACTION")
    },
    SET_ACQUISITION_POINT_ACTION: (context, payload) => {
      context.commit("SET_ACQUISITION_POINT_MUTATION", payload)
    },
    SET_AGE_ACTION: (context, payload) => {
      context.commit("SET_AGE_MUTATION", payload)
    },
    SET_CAPI_OPTOUT_ACTION: (context, payload) => {
      context.commit("SET_CAPI_OPTOUT_MUTATION", payload)
    },
    SET_EDUCATION_ACTION: (context, payload) => {
      context.commit("SET_EDUCATION_MUTATION", payload)
    },
    SET_PROGRAM_ACTION(context, payload) {
      context.commit("SET_PROGRAM_MUTATION", payload)
    },

    async CREATE_COOKIE_ACTION() {
      let days = 7
      let expiry = new Date(
        new Date().getTime() + days * 86400000
      ).toUTCString()
      let curDate = new Date()
      let curTime = curDate.getTime()

      document.cookie =
        "uti_conversionTime=" +
        curTime +
        "; expires=" +
        expiry +
        "; domain=" +
        ".uti.edu" +
        ";"
      document.cookie =
        "hf=true" + "; expires=" + expiry + "; domain=" + ".uti.edu" + ";"
    },
    async SET_TYFORM_DATA_ACTION(context, payload) {
      await context.commit("SET_TYFORM_DATA_MUTATION", payload)
      context.dispatch("SET_OPTIMIZELY_ACTION", "submittedThankYouForm");
      await context.dispatch("SUBMIT_FORMDATA_ACTION")
      console.log(`tyform payload: `, payload)
    }
    // DATA_LAYER_FORM_START_ACTION: function () {
    //     console.log("am I hitting")
    //     if (!dlEventCheck("form-start", this.state.formData.AcquisitionPoint)) {
    //       window.dataLayer.push({
    //         event: "form-start",
    //         formId: this.state.formData.AcquisitionPoint
    //       });
    //     }
    //   }
  },
  getters: {
    ACQUISITIONPOINT: state => {
      return state.formData.AcquisitionPoint
    },
    AOIS: state => {
      return state.aoiData
    },
    HIDDENCAMPAIGNAOIS: state => {
      return state.hiddenAOIByCampaignData
    },
    VISIBLECAMPAIGNAOIS: state => {
      return state.visibleAOIByCampaignData
    },
    COUNTRIES: state => {
      return state.countryData
    },
    CAMPUSES: state => {
      return state.campusData
    },
    DIPLOMAS: state => {
      return state.diplomaData
    },
    CAMPAIGN: state => {
      return state.formData.utm_campaign
    },
    PROGRAM: state => {
      return state.formData.AOI
    }
  },
  modules: {
    datalayermodule,
    cssmodule,
    optimizelymodule,
    formsetupmodule,
    dataModules,
    googleModule,
    disclaimerModule
  }
})

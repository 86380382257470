<template>
  <div>
    <b-form-group
      id="fieldset-5"
      label="Zip Code"
      label-for="input-5"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-input
        class="zipcodeinput"
        type="number"
        name="zip-code"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        placeholder="Zip Code"
        maxlength="5"
        v-model="Zipcode"
        pattern="^\d{5}$"
        @input="v.$touch()"
        @focus="optEvent('engagedZip')"
        :class="{ isInvalidInput: v.$error }"
        ref="ZipCode"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "ZipcodeInput",
  props: {
    value: {
      type: String,
      default: null
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    Zipcode: {
      get() {
        return this.value
      },
      set(value) {
        this.v.$touch()
        this.$emit("input", value)
        //	console.log(`Zipcode:${this.value}`);
      }
    }
  },
  methods: {
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>
<style lang="scss" scoped>
.zipcodeinput {
  width: 50%;
}
</style>

<template>
<div>
    <b-row class="col-12 py-2 pt-4">
        <h5 class="text-muted mb-0 pb-0 font-weight-light">
            Personal Information
        </h5>
    </b-row>
    <hr color="grey" class="mt-0" />
 </div>
</template>

<script>
export default {
    name: "PersonalInfoFormHeader",
     data() {
        return {
            state: true
        }
    }
};
</script>

import Vue from "vue";
import App from "./App.vue";
import Vuelidate from 'vuelidate';
import router from "./router";
import store from "./store";
import '@/scss/custom.scss';
import VueCookies from 'vue-cookies';
import { LayoutPlugin, FormInputPlugin, InputGroupPlugin, FormRadioPlugin, ButtonPlugin, FormSelectPlugin, FormCheckboxPlugin, CollapsePlugin, ModalPlugin, TabsPlugin, AlertPlugin, CarouselPlugin } from 'bootstrap-vue'

Vue.config.productionTip = true;
Vue.use( LayoutPlugin );
Vue.use( FormInputPlugin );
Vue.use( InputGroupPlugin );
Vue.use( FormRadioPlugin );
Vue.use( ButtonPlugin );
Vue.use( FormSelectPlugin );
Vue.use( FormCheckboxPlugin  );
Vue.use( CollapsePlugin );
Vue.use( ModalPlugin );
Vue.use( CarouselPlugin );
Vue.use( TabsPlugin );
Vue.use( AlertPlugin );
Vue.use( Vuelidate );
Vue.use( VueCookies );

let vm = new Vue({
  router,
  store,
  validations: {},
  render: h => h(App)
}).$mount("#app");

global.vm = vm;

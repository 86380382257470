<template>
<svg width="64" height="51" viewBox="0 0 64 51" fill="none" class="school1-icon" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_111_809)">
<path d="M60.4986 15.2184L34.3092 6.75081C32.549 6.18255 30.7019 6.18255 28.9418 6.75081L2.75236 15.2184C1.08118 15.759 0 17.2335 0 18.9749C0 20.7162 1.08118 22.1907 2.75137 22.7313L5.68756 23.6801C5.36143 24.2908 5.12029 24.948 4.96315 25.6369C3.90668 26.1488 3.1625 27.2092 3.1625 28.4624C3.1625 29.7204 3.91063 30.7868 4.97402 31.2958L3.19017 42.3507C3.03106 43.338 3.57659 44.2749 4.31088 44.2749H8.33813C9.07242 44.2749 9.61894 43.338 9.45884 42.3507L7.67598 31.2958C8.73937 30.7868 9.4875 29.7204 9.4875 28.4624C9.4875 27.4148 8.94493 26.5313 8.16024 25.9551C8.30749 25.4946 8.53578 25.0795 8.80954 24.6891L14.0939 26.3978L12.65 37.9499C12.65 41.4434 21.1453 44.2749 31.625 44.2749C42.1047 44.2749 50.6 41.4434 50.6 37.9499L49.1561 26.3978L60.4986 22.7303C62.1688 22.1907 63.25 20.7162 63.25 18.9749C63.25 17.2335 62.1688 15.759 60.4986 15.2184ZM47.3861 37.7384C46.3227 38.8581 40.9188 41.1124 31.625 41.1124C22.3312 41.1124 16.9273 38.8581 15.8639 37.7384L17.1576 27.3881L28.9418 31.1979C29.1988 31.2809 31.4837 32.1101 34.3092 31.1979L46.0934 27.3881L47.3861 37.7384ZM59.5222 19.724L33.3327 28.1916C32.215 28.5533 31.035 28.5533 29.9172 28.1916L12.5561 22.5781L31.9156 18.9472C32.7744 18.7871 33.3397 17.9609 33.1786 17.1021C33.0185 16.2423 32.1745 15.6799 31.3344 15.84L11.1952 19.6153C9.96978 19.8445 8.8718 20.3713 7.92602 21.0809L3.72681 19.723C2.95101 19.471 2.98955 18.4639 3.72681 18.2257L29.9163 9.75815C31.4086 9.27587 32.6676 9.54369 33.3318 9.75815L59.5212 18.2257C60.2516 18.4619 60.3029 19.471 59.5222 19.724V19.724Z" fill="#25292B"/>
</g>
<defs>
<clipPath id="clip0_111_809">
<rect width="63.25" height="50.6" fill="white"/>
</clipPath>
</defs>
</svg>
</template>




<script>
export default {
  name: "school1Icon"
}
</script>

<style scoped>
.school1-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
export default {
    state: {
        placeId: {
            UTIAustin: "ChIJxdkW6Z3PRIYRVR5ZEouYMEM",
            UTIBloomfield: "ChIJz5OBDH3_wokRFT7_M0kck50",
            UTIDFW: "ChIJsbjprtArTIYRpZ7WwzRTLwo",
            UTIExton: "ChIJiY-MdsyKxokRQE8fZo7FSfA",
            UTIHouston: "ChIJo_wxK2bKQIYRUs6b1n1ZDoc",
            UTILisle: "ChIJa87p17hWDogRR1MrjbZwBec",
            UTILongBeach: "ChIJqxk6iTgy3YARFKIaECtRl-A",
            UTIMiramar: "ChIJsRRuETyk2YgRbfv5AIv390o",
            UTIOrlando: "ChIJp1l0Qet954gR61vhcofk2hM",
            UTIPhoenix: "ChIJ9XbqEgc_K4cR3KGf1exmJgk",
            UTIRanchoCucamonga: "ChIJ4RcYJrw1w4ARj5RVmZ_HZPU",
            UTISacramento: "ChIJxzAbwgPWmoARZ0WoM9oH66Q",
            MMIPhoenix: "ChIJNVbvz11vK4cR6yVrCqPTAJI",
            MMIOrlando: "ChIJu07lbep954gRT4rElt1UkQk",
            MarineOrlando: "ChIJ8-hQ6f9954gRRs4UcMc2DX8",
            NTIMooresville: "ChIJR1nrxv1UUYgR4FoXzY1TWmE",
        }
    },
    getters: {
        PLACE_IDS: (state) => {
            return state.placeId;
        }
    }
}
<template>
<svg width="53" height="48" viewBox="0 0 53 48" fill="none" class="school2-icon" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_111_913)">
<path d="M52.9384 41.9979L36.7135 0.737951C36.5553 0.285243 36.1256 0 35.6692 0C35.3922 0 35.2119 0.0947743 34.6119 0.304566C34.0175 0.512517 33.7084 1.06276 34.035 1.99578C32.5205 2.8874 28.5924 4.2124 27.088 4.42679C26.9656 4.07898 26.9067 3.81766 26.4991 3.5775V2.94444C26.4991 1.31856 25.1805 0 23.5546 0H14.7213C14.1729 0 13.6889 0.190469 13.2491 0.450868C12.8102 0.190469 12.3262 0 11.7778 0H2.94444C1.31856 0 0 1.31856 0 2.94444V44.1667C0 45.7926 1.31856 47.1111 2.94444 47.1111H11.7778C12.3262 47.1111 12.8102 46.9206 13.25 46.6602C13.6898 46.9216 14.1738 47.1111 14.7222 47.1111H23.5556C25.1814 47.1111 26.5 45.7926 26.5 44.1667V10.9386L40.4337 46.3732C40.5928 46.8259 41.0226 47.1111 41.478 47.1111C41.7541 47.1111 41.9344 47.0163 42.5353 46.8065C43.1278 46.5995 43.4398 46.0493 43.1122 45.1153C44.6267 44.2237 48.5548 42.8987 50.0592 42.6843C50.3951 43.6413 50.9913 43.8483 51.5664 43.6468L52.2611 43.4039C52.8344 43.2024 53.1389 42.5693 52.9384 41.9979V41.9979ZM11.7778 44.1667H2.94444V38.2778H11.7778V44.1667ZM11.7778 35.3333H2.94444V11.7778H11.7778V35.3333ZM11.7778 8.83333H2.94444V2.94444H11.7778V8.83333ZM23.5556 44.1667H14.7222V38.2778H23.5556V44.1667ZM23.5556 35.3333H14.7222V11.7778H23.5556V35.3333ZM23.5556 8.83333H14.7222V2.94444H23.5556V8.83333ZM42.2482 42.6521L27.9529 6.89C30.2735 6.45385 33.8427 5.01936 34.8999 4.45899L49.1952 40.2202C46.8737 40.6573 43.3054 42.0908 42.2482 42.6521V42.6521Z" fill="#25292B"/>
</g>
<defs>
<clipPath id="clip0_111_913">
<rect width="53" height="47.1111" fill="white"/>
</clipPath>
</defs>
</svg>
</template>



<script>
export default {
  name: "school2Icon"
}
</script>

<style scoped>
.school2-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>
import axios from "axios"

export default {
  state: {
    restrictedEmailDomains: [
      ".my.id",
      "bozztirex.us",
      "tirex.com",
      "bozzcello.com",
      "driveplus.com",
      "vivaldigital.com",
      "drive.com",
      "putrajayabaya.skom.id",
      "driveplus.my.id",
      "white2828.com",
      "karyakediri.oool.ooo",
      "jourrapide.com",
      "tintadev.com",
      "temporary-mail.net",
      "chothuevenhomesquan9.com",
      "iceglue.skom.id",
      "yajoo.col",
      "nader.com",
      "dnitem.com",
      "xmail.com",
      "geekjun.com",
      "mail.co.id",
      "bodrex.com",
      "living.com",
      "laluxy.com",
      "capas.my.id",
      "black199.com",
      "teleworm.us",
      "sau53.org",
      "aburame08.com",
      "gdriveme.id",
      "corp.com",
      "redsium.com",
      "company.com",
      "putrajayabaya.skom",
      "goldenteam888.com",
      "armyspy.com",
      "dayrep.com",
      "driveplus.com",
      "uma3.be",
      "shop.com",
      "black199.com",
      "digdayarecord.online",
      "tinktank.biz",
      "bio.com",
      "hhmel.com"
    ]
  },
  getters: {
    RESTRICTED_EMAIL_DOMAINS_DATA: state => {
      return state.restrictedEmailDomains
    }
  },
  CREATE_LEADGUID_MUTATION: state => {
    let s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)

    let guidid =
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()

    //This worked before having to change to the Prod payload. May need to Filter the state to set the value. Just leaving for a reference before it is changed.
    state.formData.leadGuid = guidid
    state.formData.inqId = guidid
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "LeadGuid"
    })[0].Value = guidid
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "inquiryid"
    })[0].Value = guidid
  },
  SET_INCOMING_QUERYSTRING_MUTATION(state) {
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "incomingQueryString"
    })[0].Value = document.location.search
  },
  SET_UTM_PARAMS_MUTATION: (state, querystring) => {
    let campaign,
      medium,
      source,
      content,
      term,
      adid,
      campaignName,
      adSetName,
      placement

    /// Set default values based on referrer
    let orgSrchX = /(google|bing|yahoo|duckduckgo|presearch)\.com/
    let orgSocX = /(facebook|youtube|instagram|twitter|pinterest|snapchat|tiktok)/
    if (
      orgSrchX.test(document.referrer) ||
      document.referrer.indexOf("amp=") > -1 ||
      document.referrer.indexOf("ampproject") > -1
    ) {
      campaign = "CMP-01032-Q0V1H0"
      medium = "organic_search"
      /// if Medium is organic search, trim referrer src values down to domain
      if (/google|amp=|ampproject/i.test(document.referrer)) {
        source = "google"
      } else if (/yahoo/i.test(document.referrer)) {
        source = "yahoo"
      } else if (/bing/i.test(document.referrer)) {
        source = "bing"
      } else if (/duck/i.test(document.referrer)) {
        source = "duckduckgo"
      } else if (/presearch/i.test(document.referrer)) {
        source = "presearch"
      }
    } else if (orgSocX.test(document.referrer)) {
      campaign = "CMP-01314-Y6C1Q5"
      medium = "social_generic"
      try {
        source = orgSocX.exec(document.referrer)[0]
      } catch (err) {
        source = document.referrer
      }
    } else if (document.referrer.indexOf("info.uti.edu") > 0) {
      campaign = ""
      medium = "email"
      source = "marketo"
    } else if (document.referrer == "") {
      campaign = "CMP-01032-Q0V1H0"
      medium = "direct"
      source = "pmlp"
    } else if (document.referrer.length > 0) {
      campaign = "CMP-01032-Q0V1H0"
      medium = "referring_site"
      source = document.referrer
    }

    /// Overwrite default values if UTMs in query string (Check for Array to prevent error if multiple instances of param exist in querystring)
    campaign =
      querystring.utm_campaign != undefined
        ? querystring.utm_campaign instanceof Array
          ? querystring.utm_campaign[0]
          : querystring.utm_campaign
        : campaign
    medium =
      querystring.utm_medium != undefined
        ? querystring.utm_medium instanceof Array
          ? querystring.utm_medium[0]
          : querystring.utm_medium
        : medium
    source =
      querystring.utm_source != undefined
        ? querystring.utm_source instanceof Array
          ? querystring.utm_source[0]
          : querystring.utm_source
        : source
    content =
      querystring.utm_content != undefined
        ? querystring.utm_content instanceof Array
          ? querystring.utm_content[0]
          : querystring.utm_content
        : ""
    term =
      querystring.utm_term != undefined
        ? querystring.utm_term instanceof Array
          ? querystring.utm_term[0]
          : querystring.utm_term
        : ""
    adid =
      querystring.utm_adid != undefined
        ? querystring.utm_adid instanceof Array
          ? querystring.utm_adid[0]
          : querystring.utm_adid
        : ""
    campaignName =
      querystring.utm_campaignname != undefined
        ? querystring.utm_campaignname instanceof Array
          ? querystring.utm_campaignname[0]
          : querystring.utm_campaignname
        : ""
    adSetName =
      querystring.utm_adsetname != undefined
        ? querystring.utm_adsetname instanceof Array
          ? querystring.utm_adsetname[0]
          : querystring.utm_adsetname
        : ""
    placement =
      querystring.utm_placement != undefined
        ? querystring.utm_placement instanceof Array
          ? querystring.utm_placement[0]
          : querystring.utm_placement
        : ""

    state.formData.utm_campaign = campaign
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_campaign"
    })[0].Value = campaign

    state.formData.utm_medium = medium
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_medium"
    })[0].Value = medium

    state.formData.utm_source = source
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_source"
    })[0].Value = source

    state.formData.utm_content = content
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_content"
    })[0].Value = content

    state.formData.utm_term = term
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_term"
    })[0].Value = term

    state.formData.utm_adid = adid

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_adid"
    })[0].Value = adid

    state.formData.utm_campaignname = campaignName

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_campaignname"
    })[0].Value = campaignName

    state.formData.utm_adsetname = adSetName

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_adsetname"
    })[0].Value = adSetName

    state.formData.utm_placement = placement

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "utm_placement"
    })[0].Value = placement

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "facebookuserid"
    })[0].Value = querystring.fbclid

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "gclid"
    })[0].Value = querystring.gclid

    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "dclid"
    })[0].Value = querystring.dclid
  },
  SET_GACLIENTID_MUTATION: state => {
    //console.log("SET_GACLIENTID_MUTATION()")
    if (document.cookie.match(/_ga=(.+?);/) != null) {
      //console.log("gaid")
      let gaUserId = document.cookie
        .match(/_ga=(.+?);/)[1]
        .split(".")
        .slice(-2)
        .join(".")
      state.formData.dataPayload.AdditionalInfo.filter(item => {
        return item.Key == "googleanalyticsclientid"
      })[0].Value = gaUserId
      // console.log(gaUserId);
    } else {
      //console.log("no gaid")
    }
  },
  async SET_IP_MUTATION(state) {
    let ip = 0

    await axios.get("https://api.ipify.org?format=json").then(response => {
      try {
        ip = response.data.ip
      } catch (err) {
        //
      }
    })

    state.formData.dataPayload.IPAddress = ip
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "IpAddress"
    })[0].Value = ip
  },
  SET_CONTENT_PAGE_FOR_CONVERSION_MUTATION(state) {
    state.formData.ContentPageForConversion =
      document.referrer == "" ? document.location.origin : document.referrer
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "ContentPageForConversion"
    })[0].Value = state.formData.ContentPageForConversion
  },
  SET_PREVIOUS_PAGE_MUTATION(state) {
    state.formData.dataPayload.AdditionalInfo.filter(item => {
      return item.Key == "PreviousPage"
    })[0].Value = document.referrer.substring(0, 399)
  },
  SET_VENDOR_CAPI_MUTATION(state, querystring) {
    //console.log(`SET_VENDOR_CAPI_MUTATION...`)

    if (querystring.utm_medium) {
      let medium =
        querystring.utm_medium == undefined
          ? "direct"
          : querystring.utm_medium instanceof Array
          ? querystring.utm_medium[0]
          : querystring.utm_medium
      medium = medium.split("_")[0].toLowerCase()
      //let medium = querystring.utm_medium.split("_")[0].toLowerCase();
      //let mediumLowerCase = medium[0].toLowerCase()
      let source =
        querystring.utm_source == undefined
          ? "pmlp"
          : querystring.utm_source instanceof Array
          ? querystring.utm_source[0]
          : querystring.utm_source

      if (/facebook|instagram|fbig/.test(medium)) {
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediavendor"
        })[0].Value = "Facebook"
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediaid"
        })[0].Value = querystring.fbclid
      } else if (/snapchat/.test(medium)) {
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediavendor"
        })[0].Value = "Snapchat"
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediaid"
        })[0].Value = querystring.ScCid
      } else if (/tiktok/.test(medium)) {
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediavendor"
        })[0].Value = "TikTok"
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediaid"
        })[0].Value = querystring.ttclid
      } else if (/sem/.test(medium) && /google|bing/.test(source)) {
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediavendor"
        })[0].Value = source
        if (querystring.gclid) {
          state.formData.dataPayload.AdditionalInfo.filter(item => {
            return item.Key == "uti_socialmediaid"
          })[0].Value = querystring.gclid
        } else {
          state.formData.dataPayload.AdditionalInfo.filter(item => {
            return item.Key == "uti_socialmediaid"
          })[0].Value = querystring.dclid
        }
      } else if(querystring.dclid) {
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediavendor"
        })[0].Value = "Google";
        
        state.formData.dataPayload.AdditionalInfo.filter(item => {
          return item.Key == "uti_socialmediaid"
        })[0].Value = querystring.dclid
      }
    }
  }
}

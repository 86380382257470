var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'bottom-nav navbar-container '},[_c('header',[(_vm.data.nestedComponents !== undefined)?_c(_vm.data.nestedComponents.type,{tag:"component",class:['alertNested', _vm.data.nestedComponents.rootClasses],attrs:{"data":_vm.data.nestedComponents.data}}):_vm._e()],1),_c('b-navbar',{class:_vm.data.techSettings.navbarClasses,style:({ backgroundColor: _vm.data.color.background }),attrs:{"fixed":_vm.isSticky}},[_c('b-container',{attrs:{"fluid":_vm.data.techSettings.fullWidth}},[_c('b-row',{staticClass:"mx-0",attrs:{"align-h":_vm.data.techSettings.alignment ? _vm.data.techSettings.alignment : 'between'}},[(_vm.data.logo)?_c('b-col',{staticClass:"logo-container px-0 flex align-items-center",attrs:{"cols":_vm.data.cto != undefined ? 3 : 12}},[_c('img',{class:['ml-0 mr-0 pl-0 pr-0', _vm.data.cto == undefined ? 'fullWidthLogo' : ''],attrs:{"src":_vm.data.logo,"alt":_vm.data.alt || 'This is a logo'}})]):_vm._e(),_c('b-col',{staticClass:"cto-container px-0 my-auto",class:_vm.data.cto.classes,attrs:{"cols":_vm.data.cto.columns ? _vm.data.cto.columns : '7'}},[_c('div',{staticClass:"cto-headline d-none d-md-block",style:({ color: _vm.data.color.headline })},[_vm._v(" "+_vm._s(_vm.data.headline)+" ")]),(_vm.data.ctoVHTML)?_c('div',{class:[_vm.data.vhtmlClasses, 'cto-vhtml'],style:({ color: _vm.data.color.headline }),domProps:{"innerHTML":_vm._s(_vm.data.ctoVHTML)}}):_vm._e(),(_vm.data.phoneNumber)?_c('div',{staticClass:"cto-number"},[(
                _vm.data.cto.mobile &&
                (_vm.activeBreakpoint('xs') || _vm.activeBreakpoint('sm'))
              )?_c('a',{class:{ ctoBorder: _vm.data.cto.borderMobile },style:(Object.assign({}, _vm.data.style,
                {color: _vm.data.color.cto,
                borderColor: _vm.data.color.ctoBorder})),attrs:{"href":'tel:' + _vm.data.phoneNumber.replace(/\D/g, '')},on:{"click":function($event){return _vm.engaged()}}},[_c('b-icon-telephone-fill',{staticClass:"cto-icon mr-1"}),_vm._v(_vm._s(_vm.data.cto.mobile))],1):_c('a',{class:{ ctoBorder: _vm.data.cto.borderDesktop },style:({
                color: _vm.data.color.cto,
                borderColor: _vm.data.color.ctoBorder
              }),attrs:{"href":'tel:' + _vm.data.phoneNumber.replace(/\D/g, '')},on:{"click":function($event){return _vm.engaged()}}},[_c('b-icon-telephone-fill',{staticClass:"cto-icon mr-1"}),_vm._v(_vm._s(_vm.data.cto.desktop))],1)]):_vm._e(),_vm._l((_vm.data.nestedComponents),function(nestedComponent,index){return _c('div',{key:index,class:[nestedComponent.rootClasses ]},[(nestedComponent.data !== null)?_c(nestedComponent.type,{tag:"component",class:['gridColNested', nestedComponent.classes],attrs:{"data":nestedComponent.data}}):_vm._e()],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
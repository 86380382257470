<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    class="moto-icon"
  >
    <path
      d="M18,27.69a1.94,1.94,0,1,0-1.93-1.93A1.94,1.94,0,0,0,18,27.69Zm-.27-1.93a.27.27,0,0,1,.27-.29.28.28,0,0,1,.29.29C18.28,26.08,17.72,26.09,17.72,25.76Z"
    />
    <path d="M15.5,25.75a.83.83,0,1,0-.82.83A.82.82,0,0,0,15.5,25.75Z" />
    <path d="M21.32,26.58a.83.83,0,1,0-.83-.83A.83.83,0,0,0,21.32,26.58Z" />
    <path d="M18,23.26a.83.83,0,1,0-.83-.83A.83.83,0,0,0,18,23.26Z" />
    <path d="M18,28.25a.83.83,0,1,0,.83.83A.83.83,0,0,0,18,28.25Z" />
    <path
      d="M35.61,8l-.26.41h0L35.6,8,25.27,1.78a.93.93,0,0,0-.41-.12.87.87,0,0,0-.74.39L22.83,4.13a.86.86,0,0,0-.1.63.83.83,0,0,0,.37.51l0,0-.54,1-1-.64a3.6,3.6,0,0,0-7.18,0l-1,.64-.54-1,0,0a.79.79,0,0,0,.38-.51.81.81,0,0,0-.09-.64l-1.3-2.08a.82.82,0,0,0-.7-.39.77.77,0,0,0-.43.12L.4,8A.83.83,0,0,0,.12,9.15l1.29,2.09a.83.83,0,0,0,1.11.28L8,21.53v4.22a.79.79,0,0,0,.24.59.82.82,0,0,0,.59.25h.55v4.49l3.26,3.26H23.33l3.25-3.26V26.59h.56a.83.83,0,0,0,.58-.24.82.82,0,0,0,.25-.59V21.53l5.5-10a.82.82,0,0,0,1.11-.28l1.3-2.08A.83.83,0,0,0,35.61,8ZM11.31,4.29,2.39,9.66,2,9,10.9,3.62ZM27.9,18.22l-1.53-1,4.16-7.5,1.53.92ZM13.36,32.68l-2.29-2.29v-3.8h.61a6.37,6.37,0,0,0,12.63,0h.62v3.8l-2.29,2.29Zm.71-18.22L10,7l1.47-.89,4.18,7.34ZM6.89,8.89l1.65-1,4.13,7.44-1.64,1Zm-1.43.86,4.17,7.5-1.54,1L3.94,10.67ZM18,3.87a1.94,1.94,0,1,1-1.93,1.94A1.94,1.94,0,0,1,18,3.87ZM16.58,9.12a3.55,3.55,0,0,0,2.84,0l1.14.72L18,14.31,15.44,9.83Zm4.8-.73L20.82,8a4.17,4.17,0,0,0,.43-.69l.53.34ZM15.18,8l-.56.35-.4-.7.53-.34A4.17,4.17,0,0,0,15.18,8Zm1.25,6.88.32.56L9.17,20.16l-.28-.49ZM13.29,25.76a4.71,4.71,0,1,1,4.7,4.7A4.72,4.72,0,0,1,13.29,25.76ZM18,19.39a6.42,6.42,0,0,0-6.31,5.54h-2V21.79L18,16.66l8.31,5.13v3.14h-2A6.42,6.42,0,0,0,18,19.39Zm1.26-3.91.31-.55,7.54,4.74-.27.49Zm1.14-2,4.18-7.34L26,7l-4.11,7.42Zm2.94,1.85L27.46,7.9l1.65,1L25,16.37ZM34,9l-.41.66L24.69,4.29l.41-.67Z"
    />
  </svg>
</template>

<script>
export default {
  name: "moto"
}
</script>
<style scoped>
.moto-icon {
  font-size: 333%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
</style>

<template>
  <b-container
    :fluid="data.techSettings.fullWidth"
    :class="[
      'alertComponent',
      { '': data.techSettings.padding }, data.techSettings.rootClasses
    ]"
    v-if="data.headline || data.subHeadline || data.body || data.innerHtml"
  >
    <b-alert
      :class="['alertContent mb-0', data.techSettings.textAlignment]"
      show
      :variant="data.techSettings.variant"
      :dismissible="data.techSettings.dismissible"
      :fade="data.techSettings.dismissible"
    >
    <div>
      <strong v-if="data.headline"
        ><p v-html="data.headline" class="alertHeadline my-0"></p
      ></strong>
      <p
        v-if="data.subHeadline"
        v-html="data.subHeadline"
        class="alertSubHeadline"
      ></p>
      <p class="alertBody mb-0" v-if="data.body" v-html="data.body"></p>
    </div>
      

    </b-alert>
  </b-container>
</template>

<script>


export default {
  name: "Alert",
  props: ["data"]
}
</script>

<style lang="scss" scoped>
  .alertComponent {
    &.container-fluid {
      max-width: 100%;
    }

    .alert {
      padding: .75rem;
    }
    .alert-warning {
      color: black;
      background-color: #ffd400;
      border-color: #ffd400;
    }

    .alertHeadline {
      font-size: 14px;
      line-height: 21px;
    }
  }
</style>
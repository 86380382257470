<template>
  <div>
    <b-form-group
      label="Graduation Year"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-input
        type="number"
        name="grad-year"
        placeholder="YYYY"
        v-model="GradYear"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        maxlength="4"
        min="1980"
        max="2050"
        :class="{ isInvalidInput: v.$error }"
        @input="v.$touch()"
        @focus="optEvent('engagedHighSchoolGradYear')"
        ref="GradYear"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: "GradYearInput",
  props: {
    v: {
      type: Object,
      required: true
    }
  },

  value: "",

  computed: {
    GradYear: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
        console.log("emit GradYear event fired")
      }
    }
  },
  methods: {
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  }
}
</script>
<style lang="scss" scoped>
.hasError {
  color: #d50019;
  font-weight: 600;
}
.isInvalidInput {
  border-color: #cc4b37;
  background-color: #f9ecea;
}
</style>

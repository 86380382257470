<template>


  <b-container fluid class="footer-hero px-0">
    <b-row v-if="data.title" class="headline-container mx-0">
      <span class="h2 ml-2">{{ data.title }}</span>
    </b-row>
    <b-row
      class="footer-container"
      :style="'background-image: url(' + data.img + ');'"
    >
      <div class="footer-content">
        <b-row align-v="center" class="mb-3">
       
          <b-col class="fact" sm v-for="(fact, index) in this.getFacts()" :key="index" v-html="fact">
            
          </b-col>
     
        </b-row>
      </div>
    </b-row>
  </b-container>
 


</template>


<script>

export default {
  name: "NumbersFacts",
  props: ["data"],
 
  data(){
    return {

    }
  },
  methods: {
       getFacts() {

         let program = this.$store.getters.PROGRAM;
         let content = this.$store.getters.AOIS;
    
          let programSelected = content.filter(function(content){
            return content.shortCode === program
          })
        return programSelected[0].facts
      }
  },
}
</script>

<style lang="scss" scoped>
.h2 {
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: #003653;
  text-align: center;
}

.footer-hero {
  .headline-container span {
    display: block;
  }
  .footer-container {
    
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: top;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  .footer-content {
    color: #fff;
    font-style: italic;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    font-family: Oswald, sans-serif !important;
    text-transform: uppercase;
    height: unset;
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    .row{
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }

  .fact {
    margin: 5px;
    padding: 3rem 1.5rem;
  }

  .fact::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 10%;
      height: 1px;
      width: 80%;
      background-color: white;
      transform: rotate(180deg);
   
  
  }
  .fact:last-child::after{
    content: none; 
  }
  @media screen and (min-width: 577px){
    .fact::after {
    position: absolute;  
    content: '';
    top: 50%;
    left: 105%;
    height: 148px;
    width: 1px;
    background-color: white;
    transform: translateY(-50%) rotate(8deg); 
  }
  }
  
}
</style>

<template>
  <b-container
    
    :class="[
      'localAlertComponent px-md-0 mx-0 alert-dark',
      { 'uti-component': data.techSettings.padding }, data.techSettings.rootClasses
    ]"
    fluid
    v-if="isIncluded() && (data.headline || data.subHeadline || data.body)"
  >
  <b-container :fluid="data.techSettings.fullWidth" class="px-0">
    <b-alert
      :class="['row alertContent mb-0 mx-0 px-0', data.techSettings.textAlignment]"
      show
      :variant="data.techSettings.variant"
      :dismissible="data.techSettings.dismissible"
      :fade="data.techSettings.dismissible"
      align-h="between"
    >
    <b-col cols="7" md="10" class="pl-0">
      <strong v-if="data.headline"
        ><p v-html="data.headline" class="alertHeadline my-0"></p
      ></strong>
      <p
        v-if="data.subHeadline"
        v-html="data.subHeadline"
        class="alertSubHeadline"
      ></p>
      <p class="alertBody mb-0" v-if="data.body" v-html="data.body"></p>
    </b-col>
    <b-col class="px-0">
      <component v-if="data.nestedComponent" v-bind:is="data.nestedComponent.type"
              :data="data.nestedComponent.data"
            ></component>
    </b-col>
      
    </b-alert>
  </b-container>
    
    
  </b-container>
</template>

<script>
import Modal from "@/components/Content/Modal";

export default {
  name: "Globalalert",
  props: ["data"],
  components: {
    Modal
  },
  methods: {
    isIncluded() {
      const query = this.$route.query.expvar;
      
      return !this.data.techSettings.excludedPages.includes(query);
    },
  }
}
</script>

<style lang="scss" scoped>

.alert {
      padding: .75rem;
    }
    .alert-warning {
      color: black;
      background-color: #ffd400;
      border-color: #ffd400;
    }

    .alert-dark {
      background-color: #25292B;
      color: white;
      border: none;
    }
  .localAlertComponent {
    &.container-fluid {
      max-width: 100%;
    }

    

    .alertHeadline {
      font-size: 14px;
      line-height: 21px;
    }

    .alertBody {
      font-size: 16px;
      line-height: 18px;
    }

    .alertContent {
      align-items: center;
    }

    &.cro-hide {
      display: none;
    }
  }
</style>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    class="weldmask"
  >
    <path
      d="M35.2,6h-.38V2.71A2.29,2.29,0,0,0,32.53.42H21.75a2.29,2.29,0,0,0-2.29,2.29V6h-.39a.79.79,0,0,0-.79.79v3a.79.79,0,0,0,.79.79h.39v3.32a2.29,2.29,0,0,0,2.29,2.29h7a.79.79,0,1,0,0-1.58H21.75a.7.7,0,0,1-.7-.71V2.71a.7.7,0,0,1,.7-.7H32.53a.7.7,0,0,1,.7.7V13.93a.7.7,0,0,1-.7.71.79.79,0,1,0,0,1.58,2.29,2.29,0,0,0,2.3-2.29V10.61h.38a.8.8,0,0,0,.8-.79v-3A.8.8,0,0,0,35.2,6Z"
    />
    <path
      d="M22.39,5.89a.8.8,0,0,0-.8.79V8.92a.79.79,0,0,0,.8.79h9.5a.79.79,0,0,0,.8-.79V3.45a.8.8,0,0,0-.8-.79h-9.5a.8.8,0,1,0,0,1.59h6.94a.85.85,0,0,0-.38.24l-.62.77a.77.77,0,0,0-.26-.44.8.8,0,0,0-.59-.17.78.78,0,0,0-.53.29L24.38,8.12h-1.2V6.68A.8.8,0,0,0,22.39,5.89ZM31.1,4.25V8.12H26.42l.67-.82a.77.77,0,0,0,.76.62.79.79,0,0,0,.62-.3l1.72-2.13a.74.74,0,0,0,.17-.58.79.79,0,0,0-.29-.54,1,1,0,0,0-.23-.12Z"
    />
    <path
      d="M15.66,20.31l-1.79-.52A.79.79,0,0,0,13,20.93a.74.74,0,0,0,.47.38l1.78.53.22,0a.81.81,0,0,0,.77-.57.78.78,0,0,0-.06-.6A.83.83,0,0,0,15.66,20.31Z"
    />
    <path
      d="M12.09,19.14l-1-.26a.83.83,0,0,0-.6.08.77.77,0,0,0-.37.48.81.81,0,0,0,.56,1l1,.25.2,0a.79.79,0,0,0,.77-.59.75.75,0,0,0-.08-.6A.8.8,0,0,0,12.09,19.14Z"
    />
    <path
      d="M9.39,20.47h0a.79.79,0,0,0-.23.56.83.83,0,0,0,.23.57l.43.43a.81.81,0,0,0,1.13,0,.77.77,0,0,0,.23-.56.79.79,0,0,0-.23-.56l-.43-.44A.81.81,0,0,0,9.39,20.47Z"
    />
    <path
      d="M11.94,22.24h0a.75.75,0,0,0-.55.23.76.76,0,0,0-.24.56.79.79,0,0,0,.23.56l.81.82a.79.79,0,0,0,.57.23.8.8,0,0,0,.56-.23.8.8,0,0,0,0-1.12l-.81-.82A.79.79,0,0,0,11.94,22.24Z"
    />
    <path
      d="M12.86,18.13a.76.76,0,0,0,.42.12L14.54,18a.76.76,0,0,0,.48-.36.75.75,0,0,0,.1-.6.79.79,0,0,0-1-.59l-1.07.26a.79.79,0,0,0-.59,1A.81.81,0,0,0,12.86,18.13Z"
    />
    <path
      d="M5.16,13.06h0a.79.79,0,0,0,.39.47.86.86,0,0,0,.38.1l.22,0a.78.78,0,0,0,.47-.38.79.79,0,0,0,.07-.6l-.53-1.79a.8.8,0,0,0-1-.53.77.77,0,0,0-.47.38.81.81,0,0,0-.06.61Z"
    />
    <path
      d="M4.45,16.66l.43.43a.79.79,0,0,0,.56.23A.77.77,0,0,0,6,17.09.8.8,0,0,0,6,16l-.43-.43A.79.79,0,0,0,5,15.31a.75.75,0,0,0-.56.23.79.79,0,0,0-.23.56A.75.75,0,0,0,4.45,16.66Z"
    />
    <path
      d="M6.37,13.82a.81.81,0,0,0-.57,1l.26,1a.79.79,0,0,0,.77.59l.21,0a.79.79,0,0,0,.56-1l-.26-1a.79.79,0,0,0-1-.57Z"
    />
    <path
      d="M2.88,15.09h0a.79.79,0,0,0,.56.23A.77.77,0,0,0,4,15.09a.8.8,0,0,0,.24-.56A.82.82,0,0,0,4,14l-.81-.81a.79.79,0,1,0-1.12,1.12Z"
    />
    <path
      d="M1.48,15.85A.81.81,0,0,0,.4,15.56.84.84,0,0,0,0,16a.76.76,0,0,0,.08.61l10.7,18.54a.83.83,0,0,0,.49.37l.2,0a.79.79,0,0,0,.4-.1.84.84,0,0,0,.37-.48.86.86,0,0,0-.08-.61Z"
    />
    <path
      d="M7.31,20.69a.75.75,0,0,0-.48-.37.77.77,0,0,0-.6.07.8.8,0,0,0-.37.49.75.75,0,0,0,.08.6L13,33.78a.76.76,0,0,0,.48.36l.21,0a.73.73,0,0,0,.39-.1.76.76,0,0,0,.37-.49.75.75,0,0,0-.08-.6Z"
    />
    <path
      d="M8.32,16.47l.2,0a.8.8,0,0,0,.77-.59l.15-.59a.75.75,0,0,0-.08-.6.79.79,0,0,0-.48-.36.77.77,0,0,0-.61.08.79.79,0,0,0-.37.48l-.15.58a.86.86,0,0,0,.08.61A.78.78,0,0,0,8.32,16.47Z"
    />
    <path
      d="M23.73,18.18l-.33.33.33-.33a.8.8,0,0,0-1.13,0l-1,1L16,13.66a3,3,0,0,0-4.15,0L9.22,16.32a.8.8,0,0,0,0,1.12.83.83,0,0,0,.84.17.8.8,0,0,0-.06.55.78.78,0,0,0,.37.48.8.8,0,0,0,.4.11l.79-.18A.75.75,0,0,0,12,18.2a.79.79,0,0,0,.09-.6.83.83,0,0,0-.37-.49h0a.84.84,0,0,0-.6-.08l-.55.15,2.4-2.4a1.38,1.38,0,0,1,1.89,0l5.55,5.55-1,1a.8.8,0,0,0,0,1.12l6.26,6.27a.82.82,0,0,0,1.13,0,.81.81,0,0,0,.23-.57.79.79,0,0,0-.23-.56l-5.7-5.7,2.05-2.05,9.28,9.28a1.45,1.45,0,0,1,.42,1,1.42,1.42,0,0,1-.42,1,1.49,1.49,0,0,1-2.05,0l-.89-.89a.83.83,0,0,0-1.12,0,.79.79,0,0,0-.23.56.77.77,0,0,0,.23.56l.89.89a3,3,0,0,0,2.14.88,3.09,3.09,0,0,0,2.16-.88,3.07,3.07,0,0,0,0-4.31Z"
    />
    <path
      d="M8.84,14,9,14a.8.8,0,0,0,.78-.61l.25-1.06a.78.78,0,0,0-.09-.6.8.8,0,0,0-.5-.36.77.77,0,0,0-1,.59L8.25,13A.79.79,0,0,0,8.84,14Z"
    />
  </svg>
</template>
<script>
export default {
  name: "weld_svg"
}
</script>
<style scoped>
.weldmask {
  font-size: 330%;
  height: 1em;
  width: 1em;
  fill: #737373;
}
.stroke {
  font-size: 300%;
  stroke-width: 1%;
}
</style>

<template>
  <div>
    <b-container
    :class="['gridComponent px-0', data.rootContainerClasses]"
    :id="data.rootID"
    fluid>
      <b-container
      :class="[container.classes]"
      :style="container.css"
      v-for="(container, conIndex) in data.containers"
      :key="conIndex"
      :fluid="container.techSettings.fluid"
      :id="data.id"
    >
      <b-row
        v-for="(row, rIndex) in container.rows"
        :key="rIndex"
        :no-gutters="!row.techSettings.noGutters"
        :align-h="row.techSettings.horizontalAlignment"
        :align-v="row.techSettings.verticalAlignment"
        :class="row.classes"
      >
        <b-col
          v-for="(column, cIndex) in row.columns"
          :key="cIndex"
          :class="column.classes"
          :style="column.style"
          :order="column.techSettings.order"
          :cols="column.techSettings.xs"
          :sm="column.techSettings.sm"
          :md="column.techSettings.md"
          :lg="column.techSettings.lg"
          :xl="column.techSettings.xl"
        >
          <div
            v-for="(nestedComponent, nColIndex) in column.nestedComponents"
            v-bind:key="nColIndex"
          >
            <component
              v-if="nestedComponent.data !== null"
              v-bind:is="nestedComponent.type"
              :data="nestedComponent.data"
              :class="['gridColNested', nestedComponent.classes]"
            ></component>
          </div>
        </b-col>
      </b-row>
      </b-container>
    </b-container>
    
  </div>
</template>

<script>
import Grid from "@/components/Layout/Grid"
import VideoWHeader from "@/components/Content/VideoWHeader"
import Video from "@/components/Content/Video"
import BasicList from "@/components/Content/BasicList"
import OrderedList from "@/components/Content/OrderedList"
import UnorderedListIcons from "@/components/Content/UnorderedListIcons"
import UnorderedListFacts from "@/components/Content/UnorderedListFacts"
import SmallTestimonialWithPictures from "@/components/Content/SmallTestimonialWithPictures"
import Alert from "@/components/Content/Alert"
import ImageBlock from "@/components/Content/ImageBlock"
//import WYSIWYG from "@/components/Content/WYSIWYG"
import UpcomingEvent from "@/components/Content/UpcomingEvent"
import GoogleMap from "@/components/Content/GoogleMap"
import GoogleMapiFrame from "@/components/Content/GoogleMapiFrame";
import TwoStepForm from "@/components/Form/TwoStepForm"
import CollapsedForm from "@/components/Form/CollapsedForm"
import SelectForm from "@/components/Form/SelectForm"
import AoiCampusTwoStep from "@/components/Form/AoiCampusTwoStep";
import CampusAoiTwoStep from "@/components/Form/CampusAoiTwoStep";
import ContentBlock from "@/components/Content/ContentBlock";
import GoogleRecaptchaV2 from "@/components/Form/GoogleRecaptchaV2";
import GoogleRecaptchaV3 from "@/components/Form/GoogleRecaptchaV3";
import Tabs from "@/components/Content/Tabs";
import QuoteBlock from "@/components/Content/QuoteBlock";
import AccordionButton from "@/components/Content/AccordionButton";
import IconForm from "@/components/Form/IconForm";
import IconGrid from "@/components/Content/IconGrid";
import Modal from "@/components/Content/Modal";
import LocalAlert from "@/components/Header/LocalAlert";
import Button from "@/components/Content/Button";
import MarketoForm from "@/components/Form/MarketoForm.vue"


export default {
  name: "Grid",
  props: ["data"],
  components: {
    Grid,
    VideoWHeader,
    Video,
    BasicList,
    OrderedList,
    UnorderedListIcons,
    UnorderedListFacts,
    SmallTestimonialWithPictures,
    Alert,
    ImageBlock,
    TwoStepForm,
    CollapsedForm,
    WYSIWYG: () => import("@/components/Content/WYSIWYG"),
    UpcomingEvent,
    AoiCampusTwoStep,
    CampusAoiTwoStep,
    GoogleMap,
    GoogleMapiFrame,
    ContentBlock,
    SelectForm,
    GoogleRecaptchaV2,
    GoogleRecaptchaV3,
    Tabs,
    QuoteBlock,
    AccordionButton,
    IconForm,
    IconGrid,
    Modal,
    LocalAlert,
    Button,
    MarketoForm
  },
  mounted() {
  //  console.log(`mounted: `, this.data)
  }
}
</script>

<style lang="scss" scoped>

//To see grid styles
// .gridComponent {
//   &.container,
//   &.container-fluid {
//     min-height: 100px;
//     background-color: darkgray;
//     padding: 10px;
//     .row {
//       background-color: darkseagreen;
//       border: black 5px solid;
//       min-height: 100px;
//       padding: 10px;
//     }

//     .col {
//       min-height: 100px;
//       border: white 5px dashed;
//       padding: 10px;
//       background-color: darkslateblue;
//       color: white;
//     }
//   }
// }
</style>

export default {
  AOI: [
    {
      name: "Auto Mechanic",
      shortName: "Auto",
      shortCode: "AU",
      // group: "Ground Transportation and Motorsports",
      group: "Universal Technical Institute",
      facts: [
        "<h3>Automotive Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "Automotive program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Automotive Engines Service & Repair",
        "Professional Automotive Service Writing",
        "Automotive Powertrains & Transmissions",
        "Vehicle Steering & Suspension Service & Repair",
        "Vehicle Brake Systems",
        "Vehicle Electronic Systems & Technology",
        "Automotive Climate Control Systems & Repair",
        "Automotive Power/Performance Tuning & Emissions Repair",
        "Hybrid Vehicle System Maintenance"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Auto.png"
    },
    {
      name: "Collision and Auto Body Repair",
      shortName: "Collision",
      shortCode: "CR",
      // group: "Ground Transportation and Motorsports",
      group: "Universal Technical Institute",

      facts: [
        "<h3>Collision and Auto Body Repair Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "Collision and Auto Body Repair program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Get into trade school",
        "Get out in less than 1 year<sup>1</sup>",
        "Get ready for a career as a skilled technician"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Collision.png"
    },
    {
      name: "Diesel/Industrial Technician",
      shortName: "Diesel",
      shortCode: "DI",
      // group: "Ground Transportation and Motorsports",
      group: "Universal Technical Institute",

      facts: [
        "<h3>Diesel/Industrial Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "Diesel/Industrial program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Gasoline & Diesel Engines Service & Repair",
        "Diesel Truck Manual Transmission",
        "Diesel Truck Brake Systems & Chassis",
        "Diesel Truck Steering & Suspension Systems",
        "Automotive Climate Control Systems & Repair",
        "Diesel Hydraulic Systems",
        "Vehicle Electronic Systems & Technology",
        "Diesel Truck Transport Refrigeration",
        "Diesel Truck Preventative Maintenance"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Diesel.png"
    },
    {
      name: "Motorcycle Technician",
      shortName: "Moto",
      shortCode: "MO",
      // group: "Ground Transportation and Motorsports",
      group: "Universal Technical Institute",

      facts: [
        "<h3>Motorcycle Mechanic Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description: "Motorcycle Mechanic program description",
      list: [
        "Engines, Transmissions, and Precision Measurement",
        "Chassis, Suspension, and Final Drive",
        "Electrical Systems",
        "Vehicle Maintenance",
        "Engine Troubleshooting and Noise Diagnosis",
        "Electrical Diagnostics"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Moto-yellow.png"
    },
    {
      name: "NASCAR Pit Crew",
      shortName: "NASCAR",
      shortCode: "NASCAR",
      // group: "Ground Transportation and Motorsports",
      group: "Universal Technical Institute",

      facts: [
        "<h3>NASCAR Technician and Pit Crew Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "NASCAR Technician and Pit Crew program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Get into trade school",
        "Get out in less than 1 year<sup>1</sup>",
        "Get ready for a career as a skilled technician"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-NASCAR.png"
    },
    {
      name: "Marine Mechanic",
      shortName: "Marine",
      shortCode: "MA",
      // group: "Water",
      group: "Universal Technical Institute",

      facts: [
        "<h3>Marine Mechanic Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "Marine Mechanic program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Get into trade school",
        "Get out in less than 1 year<sup>1</sup>",
        "Get ready for a career as a skilled technician"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Marine-yellow.png"
    },
    {
      name: "CNC Technician",
      shortName: "CNC",
      shortCode: "CNC",
      // group: "Advanced Manufacturing",
      group: "Universal Technical Institute",

      facts: [
        "<h3>CNC Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "CNC Technician program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Get into trade school",
        "Get out in less than 1 year<sup>1</sup>",
        "Get ready for a career as a skilled technician"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-CNC.png"
    },
    {
      name: "Industrial Maintenance",
      shortName: "Industrial Maintenance",
      shortCode: "IM",
      // group: "Advanced Manufacturing",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-IMT.png"
    },
    {
      name: "Non-Destructive Testing Technician",
      shortName: "NDT",
      shortCode: "NDT",
      // group: "Advanced Manufacturing",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-NDT.png"
    },
    {
      name: "Robotics and Automation Technician",
      shortName: "Robotics & Automation",
      shortCode: "RA",
      // group: "Advanced Manufacturing",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Robotics.png"
    },
    {
      name: "Welding Technology Training Program",
      shortName: "Welding",
      shortCode: "WELD",
      // group: "Welding",
      group: "Universal Technical Institute",

      facts: [
        "<h3>Welding Technology Training Program Salary information</h3>",
        "<h3>$15m</h3> <p>in scholarships</p>",
        "<p>something new dynamic</p>"
      ],
      description:
        "Welding Technology Training Program program description Each course covers specific aspects of the auto industry, so you’ll have a foundation to build on by the time you graduate. Students have the opportunity to diagnose, maintain and repair domestic and imported automobiles.",
      list: [
        "Get into trade school",
        "Get out in less than 1 year<sup>1</sup>",
        "Get ready for a career as a skilled technician"
      ],
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Welding.png"
    },
    // {
    //     name: "Welding Technology Training Program",
    //     shortName: "",
    //     shortCode: "MWELD",
    //     // group: "MIAT Welding",
    //     group: "MIAT College of Technology"

    // },
    {
      name: "Airframe and Powerplant Tech",
      shortName: "Airframe & Powerplant",
      shortCode: "AP",
      // group: "Aviation",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Airframe_Powerplant.png"
    },
    {
      name: "Aviation Maintenance",
      shortName: "Aviation",
      shortCode: "AV",
      // group: "Aviation",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Aviation.png"
    },
    {
      name: "Energy Technology",
      shortName: "Energy",
      shortCode: "ET",
      // group: "Energy",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Energy.png"
    },
    {
      name: "Wind Turbine",
      shortName: "Wind",
      shortCode: "WP",
      //group: "Energy",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-Wind.png"
    },
    {
      name: "HVACR Technician",
      shortName: "HVACR",
      shortCode: "HVAC",
      // group: "HVAC",
      group: "MIAT College of Technology",
      icon:
        "https://res.cloudinary.com/utidinary/image/upload/f_auto,q_auto/Icon-HVACR.png"
    }
    // {
    //     name: "Undecided",
    //     shortName: "",
    //     shortCode: "U",
    //     group: "I am not sure...",
    //     facts: [
    //         "<h3>Salary information</h3>", "<h3>$15m</h3> <p>in scholarships</p>", "<p>something new dynamic</p>"
    //     ],
    //     description: "Select a program to learn more!",
    //     list: [
    //         "Get into trade school",
    //         "Get out in less than 1 year<sup>1</sup>",
    //         "Get ready for a career as a skilled technician"
    //     ]
    // },
  ],
  campusData: [
    {
      code: "1",
      name: "UTI-Phoenix",
      address: {
        address1: "10695 West Pierce Street",
        address2: "",
        city: "Avondale",
        state: "AZ",
        zip: "85323"
      },
      hours: {
        operation: "M-F | 8am-4:30pm",
        campusTours: "M-F | 9am & 2:30pm"
      },
      phoneNumbers: {
        campus: "6238699644",
        admissions: "8008347308",
        financialAid: "8662471942",
        studentServices: "6238699644",
        careerServices: "6238699644"
      },
      emails: {
        financialAid: "F.A.DeptMMIPhx-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamMMIPhx@uti.edu",
        careerServices: "MMIPhx-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJ9XbqEgc_K4cR3KGf1exmJgk"
      }
    },
    {
      code: "2",
      name: "UTI-Houston",
      address: {
        address1: "721 Lockhaven Drive",
        address2: "",
        city: "Houston",
        state: "TX",
        zip: "77073"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 9:30am & 1:30pm"
      },
      phoneNumbers: {
        campus: "2814436262",
        admissions: "8008347308",
        financialAid: "8662461249",
        studentServices: "8005105072",
        careerServices: "2812147251"
      },
      emails: {
        financialAid: "F.A.DeptHouston-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamHouston@uti.edu",
        careerServices: "Employment_Services_Houston@uti.edu"
      },
      google: {
        placesId: "ChIJo_wxK2bKQIYRUs6b1n1ZDoc"
      }
    },
    {
      code: "3",
      name: "UTI-Lisle",
      address: {
        address1: "2611 Corporate West Drive",
        address2: "",
        city: "Lisle",
        state: "IL",
        zip: "60532"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "Please call to schedule"
      },
      phoneNumbers: {
        campus: "6305292662",
        admissions: "8008347308",
        financialAid: "8662462111",
        studentServices: "8004414248",
        careerServices: "6305292662"
      },
      emails: {
        financialAid: "F.A.DeptLisle-internet@uti.edu",
        studentServices: "StudentServicesSupportTeamLisle@uti.edu",
        careerServices: "lisleemploymentservices@uti.edu"
      },
      google: {
        placesId: "ChIJa87p17hWDogRR1MrjbZwBec"
      }
    },
    {
      code: "4",
      name: "UTI-Rancho Cucamonga",
      address: {
        address1: "9494 Haven Avenue",
        address2: "",
        city: "Rancho Cucamonga",
        state: "CA",
        zip: "91730"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10:30am & 3:30pm"
      },
      phoneNumbers: {
        campus: "9094841929",
        admissions: "8008347308",
        financialAid: "8662462151",
        studentServices: "8886927800",
        careerServices: "9095106440"
      },
      emails: {
        financialAid: "F.A.DeptUTIRanch-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamRancho@uti.edu",
        careerServices: "Rancho-Employment@uti.edu"
      },
      google: {
        placesId: "ChIJ4RcYJrw1w4ARj5RVmZ_HZPU"
      }
    },
    {
      code: "5",
      name: "UTI-Exton",
      address: {
        address1: "750 Pennsylvania Drive",
        address2: "",
        city: "Exton",
        state: "Pennsylvania",
        zip: "19341"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 8:30am & 2pm By Appointment Only"
      },
      phoneNumbers: {
        campus: "6104585595",
        admissions: "8008347308",
        financialAid: "8662463072",
        studentServices: "8778843986",
        careerServices: "6106468654"
      },
      emails: {
        financialAid: "F.A.DeptUTIExton-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamExton@uti.edu",
        careerServices: "UTIExton-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJiY-MdsyKxokRQE8fZo7FSfA"
      }
    },
    {
      code: "6",
      name: "UTI-Sacramento",
      address: {
        address1: "4100 Duckhorn Drive",
        address2: "",
        city: "Sacramento",
        state: "CA",
        zip: "95834"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 1:30pm & 3pm"
      },
      phoneNumbers: {
        campus: "9162639100",
        admissions: "8008347308",
        financialAid: "8662463432",
        studentServices: "8778842254",
        careerServices: "9164736200"
      },
      emails: {
        financialAid: "F.A.DeptSacramento@uticorp.com",
        studentServices: "StudentServicesSupportTeamSacramento@uti.edu",
        careerServices: "Sacramento-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJxzAbwgPWmoARZ0WoM9oH66Q"
      }
    },
    {
      code: "7",
      name: "NTI-Mooresville",
      address: {
        address1: "220 Byers Creek Road",
        address2: "",
        city: "Mooresville",
        state: "NC",
        zip: "28117"
      },
      hours: {
        operation: "M-F | 9am & 2pm",
        campusTours: "M-F | 9am & 2pm"
      },
      phoneNumbers: {
        campus: "7046581950",
        admissions: "8772012597",
        financialAid: "8664162722",
        studentServices: "8663162722",
        careerServices: "7046582146"
      },
      emails: {
        financialAid: "F.A.Deptnascar-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamNTI@uti.edu",
        careerServices: "Nascar-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJR1nrxv1UUYgR4FoXzY1TWmE"
      }
    },
    {
      code: "10",
      name: "MMI-Phoenix",
      address: {
        address1: "10695 West Pierce Street",
        address2: "Suite 200",
        city: "Avondale",
        state: "AZ",
        zip: "85323"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 9am & 2:30pm"
      },
      phoneNumbers: {
        campus: "6238699644",
        admissions: "8008347308",
        financialAid: "8662471942",
        studentServices: "6238699644",
        careerServices: "6238699644"
      },
      emails: {
        financialAid: "F.A.DeptMMIPhx-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamMMIPhx@uti.edu",
        careerServices: "MMIPhx-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJNVbvz11vK4cR6yVrCqPTAJI"
      }
    },
    {
      code: "11",
      name: "MMI-Orlando",
      address: {
        address1: "2202 West Taft Vineland Road",
        address2: "",
        city: "Orlando",
        state: "FL",
        zip: "32837"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10am, 1:30pm & 3pm"
      },
      phoneNumbers: {
        campus: "4072402422",
        admissions: "8008347308",
        financialAid: "8662477995",
        studentServices: "8003429253",
        careerServices: "3212819740"
      },
      emails: {
        financialAid: "F.A.DeptMMIOrlando-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamMMI_UTIOrl@uti.edu",
        careerServices: "MMIOrl-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJu07lbep954gRT4rElt1UkQk"
      }
    },
    {
      code: "11",
      name: "Marine-Orlando",
      address: {
        address1: "2202 West Taft Vineland Road",
        address2: "",
        city: "Orlando",
        state: "FL",
        zip: "32837"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10am, 1:30pm & 3pm"
      },
      phoneNumbers: {
        campus: "4072402422",
        admissions: "8772013152",
        financialAid: "8662471928",
        studentServices: "8003429253",
        careerServices: "3212819740"
      },
      emails: {
        financialAid: "F.A.DeptMMIOrlando-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamMMI_UTIORl@uti.edu",
        careerServices: "MMIOrl-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJ8-hQ6f9954gRRs4UcMc2DX8"
      }
    },
    {
      code: "12",
      name: "UTI-Orlando",
      address: {
        address1: "2202 West Taft Vineland Road",
        address2: "",
        city: "Orlando",
        state: "FL",
        zip: "32837"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10am, 1:30pm & 3pm"
      },
      phoneNumbers: {
        campus: "4072402422",
        admissions: "8008347308",
        financialAid: "8662471928",
        studentServices: "8003429253",
        careerServices: "3212819740"
      },
      emails: {
        financialAid: "F.A.DeptMMIOrlando-internet@uticorp.com",
        studentServices: "StudentServicesSupportMMI_UTIORl@uti.edu",
        careerServices: "UTIOrl-EmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJp1l0Qet954gR61vhcofk2hM"
      }
    },
    {
      code: "51",
      name: "UTI-Dallas/Fort Worth",
      address: {
        address1: "5151 Regent Boulevard",
        address2: "",
        city: "Irving",
        state: "TX",
        zip: "75063"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | By Appointment"
      },
      phoneNumbers: {
        campus: "9725052200",
        admissions: "8008347308",
        financialAid: "8778731084",
        studentServices: "8005048786",
        careerServices: "4694422390"
      },
      emails: {
        financialAid: "F.A.DeptDallas-internet@uticorp.com",
        studentServices: "StudentServicesSupportTeamDFW@uti.edu",
        careerServices: "utidallasemploymentteam@uti.edu"
      },
      google: {
        placesId: "ChIJsbjprtArTIYRpZ7WwzRTLwo"
      }
    },
    {
      code: "52",
      name: "UTI-Long Beach",
      address: {
        address1: "4175 East Conant Street",
        address2: "",
        city: " Long Beach",
        state: "CA",
        zip: "90808"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10am & 2pm"
      },
      phoneNumbers: {
        campus: "5625417000",
        admissions: "8008347308",
        financialAid: "8443088840",
        studentServices: "8443088838",
        careerServices: "5625417058"
      },
      emails: {
        financialAid: "UTILongBeachFinancialAid@uti.edu",
        studentServices: "UTILongBeachStudentServices@uti.edu",
        careerServices: "UTILongBeachEmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJqxk6iTgy3YARFKIaECtRl-A"
      }
    },
    {
      code: "53",
      name: "UTI-Bloomfield",
      address: {
        address1: "1515 Broad Street",
        address2: "",
        city: "Bloomfield",
        state: "NJ",
        zip: "07003"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 11am & 2pm"
      },
      phoneNumbers: {
        campus: "8332076077",
        admissions: "9738662200",
        financialAid: "8332076074",
        studentServices: "9738662206",
        housingAssistance: "9738662246",
        careerServices: "9738662233"
      },
      emails: {
        financialAid: "F.A.DeptUTIBloomfield-internet@uti.edu@uti.edu",
        studentServices: "UTIBloomfieldStudentServices@uti.edu",
        housingAssistance: "BloomfieldHousing@uti.edu",
        careerServices: "UTIBloomfieldEmploymentServices@uti.edu"
      },
      google: {
        placesId: "ChIJz5OBDH3_wokRFT7_M0kck50"
      }
    },
    {
      code: "54",
      name: "UTI-Austin",
      address: {
        address1: "301 W. Howard Lane",
        address2: "",
        city: "Austin",
        state: "TX",
        zip: "78753"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 9:30am & 2:30pm"
      },
      phoneNumbers: {
        campus: "7372843100",
        admissions: "7372843194",
        financialAid: "8443380033",
        housingAssistance: "9092229626",
        studentServices: "4694422389",
        careerServices: "4694422389"
      },
      emails: {
        financialAid: "",
        housingAssistance: "",
        studentServices: "supportservicesteamaustin@uti.edu",
        careerServices: "supportservicesteamaustin@uti.edu"
      },
      google: {
        placesId: "ChIJxdkW6Z3PRIYRVR5ZEouYMEM"
      }
    },
    {
      code: "55",
      name: "UTI-Miramar",
      address: {
        address1: "2601 SW 145th Ave",
        address2: "",
        city: "Miramar",
        state: "FL",
        zip: "33027"
      },
      hours: {
        operation: "M-F | 8am-5pm",
        campusTours: "M-F | 10am & 2pm"
      },
      phoneNumbers: {
        campus: "7549465595",
        admissions: "8008347308",
        financialAid: "8443380033",
        housingAssistance: "4072325259",
        studentServices: "8666096928",
        careerServices: "8666096928"
      },
      emails: {
        financialAid: "",
        studentServices: "SupportServicesTeamMiramar@uti.edu",
        careerServices: "SupportServicesTeamMiramar@uti.edu"
      },
      google: {
        placesId: "ChIJBfnURfql2YgReTBlywCbV3I"
      }
    }
  ],
  countryData: [
    { code: "", country: "-- Select --" },
    { code: "US", country: "United States" },
    { code: "AF", country: "Afghanistan" },
    { code: "AL", country: "Albania" },
    { code: "DZ", country: "Algeria" },
    { code: "AS", country: "American Samoa" },
    { code: "AD", country: "Andorre" },
    { code: "AO", country: "Angola" },
    { code: "AI", country: "Anguilla" },
    { code: "AQ", country: "Antarctica" },
    { code: "AG", country: "Antigua And Barbuda" },
    { code: "AR", country: "Argentina" },
    { code: "AM", country: "Armenia" },
    { code: "AW", country: "Aruba" },
    { code: "AU", country: "Australia" },
    { code: "AZ", country: "Azerbaijan" },
    { code: "BS", country: "Bahamas" },
    { code: "BH", country: "Bahrain" },
    { code: "BD", country: "Bangladesh" },
    { code: "BB", country: "Barbados" },
    { code: "BY", country: "Belarus" },
    { code: "BZ", country: "Belize" },
    { code: "BJ", country: "Benin" },
    { code: "BM", country: "Bermuda" },
    { code: "BT", country: "Bhutan" },
    { code: "BO", country: "Bolivia" },
    { code: "BA", country: "Bosnia And Herzegovina" },
    { code: "BW", country: "Botswana" },
    { code: "BV", country: "Bouvet Island" },
    { code: "BR", country: "Brazil" },
    { code: "IO", country: "British Indian Ocean Territory" },
    { code: "BN", country: "Brunei Darussalam" },
    { code: "BF", country: "Burkina Faso" },
    { code: "BI", country: "Burundi" },
    { code: "KH", country: "Cambodia" },
    { code: "CM", country: "Cameroon" },
    { code: "CA", country: "Canada" },
    { code: "CV", country: "Cape Verde" },
    { code: "KY", country: "Cayman Islands" },
    { code: "CF", country: "Central African Republic" },
    { code: "TD", country: "Chad" },
    { code: "CL", country: "Chile" },
    { code: "CN", country: "China" },
    { code: "CX", country: "Christmas Island" },
    { code: "CC", country: "Cocos (Keeling) Islands" },
    { code: "CO", country: "Colombia" },
    { code: "KM", country: "Comoros" },
    { code: "CG", country: "Congo" },
    { code: "CD", country: "Congo, The Democratic Republic Of The" },
    { code: "CK", country: "Cook Islands" },
    { code: "CR", country: "Costa Rica" },
    { code: "CI", country: "Cote D'Ivoire" },
    { code: "CU", country: "Cuba" },
    { code: "DJ", country: "Djibouti" },
    { code: "DM", country: "Dominica" },
    { code: "DO", country: "Dominican Republic" },
    { code: "TP", country: "East Timor" },
    { code: "EC", country: "Ecuador" },
    { code: "EG", country: "Egypt" },
    { code: "SV", country: "El Salvador" },
    { code: "GQ", country: "Equatorial Guinea" },
    { code: "ER", country: "Eritrea" },
    { code: "ET", country: "Ethiopia" },
    { code: "FK", country: "Falkland Islands (Malvinas)" },
    { code: "FO", country: "Faroe Islands" },
    { code: "FJ", country: "Fiji" },
    { code: "GF", country: "French Guiana" },
    { code: "PF", country: "French Polynesia" },
    { code: "TF", country: "French Southern Territories" },
    { code: "GA", country: "Gabon" },
    { code: "GM", country: "Gambia" },
    { code: "GE", country: "Georgia" },
    { code: "GH", country: "Ghana" },
    { code: "GI", country: "Gibraltar" },
    { code: "GL", country: "Greenland" },
    { code: "GD", country: "Grenada" },
    { code: "GP", country: "Guadeloupe" },
    { code: "GU", country: "Guam" },
    { code: "GT", country: "Guatemala" },
    { code: "GN", country: "Guinea" },
    { code: "GW", country: "Guinea-Bissau" },
    { code: "GY", country: "Guyana" },
    { code: "HT", country: "Haiti" },
    { code: "HM", country: "Heard Island And Mcdonald Islands" },
    { code: "VA", country: "Holy See (Vatican City State)" },
    { code: "HN", country: "Honduras" },
    { code: "HK", country: "Hong Kong" },
    { code: "IS", country: "Iceland" },
    { code: "IN", country: "India" },
    { code: "ID", country: "Indonesia" },
    { code: "IR", country: "Iran, Islamic Republic Of" },
    { code: "IQ", country: "Iraq" },
    { code: "IL", country: "Israel" },
    { code: "JM", country: "Jamaica" },
    { code: "JP", country: "Japan" },
    { code: "JO", country: "Jordan" },
    { code: "KZ", country: "Kazakstan" },
    { code: "KE", country: "Kenya" },
    { code: "KI", country: "Kiribati" },
    { code: "KP", country: "Korea, Democratic People's Republic Of" },
    { code: "KR", country: "Korea, Republic Of" },
    { code: "KW", country: "Kuwait" },
    { code: "KG", country: "Kyrgyzstan" },
    { code: "LA", country: "Lao People's Democratic Republic" },
    { code: "LB", country: "Lebanon" },
    { code: "LS", country: "Lesotho" },
    { code: "LR", country: "Liberia" },
    { code: "LY", country: "Libyan Arab Jamahiriya" },
    { code: "LI", country: "Liechtenstein" },
    { code: "MO", country: "Macau" },
    { code: "MK", country: "Macedonia, Former Yugoslav Republic Of" },
    { code: "MG", country: "Madagascar" },
    { code: "MW", country: "Malawi" },
    { code: "MY", country: "Malaysia" },
    { code: "MV", country: "Maldives" },
    { code: "ML", country: "Mali" },
    { code: "MH", country: "Marshall Islands" },
    { code: "MQ", country: "Martinique" },
    { code: "MR", country: "Mauritania" },
    { code: "MU", country: "Mauritius" },
    { code: "YT", country: "Mayotte" },
    { code: "MX", country: "Mexico" },
    { code: "FM", country: "Micronesia, Federated States Of" },
    { code: "MD", country: "Moldova, Republic Of" },
    { code: "MC", country: "Monaco" },
    { code: "MN", country: "Mongolia" },
    { code: "MS", country: "Montserrat" },
    { code: "MA", country: "Morocco" },
    { code: "MZ", country: "Mozambique" },
    { code: "MM", country: "Myanmar" },
    { code: "NA", country: "Namibia" },
    { code: "NR", country: "Nauru" },
    { code: "NP", country: "Nepal" },
    { code: "AN", country: "Netherlands Antilles" },
    { code: "NC", country: "New Caledonia" },
    { code: "NZ", country: "New Zealand" },
    { code: "NI", country: "Nicaragua" },
    { code: "NE", country: "Niger" },
    { code: "NG", country: "Nigeria" },
    { code: "NU", country: "Niue" },
    { code: "NF", country: "Norfolk Island" },
    { code: "MP", country: "Northern Mariana Islands" },
    { code: "NO", country: "Norway" },
    { code: "OM", country: "Oman" },
    { code: "PK", country: "Pakistan" },
    { code: "PW", country: "Palau" },
    { code: "PS", country: "Palestinian Territory, Occupied" },
    { code: "PA", country: "Panama" },
    { code: "PG", country: "Papua New Guinea" },
    { code: "PY", country: "Paraguay" },
    { code: "PE", country: "Peru" },
    { code: "PH", country: "Philippines" },
    { code: "PN", country: "Pitcairn" },
    { code: "PR", country: "Puerto Rico" },
    { code: "QA", country: "Qatar" },
    { code: "RE", country: "Reunion" },
    { code: "RU", country: "Russian Federation" },
    { code: "RW", country: "Rwanda" },
    { code: "SH", country: "Saint Helena" },
    { code: "KN", country: "Saint Kitts And Nevis" },
    { code: "LC", country: "Saint Lucia" },
    { code: "PM", country: "Saint Pierre And Miquelon" },
    { code: "VC", country: "Saint Vincent And The Grenadines" },
    { code: "WS", country: "Samoa" },
    { code: "SM", country: "San Marino" },
    { code: "ST", country: "Sao Tome And Principe" },
    { code: "SA", country: "Saudi Arabia" },
    { code: "SN", country: "Senegal" },
    { code: "SC", country: "Seychelles" },
    { code: "SL", country: "Sierra Leone" },
    { code: "SG", country: "Singapore" },
    { code: "SB", country: "Solomon Islands" },
    { code: "SO", country: "Somalia" },
    { code: "ZA", country: "South Africa" },
    { code: "GS", country: "South Georgia And The South Sandwich Isl" },
    { code: "LK", country: "Sri Lanka" },
    { code: "SD", country: "Sudan" },
    { code: "SR", country: "Suriname" },
    { code: "SJ", country: "Svalbard And Jan Mayen" },
    { code: "SZ", country: "Swaziland" },
    { code: "CH", country: "Switzerland" },
    { code: "SY", country: "Syrian Arab Republic" },
    { code: "TW", country: "Taiwan, Province Of China" },
    { code: "TJ", country: "Tajikistan" },
    { code: "TZ", country: "Tanzania, United Republic Of" },
    { code: "TH", country: "Thailand" },
    { code: "TG", country: "Togo" },
    { code: "TK", country: "Tokelau" },
    { code: "TO", country: "Tonga" },
    { code: "TT", country: "Trinidad And Tobago" },
    { code: "TN", country: "Tunisia" },
    { code: "TR", country: "Turkey" },
    { code: "TM", country: "Turkmenistan" },
    { code: "TC", country: "Turks And Caicos Islands" },
    { code: "TV", country: "Tuvalu" },
    { code: "UG", country: "Uganda" },
    { code: "UA", country: "Ukraine" },
    { code: "AE", country: "United Arab Emirates" },
    { code: "UM", country: "United States Minor Outlying Islands" },
    { code: "UY", country: "Uruguay" },
    { code: "UZ", country: "Uzbekistan" },
    { code: "VU", country: "Vanuatu" },
    { code: "VE", country: "Venezuela" },
    { code: "VN", country: "Vietnam" },
    { code: "VG", country: "Virgin Islands, British" },
    { code: "VI", country: "Virgin Islands, U.S." },
    { code: "WF", country: "Wallis And Futuna" },
    { code: "EH", country: "Western Sahara" },
    { code: "YE", country: "Yemen" },
    { code: "YU", country: "Yugoslavia" },
    { code: "ZM", country: "Zambia" },
    { code: "ZW", country: "Zimbabwe" }
  ],
  diplomaData: [
    {
      option: "High School Diploma",
      value: "HS Grad"
    },
    {
      option: "GED",
      value: "GED"
    },
    {
      option: "Home School",
      value: "Home School"
    },
    {
      option: "None, I'm in High School",
      value: "HS Grad"
    },
    {
      option: "None, I'm getting my GED",
      value: "GED"
    },
    {
      option: "None, I'm being Home Schooled",
      value: "Home School"
    }
  ],
  hiddenAOIByCampaignData: [],
  visibleAOIbyCampaignData: [
    {
      campaign: "CMP-01268-T2F4C0",
      aois: ["CNC"]
    },
    {
      campaign: "CMP-01029-B1V2F7",
      aois: ["CR", "WELD"]
    },
    {
      campaign: "CMP-01283-Q4Y2K1",
      aois: ["CR", "WELD"]
    },
    {
      campaign: "CMP-01031-G8P1F4",
      aois: ["CR", "WELD"]
    },
    {
      campaign: "CMP-01027-J1R9Y5",
      aois: ["WELD"]
    },
    {
      campaign: "CMP-01266-Y8C1F3",
      aois: ["WELD"]
    },
    {
      campaign: "CMP-01267-F5S2L8",
      aois: ["WELD"]
    },
    {
      campaign: "CMP-01027-J1R9Y5",
      aois: ["WELD"]
    },
    {
      campaign: "CMP-01311-D6D4B1",
      aois: ["WELD"]
    }
  ]
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    class="auto-icon"
  >
    <rect y="18.32" width="3.25" height="1.69" rx="0.49" />
    <rect x="32.75" y="18.32" width="3.25" height="1.69" rx="0.49" />
    <path
      d="M5.05,26.23a.49.49,0,0,0-.67-.18l-2,1.14a.49.49,0,0,0-.18.67l.36.62a.47.47,0,0,0,.42.24.57.57,0,0,0,.25-.06l2-1.14a.49.49,0,0,0,.18-.66Z"
    />
    <path
      d="M30.54,11.11a.52.52,0,0,0,.05.37l.36.63a.5.5,0,0,0,.42.24.45.45,0,0,0,.25-.07l2-1.13a.49.49,0,0,0,.18-.67l-.36-.62a.49.49,0,0,0-.67-.18l-2,1.14A.51.51,0,0,0,30.54,11.11Z"
    />
    <path
      d="M2.41,11.15l2,1.14a.54.54,0,0,0,.25.06.5.5,0,0,0,.42-.24l.36-.63a.5.5,0,0,0,0-.36.53.53,0,0,0-.23-.3l-2-1.14a.5.5,0,0,0-.67.18l-.36.62a.49.49,0,0,0,.18.67Z"
    />
    <path
      d="M33.59,27.19l-2-1.14a.51.51,0,0,0-.67.18l-.36.63a.49.49,0,0,0,.18.66l2,1.14a.55.55,0,0,0,.25.07.49.49,0,0,0,.42-.25l.36-.62a.49.49,0,0,0-.18-.67Z"
    />
    <path
      d="M9.65,6.4a.51.51,0,0,0,.29.23h.13a.48.48,0,0,0,.24-.06l.63-.36a.5.5,0,0,0,.18-.67L10,3.58a.53.53,0,0,0-.3-.23.52.52,0,0,0-.37,0l-.62.36a.49.49,0,0,0-.18.66Z"
    />
    <rect x="17.15" y="1.17" width="1.69" height="3.25" rx="0.49" />
    <path
      d="M25.06,6.22l.62.36a.54.54,0,0,0,.25.06h.12a.53.53,0,0,0,.3-.23l1.14-2a.49.49,0,0,0-.18-.66l-.62-.36a.49.49,0,0,0-.67.18l-1.14,2A.5.5,0,0,0,25.06,6.22Z"
    />
    <path
      d="M.86,15.45l1.45.39.13,0a.5.5,0,0,0,.47-.36l.18-.7a.46.46,0,0,0-.34-.59L1.3,13.82a.49.49,0,0,0-.59.34l-.19.7a.48.48,0,0,0,0,.37A.46.46,0,0,0,.86,15.45Z"
    />
    <path
      d="M35.13,22.88l-1.44-.38a.49.49,0,0,0-.6.34l-.19.7a.53.53,0,0,0,.05.37.48.48,0,0,0,.3.22l1.45.39.12,0a.49.49,0,0,0,.47-.36l.19-.7a.53.53,0,0,0,0-.37A.5.5,0,0,0,35.13,22.88Z"
    />
    <path
      d="M6.08,8.44a.45.45,0,0,0,.34.15.49.49,0,0,0,.35-.15l.5-.51a.48.48,0,0,0,0-.68L6.21,6.19a.46.46,0,0,0-.68,0L5,6.7a.48.48,0,0,0,0,.68Z"
    />
    <path
      d="M13,3.92a.48.48,0,0,0,.47.36h.12l.7-.19a.51.51,0,0,0,.29-.23.44.44,0,0,0,.05-.37L14.28,2a.48.48,0,0,0-.59-.34l-.7.19a.46.46,0,0,0-.29.22.44.44,0,0,0,0,.37Z"
    />
    <path
      d="M21.67,4.08l.7.19h.12a.54.54,0,0,0,.25-.06.48.48,0,0,0,.22-.3l.39-1.45A.48.48,0,0,0,23,1.88l-.7-.19a.48.48,0,0,0-.37.05.51.51,0,0,0-.23.29l-.38,1.45A.49.49,0,0,0,21.67,4.08Z"
    />
    <path
      d="M29.23,8.44a.49.49,0,0,0,.35.15.46.46,0,0,0,.34-.15L31,7.38a.48.48,0,0,0,0-.68l-.51-.51a.49.49,0,0,0-.69,0L28.72,7.25a.48.48,0,0,0,0,.68Z"
    />
    <path
      d="M32.9,14.8l.19.7a.5.5,0,0,0,.47.36l.13,0,1.44-.39a.46.46,0,0,0,.3-.22.53.53,0,0,0,0-.37l-.19-.7a.46.46,0,0,0-.22-.29.49.49,0,0,0-.37,0l-1.45.39A.48.48,0,0,0,32.9,14.8Z"
    />
    <path
      d="M3.05,23.91a.48.48,0,0,0,0-.37l-.18-.7a.49.49,0,0,0-.6-.34l-1.45.38a.51.51,0,0,0-.29.23.48.48,0,0,0,0,.37l.19.7a.48.48,0,0,0,.47.36l.12,0,1.45-.39A.48.48,0,0,0,3.05,23.91Z"
    />
    <path
      d="M30.9,22.51A13.32,13.32,0,1,0,6.47,25.84a.8.8,0,0,0,.52.37.62.62,0,0,0,.19,0,.89.89,0,0,0,.45-.12A.86.86,0,0,0,7.92,25a11.63,11.63,0,1,1,21.34-2.89.89.89,0,0,0,.09.64.86.86,0,0,0,.52.39.85.85,0,0,0,1-.6Z"
    />
    <path
      d="M20.89,17.75s0,0-.07,0a3.18,3.18,0,0,0-5.85.47,3.16,3.16,0,0,0,2.82,4.14L28.62,26.2a.65.65,0,0,0,.25.05h.07a.8.8,0,0,0,.57-.24.85.85,0,0,0-.06-1.26ZM18,20.66a1.49,1.49,0,1,1,1.49-1.49A1.49,1.49,0,0,1,18,20.66Zm2.25.75a3.2,3.2,0,0,0,.8-1.35L24.6,23Z"
    />
    <path
      d="M21.12,26.9H14.88a4,4,0,0,0,0,7.93h6.24a4,4,0,0,0,0-7.93Zm0,6.24H14.88a2.28,2.28,0,0,1,0-4.55h6.24a2.28,2.28,0,0,1,0,4.55Z"
    />
    <path d="M16.44,30H14.88a.85.85,0,1,0,0,1.69h1.56a.85.85,0,1,0,0-1.69Z" />
    <path d="M21.12,30H19.56a.85.85,0,1,0,0,1.69h1.56a.85.85,0,1,0,0-1.69Z" />
  </svg>
</template>

<script>
export default {
  name: "autoIcon"
}
</script>

<style scoped>
.auto-icon {
  font-size: 315%;
  height: 1em;
  width: 1em;
  fill: #737373;
}

</style>

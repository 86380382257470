<template>
  <div>
    <b-form-group
      label="Military Release Date"
      label-class="text-left"
      :class="{ hasError: v.$error }"
    >
      <b-form-input
        name="release-date"
        placeholder="MM/YYYY"
        v-model="MilitarySeparation"
        :class="{ isInvalidInput: v.$error }"
        :formatter="formatter"
        @focus="optEvent('engagedMilitaryReleaseDate')"
        ref="MilitarySeparation"
        minlength="7"
        maxlength="7"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "MilitaryReleaseDateInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatter: function (value) {
      console.log(value)
      let number = value.replace(/\D/g, "")
      if (value.length <= 2) {
        return value
      }
      if (value.length >= 3) {
        return number.substring(0, 2) + "/" + number.substring(2)
      }

      //regex that verfies the correct format is ^((0[1-9])|(1[0-2]))\/(\d{4})
      //How to replace the input value with this format
      // var lastChar = $(this).val().substring($(this).val().length - 1);
      //           if (lastChar != " " && lastChar != "-") { lastChar = ""; }
      //           var number = $(this).val().replace(/\D/g, '');
      //           if (number.length === 0) $(this).val('');
      //           //if (number.length > 1 && number.length < 4) $(this).val(number.substring(0, 2) + '-' + number.substring(2));
      //           if (number.length >= 3) {
      //               $(this).val(number.substring(0, 2) + '-' + number.substring(2));
      //           } else {
      //               $(this).val(number + lastChar);
      //           }

      //return value.replace(/^(\d{3})(\d{3})(\d)/, "($1)$2-$3");
      //"($1)$2-$3");
      ///^(\d{3})(\d{3})(\d)/
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event)
    }
  },
  computed: {
    MilitarySeparation: {
      get() {
        return this.value
      },
      set(value) {
        setTimeout(this.v.$touch, 3000)
        this.$emit("input", value)
        console.log("emit MilitarySeparation event fired")
      }
    }
  }
}
</script>

<template>
  <div>
    <b-form-group id="fieldset-6" label="Phone Number" label-for="input-6" label-class="text-left" :class="{ hasError: v.$error }">
      <b-form-input
        class="phonenumberinput"
        type="tel"
        name="phone-number"
        placeholder="(123) 555-6789"
        v-model="PhoneNumber"
        :formatter="formatter"
        maxlength="14"
        @blur="v.$touch()"
        :class="{ isInvalidInput: v.$error }"
        ref="PhoneNumber"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "PhoneNumberInput",
  props: ["Country", "v"],
  data() {
    return {
      selected: null,
      selectedCountry: this.Country
    };
  },
  computed: {
    PhoneNumber: {
      get() {
        return this.value;
      },
      set(value) {
        //this.v.$touch();
        this.$emit("input", value);
      }
    }
  },
  methods: {
    formatter: function (value) {
      //return value.replace(/[\D\._\-\(\)]+/g, "");
      return value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    },
    optEvent(event) {
      this.$store.dispatch("SET_OPTIMIZELY_ACTION", event);
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.85rem;
}
.phonenumberinput {
  width: 65%;
}
</style>
